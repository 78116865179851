import React, { useEffect, useState } from 'react';
import { ReactSession } from 'react-client-session';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import Navbar from "./navbar";
import { Paginator } from 'primereact/paginator';
import Header from "./header";
import './box.css';
import * as XLSX from 'xlsx';
ReactSession.setStoreType("localStorage");

function Hardwarereport() {
    const userId = ReactSession.get("upvcuserId");
    const userType = ReactSession.get("upvcuserType");
    const Empid = ReactSession.get("upvcempid");
    const [quoteData, setData] = useState('');
    const [Invoicedata, setInvoicedata] = useState('');
    const [filteredquotee, setfilteredquotee] = useState('');
    const [matchedQuotes, setMatchedQuotes] = useState([]);
   // console.log(matchedQuotes);
    const [first, setFirst] = useState(0); 
    const [rows, setRows] = useState(15);
    const [button, setButton] = useState(false); 

   // console.log(Invoicedata);


    // Event handlers

    let users = '';
    if (userType === '3') {
        users = Empid;
    } else {
        users = userId;
    }
    const user = { "userId": users };
  //  console.log(users);


    const navigate = useNavigate();
    const indexPath = '/';


    useEffect(() => {
        if (userId === 0) {
            navigate(indexPath);
        }
    }, [userId, navigate]);


    const InvoiceData = async () => {

        try {
            const response = await axios.get('https://upvcapi.sninfoserv.com/invoice/', {
                params: { user: userId }
            });
         //   console.log("invoice", response.data);
            setInvoicedata(response.data);

        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };




    useEffect(() => {
        InvoiceData();

    }, []);


    // useEffect(() => {
    //   const matches = []; // Array to hold all matched quotes

    //   Invoicedata.data.forEach(invoice => {
    //     const invoiceQuoteID = invoice.quoteID;
    //     const matchedQuote = quoteData.data.find(quote => quote.quoteId === invoiceQuoteID);

    //     if (matchedQuote) {
    //       console.log('Matched Quote for Invoice ID:', invoice.invoice_id, '->', matchedQuote);
    //       matches.push({ invoiceId: invoice.invoice_id, matchedQuote });
    //     } else {
    //       console.log('No matching quote found for Invoice ID:', invoice.invoice_id);
    //     }
    //   });

    //   setMatchedQuotes(matches); // Store all matched quotes in state

    // }, []);

    useEffect(() => {
        if (!Invoicedata || !Invoicedata.data || !quoteData || !quoteData.data) {
            console.error("Invoicedata or quoteData is undefined.");
            return; // Exit early if the data is not available
        }

        const matches = []; // Array to hold all matched quotes

        Invoicedata.data.forEach(invoice => {
            const invoiceQuoteID = invoice.quoteID;
            const matchedQuotes = quoteData.data.filter(quote => quote.quoteId === invoiceQuoteID); // Use filter to find all matches

            if (matchedQuotes.length > 0) {
                matchedQuotes.forEach(matchedQuote => {
                   // console.log('Matched Quote for Invoice ID:', invoice.invoice_id, '->', matchedQuote);
                    matches.push({ invoiceId: invoice.invoice_id, matchedQuote });
                });
            } else {
              //  console.log('No matching quote found for Invoice ID:', invoice.invoice_id);
            }
        });

        setMatchedQuotes(matches); // Store all matched quotes in state
    }, [Invoicedata, quoteData]); // Add dependencies to re-run effect when data changes




    const fetchData = async () => {
        try {
            const response = await axios.post('https://upvcapi.sninfoserv.com/all-quotes/', user);
            setData(response.data);
          //  console.log(response.data);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };




    useEffect(() => {
        fetchData();
        const filteredQuotes = quoteData && quoteData.data.filter(quote => quote.quoteId === Invoicedata);
        setfilteredquotee(filteredQuotes);
       // console.log(filteredQuotes);
    }, []);




    const total = (quoteId) => {
        // Filter quotes based on the provided quoteId
        const filteredQuotes = quoteData && quoteData.data.filter(quote => quote.quoteId === quoteId);
       // console.log(filteredQuotes);

        let total = 0;

        // Calculate the total from the filtered quotes
        filteredQuotes.forEach(quote => {
            if (quote && quote.quoteDetails) {
                quote.quoteDetails.forEach(detail => {
                    if (detail.quoteData) {
                        total += (detail.quoteData.grandTotal * detail.quoteData.qty);
                     //   console.log(detail.quoteData.qty);
                    }
                });
            }
        });



        return (total);

    };

    const grandtotal = (quoteId) => {
        const filteredQuotes = quoteData && quoteData.data.filter(quote => quote.quoteId === quoteId);
       // console.log(filteredQuotes);


        let total = 0;

        // Calculate the total from the filtered quotes
        filteredQuotes.forEach(quote => {
            if (quote && quote.quoteDetails) {
                quote.quoteDetails.forEach(detail => {
                    if (detail.quoteData) {
                        total += (detail.quoteData.grandTotal * detail.quoteData.qty);

                      //  console.log(detail.quoteData.qty);
                    }
                });
            }
        });
        const gst = (18 / 100) * total; // Calculate GST based on the total
        let grandtotal = total + gst; // Total including GST

        return (grandtotal);



    };


    
  
  const handleDownloadExcel = () => {
    setFirst(0);
    setRows(filteredDatas.length);
    setButton(true);
    setTimeout(() => {
      downloadExcel();
    }, 1000);
  };

  const downloadExcel = () => {
  
    const input = document.getElementById('order'); 
    const table = input.querySelector('table');
    if (!table) {
      console.error('No table found inside the specified element');
      return;
    }
  
    const ws = XLSX.utils.table_to_sheet(table);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, `Hardware_report.xlsx`);

    setFirst(0);
    setRows(15);
    setButton(false);
  };

    const [filterId, setFilterId] = useState('');
    const [filterName, setFilterName] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  
  const filteredDatas = matchedQuotes?.filter(match => {
    const itemDate = new Date(match.matchedQuote.date.split('-').reverse().join('-')); // Convert 'dd-mm-yyyy' to 'yyyy-mm-dd'
   
    const startDateObj = startDate ? new Date(startDate) : null;
    if (startDateObj) {
      startDateObj.setHours(0, 0, 0, 0); 
    }
  
    const endDateObj = endDate ? new Date(endDate) : null;
    if (endDateObj) {
      endDateObj.setHours(23, 59, 59, 999); 
    }
  
    
    return (
        (filterId ? match.matchedQuote.cusQuoteId.toString().includes(filterId) : true) &&
        (filterName ? 
            match.matchedQuote && match.matchedQuote.quoteDetails.some(detail => 
                detail.quoteData.item.toLowerCase().includes(filterName.toLowerCase())
            ) : true) &&
            (startDateObj ? itemDate >= startDateObj : true) && 
            (endDateObj ? itemDate <= endDateObj : true) 
    );
});



 // console.log(filteredDatas);

  let totalCollected = 0;
 
  let totalItems = 0;
  filteredDatas?.slice().reverse().map(match => {
    totalCollected += Number(grandtotal(match.matchedQuote.quoteId));
  
    totalItems +=match.matchedQuote.quoteDetails.length;
  })


  const onPageChange = (event) => {
    setFirst(event.first);
    setRows(15);
  };
    


    return (
        <>
            <Navbar />

            <div class="pc-container">
                <div class="pc-content">

                    <div class="row">







                        <div class="col-md-12 col-xxl-12 col-xl-12">
                        <h5>Hardware Report</h5>
                           <div  style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <button onClick={handleDownloadExcel}  className={`btn ${button === false ? 'btn-primary' : 'btn-secondary'}`} > <i class="fa fa-download me-2"></i> {button === false ? 'Download' : 'Downloading'}</button>
                             </div>
                            <div class="card mt-2">
                                <div class="card-body">
                                    <div className="row mb-3" >
                                    <div className="col-lg-3 ml-2 mt-5">
                      <input
                        type="date"
                        className="form-control"
                        placeholder="Search by start date..."
                      onChange={(e) => setStartDate(e.target.value)}
                      />
                    </div>
                    <div className="col-lg-3 ml-2 mt-5">
                      <input
                        type="date"
                        className="form-control"
                        placeholder="Search by End date..."
                       
                       onChange={(e) => setEndDate(e.target.value)}
                      />
                    </div>
                    <div className="col-lg-3 ml-2 mt-5">
                      <input
                        type="Number"
                        className="form-control"
                        placeholder="Search by quoteID..."
                       onChange={(e) => setFilterId(e.target.value)}
                      />
                    </div>

                    <div className="col-lg-3 ml-2 mt-5">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search by Item..."
                       onChange={(e) => setFilterName(e.target.value)}
                      />
                    </div>
                  </div>
                                <div className="table-responsive text-nowrap mt-2" id="order">
                                    <table id="left-right-fix" class="table stripe row-border order-column mt-3">

                                        <thead>
                                            <tr>
                                           
                                                <th>Date</th>
                                                <th>QuoteID</th>
                                                <th>Items</th>
                                                <th>Hardware cost</th>
                                                <th>profile cost</th>
                                                <th>margin cost</th>
                                                <th>Production cost</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {/* {filteredData.length > 0 ? (
            <>  {filteredData.map(client => (

              <tr key={client.clientId}>
                  <td>{client.clientName}</td>
                  <td>{client.clientPhone}</td>
                  <td class="text-wrap">{client.clientLocation}</td>
                  <td><a href={`clientsdetails?id=${client.clientPhone}`} class="btn btn-primary">View</a></td>
                  <td>
                    <a onClick={() => removeclient(client.clientId)} ><i class="btn btn-danger ti ti-trash"></i></a>
                   </td>
                </tr>
                ))} </>
                                           ) : searchQuery && filteredData.length === 0 ? (
                                         <tr >
                                                      <td>No Data Found</td>
                                                    </tr>
                                      ) : (<> */}    
                                     {filteredDatas?.length > 0 ? (
                                 filteredDatas.slice().reverse().slice(first, first + rows).map((match, matchIndex) => (
                                     match.matchedQuote.quoteDetails?.map((quoteDetail, detailIndex) => (
                                         quoteDetail.quoteData.item.toLowerCase().includes(filterName.toLowerCase()) && ( // Only render if it matches
                                             <tr key={`${matchIndex}-${detailIndex}`}>
                                                 {/* <td>{matchIndex + 1}</td> */}
                                                 <td>{match.matchedQuote.date}</td>
                                                 <td>{match.matchedQuote.cusQuoteId}</td>
                                                 <td>{quoteDetail.quoteData?.item}</td>
                                                 <td>
    {quoteDetail.quoteData?.data?.frame.amount && `frame: ${quoteDetail.quoteData.data.frame.amount}, `}
    {quoteDetail.quoteData?.data?.shutter.amount && `Shutter: ${quoteDetail.quoteData.data.shutter.amount}, `}
    {quoteDetail.quoteData?.data?.mullion.amount && `mullion: ${quoteDetail.quoteData.data.mullion.amount}, `}
    {quoteDetail.quoteData?.data?.beeding.amount && `beeding: ${quoteDetail.quoteData.data.beeding.amount}, `}
    {quoteDetail.quoteData?.data?.frameGasket && `frameGasket: ${quoteDetail.quoteData.data.frameGasket.amount}, `}
    {quoteDetail.quoteData?.data?.meshGasket && `meshGasket: ${quoteDetail.quoteData.data.meshGasket.amount}, `}
    {quoteDetail.quoteData?.data?.beedingGasket && `beedingGasket: ${quoteDetail.quoteData.data.beedingGasket.amount}, `}
    {quoteDetail.quoteData?.data?.shutterGasket && `shutterGasket: ${quoteDetail.quoteData.data.shutterGasket.amount}, `}
    {quoteDetail.quoteData?.data?.mullionGasket && `mullionGasket: ${quoteDetail.quoteData.data.mullionGasket.amount}, `}
    {quoteDetail.quoteData?.data?.cupler && `cupler: ${quoteDetail.quoteData.data.cupler.amount}, `}
    {quoteDetail.quoteData?.data?.meshFrame && `meshFrame: ${quoteDetail.quoteData.data.meshFrame.amount}, `}
    {quoteDetail.quoteData?.data?.panelCost && `Panel: ${quoteDetail.quoteData.data.panelCost.amount}, `}
    {quoteDetail.quoteData?.data?.interlock && `interlock: ${quoteDetail.quoteData.data.interlock.amount}, `}
    {quoteDetail.quoteData?.data?.wastage && `wastage: ${quoteDetail.quoteData.data.wastage}, `}
    {quoteDetail.quoteData?.data?.Lbeeding && `Louver Beeding: ${quoteDetail.quoteData.data.Lbeeding.amount}, `}
   
</td>

<td>
    {quoteDetail.quoteData?.data?.meshCost && `Mesh: ${quoteDetail.quoteData.data.meshCost}, `}
    {quoteDetail.quoteData?.data?.steelFrame && `SteelFrame: ${quoteDetail.quoteData.data.steelFrame}, `}
    {quoteDetail.quoteData?.data?.steelShutter && `SteelShutter: ${quoteDetail.quoteData.data.steelShutter}, `}
    {quoteDetail.quoteData?.data?.steelMullion && `Steelmullion: ${quoteDetail.quoteData.data.steelMullion}, `}
    {quoteDetail.quoteData?.data?.steelMesh && `Steelmesh: ${quoteDetail.quoteData.data.steelMesh}, `}
    {quoteDetail.quoteData?.data?.mplCost && `MPL: ${quoteDetail.quoteData.data.mplCost}, `}
    {quoteDetail.quoteData?.data?.handleCost && `handle: ${quoteDetail.quoteData.data.handleCost}, `}
    {quoteDetail.quoteData?.data?.hingsCost && `Hinges: ${quoteDetail.quoteData.data.hingsCost}, `}
    {quoteDetail.quoteData?.data?.screwsCost && `Screw: ${quoteDetail.quoteData.data.screwsCost}, `}
    {quoteDetail.quoteData?.data?.grillCost && `Grill: ${quoteDetail.quoteData.data.grillCost}, `}
    {quoteDetail.quoteData?.data?.boardCost && `boardCost: ${quoteDetail.quoteData.data.boardCost}, `}
    {quoteDetail.quoteData?.data?.aluTrack && `alTrack: ${quoteDetail.quoteData.data.aluTrack}, `}
    {quoteDetail.quoteData?.data?.groRoll && `groRoll: ${quoteDetail.quoteData.data.groRoll}, `}
    {quoteDetail.quoteData?.data?.mesRoll && `mesRoll: ${quoteDetail.quoteData.data.mesRoll}, `}
    {quoteDetail.quoteData?.data?.wStrip && `wStrip: ${quoteDetail.quoteData.data.wStrip}, `}
    {quoteDetail.quoteData?.data?.tLock && `tLock: ${quoteDetail.quoteData.data.tLock}, `}
    {quoteDetail.quoteData?.data?.bArrest && `bArrest: ${quoteDetail.quoteData.data.bArrest}, `}
    {quoteDetail.quoteData?.data?.jStop && `jStop: ${quoteDetail.quoteData.data.jStop}, `}
    {quoteDetail.quoteData?.data?.dArrest && `dArrest: ${quoteDetail.quoteData.data.dArrest}, `}
    {quoteDetail.quoteData?.data?.LouverBlades && `LouverBlades: ${quoteDetail.quoteData.data.LouverBlades}`}
</td>

                                                 <td>Margin:{quoteDetail.quoteData?.data?.marginCost} </td>
                                                 <td>Marketing:{quoteDetail.quoteData?.data?.marketingCost}, Transport:{quoteDetail.quoteData?.data?.transportCost}, Labour Factory:{quoteDetail.quoteData?.data?.labourFCost} 
                                                 Labour Site:{quoteDetail.quoteData?.data?.labourScost},  Glass:{quoteDetail.quoteData?.data?.glassCost}
                                                 </td>
                                                 
                                             </tr>
                                         )
                                     ))
                                 ))
                             ) : (
                                 <tr>
                                     <td colSpan={7}>No matching quotes found.</td>
                                 </tr>
                             )}
                                                                        
                             
                               
                                        </tbody>
                                    </table>

                                    <Paginator className="custom-paginator" first={first} rows={rows} totalRecords={filteredDatas ? filteredDatas.length : 0} onPageChange={onPageChange} />
              
                                </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>



        </>
    )
}
export default Hardwarereport;
