import React, { useEffect, useState } from 'react';
import { ReactSession } from 'react-client-session';
import axios from 'axios';
import numeral from 'numeral';
import left from '../images/handleLeft.png';
import right from '../images/handleRight.png';
import meshimg from '../images/mesh.png';
import Handle from '../images/slideHandle.png';
import Fan from '../images/fan.png';
import ContentEditable from 'react-contenteditable';


function Downlaod({ quotes, discount, toggle}) {
  const urlParams = new URLSearchParams(window.location.search);
  const quoteIds = urlParams.get('id');
  const arrayValues = urlParams.get('arrayValues');
  const userType = ReactSession.get("upvcuserType");
  const Empid = ReactSession.get("upvcempid");
  const userId = ReactSession.get('upvcuserId');
  const Number = ReactSession.get('upvcNumber');
  const Address = ReactSession.get('upvcAddress');
  const Name = ReactSession.get('upvcuserName');
  const Image = ReactSession.get('upvcImage');
  const regid = ReactSession.get("upvcregid");
  const [brandDatas, setBdata] = useState(null);



  const [quoteData, setQuoteData] = useState(null);
  const [filteredArray, setFilteredArray] = useState([]);
  const [des, setDes] = useState("");


  const [measure, setMeasure] = useState('ft');
  const [mCalc, setMcalc] = useState(1);
  const [bottomFrame, setBottomFrame] = useState('yes');
  const [mesh, setMesh] = useState('no');
  const [panelHeights, setpanelHeight] = useState(0);
  const [deviceWidth, setDeviceWidth] = useState(window.innerWidth);
  const [screen, setScreen] = useState(deviceWidth > 700 ? 40 : 40);
  const [fanTop, setfanTop] = useState('Top');
  const [fanLeft, setfanLeft] = useState('Left');
  const [divider, setDevider] = useState('Vertical');
  const [switchs, setSwitch] = useState('on');
  const [switchFixed, setFixed] = useState('on');
  const [fanType, setfanType] = useState(1);
  const [total, setTotal] = useState(0);
  const [qty, setQty] = useState(0);
  const [totalframearea, setTotalFramearea] = useState(0);
  const [gst, setGst] = useState(0);
  const [average, setAverage] = useState(0);
  const [discountprice, setDiscountprice] = useState(0);
  const [totalaftrdis, setTotalaftrdis]= useState(0);
  console.log(toggle);


  let users = '';
  if (userType === '3') {
    users = Empid;
  } else {
    users = userId;
  }
  const user = { "userId": users };


  useEffect(() => {
    const updateDeviceWidth = () => {
      setDeviceWidth(window.innerWidth);
      setScreen(window.innerWidth > 700 ? 40 : 40);
    };

    window.addEventListener('resize', updateDeviceWidth);

    return () => {
      window.removeEventListener('resize', updateDeviceWidth);
    };
  }, []);

  const brandData = async () => {
    try {
      const response = await axios.get('https://upvcapi.sninfoserv.com/company/', { params: { userId: users } });
      //console.log(response.data);
      if (response.data.status === 'success') {
        setBdata(response.data.data);
      }
    } catch (error) {
      console.error('Error fetching brand data:', error);
    }
  };

  useEffect(() => {
    brandData();
  }, []);


  const fetchData = async () => {
    try {
      const response = await axios.post(`https://upvcapi.sninfoserv.com/all-quotes/`, user);
      setQuoteData(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Ensure quoteData is valid before using filter method
  const filteredQuotes = quoteData && quoteData.data.filter(quote => quote.quoteId === quoteIds);
  const profileImage = filteredQuotes && filteredQuotes[0]?.quoteDetails[0]?.quoteData?.selectedProfile || 'profile_logo';


  useEffect(() => {
    if (filteredQuotes && filteredQuotes.length > 0) {
      let sum = 0;
      let qty = 0;
      let totalframe = 0;
      let average = 0;
      filteredQuotes.forEach(quote => {
        quote.quoteDetails.forEach(detail => {
          qty += (detail.quoteData.qty);
          totalframe += (detail.quoteData.frameArea * detail.quoteData.qty);
          sum += (detail.quoteData.grandTotal * detail.quoteData.qty);

          // console.log(sum);
        });
      });
      average = (sum / totalframe);

    


      const discountsAmount=(((sum*discount)/100));
      setDiscountprice(discountsAmount);
      setTotalaftrdis(sum-discountsAmount);
      setTotal(sum);
      setQty(qty);
      setTotalFramearea(totalframe);
      setGst((18 / 100) * sum);
      setAverage(average);
    }
  }, [filteredQuotes]);


  useEffect(() => {
    if (arrayValues) {
      // Split arrayValues string into an array of values
      const valuesArray = arrayValues.split(',');

      // Filter quotes based on valuesArray
      const filtered = quotes.filter(quote =>
        valuesArray.every(value => quote.details.includes(value))
      );

      setFilteredArray(filtered);
    }
  }, [arrayValues, quotes]);


  const createGrill = (boxHeight, topnewHeights, bottomnewHeights, mCalc) => {

    const boxes = Array.from({ length: (boxHeight - topnewHeights - bottomnewHeights) * 1.9 }).map((_, index) => (
      <div
        key={index}
        style={{
          position: 'relative',
          width: '100%',
          height: '0.26vw',
          border: '1px solid #333',
          background: '#fff',
          marginTop: `calc((${boxHeight * screen + 'vw'} - 25px) / ${boxHeight * screen}) `,
        }}
      ></div>
    ));

    return boxes;

  };

  
  const fetchterms = async () => {
    try {

      const response = await axios.get('https://upvcapi.sninfoserv.com/quote-terms/', { params: { userId: users } });

      setDes(response.data.companyTerms)
    } catch (error) {
      // Handle error
      console.error('There was a problem with login:', error);

    }
  }
  useEffect(() => {
    fetchterms();
  }, [])

  const getInitials = (str) => {
    return str
      .split(' ')  
      .map(word => word[0].toUpperCase())  
      .join('');  
  };

  return (

    <div >

      <div class="modal fade custom-modal" id="onloadModal" tabindex="-1" aria-labelledby="onloadModalLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content" align="center">
            <div class="mt-3 mb-3">
              <h5>Preparing Quote...</h5>
            </div>

          </div>
        </div>
      </div>



      <div className='hide1' id="pdf-content" style={{ maxWidth: '100%', padding: "20px", background: '#fff' }}>
        <table width='100%' id="first-table-1" style={{ marginBottom: '60px' }}>
          <tr>
            <th colspan='4' width='100%' style={{ textAlign: 'center', padding: '10px', verticalAlign: 'middle' }}>
              <img src={`assets/user/${brandDatas && brandDatas[0].companyLogo}`} height='50px' alt={brandDatas && brandDatas[0].companyLogo}
                style={{ marginRight: '10px', marginBottom: '5px' }}
              />
            </th>
          </tr>
          <tr>
            <th width='20%' style={{ textAlign: 'left', verticalAlign: 'middle', padding: '0px 20px 20px 0px', border: 'none' }}>
              <img
                style={{ color: '#000', marginRight: '25px' }}
                src="assets/images/saalaram.png"
                width='90px'
                alt='Logo'
                class='mb-2'
              />
            </th>

            <th colspan="2" width='60%' style={{ textAlign: 'center', verticalAlign: 'middle', padding: '0px 20px 20px 0px', border: 'none' }}>
              <p style={{ fontSize: '13px', margin: 0, color: '#000' }}> {brandDatas && brandDatas[0].companyName}  </p>
              <p style={{ fontSize: '10px', margin: 0, color: '#000' }}>
                {brandDatas && brandDatas[0].companyAddress}<br /> Phone : {brandDatas && brandDatas[0].companyContact} , GST : {brandDatas && brandDatas[0].companyGst}
              </p>

            </th>

            <th width='20%' style={{ textAlign: 'right', verticalAlign: 'middle', padding: '0px 20px 20px 0px', border: 'none'}}>
              <img
                src={`assets/images/${profileImage}.jpg`}
                width='90px'
                alt={profileImage}
                style={{ marginRight: '12px' }}
              />
            </th>

          </tr>

         
          <tr>
            
            <th colspan="2" width='50%' style={{ textAlign: 'left', padding: "20px 20px", color: '#000', border: '1px solid #333', borderRight: 'none' }}>
              

            {filteredQuotes?.map((quote, index) => (
                <div key={index}>
                  <h6 style={{ color: '#000', fontSize: '12px' }}>
                    Quotation No : {brandDatas && brandDatas[0].companyName.length > 0 && getInitials(brandDatas && brandDatas[0].companyName)}-{quote.cusQuoteId}
                  </h6>
                </div>
              ))}
            </th>

            <th colspan="2" width='50%' style={{ textAlign: 'right', padding: "20px 20px", color: '#000', border: '1px solid #333' }}>
              

            {filteredQuotes?.map((quote, index) => (
                <div key={index}>
                  <h6 style={{ color: '#000', fontSize: '12px'}}>
                    Date : {quote.date}
                  </h6>
                </div>
              ))}
            </th>

          </tr>
          <tr>
            
            <th colspan="2" width='50%' style={{ textAlign: 'centre', padding: "20px 20px", color: '#000', border: '1px solid #333', borderTop: 'none', borderRight: 'none' }}>
              

              {filteredQuotes?.map((quote, index) => (
                <div key={index}>
                  <h6 style={{ color: '#000', fontSize: '12px' }}>
                    To
                    <ul style={{ listStyleType: 'none', fontSize: '10px' }}>
                      <li> {quote.client.name}</li>
                      <li> {quote.client.location}</li>
                      <li> {quote.client.number}</li>
                    </ul>
                  </h6>
                </div>
              ))}
            </th>

            <th colspan="2" width='50%' style={{ textAlign: 'right', padding: "20px 20px", color: "#000", border: 'none', border: '1px solid #333', borderTop: 'none' }}>
             

              {filteredQuotes?.map((quote, index) => (
                <div key={index}>
                  <h6 style={{ color: '#000', fontSize: '12px', textAlign: 'left', }}>
                    Site
                    <ul style={{ listStyleType: 'none', fontSize: '10px' }}>
                      <li> {quote.client.name}</li>
                      <li> {quote.client.siteLocation}</li>
                      <li> {quote.client.number}</li>
                    </ul>
                  </h6>
                </div>
              ))}
            </th>

          </tr>

          <tr>
            <td colspan="4" style={{ textAlign: "left", padding: '20px 0px', fontSize: '12px', color: "#000" }}>
              <p style={{ color: "#333", fontSize: '12px' }}>With reference to the above, We are pleased to submit our best possible quote and we hope this would be up to your expectations, We assure you our best services at all times and for any further clarifications kinldy feel free to call us at any time.</p>
              {des !== '' && <h5 style={{ color: '#333' }}>Terms & Conditions: -</h5>}

              <ContentEditable  html={`${des}`} />

            </td>

          </tr>

        

          <tr>
            <td colspan="4" style={{ textAlign: 'right', padding: '30px 0px 10px 0px', color: "#000", fontSize: '12px' }}>for {brandDatas && brandDatas[0].companyName} </td>
          </tr>
          <tr><td></td></tr>
          <tr><td></td></tr>
          <tr>
            <td colspan="4" style={{ textAlign: 'right', color: "#000", fontSize: '12px' }}> {Name} </td>
          </tr>
          <tr>
            <td colspan="4" style={{ textAlign: 'right', color: "#000", fontSize: '12px' }}> {Number} </td>
          </tr>

        </table>


        <table style={{ width: "100%", border: '1px solid #666', borderCollapse: 'collapse' }} id="second-table" width="100%" >


          <thead >
            <tr>
              <th width="3%" style={{ border: '1px solid #666', padding: '2px', textAlign: 'center', color: "#000", fontSize: '12px' }}>S.No</th>
              <th style={{ border: '1px solid #666', width: "10%", padding: '2px', textAlign: 'center', color: "#000", fontSize: '12px' }}>Description</th>
              <th style={{ border: '1px solid #666', width: "7%", padding: '2px', textAlign: 'center', color: "#000", fontSize: '12px' }}>Series</th>
              <th colspan="2" style={{ border: '1px solid #666', width: "8%", padding: '2px', textAlign: 'center', borderBottom: '1px solid #000', position: 'relative', color: "#000", fontSize: '12px' }}>
                <div style={{ margin: '0', padding: '0' }} >
                  Sizes in Feet
                </div>
                <div style={{ borderBottom: '1px solid #000', margin: '0', color: "#000" }}></div>
                <div style={{ display: 'flex', justifyContent: 'space-between', padding: '5px', verticalAlign: 'middle', color: "#000" }}>
                  <div style={{ flex: '1', borderRight: '1px solid #000', width: "4%", padding: '3px', color: "#000", fontSize: '12px' }}>Width</div>
                  <div style={{ flex: '1', borderRight: '1px solid #fff', width: "4%", padding: '3px', color: "#000", fontSize: '12px' }}>Height</div>
                </div>
              </th>
              <th style={{ border: '1px solid #666', width: "5%", padding: '2px', textAlign: 'center', color: "#000", fontSize: '12px' }}>Area</th>
              <th style={{ border: '1px solid #666', width: "5%", padding: '2px', textAlign: 'center', color: "#000", fontSize: '12px' }}>Qty</th>
              <th style={{ border: '1px solid #666', width: "5%", padding: '2px', textAlign: 'center', color: "#000", fontSize: '12px' }}>Total Area</th>
              <th style={{ border: '1px solid #666', width: "5%", padding: '2px', textAlign: 'center', color: "#000", fontSize: '12px' }}>Amount</th>
            </tr>
          </thead>
          {filteredQuotes?.map((quote, index) => (
            <tbody key={index} >
              {quote.quoteDetails.map((detail, detailIndex) => (
                <tr key={detailIndex} >
                  <td style={{ border: '1px solid #666', padding: '10px', alignContent: 'center', alignItems: 'center', textAlign: 'center', color: "#000" }}>{detailIndex + 1}</td>


                  <td style={{ border: '1px solid #666', padding: '15px', width: '15%', alignContent: 'center', alignItems: 'center', textAlign: 'center', color: "#000" }}>
                    <h6 style={{ color: '#000', fontSize: '12px' }} align="center">{detail.quoteData.item}</h6>
                    {detail.quoteData.item === 'CasementDoor' && (
                      <>


                        <div align="center" style={{ alignContent: 'center', alignItems: 'center', textAlign: 'center', marginLeft: 'auto', marginRight: 'auto' }}>

                     
                          <div style={{ float: 'left', width: (detail.quoteData.boxWidth > 7 ? 6 : detail.quoteData.boxWidth) * screen + 'px', height: detail.quoteData.boxHeight * screen + 'px', border: '1px solid #333' }}>



                            {[...Array(detail.quoteData.numFrames)].map((_, index) => (
                              <div style={{ float: 'left', margin: '5px', border: '1px solid #333', width: `calc(${(detail.quoteData.boxWidth > 7 ? 6 : detail.quoteData.boxWidth) * screen / detail.quoteData.numFrames + 'px'} - 12px)`, height: detail.quoteData.bottomFrame === 'no' ? `calc(${(detail.quoteData.boxHeight) * screen + 'px'} - 6px)` : `calc(${(detail.quoteData.boxHeight) * screen + 'px'} - 12px)`, }}>



                                {detail.quoteData.boxTop === 'on' && (

                                  [...Array(detail.quoteData.numTopBoxes)].map((_, index) => (
                                    <div style={{ display: 'flex', width: `calc(${detail.quoteData.boxWidth> 7 ? 6 : detail.quoteData.boxWidth * screen / detail.quoteData.numFrames + 'px'} - 23px)`, height: `calc(${(detail.quoteData.topnewHeights * screen)}px )`, margin: '5px' }}>
                                      {[...Array(detail.quoteData.topFixedNum)].map((_, index) => (
                                        <div style={{ flex: '1', background: 'lightgreen', width: `calc(${detail.quoteData.topFixedWidth[index] * screen / detail.quoteData.numFrames + 'px'} - 18px)`, height: `calc(${(detail.quoteData.topnewHeights * screen)}px )`, border: 'solid 1px #000', marginLeft: index === 0 ? '0px' : '5px', }}>

                                        </div>
                                      ))}
                                    </div>
                                  ))
                                )}


                                <div style={{ display: 'flex' }}>
                                  {[...Array(detail.quoteData.numSecondInnerBoxes)].map((_, index) => (

                                    <div key={index} style={{ flex: '1', height: `calc(${(detail.quoteData.boxHeight - detail.quoteData.topnewHeights - detail.quoteData.bottomnewHeights) * screen}px - ${detail.quoteData.boxTop === 'on' ? '30px' : '25px'})`, width: `calc(${detail.quoteData.newWidths[index] * screen}vw - 5px - 19px)`, backgroundColor: detail.quoteData.panelType === 1 ? 'lightgray' : detail.quoteData.panelType === 2 ? 'lightyellow' : 'lightblue', marginTop: detail.quoteData.boxTop === 'on' ? '0px' : '5px', marginRight: index === detail.quoteData.numSecondInnerBoxes - 1 ? '5px' : '2.5px', marginLeft: index === 0 ? '5px' : '2.5px', border: '1px solid #333' }}>


                                      {detail.quoteData.boxNames[index] === 'Shutter' && (detail.quoteData.boxHandle[index] === 'left' ? (
                                        <img
                                          src={left}
                                          style={{
                                            float: 'left',
                                            marginTop: `calc(${(((detail.quoteData.boxHeight - detail.quoteData.topnewHeights - detail.quoteData.bottomnewHeights) * screen) / 2)}px - 15px`,
                                            marginLeft: '2px',
                                            width: screen === 3 ? '13px' : '8px'
                                          }}

                                          alt="Left Handle"
                                        />
                                      ) : (
                                        <img
                                          src={right}
                                          style={{
                                            float: 'right',
                                            marginTop: `calc(${(((detail.quoteData.boxHeight - detail.quoteData.topnewHeights - detail.quoteData.bottomnewHeights) * screen) / 2)}px - 15px`,
                                            marginRight: '2px',
                                            width: screen === 3 ? '13px' : '8px'
                                          }}

                                          alt="Right Handle"
                                        />

                                      ))}




                                      {detail.quoteData.boxNames[index] === 'Shutter' && detail.quoteData.grill === 'yes' && createGrill(detail.quoteData.boxHeight, detail.quoteData.topnewHeights, detail.quoteData.bottomnewHeights, mCalc)}

                                    </div>

                                  ))}

                                </div>


                                {detail.quoteData.boxPosition === 'Bottom' ? (
                                  <div></div>
                                ) : (
                                  [...Array(detail.quoteData.numFirstInnerBoxes)].map((_, index) => (
                                    <div style={{ width: `calc(${(detail.quoteData.boxWidth> 7 ? 6 : detail.quoteData.boxWidth) * screen / mCalc / detail.quoteData.numFrames + 'px'} - 23px)`, height: `calc(${(detail.quoteData.newHeights * screen)}px )`, background: 'lightgreen', margin: '5px', border: '1px solid #333' }}></div>
                                  ))
                                )}



                              </div>
                            ))}
                          </div>

                        </div>
                        <br></br>
                        {detail.quoteData.values.espag.amount > 0 && (<>  Espag with SS Receiver,</>)}  {detail.quoteData.values.mpl.amount > 0 && (<> Multipoint Lock,</>)}  {detail.quoteData.values.doorstopper.amount > 0 && (<> Door Stopper,</>)}  {detail.quoteData.values.casementdoorset.amount > 0 && (<> casement Door Set,</>)}  {detail.quoteData.values.espagm.amount > 0 && (<>  Espag with Multi Lock,</>)} {detail.quoteData.values.hings.amount > 0 && (<> 3D Hinges,</>)} {detail.quoteData.values.frictionstay.amount > 0 && (<> Friction Stay,</>)}    {detail.quoteData.values.steelFrame.amount > 0 && (<> Steel Frame,</>)} {detail.quoteData.values.steelMullion.amount > 0 && (<> Steel Mullion,</>)} {detail.quoteData.values.mullionclamp.amount > 0 && (<> Mullion Clamp,</>)}  {detail.quoteData.values.steelShutter.amount > 0 && (<> Steel Shutter,</>)} {detail.quoteData.grill === 'yes' && (<> Grill,</>)} Screws/Misc

                       </>
                    )}


                    {detail.quoteData.item === 'CasementWindow' && (

                      <>
                        <div align="center" style={{ alignContent: 'center', alignItems: 'center', textAlign: 'center', marginLeft: 'auto', marginRight: 'auto' }}>


                          <div style={{ float: 'left', width: (detail.quoteData.boxWidth > 7 ? 6 : detail.quoteData.boxWidth) * screen + 'px', height: detail.quoteData.boxHeight * screen + 'px', border: '1px solid #333' }}>



                            {[...Array(detail.quoteData.numFrames)].map((_, index) => (
                              <div style={{ float: 'left', margin: '5px', border: '1px solid #333', width: `calc(${(detail.quoteData.boxWidth> 7 ? 6 : detail.quoteData.boxWidth) * screen / detail.quoteData.numFrames + 'px'} - 12px)`, height: `calc(${detail.quoteData.boxHeight * screen + 'px'} - 12px)` }}>


                                {detail.quoteData.boxTop === 'on' && (

                                  [...Array(detail.quoteData.numTopBoxes)].map((_, index) => (
                                    <div style={{ display: 'flex', width: `calc(${detail.quoteData.boxWidth> 7 ? 6 : detail.quoteData.boxWidth * screen / detail.quoteData.numFrames + 'px'} - 23px)`, height: `calc(${(detail.quoteData.topnewHeights * screen)}px )`, margin: '5px' }}>
                                      {[...Array(detail.quoteData.topFixedNum)].map((_, index) => (
                                        <div style={{ flex: '1', background: 'lightgreen', width: `calc(${detail.quoteData.topFixedWidth[index] * screen / detail.quoteData.numFrames + 'px'} - 18px)`, height: `calc(${(detail.quoteData.topnewHeights * screen)}px )`, border: 'solid 1px #000', marginLeft: index === 0 ? '0px' : '5px', }}>

                                        </div>
                                      ))}
                                    </div>
                                  ))
                                )}

                                <div style={{ display: 'flex' }}>
                                  {[...Array(detail.quoteData.numSecondInnerBoxes)].map((_, index) => (

                                    <div key={index} style={{ flex: '1', height: `calc(${(detail.quoteData.boxHeight - detail.quoteData.topnewHeights - detail.quoteData.bottomnewHeights) * screen}px - ${detail.quoteData.boxTop === 'on' ? '30px' : '25px'})`, width: `calc(${detail.quoteData.newWidths[index] * screen}vw - 5px - 19px)`, backgroundColor: detail.quoteData.boxColors[index] || 'lightblue', marginTop: detail.quoteData.boxTop === 'on' ? '0px' : '5px', marginRight: index === detail.quoteData.numSecondInnerBoxes - 1 ? '5px' : '2.5px', marginLeft: index === 0 ? '5px' : '2.5px', border: '1px solid #333' }}>


                                      {detail.quoteData.boxNames[index] === 'Shutter' && (detail.quoteData.boxHandle[index] === 'left' ? (
                                        <img
                                          src={left}
                                          style={{
                                            float: 'left',
                                            marginTop: `calc(${(((detail.quoteData.boxHeight - detail.quoteData.topnewHeights - detail.quoteData.bottomnewHeights) * screen) / 2)}px - 15px`,
                                            marginLeft: '2px',
                                            width: screen === 3 ? '13px' : '8px'
                                          }}

                                          alt="Left Handle"
                                        />
                                      ) : (
                                        <img
                                          src={right}
                                          style={{
                                            float: 'right',
                                            marginTop: `calc(${(((detail.quoteData.boxHeight - detail.quoteData.topnewHeights - detail.quoteData.bottomnewHeights) * screen) / 2)}px - 15px`,
                                            marginRight: '2px',
                                            width: screen === 3 ? '13px' : '8px'
                                          }}

                                          alt="Right Handle"
                                        />

                                      ))}

                                      {detail.quoteData.mesh === 'yes' && detail.quoteData.boxNames[index] === 'Shutter' && detail.quoteData.boxHandle[index] === 'left' && (
                                        <img src={meshimg} style={{ width: screen === 3 ? '40px' : '20px', float: 'left', marginLeft: screen === 3 ? detail.quoteData.boxHandle[index] === 'left' ? '-15px' : '0px' : detail.quoteData.boxHandle[index] === 'left' ? '-10px' : 'px', marginTop: screen === 3 ? `calc(${((detail.quoteData.boxHeight - detail.quoteData.topnewHeights - detail.quoteData.bottomnewHeights) * screen)}px - ${detail.quoteData.boxTop === 'on' ? '72px' : '67px'} )` : `calc(${((detail.quoteData.boxHeight - detail.quoteData.topnewHeights - detail.quoteData.bottomnewHeights) * screen)}px - 50px)` }} />
                                      )}

                                      {detail.quoteData.mesh === 'yes' && detail.quoteData.boxNames[index] === 'Shutter' && detail.quoteData.boxHandle[index] === 'right' && (
                                        <img src={meshimg} style={{ width: screen === 3 ? '40px' : '20px', float: 'left', marginLeft: screen === 3 ? detail.quoteData.boxHandle[index] === 'right' ? '-1px' : '0px' : detail.quoteData.boxHandle[index] === 'right' ? '0px' : 'px', marginTop: screen === 3 ? `calc(${((detail.quoteData.boxHeight - detail.quoteData.topnewHeights - detail.quoteData.bottomnewHeights) * screen)}px - ${detail.quoteData.boxTop === 'on' ? '72px' : '67px'} )` : `calc(${((detail.quoteData.boxHeight - detail.quoteData.topnewHeights - detail.quoteData.bottomnewHeights) * screen)}px - 50px)` }} />
                                      )}

                                      {detail.quoteData.boxNames[index] === 'Shutter' && detail.quoteData.grill === 'yes' && createGrill(detail.quoteData.boxHeight, detail.quoteData.topnewHeights, detail.quoteData.bottomnewHeights, mCalc)}

                                    </div>

                                  ))}

                                </div>

                                {detail.quoteData.boxBottom === 'on' && (
                                  [...Array(detail.quoteData.numBottomBoxes)].map((_, index) => (
                                    <div style={{ display: 'flex', width: `calc(${detail.quoteData.boxWidth> 7 ? 6 : detail.quoteData.boxWidth * screen / detail.quoteData.numFrames + 'px'} - 23px)`, height: `calc(${(detail.quoteData.bottomnewHeights * screen) + 'vw'} -10px)`, margin: '5px' }}>
                                      {[...Array(detail.quoteData.bottomFixedNum)].map((_, index) => (
                                        <div style={{ flex: '1', background: 'lightgreen', width: `calc(${detail.quoteData.bottomFixedWidth[index] * screen / detail.quoteData.numFrames + 'px'} - 18px)`, height: `calc(${(detail.quoteData.bottomnewHeights * screen) + 'px'} - 5px)`, border: 'solid 1px #000', marginLeft: index === 0 ? '0px' : '5px', }}>

                                        </div>
                                      ))}
                                    </div>
                                  ))
                                )}
                              </div>
                            ))}
                          </div>
                        </div>


                        <br></br>

                       
                       {detail.quoteData.values.espag.amount > 0 && (<>  Espag with SS Receiver,</>)} {detail.quoteData.values.espagm.amount > 0 && (<>  Espag with Multi Lock,</>)} {detail.quoteData.values.hings.amount > 0 && (<> Hinges,</>)} {detail.quoteData.values.frictionstay.amount > 0 && (<> Friction Stay,</>)}  {detail.quoteData.values.runnerblock.amount > 0 && (<> Runner Block,</>)} {detail.quoteData.values.securityclip.amount > 0 && (<> SecurityClip,</>)} {detail.quoteData.values.handle.amount > 0 && (<> Handle,</>)} {detail.quoteData.values.mesh.amount > 0 && (<> Mesh 3.4 SS,</>)}  {detail.quoteData.values.steelMesh.amount === 'yes' && (<> Steel Mesh,</>)} {detail.quoteData.values.steelFrame.amount > 0 && (<> Steel Frame,</>)} {detail.quoteData.values.steelMullion.amount > 0 && (<> Steel Mullion,</>)} {detail.quoteData.values.mullionclamp.amount > 0 && (<> Mullion Clamp,</>)}  {detail.quoteData.values.steelShutter.amount > 0 && (<> Steel Shutter,</>)} {detail.quoteData.grill === 'yes' && (<> Grill,</>)} Screws/Misc

                      </>


                    )}
                    {detail.quoteData.item === 'SlidingDoor' && (
                      <>
                        <div align="center" style={{ alignContent: 'center', alignItems: 'center', textAlign: 'center', marginLeft: 'auto', marginRight: 'auto' }}>


                          <div style={{ float: 'left', width:(detail.quoteData.boxWidth > 7 ? 6 : detail.quoteData.boxWidth) * screen + 'px', height: detail.quoteData.boxHeight * screen + 'px', border: '1px solid #333' }}>



                            {[...Array(detail.quoteData.numFrames)].map((_, index) => (
                              <div style={{ float: 'left', margin: '5px', border: '1px solid #333', width: `calc(${(detail.quoteData.boxWidth> 7 ? 6 : detail.quoteData.boxWidth) * screen / detail.quoteData.numFrames + 'px'} - 12px)`, height: `calc(${detail.quoteData.boxHeight * screen + 'px'} - 12px)` }}>





                                {detail.quoteData.boxTop === 'on' && (

                                  [...Array(detail.quoteData.numTopBoxes)].map((_, index) => (
                                    <div style={{ display: 'flex', width: `calc(${detail.quoteData.boxWidth> 7 ? 6 : detail.quoteData.boxWidth * screen / detail.quoteData.numFrames + 'px'} - 23px)`, height: `calc(${(detail.quoteData.topnewHeights * screen)}px )`, margin: '5px' }}>

                                      {[...Array(detail.quoteData.topFixedNum)].map((_, index) => (
                                        <div style={{ flex: '1', background: 'lightgreen', width: `calc(${detail.quoteData.topFixedWidth[index] * screen / detail.quoteData.numFrames + 'px'} - 18px)`, height: `calc(${(detail.quoteData.topnewHeights * screen)}px )`, border: 'solid 1px #000', marginLeft: index === 0 ? '0px' : '5px', }}>

                                        </div>
                                      ))}
                                    </div>
                                  ))
                                )}

                                <div style={{ display: 'flex' }}>
                                  {[...Array(detail.quoteData.numSecondInnerBoxes)].map((_, index) => (

                                    <div key={index} style={{ flex: '1', height: `calc(${(detail.quoteData.boxHeight - detail.quoteData.topnewHeights - detail.quoteData.bottomnewHeights) * screen}px - ${detail.quoteData.boxTop === 'on' ? '30px' : '25px'})`, width: `calc(${detail.quoteData.newWidths[index] * screen}vw - 5px - 19px)`, backgroundColor: detail.quoteData.boxColors[index] || 'lightblue', marginTop: detail.quoteData.boxTop === 'on' ? '0px' : '5px', marginRight: index === detail.quoteData.numSecondInnerBoxes - 1 ? '5px' : '2.5px', marginLeft: index === 0 ? '5px' : '2.5px', border: '1px solid #333' }}>





                                      {detail.quoteData.boxNames[index] === 'Shutter' && (detail.quoteData.boxHandle[index] === 'left' ? (
                                        <img
                                          src={Handle}
                                          style={{
                                            float: 'left',
                                            marginTop: `calc(${(((detail.quoteData.boxHeight - detail.quoteData.topnewHeights - detail.quoteData.bottomnewHeights) * screen) / 2)}px - 15px`,
                                            marginLeft: '2px',
                                            width: screen === 3 ? '5px' : '4px'
                                          }}

                                          alt="Left Handle"
                                        />
                                      ) : (
                                        <img
                                          src={Handle}
                                          style={{
                                            float: 'right',
                                            marginTop: `calc(${(((detail.quoteData.boxHeight - detail.quoteData.topnewHeights - detail.quoteData.bottomnewHeights) * screen) / 2)}px - 15px`,
                                            marginRight: '2px',
                                            width: screen === 3 ? '5px' : '4px'
                                          }}

                                          alt="Right Handle"
                                        />

                                      ))}

                                      {detail.quoteData.mesh === 'yes' && detail.quoteData.boxNames[index] === 'Shutter' && detail.quoteData.boxHandle[index] === 'left' && (
                                        <img src={detail.quoteData.meshimg} style={{ width: screen === 3 ? '40px' : '20px', float: 'left', marginLeft: screen === 3 ? detail.quoteData.boxHandle[index] === 'left' ? '-15px' : '0px' : detail.quoteData.boxHandle[index] === 'left' ? '-10px' : 'px', marginTop: screen === 3 ? `calc(${((detail.quoteData.boxHeight - detail.quoteData.topnewHeights - detail.quoteData.bottomnewHeights) * screen / mCalc)}vw - ${detail.quoteData.boxTop === 'on' ? '72px' : '67px'} )` : `calc(${((detail.quoteData.boxHeight - detail.quoteData.topnewHeights - detail.quoteData.bottomnewHeights) * screen / mCalc)}vw - 45px)` }} />
                                      )}

                                      {detail.quoteData.mesh === 'yes' && detail.quoteData.boxNames[index] === 'Shutter' && detail.quoteData.boxHandle[index] === 'right' && (
                                        <img src={detail.quoteData.meshimg} style={{ width: screen === 3 ? '40px' : '20px', float: 'left', marginLeft: screen === 3 ? detail.quoteData.boxHandle[index] === 'right' ? '-1px' : '0px' : detail.quoteData.boxHandle[index] === 'right' ? '0px' : 'px', marginTop: screen === 3 ? `calc(${((detail.quoteData.boxHeight - detail.quoteData.topnewHeights - detail.quoteData.bottomnewHeights) * screen / mCalc)}vw - ${detail.quoteData.boxTop === 'on' ? '72px' : '67px'} )` : `calc(${((detail.quoteData.boxHeight - detail.quoteData.topnewHeights - detail.quoteData.bottomnewHeights) * screen / mCalc)}vw - 45px)` }} />
                                      )}

                                      {detail.quoteData.boxNames[index] === 'Shutter' && detail.quoteData.grill === 'yes' && createGrill(detail.quoteData.boxHeight, detail.quoteData.topnewHeights, detail.quoteData.bottomnewHeights, mCalc)}


                                    </div>

                                  ))}

                                </div>





                              </div>
                            ))}
                          </div>


                        </div>
                        <br></br>
                        {detail.quoteData.values.adjroll.amount > 0 && (<>  Adjust Roller,</>)}  {detail.quoteData.values.alTrack.amount > 0 && (<>  Aluminium Track,</>)}  {detail.quoteData.values.bArrester.amount > 0 && (<>  Bump Arrester,</>)}  {detail.quoteData.values.dArrester.amount > 0 && (<> Dust Arrester,</>)} {detail.quoteData.values.jStopper.amount > 0 && (<>  Jump Stopper,</>)} {detail.quoteData.values.gRoller.amount > 0 && (<>  Grove Roller,</>)} {detail.quoteData.values.touchlock.amount > 0 && (<> Touch Lock,</>)}  {detail.quoteData.values.espag.amount > 0 && (<>  Espag with SS Receiver,</>)}   {detail.quoteData.values.espagm.amount > 0 && (<>  Espag with Multi Lock,</>)}  {detail.quoteData.values.frictionstay.amount > 0 && (<> Friction Stay,</>)}    {detail.quoteData.values.steelFrame.amount > 0 && (<> Steel Frame,</>)} {detail.quoteData.values.steelMullion.amount > 0 && (<> Steel Mullion,</>)} {detail.quoteData.values.mullionclamp.amount > 0 && (<> Mullion Clamp,</>)}  {detail.quoteData.values.steelShutter.amount > 0 && (<> Steel Shutter,</>)} {detail.quoteData.grill === 'yes' && (<> Grill,</>)} Screws/Misc

                      </>


                    )}
                    {detail.quoteData.item === 'SlidingWindow' && (

                      <>
                        <div align="center" style={{ alignContent: 'center', alignItems: 'center', textAlign: 'center', marginLeft: 'auto', marginRight: 'auto' }}>

                          <div style={{ float: 'left', width:(detail.quoteData.boxWidth > 7 ? 6 : detail.quoteData.boxWidth) * screen + 'px', height: detail.quoteData.boxHeight * screen + 'px', border: '1px solid #333' }}>



                            {[...Array(detail.quoteData.numFrames)].map((_, index) => (
                              <div style={{ float: 'left', margin: '5px', border: '1px solid #333', width: `calc(${(detail.quoteData.boxWidth> 7 ? 6 : detail.quoteData.boxWidth) * screen / detail.quoteData.numFrames + 'px'} - 12px)`, height: `calc(${detail.quoteData.boxHeight * screen + 'px'} - 12px)` }}>



                                {detail.quoteData.boxTop === 'on' && (

                                  [...Array(detail.quoteData.numTopBoxes)].map((_, index) => (
                                    <div style={{ display: 'flex', width: `calc(${detail.quoteData.boxWidth> 7 ? 6 : detail.quoteData.boxWidth * screen / detail.quoteData.numFrames + 'px'} - 23px)`, height: `calc(${(detail.quoteData.topnewHeights * screen)}px )`, margin: '5px' }}>
                                      {[...Array(detail.quoteData.topFixedNum)].map((_, index) => (
                                        <div style={{ flex: '1', background: 'lightgreen', width: `calc(${detail.quoteData.topFixedWidth[index] * screen / detail.quoteData.numFrames + 'px'} - 18px)`, height: `calc(${(detail.quoteData.topnewHeights * screen)}px )`, border: 'solid 1px #000', marginLeft: index === 0 ? '0px' : '5px', }}>

                                        </div>
                                      ))}
                                    </div>
                                  ))
                                )}

                                <div style={{ display: 'flex' }}>
                                  {[...Array(detail.quoteData.numSecondInnerBoxes)].map((_, index) => (

                                    <div key={index} style={{ flex: '1', height: `calc(${(detail.quoteData.boxHeight - detail.quoteData.topnewHeights - detail.quoteData.bottomnewHeights) * screen}px - ${detail.quoteData.boxTop === 'on' ? '30px' : '25px'})`, width: `calc(${detail.quoteData.newWidths[index] * screen}vw - 5px - 19px)`, backgroundColor: detail.quoteData.boxColors[index] || 'lightblue', marginTop: detail.quoteData.boxTop === 'on' ? '0px' : '5px', marginRight: index === detail.quoteData.numSecondInnerBoxes - 1 ? '5px' : '2.5px', marginLeft: index === 0 ? '5px' : '2.5px', border: '1px solid #333' }}>

                                  <div style={{
                                  height:'7px',
                                  width: '1px',
                                  float: 'right',
                                  right: '0px',
                                  marginTop: `calc(${(detail.quoteData.boxHeight-detail.quoteData.topnewHeights - detail.quoteData.bottomnewHeights) * screen}vw - ${detail.quoteData.boxTop === 'on' ? '31px' : '26px' } )`,
                                  marginRight: '-1px',
                                  background: index === (detail.quoteData.numSecondInnerBoxes-1) ? 'transparent' : 'black',
                                  }}></div>

                                      {detail.quoteData.boxNames[index] === 'Shutter' && (detail.quoteData.boxHandle[index] === 'left' ? (
                                        <img
                                          src={Handle}
                                          style={{
                                            float: 'left',
                                            marginTop: `calc(${(((detail.quoteData.boxHeight - detail.quoteData.topnewHeights - detail.quoteData.bottomnewHeights) * screen / mCalc) / 2)}px - 15px`,
                                            marginRight: '2px',
                                            width: screen === 3 ? '5px' : '4px'
                                          }}

                                          alt="Left Handle"
                                        />
                                      ) : (
                                        <img
                                          src={Handle}
                                          style={{
                                            float: 'right',
                                            marginTop: `calc(${(((detail.quoteData.boxHeight - detail.quoteData.topnewHeights - detail.quoteData.bottomnewHeights) * screen / mCalc) / 2)}px - 15px`,
                                            marginRight: '2px',
                                            width: screen === 3 ? '5px' : '4px'
                                          }}

                                          alt="Right Handle"
                                        />

                                      ))}

                                      {detail.quoteData.mesh === 'yes' && detail.quoteData.boxNames[index] === 'Shutter' && detail.quoteData.boxHandle[index] === 'left' && (
                                        <img src={meshimg} style={{ width: screen === 3 ? '40px' : '20px', float: 'left', marginLeft: screen === 3 ? detail.quoteData.boxHandle[index] === 'left' ? '-8px' : '0px' : detail.quoteData.boxHandle[index] === 'left' ? '-8px' : 'px', marginTop: screen === 3 ? `calc(${((detail.quoteData.boxHeight - detail.quoteData.topnewHeights - detail.quoteData.bottomnewHeights) * screen)}px - ${detail.quoteData.boxTop === 'on' ? '72px' : '67px'} )` : `calc(${((detail.quoteData.boxHeight - detail.quoteData.topnewHeights - detail.quoteData.bottomnewHeights) * screen)}px - 50px)` }} />
                                      )}

                                      {detail.quoteData.mesh === 'yes' && detail.quoteData.boxNames[index] === 'Shutter' && detail.quoteData.boxHandle[index] === 'right' && (
                                        <img src={meshimg} style={{ width: screen === 3 ? '40px' : '20px', float: 'left', marginLeft: screen === 3 ? detail.quoteData.boxHandle[index] === 'right' ? '-1px' : '0px' : detail.quoteData.boxHandle[index] === 'right' ? '0px' : 'px', marginTop: screen === 3 ? `calc(${((detail.quoteData.boxHeight - detail.quoteData.topnewHeights - detail.quoteData.bottomnewHeights) * screen)}px - ${detail.quoteData.boxTop === 'on' ? '72px' : '67px'} )` : `calc(${((detail.quoteData.boxHeight - detail.quoteData.topnewHeights - detail.quoteData.bottomnewHeights) * screen)}px - 50px)` }} />
                                      )}

                                      {detail.quoteData.boxNames[index] === 'Shutter' && detail.quoteData.grill === 'yes' && createGrill(detail.quoteData.boxHeight, detail.quoteData.topnewHeights, detail.quoteData.bottomnewHeights, mCalc)}

                                    </div>

                                  ))}

                                </div>


                                {detail.quoteData.boxBottom === 'on' && (
                                  [...Array(detail.quoteData.numBottomBoxes)].map((_, index) => (
                                    <div style={{ display: 'flex', width: `calc(${detail.quoteData.boxWidth> 7 ? 6 : detail.quoteData.boxWidth * screen / detail.quoteData.numFrames + 'px'} - 23px)`, height: `calc(${(detail.quoteData.bottomnewHeights * screen) + 'vw'} -10px)`, margin: '5px' }}>
                                      {[...Array(detail.quoteData.bottomFixedNum)].map((_, index) => (
                                        <div style={{ flex: '1', background: 'lightgreen', width: `calc(${detail.quoteData.bottomFixedWidth[index] * screen / detail.quoteData.numFrames + 'px'} - 18px)`, height: `calc(${(detail.quoteData.bottomnewHeights * screen) + 'px'} - 5px)`, border: 'solid 1px #000', marginLeft: index === 0 ? '0px' : '5px', }}>

                                        </div>
                                      ))}
                                    </div>
                                  ))
                                )}






                              </div>
                            ))}
                          </div>


                        </div>
                        <br></br>

                        {detail.quoteData.values.wStrip.amount > 0 && (<> Weather Strip,</>)}   {detail.quoteData.values.mesh.amount > 0 && (<> Mesh 30A Grade,</>)} {detail.quoteData.values.mRoller.amount > 0 && (<> Mesh Roller,</>)}   {detail.quoteData.values.alTrack.amount > 0 && (<>  Aluminium Track,</>)}  {detail.quoteData.values.bArrester.amount > 0 && (<>  Bump Arrester,</>)}  {detail.quoteData.values.dArrester.amount > 0 && (<> Dust Arrester,</>)} {detail.quoteData.values.jStopper.amount > 0 && (<>  Jump Stopper,</>)} {detail.quoteData.values.gRoller.amount > 0 && (<>  Grove Roller,</>)} {detail.quoteData.values.touchlock.amount > 0 && (<> Touch Lock with SS Receiver,</>)}  {detail.quoteData.values.espag.amount > 0 && (<>  Espag with SS Receiver,</>)}    {detail.quoteData.values.frictionstay.amount > 0 && (<> Friction Stay,</>)}    {detail.quoteData.values.steelFrame.amount > 0 && (<> Steel Frame,</>)} {detail.quoteData.values.steelMullion.amount > 0 && (<> Steel Mullion,</>)} {detail.quoteData.values.mullionclamp.amount > 0 && (<> Mullion Clamp,</>)}  {detail.quoteData.values.steelShutter.amount > 0 && (<> Steel Shutter,</>)} {detail.quoteData.grill === 'yes' && (<> Grill,</>)} Screws/Misc

                      </>

                    )}

                    {detail.quoteData.item === 'Ventilator' && (
                      <>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                          {detail.quoteData.boxHeight > 1 && detail.quoteData.boxWidth > 1 && (

                            detail.quoteData.fan === 'on' ? (
                              <>

                                <div style={{ float: 'left', width: detail.quoteData.boxWidth * screen + 'px', height: detail.quoteData.boxHeight * screen + 'px', border: '1px solid #333' }}>

                                  {detail.quoteData.fanTop === 'Top' && detail.quoteData.fanLeft === 'Left' && divider === 'Vertical' && switchs === 'on' && (
                                    <div style={{ float: 'left', height: `calc(${detail.quoteData.boxHeight * screen}px - 10px)`, width: `calc(${detail.quoteData.boxWidth * screen}px - 10px)`, margin: '4px', border: '1px solid #333' }}>
                                      <img src={Fan} style={{ float: 'left', width: (detail.quoteData.boxWidth > 1.25 ? .85 : .55) * screen + 'PX', marginLeft: '4px', marginTop: '4px' }} />
                                      <div style={{ float: 'left', height: `calc(${(detail.quoteData.boxHeight) * screen}px - 20px)`, width: `calc(${(detail.quoteData.boxWidth - (detail.quoteData.boxWidth > 1.25 ? .85 : .55)) * screen}px - 26px)`, margin: '5px', border: '1px solid #333', backgroundImage: `repeating-linear-gradient(lightblue, white ${(1 / detail.quoteData.boxHeight) * screen}%, lightblue ${(1 / detail.quoteData.boxHeight) * screen}%)` }}></div>
                                      <div style={{ float: 'left', height: `calc(${(detail.quoteData.boxHeight - (detail.quoteData.boxWidth > 1.25 ? .85 : .55)) * screen}px - 25px)`, width: `calc(${(detail.quoteData.boxWidth > 1.25 ? .85 : .55) * screen}px - 0px)`, margin: '4px', marginTop: `calc(-${(detail.quoteData.boxHeight - (detail.quoteData.boxWidth > 1.25 ? .85 : .55)) * screen}px + 20px)`, border: '1px solid #333', background: 'lightblue' }}></div>
                                    </div>
                                  )}

                                  {detail.quoteData.fanTop === 'Bottom' && detail.quoteData.fanLeft === 'Left' && divider === 'Vertical' && switchs === 'on' && (
                                    <div style={{ float: 'left', height: `calc(${detail.quoteData.boxHeight * screen}px - 10px)`, width: `calc(${detail.quoteData.boxWidth * screen}px - 10px)`, margin: '4px', border: '1px solid #333' }}>
                                      <div style={{ float: 'left', height: `calc(${(detail.quoteData.boxHeight - (detail.quoteData.boxWidth > 1.25 ? .85 : .55)) * screen}px - 25px)`, width: `calc(${(detail.quoteData.boxWidth > 1.25 ? .85 : .55) * screen}px - 0px)`, margin: '4px', marginTop: '5px', border: '1px solid #333', background: 'lightblue' }}></div>
                                      <div style={{ float: 'right', height: `calc(${(detail.quoteData.boxHeight) * screen}px - 20px)`, width: `calc(${(detail.quoteData.boxWidth - (detail.quoteData.boxWidth > 1.25 ? .85 : .55)) * screen}px - 26px)`, margin: '5px', marginTop: `calc(-${(detail.quoteData.boxHeight - (detail.quoteData.boxWidth > 1.25 ? .85 : .55)) * screen}px + 20px)`, border: '1px solid #333', backgroundImage: `repeating-linear-gradient(lightblue, white ${(1 / detail.quoteData.boxHeight) * screen}%, lightblue ${(1 / detail.quoteData.boxHeight) * screen}%)` }}></div>
                                      <img src={Fan} style={{ float: 'left', width: (detail.quoteData.boxWidth > 1.25 ? .85 : .55) * screen + 'PX', marginLeft: '4px', marginBottom: '4px' }} />
                                    </div>
                                  )}

                                  {detail.quoteData.fanTop === 'Top' && detail.quoteData.fanLeft === 'Right' && divider === 'Vertical' && switchs === 'on' && (
                                    <div style={{ float: 'left', height: `calc(${detail.quoteData.boxHeight * screen}px - 10px)`, width: `calc(${detail.quoteData.boxWidth * screen}px - 10px)`, margin: '4px', border: '1px solid #333' }}>
                                      <img src={Fan} style={{ float: 'right', width: (detail.quoteData.boxWidth > 1.25 ? .85 : .55) * screen + 'PX', marginRight: '4px', marginTop: '4px' }} />
                                      <div style={{ float: 'left', height: `calc(${(detail.quoteData.boxHeight) * screen}px - 20px)`, width: `calc(${(detail.quoteData.boxWidth - (detail.quoteData.boxWidth > 1.25 ? .85 : .55)) * screen}px - 26px)`, margin: '5px', border: '1px solid #333', backgroundImage: `repeating-linear-gradient(lightblue, white ${(1 / detail.quoteData.boxHeight) * screen}%, lightblue ${(1 / detail.quoteData.boxHeight) * screen}%)` }}></div>
                                      <div style={{ float: 'right', height: `calc(${(detail.quoteData.boxHeight - (detail.quoteData.boxWidth > 1.25 ? .85 : .55)) * screen}px - 25px)`, width: `calc(${(detail.quoteData.boxWidth > 1.25 ? .85 : .55) * screen}px - 0px)`, margin: '4px', marginTop: `calc(-${(detail.quoteData.boxHeight - (detail.quoteData.boxWidth > 1.25 ? .85 : .55)) * screen}px + 20px)`, border: '1px solid #333', background: 'lightblue' }}></div>
                                    </div>
                                  )}

                                  {detail.quoteData.fanTop === 'Bottom' && detail.quoteData.fanLeft === 'Right' && divider === 'Vertical' && switchs === 'on' && (
                                    <div style={{ float: 'left', height: `calc(${detail.quoteData.boxHeight * screen}px - 10px)`, width: `calc(${detail.quoteData.boxWidth * screen}px - 10px)`, margin: '4px', border: '1px solid #333' }}>
                                      <div style={{ float: 'right', height: `calc(${(detail.quoteData.boxHeight - (detail.quoteData.boxWidth > 1.25 ? .85 : .55)) * screen}px - 25px)`, width: `calc(${(detail.quoteData.boxWidth > 1.25 ? .85 : .55) * screen}px - 0px)`, margin: '4px', marginTop: '5px', border: '1px solid #333', background: 'lightblue' }}></div>
                                      <div style={{ float: 'left', height: `calc(${(detail.quoteData.boxHeight) * screen}px - 20px)`, width: `calc(${(detail.quoteData.boxWidth - (detail.quoteData.boxWidth > 1.25 ? .85 : .55)) * screen}px - 26px)`, margin: '5px', marginTop: `calc(-${(detail.quoteData.boxHeight - (detail.quoteData.boxWidth > 1.25 ? .85 : .55)) * screen}px + 20px)`, border: '1px solid #333', backgroundImage: `repeating-linear-gradient(lightblue, white ${(6 / detail.quoteData.boxHeight) * screen}%, lightblue ${(6 / detail.quoteData.boxHeight) * screen}%)` }}></div>
                                      <img src={Fan} style={{ float: 'right', width: (detail.quoteData.boxWidth > 1.25 ? .85 : .55) * screen + 'PX', marginRight: '4px', marginBottom: '4px' }} />
                                    </div>
                                  )}

                                  {detail.quoteData.fanTop === 'Top' && detail.quoteData.fanLeft === 'Left' && divider === 'Vertical' && switchs === 'off' && (
                                    <div style={{ float: 'left', height: `calc(${detail.quoteData.boxHeight * screen}px - 10px)`, width: `calc(${detail.quoteData.boxWidth * screen}px - 10px)`, margin: '4px', border: '1px solid #333' }}>
                                      <img src={Fan} style={{ float: 'left', width: (detail.quoteData.boxWidth > 1.25 ? .85 : .55) * screen + 'PX', marginLeft: '4px', marginTop: '4px' }} />
                                      <div style={{ float: 'left', height: `calc(${(detail.quoteData.boxHeight) * screen}px - 20px)`, width: `calc(${(detail.quoteData.boxWidth - (detail.quoteData.boxWidth > 1.25 ? .85 : .55)) * screen}px - 26px)`, margin: '5px', border: '1px solid #333', background: 'lightblue' }}></div>
                                      <div style={{ float: 'left', height: `calc(${(detail.quoteData.boxHeight - (detail.quoteData.boxWidth > 1.25 ? .85 : .55)) * screen}px - 25px)`, width: `calc(${(detail.quoteData.boxWidth > 1.25 ? .85 : .55) * screen}px - 0px)`, margin: '4px', marginTop: `calc(-${(detail.quoteData.boxHeight - (detail.quoteData.boxWidth > 1.25 ? .85 : .55)) * screen}px + 20px)`, border: '1px solid #333', backgroundImage: `repeating-linear-gradient(lightblue, white ${(10 / detail.quoteData.boxHeight) * screen}%, lightblue ${(10 / detail.quoteData.boxHeight) * screen}%)` }}></div>
                                    </div>
                                  )}

                                  {detail.quoteData.fanTop === 'Bottom' && detail.quoteData.fanLeft === 'Left' && divider === 'Vertical' && switchs === 'off' && (
                                    <div style={{ float: 'left', height: `calc(${detail.quoteData.boxHeight * screen}px - 10px)`, width: `calc(${detail.quoteData.boxWidth * screen}px - 10px)`, margin: '4px', border: '1px solid #333' }}>
                                      <div style={{ float: 'left', height: `calc(${(detail.quoteData.boxHeight - (detail.quoteData.boxWidth > 1.25 ? .85 : .55)) * screen}px - 25px)`, width: `calc(${(detail.quoteData.boxWidth > 1.25 ? .85 : .55) * screen}px - 0px)`, margin: '4px', marginTop: '5px', border: '1px solid #333', backgroundImage: `repeating-linear-gradient(lightblue, white ${(10 / detail.quoteData.boxHeight) * screen}%, lightblue ${(10 / detail.quoteData.boxHeight) * screen}%)` }}></div>
                                      <div style={{ float: 'right', height: `calc(${(detail.quoteData.boxHeight) * screen}px - 20px)`, width: `calc(${(detail.quoteData.boxWidth - (detail.quoteData.boxWidth > 1.25 ? .85 : .55)) * screen}px - 26px)`, margin: '5px', marginTop: `calc(-${(detail.quoteData.boxHeight - (detail.quoteData.boxWidth > 1.25 ? .85 : .55)) * screen}px + 20px)`, border: '1px solid #333', background: 'lightblue' }}></div>
                                      <img src={Fan} style={{ float: 'left', width: (detail.quoteData.boxWidth > 1.25 ? .85 : .55) * screen + 'PX', marginLeft: '4px', marginBottom: '4px' }} />
                                    </div>
                                  )}

                                  {detail.quoteData.fanTop === 'Top' && detail.quoteData.fanLeft === 'Right' && divider === 'Vertical' && switchs === 'off' && (
                                    <div style={{ float: 'left', height: `calc(${detail.quoteData.boxHeight * screen}px - 10px)`, width: `calc(${detail.quoteData.boxWidth * screen}px - 10px)`, margin: '4px', border: '1px solid #333' }}>
                                      <img src={Fan} style={{ float: 'right', width: (detail.quoteData.boxWidth > 1.25 ? .85 : .55) * screen + 'PX', marginRight: '4px', marginTop: '4px' }} />
                                      <div style={{ float: 'left', height: `calc(${(detail.quoteData.boxHeight) * screen}px - 20px)`, width: `calc(${(detail.quoteData.boxWidth - (detail.quoteData.boxWidth > 1.25 ? .85 : .55)) * screen}px - 26px)`, margin: '5px', border: '1px solid #333', background: 'lightblue' }}></div>
                                      <div style={{ float: 'right', height: `calc(${(detail.quoteData.boxHeight - (detail.quoteData.boxWidth > 1.25 ? .85 : .55)) * screen}px - 25px)`, width: `calc(${(detail.quoteData.boxWidth > 1.25 ? .85 : .55) * screen}px - 0px)`, margin: '4px', marginTop: `calc(-${(detail.quoteData.boxHeight - (detail.quoteData.boxWidth > 1.25 ? .85 : .55)) * screen}px + 20px)`, border: '1px solid #333', backgroundImage: `repeating-linear-gradient(lightblue, white ${(10 / detail.quoteData.boxHeight) * screen}%, lightblue ${(10 / detail.quoteData.boxHeight) * screen}%)` }}></div>
                                    </div>
                                  )}

                                  {detail.quoteData.fanTop === 'Bottom' && detail.quoteData.fanLeft === 'Right' && divider === 'Vertical' && switchs === 'off' && (
                                    <div style={{ float: 'left', height: `calc(${detail.quoteData.boxHeight * screen}px - 10px)`, width: `calc(${detail.quoteData.boxWidth * screen}px - 10px)`, margin: '4px', border: '1px solid #333' }}>
                                      <div style={{ float: 'right', height: `calc(${(detail.quoteData.boxHeight - (detail.quoteData.boxWidth > 1.25 ? .85 : .55)) * screen}px - 25px)`, width: `calc(${(detail.quoteData.boxWidth > 1.25 ? .85 : .55) * screen}px - 0px)`, margin: '4px', marginTop: '5px', border: '1px solid #333', backgroundImage: `repeating-linear-gradient(lightblue, white ${(10 / detail.quoteData.boxHeight) * screen}%, lightblue ${(10 / detail.quoteData.boxHeight) * screen}%)` }}></div>
                                      <div style={{ float: 'left', height: `calc(${(detail.quoteData.boxHeight) * screen}px - 20px)`, width: `calc(${(detail.quoteData.boxWidth - (detail.quoteData.boxWidth > 1.25 ? .85 : .55)) * screen}px - 26px)`, margin: '5px', marginTop: `calc(-${(detail.quoteData.boxHeight - (detail.quoteData.boxWidth > 1.25 ? .85 : .55)) * screen}px + 20px)`, border: '1px solid #333', background: 'lightblue' }}></div>
                                      <img src={Fan} style={{ float: 'right', width: (detail.quoteData.boxWidth > 1.25 ? .85 : .55) * screen + 'PX', marginRight: '4px', marginBottom: '4px' }} />
                                    </div>
                                  )}

                                  {detail.quoteData.fanTop === 'Top' && detail.quoteData.fanLeft === 'Left' && divider === 'Horizontal' && switchs === 'on' && (
                                    <div style={{ float: 'left', height: `calc(${detail.quoteData.boxHeight * screen}px - 10px)`, width: `calc(${detail.quoteData.boxWidth * screen}px - 10px)`, margin: '4px', border: '1px solid #333' }}>
                                      <img src={Fan} style={{ float: 'left', width: (detail.quoteData.boxWidth > 1.25 ? .85 : .55) * screen + 'PX', marginLeft: '4px', marginTop: '4px' }} />
                                      <div style={{ float: 'right', height: `calc(${(detail.quoteData.boxWidth > 1.25 ? .85 : .55) * screen}px - 0px)`, width: `calc(${(detail.quoteData.boxWidth - (detail.quoteData.boxWidth > 1.25 ? .85 : .55)) * screen}px - 26px)`, margin: '5px', border: '1px solid #333', backgroundImage: `repeating-linear-gradient(lightblue, white ${(10 / detail.quoteData.boxHeight) * screen}%, lightblue ${(10 / detail.quoteData.boxHeight) * screen}%)` }}></div>
                                      <div style={{ float: 'left', height: `calc(${(detail.quoteData.boxHeight - (detail.quoteData.boxWidth > 1.25 ? .85 : .55)) * screen}px - 25px)`, width: `calc(${(detail.quoteData.boxWidth) * screen}px - 20px)`, margin: '4px', marginTop: '0px', border: '1px solid #333', background: 'lightblue' }}></div>
                                    </div>
                                  )}

                                  {detail.quoteData.fanTop === 'Bottom' && detail.quoteData.fanLeft === 'Left' && divider === 'Horizontal' && switchs === 'on' && (
                                    <div style={{ float: 'left', height: `calc(${detail.quoteData.boxHeight * screen}px - 10px)`, width: `calc(${detail.quoteData.boxWidth * screen}px - 10px)`, margin: '4px', border: '1px solid #333' }}>
                                      <div style={{ float: 'left', height: `calc(${(detail.quoteData.boxHeight - (detail.quoteData.boxWidth > 1.25 ? .85 : .55)) * screen}px - 25px)`, width: `calc(${(detail.quoteData.boxWidth) * screen}px - 20px)`, margin: '4px', marginTop: '5px', border: '1px solid #333', background: 'lightblue' }}></div>
                                      <div style={{ float: 'right', height: `calc(${(detail.quoteData.boxWidth > 1.25 ? .85 : .55) * screen}px - 0px)`, width: `calc(${(detail.quoteData.boxWidth - (detail.quoteData.boxWidth > 1.25 ? .85 : .55)) * screen}px - 26px)`, marginRight: '5px', marginBottom: '4px', border: '1px solid #333', backgroundImage: `repeating-linear-gradient(lightblue, white ${(10 / detail.quoteData.boxHeight) * screen}%, lightblue ${(10 / detail.quoteData.boxHeight) * screen}%)` }}></div>
                                      <img src={Fan} style={{ float: 'left', width: (detail.quoteData.boxWidth > 1.25 ? .85 : .55) * screen + 'PX', marginLeft: '4px', marginBottom: '4px' }} />
                                    </div>
                                  )}

                                  {detail.quoteData.fanTop === 'Top' && detail.quoteData.fanLeft === 'Right' && divider === 'Horizontal' && switchs === 'on' && (
                                    <div style={{ float: 'left', height: `calc(${detail.quoteData.boxHeight * screen}px - 10px)`, width: `calc(${detail.quoteData.boxWidth * screen}px - 10px)`, margin: '4px', border: '1px solid #333' }}>
                                      <img src={Fan} style={{ float: 'right', width: (detail.quoteData.boxWidth > 1.25 ? .85 : .55) * screen + 'PX', marginRight: '4px', marginTop: '4px' }} />
                                      <div style={{ float: 'left', height: `calc(${(detail.quoteData.boxWidth > 1.25 ? .85 : .55) * screen}px - 0px)`, width: `calc(${(detail.quoteData.boxWidth - (detail.quoteData.boxWidth > 1.25 ? .85 : .55)) * screen}px - 26px)`, margin: '5px', border: '1px solid #333', backgroundImage: `repeating-linear-gradient(lightblue, white ${(10 / detail.quoteData.boxHeight) * screen}%, lightblue ${(10 / detail.quoteData.boxHeight) * screen}%)` }}></div>
                                      <div style={{ float: 'left', height: `calc(${(detail.quoteData.boxHeight - (detail.quoteData.boxWidth > 1.25 ? .85 : .55)) * screen}px - 25px)`, width: `calc(${(detail.quoteData.boxWidth) * screen}px - 20px)`, margin: '4px', marginTop: '0px', border: '1px solid #333', background: 'lightblue' }}></div>
                                    </div>
                                  )}

                                  {detail.quoteData.fanTop === 'Bottom' && detail.quoteData.fanLeft === 'Right' && divider === 'Horizontal' && switchs === 'on' && (
                                    <div style={{ float: 'left', height: `calc(${detail.quoteData.boxHeight * screen}px - 10px)`, width: `calc(${detail.quoteData.boxWidth * screen}px - 10px)`, margin: '4px', border: '1px solid #333' }}>
                                      <div style={{ float: 'left', height: `calc(${(detail.quoteData.boxHeight - (detail.quoteData.boxWidth > 1.25 ? .85 : .55)) * screen}px - 25px)`, width: `calc(${(detail.quoteData.boxWidth) * screen}px - 20px)`, margin: '4px', marginTop: '5px', border: '1px solid #333', background: 'lightblue' }}></div>
                                      <div style={{ float: 'left', height: `calc(${(detail.quoteData.boxWidth > 1.25 ? .85 : .55) * screen}px - 0px)`, width: `calc(${(detail.quoteData.boxWidth - (detail.quoteData.boxWidth > 1.25 ? .85 : .55)) * screen}px - 26px)`, marginLeft: '5px', marginBottom: '4px', border: '1px solid #333', backgroundImage: `repeating-linear-gradient(lightblue, white ${(10 / detail.quoteData.boxHeight) * screen}%, lightblue ${(10 / detail.quoteData.boxHeight) * screen}%)` }}></div>
                                      <img src={Fan} style={{ float: 'right', width: (detail.quoteData.boxWidth > 1.25 ? .85 : .55) * screen + 'PX', marginRight: '4px', marginBottom: '4px' }} />
                                    </div>
                                  )}

                                  {detail.quoteData.fanTop === 'Top' && detail.quoteData.fanLeft === 'Left' && divider === 'Horizontal' && switchs === 'off' && (
                                    <div style={{ float: 'left', height: `calc(${detail.quoteData.boxHeight * screen}px - 10px)`, width: `calc(${detail.quoteData.boxWidth * screen}px - 10px)`, margin: '4px', border: '1px solid #333' }}>
                                      <img src={Fan} style={{ float: 'left', width: (detail.quoteData.boxWidth > 1.25 ? .85 : .55) * screen + 'PX', marginLeft: '4px', marginTop: '4px' }} />
                                      <div style={{ float: 'right', height: `calc(${(detail.quoteData.boxWidth > 1.25 ? .85 : .55) * screen}px - 0px)`, width: `calc(${(detail.quoteData.boxWidth - (detail.quoteData.boxWidth > 1.25 ? .85 : .55)) * screen}px - 26px)`, margin: '5px', border: '1px solid #333', background: 'lightblue' }}></div>
                                      <div style={{ float: 'left', height: `calc(${(detail.quoteData.boxHeight - (detail.quoteData.boxWidth > 1.25 ? .85 : .55)) * screen}px - 25px)`, width: `calc(${(detail.quoteData.boxWidth) * screen}px - 20px)`, margin: '4px', marginTop: '0px', border: '1px solid #333', backgroundImage: `repeating-linear-gradient(lightblue, white ${(10 / detail.quoteData.boxHeight) * screen}%, lightblue ${(10 / detail.quoteData.boxHeight) * screen}%)` }}></div>
                                    </div>
                                  )}

                                  {detail.quoteData.fanTop === 'Top' && detail.quoteData.fanLeft === 'Right' && divider === 'Horizontal' && switchs === 'off' && (
                                    <div style={{ float: 'left', height: `calc(${detail.quoteData.boxHeight * screen}px - 10px)`, width: `calc(${detail.quoteData.boxWidth * screen}px - 10px)`, margin: '4px', border: '1px solid #333' }}>
                                      <img src={Fan} style={{ float: 'right', width: (detail.quoteData.boxWidth > 1.25 ? .85 : .55) * screen + 'PX', marginRight: '4px', marginTop: '4px' }} />
                                      <div style={{ float: 'left', height: `calc(${(detail.quoteData.boxWidth > 1.25 ? .85 : .55) * screen}px - 0px)`, width: `calc(${(detail.quoteData.boxWidth - (detail.quoteData.boxWidth > 1.25 ? .85 : .55)) * screen}px - 26px)`, margin: '5px', border: '1px solid #333', background: 'lightblue' }}></div>
                                      <div style={{ float: 'left', height: `calc(${(detail.quoteData.boxHeight - (detail.quoteData.boxWidth > 1.25 ? .85 : .55)) * screen}px - 25px)`, width: `calc(${(detail.quoteData.boxWidth) * screen}px - 20px)`, margin: '4px', marginTop: '0px', border: '1px solid #333', backgroundImage: `repeating-linear-gradient(lightblue, white ${(10 / detail.quoteData.boxHeight) * screen}%, lightblue ${(10 / detail.quoteData.boxHeight) * screen}%)` }}></div>
                                    </div>
                                  )}

                                  {detail.quoteData.fanTop === 'Bottom' && detail.quoteData.fanLeft === 'Right' && divider === 'Horizontal' && switchs === 'off' && (
                                    <div style={{ float: 'left', height: `calc(${detail.quoteData.boxHeight * screen}px - 10px)`, width: `calc(${detail.quoteData.boxWidth * screen}px - 10px)`, margin: '4px', border: '1px solid #333' }}>
                                      <div style={{ float: 'left', height: `calc(${(detail.quoteData.boxHeight - (detail.quoteData.boxWidth > 1.25 ? .85 : .55)) * screen}px - 25px)`, width: `calc(${(detail.quoteData.boxWidth) * screen}px - 20px)`, margin: '4px', marginTop: '5px', border: '1px solid #333', backgroundImage: `repeating-linear-gradient(lightblue, white ${(10 / detail.quoteData.boxHeight) * screen}%, lightblue ${(10 / detail.quoteData.boxHeight) * screen}%)` }}></div>
                                      <img src={Fan} style={{ float: 'right', width: (detail.quoteData.boxWidth > 1.25 ? .85 : .55) * screen + 'PX', marginRight: '4px', marginTop: '0px' }} />
                                      <div style={{ float: 'left', height: `calc(${(detail.quoteData.boxWidth > 1.25 ? .85 : .55) * screen}px - 0px)`, width: `calc(${(detail.quoteData.boxWidth - (detail.quoteData.boxWidth > 1.25 ? .85 : .55)) * screen}px - 26px)`, marginLeft: '4px', border: '1px solid #333', background: 'lightblue' }}></div>
                                    </div>
                                  )}

                                  {detail.quoteData.fanTop === 'Bottom' && detail.quoteData.fanLeft === 'Left' && divider === 'Horizontal' && switchs === 'off' && (
                                    <div style={{ float: 'left', height: `calc(${detail.quoteData.boxHeight * screen}px - 10px)`, width: `calc(${detail.quoteData.boxWidth * screen}px - 10px)`, margin: '4px', border: '1px solid #333' }}>
                                      <div style={{ float: 'left', height: `calc(${(detail.quoteData.boxHeight - (detail.quoteData.boxWidth > 1.25 ? .85 : .55)) * screen}px - 25px)`, width: `calc(${(detail.quoteData.boxWidth) * screen}px - 20px)`, margin: '4px', marginTop: '5px', border: '1px solid #333', backgroundImage: `repeating-linear-gradient(lightblue, white ${(10 / detail.quoteData.boxHeight) * screen}%, lightblue ${(10 / detail.quoteData.boxHeight) * screen}%)` }}></div>
                                      <img src={Fan} style={{ float: 'left', width: (detail.quoteData.boxWidth > 1.25 ? .85 : .55) * screen + 'PX', marginLeft: '4px', marginRight: '4px' }} />
                                      <div style={{ float: 'right', height: `calc(${(detail.quoteData.boxWidth > 1.25 ? .85 : .55) * screen}px - 0px)`, width: `calc(${(detail.quoteData.boxWidth - (detail.quoteData.boxWidth > 1.25 ? .85 : .55)) * screen}px - 26px)`, marginRight: '5px', border: '1px solid #333', background: 'lightblue' }}></div>
                                    </div>
                                  )}

                                </div>

                              </>

                            ) : (
                              <>
                                <div style={{ float: 'left', width: detail.quoteData.boxWidth * screen + 'px', height: detail.quoteData.boxHeight * screen + 'px', border: '1px solid #333' }}>
                                  {detail.quoteData.switchFixed === 'on' ? (
                                    <div style={{ float: 'right', height: `calc(${(detail.quoteData.boxHeight) * screen}px - 12px)`, width: `calc(${(detail.quoteData.boxWidth) * screen}px - 12px)`, marginRight: '5px', marginTop: '5px', background: 'lightblue', border: '1px solid #333' }}></div>
                                  ) : (
                                    <>
                                      <div style={{ float: 'right', height: `calc(${(detail.quoteData.boxHeight) * screen}px - 12px)`, width: `calc(${(detail.quoteData.boxWidth) * screen}px - 12px)`, marginRight: '5px', marginTop: '5px', backgroundImage: `repeating-linear-gradient(lightblue, white ${(1 / detail.quoteData.boxHeight) * screen}%, lightblue ${(1 / detail.quoteData.boxHeight) * screen}%)`, border: '1px solid #333' }}></div>
                                    </>
                                  )}

                                </div>
                              </>
                            )
                          )}

                          {detail.quoteData.boxHeight === 1 && detail.quoteData.boxWidth > 1 && (

                            detail.quoteData.fan === 'on' ? (
                              <>
                                {detail.quoteData.switchFixed === 'on' && (

                                  <div style={{ float: 'left', width: detail.quoteData.boxWidth * screen + 'px', height: detail.quoteData.boxHeight * screen + 'px', border: '1px solid #333' }}>
                                    <div style={{ float: 'left', height: `calc(${detail.quoteData.boxHeight * screen}px - 10px)`, width: `calc(${detail.quoteData.boxWidth * screen}px - 10px)`, margin: '4px', border: '1px solid #333' }}>
                                      <img src={Fan} style={{ float: 'left', width: .75 * screen + 'px', margin: '4px', }} />
                                      <div style={{ float: 'right', height: `calc(${(detail.quoteData.boxHeight) * screen}px - 22px)`, width: `calc(${(detail.quoteData.boxWidth - .94) * screen}px - 10px)`, marginRight: '5px', marginTop: '5px', background: 'lightblue', border: '1px solid #333' }}></div>
                                    </div>
                                  </div>
                                )}

                                {detail.quoteData.switchFixed === 'off' && (

                                  <div style={{ float: 'left', width: detail.quoteData.boxWidth * screen + 'px', height: detail.quoteData.boxHeight * screen + 'px', border: '1px solid #333' }}>
                                    <div style={{ float: 'left', height: `calc(${detail.quoteData.boxHeight * screen}px - 10px)`, width: `calc(${detail.quoteData.boxWidth * screen}px - 10px)`, margin: '4px', border: '1px solid #333' }}>
                                      <img src={Fan} style={{ float: 'left', width: .75 * screen + 'px', margin: '4px', }} />
                                      <div style={{ float: 'right', height: `calc(${(detail.quoteData.boxHeight) * screen}px - 22px)`, width: `calc(${(detail.quoteData.boxWidth - .94) * screen}px - 10px)`, marginRight: '5px', marginTop: '5px', backgroundImage: `repeating-linear-gradient(lightblue, white ${(1 / detail.quoteData.boxHeight) * screen}%, lightblue ${(1 / detail.quoteData.boxHeight) * screen}%)`, border: '1px solid #333' }}></div>
                                    </div>
                                  </div>
                                )}
                              </>
                            ) : (

                              <div style={{ float: 'left', width: detail.quoteData.boxWidth * screen + 'px', height: detail.quoteData.boxHeight * screen + 'px', border: '1px solid #333' }}>
                                {detail.quoteData.switchFixed === 'off' ?
                                  <div style={{ float: 'right', height: `calc(${(detail.quoteData.boxHeight) * screen}px - 12px)`, width: `calc(${(detail.quoteData.boxWidth) * screen}px - 10px)`, marginRight: '5px', marginTop: '0px', backgroundImage: `repeating-linear-gradient(lightblue, white ${(1 / detail.quoteData.boxHeight) * screen}%, lightblue ${(1 / detail.quoteData.boxHeight) * screen}%)`, border: '1px solid #333' }}></div>
                                  :
                                  <div style={{ float: 'right', height: `calc(${(detail.quoteData.boxHeight) * screen}px - 12px)`, width: `calc(${(detail.quoteData.boxWidth) * screen}px - 10px)`, marginRight: '5px', marginTop: '0px', background: 'lightblue', border: '1px solid #333' }}></div>
                                }
                              </div>


                            )

                          )}

                          {detail.quoteData.boxHeight >= 1 && detail.quoteData.boxWidth == 1 && (
                            detail.quoteData.fan === 'on' ? (
                              <>
                                {detail.quoteData.switchFixed === 'on' && (
                                  <div style={{ float: 'left', width: detail.quoteData.boxWidth * screen + 'px', height: detail.quoteData.boxHeight * screen + 'px', border: '1px solid #333' }}>
                                    <div style={{ float: 'left', height: `calc(${detail.quoteData.boxHeight * screen}px - 10px)`, width: `calc(${detail.quoteData.boxWidth * screen}px - 10px)`, margin: '4px', border: '1px solid #333' }}>
                                      <img src={Fan} style={{ float: 'left', width: .75 * screen + 'px', margin: '4px', }} />
                                      <div style={{ float: 'right', height: `calc(${(detail.quoteData.boxHeight - .75) * screen}px - 25px)`, width: `calc(${(detail.quoteData.boxWidth) * screen}px - 20px)`, marginRight: '5px', background: 'lightblue', border: '1px solid #333' }}></div>
                                    </div>
                                  </div>
                                )}

                                {detail.quoteData.switchFixed === 'off' && (
                                  <div style={{ float: 'left', width: detail.quoteData.boxWidth * screen + 'px', height: detail.quoteData.boxHeight * screen + 'px', border: '1px solid #333' }}>
                                    <div style={{ float: 'left', height: `calc(${detail.quoteData.boxHeight * screen}px - 10px)`, width: `calc(${detail.quoteData.boxWidth * screen}px - 10px)`, margin: '4px', border: '1px solid #333' }}>
                                      <img src={Fan} style={{ float: 'left', width: .75 * screen + 'px', margin: '4px', }} />
                                      <div style={{ float: 'right', height: `calc(${(detail.quoteData.boxHeight - .75) * screen}px - 25px)`, width: `calc(${(detail.quoteData.boxWidth) * screen}px - 20px)`, marginRight: '5px', backgroundImage: `repeating-linear-gradient(lightblue, white ${(1 / detail.quoteData.boxHeight) * screen}%, lightblue ${(1 / detail.quoteData.boxHeight) * screen}%)`, border: '1px solid #333' }}></div>
                                    </div>
                                  </div>
                                )}
                              </>
                            ) : (
                              <div style={{ float: 'left', width: detail.quoteData.boxWidth * screen + 'px', height: detail.quoteData.boxHeight * screen + 'px', border: '1px solid #333' }}>
                                {detail.quoteData.switchFixed === 'off' ?
                                  <div style={{ float: 'right', height: `calc(${(detail.quoteData.boxHeight) * screen}px - 12px)`, width: `calc(${(detail.quoteData.boxWidth) * screen}px - 12px)`, marginRight: '5px', marginTop: '5px', backgroundImage: `repeating-linear-gradient(lightblue, white ${(1 / detail.quoteData.boxHeight) * screen}%, lightblue ${(1 / detail.quoteData.boxHeight) * screen}%)`, border: '1px solid #333' }}></div>
                                  :
                                  <div style={{ float: 'right', height: `calc(${(detail.quoteData.boxHeight) * screen}px - 12px)`, width: `calc(${(detail.quoteData.boxWidth) * screen}px - 12px)`, marginRight: '5px', marginTop: '5px', background: 'lightblue', border: '1px solid #333' }}></div>
                                }
                              </div>
                            )
                          )}


                        </div>

                        <br></br>
                        {detail.quoteData.values.steelFrame.amount > 0 && (<> Steel Frame,</>)} {detail.quoteData.values.steelMullion.amount > 0 && (<> Steel Mullion,</>)} {detail.quoteData.values.LouverBlades.amount > 0 && (<> Louver Blades,</>)} {detail.quoteData.grill === 'yes' && (<> Grill,</>)} Screws/Misc

                        </>
                    )}

                  </td>




                  <td style={{ border: '1px solid #666', padding: '2px', textAlign: 'center', color: "#000", fontSize: '12px', }}>{detail.quoteData?.series?.match(/(\d+)/)?.[0]}</td>
                  <td style={{ border: '1px solid #666', padding: '2px', textAlign: 'center', color: "#000", width: "4%", fontSize: '12px', }}>{numeral(detail.quoteData.boxWidth * 1).format('0.00')} </td>
                  <td style={{ border: '1px solid #666', padding: '2px', textAlign: 'center', color: "#000", width: "4%", fontSize: '12px', }}>{numeral(detail.quoteData.boxHeight).format('0.00')} </td>
                  <td style={{ border: '1px solid #666', padding: '2px', textAlign: 'center', color: "#000", fontSize: '12px', }}>{numeral(detail.quoteData.frameArea).format('0.00')}</td>
                  <td style={{ border: '1px solid #666', padding: '2px', textAlign: 'center', color: "#000", fontSize: '12px', }}>{detail.quoteData.qty}</td>
                  <td style={{ border: '1px solid #666', padding: '2px', textAlign: 'center', color: "#000", fontSize: '12px', }}>{numeral(detail.quoteData.frameArea * detail.quoteData.qty).format('0.00')}</td>
                  <td style={{ border: '1px solid #666', padding: '2px', textAlign: 'center', color: "#000", fontSize: '12px', }}>₹ {numeral(detail.quoteData.grandTotal * detail.quoteData.qty).format('0,0.00')}</td>
                </tr>

              ))}




              {/* Add more rows as needed */}
            </tbody>
          ))}

          <tfoot>
            <tr >

              <th colspan="3" style={{ border: '1px solid #666', textAlign: "right", padding: '10px', fontSize: '12px', color: "#000" }}>Average Rate/sqft</th>
              <td colspan="2" style={{ border: '1px solid #666', textAlign: "left", padding: '10px', paddingLeft:'50px ',fontSize: '12px', color: "#000" }}>{numeral(average).format('0.00')}/sqft</td>
              <th colspan="1" style={{ border: '1px solid #666', textAlign: "right", padding: '10px', fontSize: '12px', color: "#000" }}>Total</th>
              <td style={{ border: '1px solid #666', textAlign: "right", padding: '2px', textAlign: 'center', color: "#000", fontSize: '12px', }}>{qty}</td>
              <td style={{ border: '1px solid #666', textAlign: "right", padding: '2px', textAlign: 'center', color: "#000", fontSize: '12px', }}>{numeral(totalframearea).format('0.00')} sqft</td>
              <td style={{ border: '1px solid #666', textAlign: "right", padding: '10px', fontSize: '12px', color: "#000" }}>₹ {numeral(total).format('0,0.00')}</td>
            </tr>

            <tr>
              <td colspan="6" ></td>
              <th colspan="2" style={{ border: '1px solid #666', textAlign: "left", padding: '10px', fontSize: '12px', color: "#000" }}>Discount</th>
              <th style={{ border: '1px solid #666', textAlign: "right", padding: '10px', fontSize: '12px', color: "#000" }}>{discount} %</th>
            </tr>

            <tr >
              <td colspan="1" rowspan="2" width="13%" style={{ textAlign: "left", padding: '10px', fontSize: '12px', color: "#000" }}>

                <b>Bank<br />
                  Branch<br />
                  A/c Type<br />
                  A/C Number<br />
                  IFS Code<br /></b>

              </td>
              <td colspan="3" rowspan="2" style={{ textAlign: "left", padding: '10px', fontSize: '12px', color: "#000" }}>
                :  {brandDatas && brandDatas[0].companyBank}<br />
                :  {brandDatas && brandDatas[0].companyBranch}<br />
                :  {brandDatas && brandDatas[0].companyBankType}<br />
                :  {brandDatas && brandDatas[0].companyAcNum}<br />
                :  {brandDatas && brandDatas[0].companyIfsc}<br />

              </td>
              <td colspan="2"></td>
              {toggle === false ? (
                <> <th colspan="2" style={{ border: '1px solid #666', textAlign: "left", padding: '10px', fontSize: '12px', color: "#000" }}>GST</th>
              <td style={{ border: '1px solid #666', textAlign: "right", padding: '10px', fontSize: '12px', color: "#000" }}>Gst 18% Extra</td>
           </>
              ):
              (<> <th colspan="2" style={{ border: '1px solid #666', textAlign: "left", padding: '10px', fontSize: '12px', color: "#000" }}>GST 18%</th>
                <td style={{ border: '1px solid #666', textAlign: "right", padding: '10px', fontSize: '12px', color: "#000" }}>₹ {numeral(gst).format('0,0.00')}</td>
             </>)
             }
            </tr>
            <tr>
              <td colspan="2" ></td>
              <th colspan="2" style={{ border: '1px solid #666', textAlign: "left", padding: '10px', fontSize: '12px', color: "#000" }}>Grand Total</th>
              {toggle === false ? (<>
              <th style={{ border: '1px solid #666', textAlign: "right", padding: '10px', fontSize: '12px', color: "#000" }}>₹ {numeral(totalaftrdis).format('0,0.00')}</th>
              </>
              ):
              (<> <th style={{ border: '1px solid #666', textAlign: "right", padding: '10px', fontSize: '12px', color: "#000" }}>₹  {numeral(totalaftrdis+gst).format('0,0.00')}</th>
             </>)
             }
            </tr>
          </tfoot>




        </table>
        <table width="100%" class="mt-3">
          <tr>
            <td style={{ textAlign: 'left', padding: '80px 0px 0px 8px', color: "#000", fontSize: '12px' }}> for {filteredQuotes && filteredQuotes[0]?.client?.name} </td>
            <td style={{ textAlign: 'right', padding: '80px 8px 0px 0px', color: "#000", fontSize: '12px' }}>for {brandDatas && brandDatas[0].companyName}</td>
          </tr>
          <tr><td></td></tr>
          <tr><td></td></tr>
          <tr>
            <td style={{ textAlign: 'left', color: "#000", fontSize: '12px' }}>  </td>
            <td style={{ textAlign: 'right', color: "#000", fontSize: '12px' }}> {Name} </td>
          </tr>
          <tr>
            <td style={{ textAlign: 'left', color: "#000", fontSize: '12px' }}> {filteredQuotes && filteredQuotes[0]?.client?.number} </td>
            <td style={{ textAlign: 'right', color: "#000", fontSize: '12px' }}> {Number} </td>
          </tr>
          <tr><td colspan='2'><hr></hr></td></tr>

          <tr>
            <td colspan='2' style={{ textAlign: 'right' }}>
              <p> Powered By <img src="assets/images/sni-logo.png" width='90px' alt='Logo' /></p>
            </td>
          </tr>
        </table>

      </div>

    </div>

  )
}
export default Downlaod;
