import React, { useEffect, useState } from 'react';
import { ReactSession } from 'react-client-session';
import axios from 'axios';
import Navbar from './navbar';
import Header from './header';
import numeral from 'numeral';
import left from '../images/handleLeft.png';
import right from '../images/handleRight.png';
import meshimg from '../images/mesh.png';
import Handle from '../images/slideHandle.png';
import Fan from '../images/fan.png';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import HomeComponent from './download';
import ScheduleComponent from './cuttingSchedule';
import Invoice from './invoice';
import html2pdf from 'html2pdf.js';
import './box.css';





function Quotesdet({ quotes }) {
  const urlParams = new URLSearchParams(window.location.search);
  const quoteIds = urlParams.get('id');
  const arrayValues = urlParams.get('arrayValues');
  //console.log(quoteIds);
  const userId = ReactSession.get('upvcuserId');
  const [quoteData, setQuoteData] = useState(null);
  const [filteredArray, setFilteredArray] = useState([]);

  const [bottomFrame, setBottomFrame] = useState('yes');
  const [mesh, setMesh] = useState('no');
  const [panelHeights, setpanelHeight] = useState(0);
  const [deviceWidth, setDeviceWidth] = useState(window.innerWidth);
  const [screen, setScreen] = useState(deviceWidth > 700 ? 3 : 8);
  const [divider, setDevider] = useState('Vertical');
  const [switchs, setSwitch] = useState('on');
  const [mCalc, setMcalc] = useState(1);

  const [total, setTotal] = useState(0);
  const [gst, setGst] = useState(0);
  const Empid = ReactSession.get("upvcempid");
  const userType = ReactSession.get("upvcuserType");
  const [Invoicedata, setInvoicedata] = useState('');
  const [downloadData, setDownloadData] = useState('');
  const [matchedQuotes, setMatchedQuotes] = useState([]);
  //console.log(matchedQuotes);
  // console.log(matchedQuotes[0]?.invoiceGst);
  const [isToggled, setIsToggled] = useState(false); 
console.log(isToggled);


  let users = '';
  if (userType === '3') {
    users = Empid;
  } else {
    users = userId;
  }
  const user = { "userId": users };


  // Function to handle the toggle action
  const toggleButton = () => {
    setIsToggled(prevState => !prevState);
  };


  useEffect(() => {
    const updateDeviceWidth = () => {
      setDeviceWidth(window.innerWidth);
      setScreen(window.innerWidth > 700 ? 3 : 8);
    };

    window.addEventListener('resize', updateDeviceWidth);

    return () => {
      window.removeEventListener('resize', updateDeviceWidth);
    };
  }, []);

  const fetchDatalog = async (quoteIds, detailIndex) => {
    const formData = {
      quoteId: quoteIds,
      rowId: detailIndex,
    }
    try {
      const response = await axios.post(`https://upvcapi.sninfoserv.com/log/`, { userId, "removequote": formData });
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };


  const InvoiceData = async () => {

    try {
      const response = await axios.get('https://upvcapi.sninfoserv.com/invoice/', {
        params: { user: userId }
      });
      // console.log("invoice", response.data);
      setInvoicedata(response.data);

    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const DownloadData = async () => {

    try {
      const response = await axios.get('https://upvcapi.sninfoserv.com/download/', {
        params: { quoteID: quoteIds }
      });
      //  console.log("download:", response.data.data);
      //  console.log("download:",response.data.data[0].discount);
      setDownloadData(response.data.data[0].discount);

    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };




  useEffect(() => {
    DownloadData();
    InvoiceData();
  }, []);


  useEffect(() => {
    const singleInvoice = Invoicedata && Invoicedata.data && Invoicedata.data.filter(invoice => invoice.quoteID === quoteIds);
    //  console.log('s_inv',singleInvoice);

    setMatchedQuotes(singleInvoice); // Store all matched quotes in state
  }, [Invoicedata]);

  const removequote = async (quoteIds, detailIndex) => {
    fetchDatalog(quoteIds, detailIndex);
    const formData = {
      quoteId: quoteIds,
      rowId: detailIndex,
    }
    //console.log(formData);
    try {
      const response = await axios.post(`https://upvcapi.sninfoserv.com/item-delete/`, formData);
      //console.log(response.data);
      if (response.data.status === 'succes') {
        window.location.reload();
        fetchData();
        window.location.reload();
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const fetchData = async () => {
    try {
      const response = await axios.post(`https://upvcapi.sninfoserv.com/all-quotes/`, user);
      setQuoteData(response.data);
      console.log(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();

  }, []);

  // Ensure quoteData is valid before using filter method
  const filteredQuotes = quoteData && quoteData.data.filter(quote => quote.quoteId === quoteIds);
  //console.log(filteredQuotes);

  useEffect(() => {

    if (arrayValues) {
      // Split arrayValues string into an array of values
      const valuesArray = arrayValues.split(',');

      // Filter quotes based on valuesArray
      const filtered = quotes.filter(quote =>
        valuesArray.every(value => quote.details.includes(value))
      );

      setFilteredArray(filtered);
      //console.log(filtered);
    }
  }, [arrayValues, quotes]);



  useEffect(() => {
    if (filteredQuotes && filteredQuotes.length > 0) {
      let sum = 0;
      filteredQuotes.forEach(quote => {
        quote && quote.quoteDetails && quote.quoteDetails.forEach(detail => {
          sum += (detail.quoteData.grandTotal * detail.quoteData.qty);
          //  console.log(detail.quoteData.qty);
        });

      });
      setTotal(sum);
      setGst((18 / 100) * sum);
    }
  }, [filteredQuotes]);



  const openModal = () => {
    const modal = document.getElementById('onloadModal');
    if (modal) {
      modal.classList.add('show');
      modal.style.display = 'block';
      document.body.classList.add('modal-open');
    }
  };

  const closeModal = () => {
    const modal = document.getElementById('onloadModal');
    if (modal) {
      modal.classList.remove('show');
      modal.style.display = 'none';
      document.body.classList.remove('modal-open');
    }

  };

  const openModals = () => {
    const modal = document.getElementById('onloadModals');
    if (modal) {
      modal.classList.add('show');
      modal.style.display = 'block';
      document.body.classList.add('modal-open');
    }
  };

  const closeModals = () => {
    const modal = document.getElementById('onloadModals');
    if (modal) {
      modal.classList.remove('show');
      modal.style.display = 'none';
      document.body.classList.remove('modal-open');
    }
  };

  const openModals1 = () => {
    const modal = document.getElementById('onloadModals1');
    if (modal) {
      modal.classList.add('show');
      modal.style.display = 'block';
      document.body.classList.add('modal-open');
    }
  };

  const closeModals1 = () => {
    const modal = document.getElementById('onloadModals1');
    if (modal) {
      modal.classList.remove('show');
      modal.style.display = 'none';
      document.body.classList.remove('modal-open');
    }

  };

  const submitDownload = async () => {
    closeModaldownload();

    const fromData = {
      user: users,
      client: filteredQuotes && filteredQuotes[0].client && filteredQuotes[0].client.number,
      quoteID: quoteIds,
      Discount: downloadData,

    }
    // console.log(fromData);
    try {
      const response = await axios.post(`https://upvcapi.sninfoserv.com/download/`, fromData);
      // console.log(response.data);
      if (response.data.status === 'success') {
        handleDownloadPDF();
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  const handleDownloadPDF = async () => {
    openModal();
    setTimeout(() => {
      handleDownload();
    }, 5000);
  }



  const handleDownload = async () => {

    const input = document.getElementById('pdf-content');
    input.classList.remove('hide');
    // Configuration for html2pdf
    const options = {
      margin: 0,
      filename: filteredQuotes && filteredQuotes[0].client && filteredQuotes[0].client.name
        ? `Quote-${filteredQuotes[0].client.name}-${quoteIds}.pdf`
        : 'document.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 4 },
      jsPDF: { unit: 'pt', format: 'a4', orientation: 'portrait' },
      // pagebreak: { avoid: ['tr', 'td'] }
      pagebreak: {
        mode: ['avoid-all', 'css', 'legacy']
      },
    };
    // Convert HTML to PDF
    html2pdf().from(input).set(options).save();
    setTimeout(() => {
      input.classList.add('hide');
    }, 1000);

    closeModal();

  };



  const submitinvoice = async () => {
    closeModalInvoice();

    const fromData = {
      user: users,
      client: filteredQuotes && filteredQuotes[0].client && filteredQuotes[0].client.number,
      quoteID: quoteIds,
      Invoicenumber: inputs.invoice,
      gstno: inputs.gst,
      date: inputs.date,
      Discount: downloadData,
    }
    //console.log(fromData);
    try {
      const response = await axios.post(`https://upvcapi.sninfoserv.com/invoice/`, fromData);
      // console.log(response.data);
      if (response.data.status === 'success') {
        handleInvoiceDownloadPDF();
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }


  const handleInvoiceDownloadPDF = async () => {

    openModals1();
    const input = document.getElementById('pdf-content-invoice');
    input.classList.remove('hide');
    // Configuration for html2pdf
    const options = {
      margin: 0,
      filename: filteredQuotes && filteredQuotes[0].client && filteredQuotes[0].client.name
        ? `invoice-${filteredQuotes[0].client.name}-${quoteIds}.pdf`
        : 'document.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 4 },
      jsPDF: { unit: 'pt', format: 'a4', orientation: 'portrait' },
      // pagebreak: { avoid: ['tr', 'td'] }
      pagebreak: {
        mode: ['avoid-all', 'css', 'legacy']
      },
    };
    // Convert HTML to PDF
    html2pdf().from(input).set(options).save();
    setTimeout(() => {
      input.classList.add('hide');
    }, 1000);
    closeModals1();
  };






  const handleSchedulePDF = async () => {
    openModals();
    const inputs = document.getElementById('pdf-schedule');
    inputs.classList.remove('hide');

    const pdfs = new jsPDF({
      orientation: 'portrait', // Use 'portrait' or 'landscape' as per your requirement
      unit: 'pt', // Set unit to millimeters
      format: 'a4', // Set format to A4
    });
    const inputHeight = inputs.clientHeight;
    const pageHeight = pdfs.internal.pageSize.getHeight() + 600;
    let currentPosition = 0;
    const scaleFactor = 4;

    // Increase this value for higher resolution and better quality

    while (currentPosition < inputHeight) {
      const canvas = await html2canvas(inputs, {
        height: Math.min(pageHeight),

        y: currentPosition,
        scrollY: -currentPosition,
        scale: scaleFactor
      });




      const imgData = canvas.toDataURL('image/png', 4);
      pdfs.addImage(imgData, 'PNG', 5, 5, 580, pdfs.internal.pageSize.getHeight(), '', 'FAST');
      //currentPosition +=canvas.height;
      currentPosition += pageHeight;
      if (currentPosition < inputHeight) {
        pdfs.addPage();
      }

    }
    pdfs.save('Cutting-Schedule-' + filteredQuotes && filteredQuotes[0].client && filteredQuotes[0].client.name + '-' + quoteIds + '.pdf');
    setTimeout(() => {
      inputs.classList.add('hide');
    }, 1000);

    closeModals();


  };


  const openModalInvoice = () => {
    const modal = document.getElementById('onloadModalInvoice');
    if (modal) {
      modal.classList.add('show');
      modal.style.display = 'block';
      document.body.classList.add('modal-open');
    }
  };

  const closeModalInvoice = () => {
    const modal = document.getElementById('onloadModalInvoice');
    if (modal) {
      modal.classList.remove('show');
      modal.style.display = 'none';
      document.body.classList.remove('modal-open');
    }
  };


  const openModaldownload = () => {

    const modal = document.getElementById('onloadModalDownload');
    if (modal) {
      modal.classList.add('show');
      modal.style.display = 'block';
      document.body.classList.add('modal-open');
    }
  };

  const closeModaldownload = () => {
    const modal = document.getElementById('onloadModalDownload');
    if (modal) {
      modal.classList.remove('show');
      modal.style.display = 'none';
      document.body.classList.remove('modal-open');
    }
  };

  const [inputs, setInputs] = useState({ gst: '', date: '', invoice: '' });
  const [input, setInput] = useState({ discount: '' });


  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs(values => ({ ...values, [name]: value }))
  };

  // const handleChangediscount = (event) => {
  //   const name = event.target.name;
  //   const value = event.target.value;
  //   setDownloadData(values => ({...values, [name]: value}))

  // };

  const handleChangediscount = (event) => {
    const newValue = event.target.value;
    setDownloadData(newValue);
  };

  const createGrill = (boxHeight, topnewHeights, bottomnewHeights, mCalc) => {

    const boxes = Array.from({ length: topnewHeights !== 0 ? (boxHeight - topnewHeights - bottomnewHeights) * 1.9 : boxHeight * 1.9 }).map((_, index) => (
      <div
        key={index}
        style={{
          position: 'relative',
          width: '100%',
          height: '0.26vw',
          border: '1px solid #333',
          background: '#fff',
          marginTop: `calc((${boxHeight * screen / mCalc + 'vw'} - 25px) / ${boxHeight * screen}) `,
        }}
      ></div>
    ));


    return boxes;

  };

  const handleAddClick = (event) => {
    event.preventDefault(); // Prevent default anchor behavior

    ReactSession.set("upvcquoteId", quoteIds);

    // Redirect to the select page
    window.location.href = '/select';
  };





  return (
    <div>
      <Navbar />
      <Header />


      <div class="modal fade custom-modal" id="onloadModalInvoice" tabindex="-1" aria-labelledby="onloadModalLabel" aria-hidden="true">
        <div class="modal-dialog">

          <div class="modal-content" align="center">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={closeModalInvoice} style={{ position: "absolute", right: "10px" }}></button>

            <div className="col-md-12 col-xxl-12 col-xl-12 mt-5">
              <div className="card">
                <div className="card-header">
                  <h5>Invoice Details</h5>
                </div>
                <div className="card-body">                                        <div className="row">
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="form-label" htmlFor="name">GST Number</label>
                      <input
                        type="text"
                        className="form-control"
                        id="Gst"
                        placeholder="Enter GST Number..."
                        name="gst"
                        required
                        value={inputs.gst || ''}
                        onChange={handleChange}
                      />
                    </div>                                            </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="form-label" htmlFor="location">Date</label>
                      <input
                        type="date"
                        className="form-control"
                        id="date"
                        placeholder="Enter location..."
                        name="date"
                        required
                        value={inputs.date || ''}
                        onChange={handleChange}
                      />
                    </div>                                            </div>
                  <div className="col-md-4">                                                <div className="form-group">
                    <label className="form-label" htmlFor="phone">Invoice Number</label>
                    <input
                      type="text"
                      className="form-control"
                      id="invoice Number"
                      placeholder="Enter Invoice Number..."
                      name="invoice"
                      required
                      value={inputs.invoice || ''}
                      onChange={handleChange}
                    />
                  </div>
                  </div>
                  <div className="col-md-12" align="center">
                    <button onClick={submitinvoice} className="btn btn-primary mb-4 w-25">Download Invoice</button>
                  </div>
                </div>                                </div>
              </div>
            </div>
          </div>
        </div>
      </div>



      <div class="modal fade custom-modal" id="onloadModalDownload" tabindex="-1" aria-labelledby="onloadModalLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content" align="center">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={closeModaldownload} style={{ position: "absolute", right: "10px" }}></button>

            <div className="col-md-12 col-xxl-12 col-xl-12 mt-5">
              <div className="card">
                <div className="card-header">
                  <h5>Download Details</h5>
                </div>


                <div className="card-body">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label className="form-label" htmlFor="name">Discount is {downloadData}%. You can Update the Discount</label>
                        <input
                          type="text"
                          className="form-control"
                          id="Gst"
                          placeholder="Enter Discount..."
                          name="discount"
                          required
                          value={downloadData}
                          onChange={handleChangediscount}
                        />
                      </div>    
                                                              </div>


                                                            
                    <div className="col-md-12" align="center">
                     <button 
                          onClick={() => {
                            toggleButton(); 
                          }} 
                          className={`btn ${isToggled ? 'btn-success' : 'btn-danger'} mb-4 w-25 mx-3`}>
                          {isToggled ? 'With Gst' : 'Without Gst'}
                     </button>
                      <button onClick={submitDownload} className="btn btn-primary mb-4 w-25">Download Quotee</button>
                    </div>
                  </div>                                </div>
              </div>
            </div>
          </div>
        </div>
      </div>




      <div className="pc-container">
        <div className="pc-content">


          <div className="row">
            <div className="col-md-12 col-xxl-12 col-xl-12">
              <div className="card">
                <div className="card-header">
                  <h5>Quotes</h5>

                  <div class="row mt-5">
                    <div class="col-md-6 col-xxl-3 col-xl-3 ">
                      <div class="card">
                        <div class="card-body">
                          <h4 class="mb-3">Quote</h4>
                          {filteredQuotes?.map((quote, index) => (
                            <>
                              <h6 class="mb-3">{quote.date}</h6>
                              <h6 class="mb-3">{quote.cusQuoteId}</h6>
                              <h6 class="mb-3">{quote.items} Items</h6>
                            </>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6 col-xxl-6 col-xl-6">
                      <div class="card">
                        <div class="card-body">
                          <h4 class="mb-3">Client</h4>
                          {filteredQuotes?.map((quote, index) => (
                            <>
                              <h6 class="mb-3">{quote.client.name}</h6>
                              <h6 class="mb-3">{quote.client.location}</h6>
                              <h6 class="mb-3">{quote.client.number}</h6>
                            </>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6 col-xxl-3 col-xl-3">
                      <div class="card">
                        <div class="card-body">
                          <h4 class="mb-3">Amount</h4>
                          <h6 class="mb-3"><span style={{ fontWeight: 'bold' }}>Total :</span> ₹ {numeral(total).format('0,0.00')}</h6>
                          <h6 class="mb-3"><span style={{ fontWeight: 'bold' }}>GST 18% :</span> ₹ {numeral(gst).format('0,0.00')}</h6>
                          <h6 class="mb-3"><span style={{ fontWeight: 'bold' }}>Grand Total :</span> ₹ {numeral(total + gst).format('0,0.00')}</h6>
                        </div>
                      </div>
                    </div>
                    {filteredQuotes && filteredQuotes[0].quoteDetails.length > 0 && (<>
                      <div class="col-md-6 col-xxl-12 col-xl-12">
                        <div class="card">

                          <div class="card-body">
                            <div class="d-flex align-items-center">

                              <div class="flex-grow-1 ms-3">
                                <h6 class="mb-2">Download</h6>
                              </div>

                            </div>
                            <div class="bg-body p-3 mt-1 rounded">
                              <div class="mt-1 row align-items-center">

                                <div class="col-12">

                                  <div class="col-md-12 col-xxl-12 col-xl-12">
                                    <div class="row">
                                      <div class="col-md-4 col-xxl-4 col-xl-4">
                                        <HomeComponent discount={downloadData} toggle={isToggled} id="pdf-content" />

                                        {matchedQuotes[0]?.quoteID === quoteIds ? (


                                          <button class="btn btn-primary p-3 mb-1 w-100" onClick={handleDownloadPDF}>Quote</button>
                                        ) : (
                                          <button class="btn btn-primary p-3 mb-1 w-100" onClick={openModaldownload}>Quote</button>
                                        )}

                                      </div>
                                      {/* <div class="col-md-4 col-xxl-4 col-xl-4">
                                           <ScheduleComponent id="pdf-schedule" />
   
                                           <button class="btn btn-warning p-3 mb-1 w-100" onClick={handleSchedulePDF}>Cutting Schedule</button>
                                       </div> */}
                                      {filteredQuotes && filteredQuotes[0].quoteDetails.length > 0 && (
                                        <>
                                          {filteredQuotes && (
                                            (filteredQuotes[0].quoteDetails[0].quoteData.selectedProfile === 'Intact' ||
                                              filteredQuotes[0].quoteDetails[0].quoteData.selectedProfile === 'Window Tech') ? (
                                              <div className="col-md-4 col-xxl-4 col-xl-4">
                                                <ScheduleComponent id="pdf-schedule" />
                                                <button className="btn btn-warning p-3 mb-1 w-100" onClick={handleSchedulePDF}>
                                                  Cutting Schedule
                                                </button>
                                              </div>
                                            ) : (
                                              <div className="col-md-4 col-xxl-4 col-xl-4">
                                                <ScheduleComponent id="pdf-schedule" />
                                                <button className="btn btn-warning p-3 mb-1 w-100">
                                                  Cutting Schedule
                                                </button>
                                              </div>
                                            )
                                          )}
                                        </>
                                      )}


                                      <div class="col-md-4 col-xxl-4 col-xl-4">
                                        <Invoice gstNo={matchedQuotes[0]?.gst} date={matchedQuotes[0]?.date} invoice={matchedQuotes[0]?.invoiceNumber} discount={downloadData} id="pdf-content-invoice" />
                                        {matchedQuotes[0]?.quoteID === quoteIds ? (


                                          <button class="btn btn-success p-3 mb-1 w-100" onClick={handleInvoiceDownloadPDF}>Invoice</button>
                                        ) : (
                                          <button class="btn btn-success p-3 mb-1 w-100" onClick={openModalInvoice}>Invoice</button>
                                        )}
                                        {/* <button class="btn btn-success p-3 mb-1 w-100" onClick={openModalInvoice}>Invoice</button> */}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>

                    </>
                    )}


                  </div>






                </div>
                {filteredQuotes && filteredQuotes[0].quoteDetails?.length === 0 ? (<>
                  <div class="card mb-3">
                    <div class="card-body">

                      <div class="row">
                        <div class="col-12">
                          <h4 align='center'>No Quotess Are Available...</h4>
                          <div class="d-grid" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                          ><a
                            class="btn btn-outline-secondary"
                            href="/select"
                            onClick={handleAddClick}
                          ><i class="ti ti-plus"></i> Add</a ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>) : (<>
                  <div className="card-body">
                    <div class="table-responsive">
                      <table id="left-right-fix" className="table stripe row-border order-column" width="100%">
                        <thead>
                          <tr align="left">
                            <th>Item</th>
                            <th width="3%">QTY</th>
                            <th width="3%">Details</th>
                            {matchedQuotes[0]?.quoteID === quoteIds ? (
                              <></>
                            ) : (
                              <th width="3%">Action</th>
                            )}
                            <th width="3%">View More</th>

                          </tr>
                        </thead>

                        {filteredQuotes?.map((quote, index) => (
                          <tbody key={index}>
                            {quote.quoteDetails.map((detail, detailIndex) => (
                              <tr key={detailIndex}>

                                <td>



                                  <h5 style={{ marginLeft: '10px' }}>{detail.quoteData.item}</h5>
                                  {detail.quoteData.item === 'CasementDoor' && (
                                    <div class="col-md-12 col-xxl-12 col-xl-12" >
                                      <div class="card" style={{ background: '#fff' }}>
                                        <div class="card-body mb-5">

                                          <div>

                                            <div style={{ float: 'left', width: detail.quoteData.boxWidth * screen / mCalc + 'vw', height: detail.quoteData.boxHeight * screen / mCalc + 'vw', border: '1px solid #333' }}>



                                              {[...Array(detail.quoteData.numFrames)].map((_, index) => (
                                                <div style={{ float: 'left', margin: '5px', border: '1px solid #333', width: `calc(${Number(detail.quoteData.boxWidth) * screen / mCalc / detail.quoteData.numFrames + 'vw'} - 12px)`, height: detail.quoteData.bottomFrame === 'no' ? `calc(${Number(detail.quoteData.boxHeight) * screen / mCalc + 'vw'} - 6px)` : `calc(${Number(detail.quoteData.boxHeight) * screen / mCalc + 'vw'} - 12px)`, }}>


                                                  {detail.quoteData.boxTop === 'on' && (

                                                    [...Array(detail.quoteData.numTopBoxes)].map((_, index) => (
                                                      <div style={{ display: 'flex', width: `calc(${detail.quoteData.boxWidth * screen / mCalc / detail.quoteData.numFrames + 'vw'} - 23px)`, height: `calc(${(detail.quoteData.topnewHeights * screen / mCalc)}vw )`, margin: '5px' }}>
                                                        {[...Array(detail.quoteData.topFixedNum)].map((_, index) => (
                                                          <div style={{ flex: '1', background: 'lightgreen', width: `calc(${detail.quoteData.topFixedWidth[index] * screen / mCalc / detail.quoteData.numFrames + 'vw'} - 18px)`, height: `calc(${(detail.quoteData.topnewHeights * screen / mCalc)}vw )`, border: 'solid 1px #000', marginLeft: index === 0 ? '0px' : '5px', }}>

                                                          </div>
                                                        ))}
                                                      </div>
                                                    ))
                                                  )}


                                                  <div style={{ display: 'flex' }}>
                                                    {[...Array(detail.quoteData.numSecondInnerBoxes)].map((_, index) => (

                                                      <div key={index} style={{ flex: '1', height: `calc(${(detail.quoteData.boxHeight - detail.quoteData.topnewHeights - detail.quoteData.bottomnewHeights) * screen / mCalc}vw - ${detail.quoteData.boxTop === 'on' ? '30px' : '25px'})`, width: `calc(${detail.quoteData.newWidths[index] * screen / mCalc}vw - 5px - 19px)`, backgroundColor: detail.quoteData.boxColors[index] || 'lightblue', marginTop: detail.quoteData.boxTop === 'on' ? '0px' : '5px', marginRight: index === detail.quoteData.numSecondInnerBoxes - 1 ? '5px' : '2.5px', marginLeft: index === 0 ? '5px' : '2.5px', border: '1px solid #333' }}>


                                                        <div className="expanding-arrow-line" style={{
                                                          position: 'absolute',
                                                          float: 'left',
                                                          width: `calc(${detail.quoteData.newWidths[index] * screen / mCalc}vw - 15px)`,
                                                          bottom: '-10px',
                                                          top: `calc(${detail.quoteData.boxHeight * screen / mCalc}vw - 25px)`,
                                                        }}

                                                        >
                                                          <div className="arrow-left"></div>
                                                          <div className="line" align="center">
                                                            <div style={{ marginTop: '-7px', background: 'white', maxWidth: '60px', textAlign: 'center' }}><p>{typeof detail.quoteData.newWidths[index] === 'number' ? detail.quoteData.newWidths[index].toFixed(2) : detail.quoteData.newWidths[index]}</p></div>
                                                          </div>
                                                          <div className="arrow-right"></div>
                                                        </div>



                                                        {detail.quoteData.boxNames[index] === 'Shutter' && (detail.quoteData.boxHandle[index] === 'left' ? (
                                                          <img
                                                            src={left}
                                                            style={{
                                                              float: 'left',
                                                              marginTop: `calc(${(((detail.quoteData.boxHeight - detail.quoteData.topnewHeights - detail.quoteData.bottomnewHeights) * screen / mCalc) / 2)}vw - 15px`,
                                                              marginLeft: '2px',
                                                              width: screen === 3 ? '13px' : '8px'
                                                            }}

                                                            alt="Left Handle"
                                                          />
                                                        ) : (
                                                          <img
                                                            src={right}
                                                            style={{
                                                              float: 'right',
                                                              marginTop: `calc(${(((detail.quoteData.boxHeight - detail.quoteData.topnewHeights - detail.quoteData.bottomnewHeights) * screen / mCalc) / 2)}vw - 15px`,
                                                              marginRight: '2px',
                                                              width: screen === 3 ? '13px' : '8px'
                                                            }}

                                                            alt="Right Handle"
                                                          />

                                                        ))}

                                                        {detail.quoteData.boxNames[index] === 'Shutter' && detail.quoteData.grill === 'yes' && createGrill(detail.quoteData.boxHeight, detail.quoteData.topnewHeights, detail.quoteData.bottomnewHeights, mCalc)}


                                                      </div>

                                                    ))}

                                                  </div>






                                                  {detail.quoteData.numSecondInnerBoxes === 0 ? (
                                                    <div className="expanding-arrow-line" style={{ float: 'left', marginLeft: '-5px', width: ((Number(detail.quoteData.boxWidth) / detail.quoteData.numFrames) * screen / mCalc) + 'vw', top: (Number(detail.quoteData.boxHeight) - detail.quoteData.newHeights) * screen / mCalc + 'vw', }} >
                                                      <div className="arrow-left"></div>
                                                      <div className="line" align="center">
                                                        <div style={{ marginTop: '-8px', background: 'white', maxWidth: '60px', textAlign: 'center' }}><p>{typeof (Number(detail.quoteData.boxWidth) / detail.quoteData.numFrames) === 'number' ? (Number(detail.quoteData.boxWidth) / detail.quoteData.numFrames).toFixed(2) : (Number(detail.quoteData.boxWidth) / detail.quoteData.numFrames)}</p></div>
                                                      </div>
                                                      <div className="arrow-right"></div>
                                                    </div>
                                                  ) : (
                                                    <div className="expanding-arrow-line" style={{ float: 'left', marginLeft: '-5px', width: ((Number(detail.quoteData.boxWidth) / detail.quoteData.numFrames) * screen / mCalc) + 'vw', bottom: '-10px', top: '50px', }} >
                                                      <div className="arrow-left"></div>
                                                      <div className="line" align="center">
                                                        <div style={{ marginTop: '-8px', background: 'white', maxWidth: '60px', textAlign: 'center' }}><p>{typeof (Number(detail.quoteData.boxWidth) / detail.quoteData.numFrames) === 'number' ? (Number(detail.quoteData.boxWidth) / detail.quoteData.numFrames).toFixed(2) : (Number(detail.quoteData.boxWidth) / detail.quoteData.numFrames)}</p></div>
                                                      </div>
                                                      <div className="arrow-right"></div>
                                                    </div>
                                                  )}

                                                </div>
                                              ))}
                                            </div>

                                            <div style={{ width: '45px', float: 'left', paddingLeft: '15px', textAlign: 'left' }}>
                      {detail.quoteData.boxTop === 'on' &&
                      <>
                        <div className="expanding-arrow-line" style={{ float: 'left', height: `calc(${detail.quoteData.topnewHeights * screen / mCalc}vw + 10px)`, top: '0', position: 'relative' }}>
                          <div className="arrow-up" style={{ borderBottom: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', top: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
                          <div className="line" style={{ width: '1px', height: '100%', marginLeft: '0px', position: 'absolute', top: '0' }}>
                            <div style={{ marginLeft: '-10px', marginTop: detail.quoteData.topnewHeights * (screen / mCalc / 2) + 'vw', background: 'white', maxWidth: '60px', textAlign: 'left' }}><p style={{ width: '50px' }}>{typeof detail.quoteData.topnewHeights === 'number' ? detail.quoteData.topnewHeights.toFixed(2) : detail.quoteData.topnewHeights}</p></div>
                          </div>
                          <div className="arrow-down" style={{ borderTop: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', bottom: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
                        </div>
                      
                      <div className="expanding-arrow-line" style={{ float: 'left', height: `calc(${(detail.quoteData.boxHeight - detail.quoteData.topnewHeights - detail.quoteData.bottomnewHeights) * screen / mCalc}vw - ${detail.quoteData.boxTop === 'on' ? '30px' : '15px'})`, top: `calc(${detail.quoteData.topnewHeights * screen / mCalc}vw + ${detail.quoteData.boxTop === 'on' ? '15px' : '0px'})`, position: 'relative' }}>
                        <div className="arrow-up" style={{ borderBottom: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', top: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
                        <div className="line" style={{ width: '1px', height: '100%', marginLeft: '0px', position: 'absolute', top: '0' }}>
                          <div style={{ marginLeft: '-10px', marginTop: (detail.quoteData.boxHeight - detail.quoteData.topnewHeights - detail.quoteData.bottomnewHeights) * ((screen / mCalc / 2) - (screen / mCalc / 8)) + 'vw', background: 'white', maxWidth: '60px', textAlign: 'left' }}><p style={{ width: '50px' }}>{ Number(detail.quoteData.boxHeight - detail.quoteData.topnewHeights - detail.quoteData.bottomnewHeights).toFixed(2)}</p></div>
                        </div>
                        <div className="arrow-down" style={{ borderTop: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', bottom: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
                      </div>
                      </>
                     }
                      {detail.quoteData.boxBottom === 'on' &&
                      <>
                        <div className="expanding-arrow-line" style={{ float: 'left', height: `calc(${(detail.quoteData.boxHeight - detail.quoteData.topnewHeights - detail.quoteData.bottomnewHeights) * screen / mCalc}vw - ${detail.quoteData.boxTop === 'on' ? '30px' : '15px'})`, top: `calc(${detail.quoteData.topnewHeights * screen / mCalc}vw + ${detail.quoteData.boxTop === 'on' ? '15px' : '0px'})`, position: 'relative' }}>
                        <div className="arrow-up" style={{ borderBottom: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', top: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
                        <div className="line" style={{ width: '1px', height: '100%', marginLeft: '0px', position: 'absolute', top: '0' }}>
                          <div style={{ marginLeft: '-10px', marginTop: (detail.quoteData.boxHeight - detail.quoteData.topnewHeights - detail.quoteData.bottomnewHeights) * ((screen / mCalc / 2) - (screen / mCalc / 8)) + 'vw', background: 'white', maxWidth: '60px', textAlign: 'left' }}><p style={{ width: '50px' }}>{ Number(detail.quoteData.boxHeight - detail.quoteData.topnewHeights - detail.quoteData.bottomnewHeights).toFixed(2)}</p></div>
                        </div>
                        <div className="arrow-down" style={{ borderTop: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', bottom: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
                      </div>
                        <div className="expanding-arrow-line" style={{ float: 'left', height: `calc(${(detail.quoteData.bottomnewHeights) * screen / mCalc}vw + 5px)`, top: `calc(${(detail.quoteData.boxHeight - detail.quoteData.bottomnewHeights) * screen / mCalc}vw - ${detail.quoteData.boxTop === 'on' ? '10px' : '10px'})`, position: 'relative' }}>
                          <div className="arrow-up" style={{ borderBottom: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', top: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
                          <div className="line" style={{ width: '1px', height: '100%', marginLeft: '0px', position: 'absolute', top: '0' }}>
                            <div style={{ marginLeft: '-10px', marginTop: (detail.quoteData.bottomnewHeights) * ((screen / mCalc / 2) - (screen / mCalc / 8)) + 'vw', background: 'white', maxWidth: '60px', textAlign: 'left' }}><p style={{ width: '50px' }}>{ Number(detail.quoteData.bottomnewHeights).toFixed(2)}</p></div>
                          </div>
                          <div className="arrow-down" style={{ borderTop: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', bottom: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
                        </div>
                        </>
                      }

                    </div>

                    <div style={{ width: '30px', float: 'left', paddingLeft: detail.quoteData.boxBottom === 'on' || detail.quoteData.boxTop === 'on' ?'0px' : '15px', textAlign: 'left' }}>
                      <div className="expanding-arrow-line" style={{ float: 'left', height: `calc(${detail.quoteData.boxHeight * screen / mCalc}vw)`, top: '0', position: 'relative' }}>
                        <div className="arrow-up" style={{ borderBottom: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', top: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
                        <div className="line" style={{ width: '1px', height: '100%', marginLeft: '0px', position: 'absolute', top: '0' }}>
                          <div style={{ marginLeft: '-10px', marginTop: detail.quoteData.boxHeight * (screen / mCalc / 2) + 'vw', background: 'white', maxWidth: '60px', textAlign: 'left' }}><p style={{ width: '50px' }}>{ Number(detail.quoteData.boxHeight).toFixed(2)}</p></div>
                        </div>
                        <div className="arrow-down" style={{ borderTop: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', bottom: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
                      </div>
                    </div>

                                          </div>

                                        </div>
                                      </div>


                                    </div>
                                  )}
                                  {detail.quoteData.item === 'CasementWindow' && (
                                    <div class="col-md-12 col-xxl-12 col-xl-12">
                                      <div class="card" style={{ background: '#fff' }}>
                                        <div class="card-body mb-5">

                                          <div>

                                            <div style={{ float: 'left', width: detail.quoteData.boxWidth * screen / mCalc + 'vw', height: detail.quoteData.boxHeight * screen / mCalc + 'vw', border: '1px solid #333' }}>



                                              {[...Array(detail.quoteData.numFrames)].map((_, index) => (
                                                <div style={{ float: 'left', margin: '5px', border: '1px solid #333', width: `calc(${detail.quoteData.boxWidth * screen / mCalc / detail.quoteData.numFrames + 'vw'} - 12px)`, height: `calc(${detail.quoteData.boxHeight * screen / mCalc + 'vw'} - 12px)` }}>


                                                  {detail.quoteData.boxTop === 'on' && (

                                                    [...Array(detail.quoteData.numTopBoxes)].map((_, index) => (
                                                      <div style={{ display: 'flex', width: `calc(${detail.quoteData.boxWidth * screen / mCalc / detail.quoteData.numFrames + 'vw'} - 23px)`, height: `calc(${(detail.quoteData.topnewHeights * screen / mCalc)}vw )`, margin: '5px' }}>
                                                        {[...Array(detail.quoteData.topFixedNum)].map((_, index) => (
                                                          <div style={{ flex: '1', background: 'lightgreen', width: `calc(${detail.quoteData.topFixedWidth[index] * screen / mCalc / detail.quoteData.numFrames + 'vw'} - 18px)`, height: `calc(${(detail.quoteData.topnewHeights * screen / mCalc)}vw )`, border: 'solid 1px #000', marginLeft: index === 0 ? '0px' : '5px', }}>

                                                          </div>
                                                        ))}
                                                      </div>
                                                    ))
                                                  )}

                                                  <div style={{ display: 'flex' }}>
                                                    {[...Array(detail.quoteData.numSecondInnerBoxes)].map((_, index) => (

                                                      <div key={index} style={{ flex: '1', height: `calc(${(detail.quoteData.boxHeight - detail.quoteData.topnewHeights - detail.quoteData.bottomnewHeights) * screen / mCalc}vw - ${detail.quoteData.boxTop === 'on' ? '30px' : '25px'})`, width: `calc(${detail.quoteData.newWidths[index] * screen / mCalc}vw - 5px - 19px)`, backgroundColor: detail.quoteData.boxColors[index] || 'lightblue', marginTop: detail.quoteData.boxTop === 'on' ? '0px' : '5px', marginRight: index === detail.quoteData.numSecondInnerBoxes - 1 ? '5px' : '2.5px', marginLeft: index === 0 ? '5px' : '2.5px', border: '1px solid #333' }}>


                                                        <div className="expanding-arrow-line" style={{
                                                          position: 'absolute',
                                                          float: 'left',
                                                          width: `calc(${detail.quoteData.newWidths[index] * screen / mCalc}vw - 15px)`,
                                                          bottom: '-10px',
                                                          top: `calc(${detail.quoteData.boxHeight * screen / mCalc}vw - 25px)`,
                                                        }}

                                                        >
                                                          <div className="arrow-left"></div>
                                                          <div className="line" align="center">
                                                            <div style={{ marginTop: '-7px', background: 'white', maxWidth: '60px', textAlign: 'center' }}><p>{typeof detail.quoteData.newWidths[index] === 'number' ? detail.quoteData.newWidths[index].toFixed(2) : detail.quoteData.newWidths[index]}</p></div>
                                                          </div>
                                                          <div className="arrow-right"></div>
                                                        </div>



                                                        {detail.quoteData.boxNames[index] === 'Shutter' && (detail.quoteData.boxHandle[index] === 'left' ? (
                                                          <img
                                                            src={left}
                                                            style={{
                                                              float: 'left',
                                                              marginTop: `calc(${(((detail.quoteData.boxHeight - detail.quoteData.topnewHeights - detail.quoteData.bottomnewHeights) * screen / mCalc) / 2)}vw - 15px`,
                                                              marginLeft: '2px',
                                                              width: screen === 3 ? '13px' : '8px'
                                                            }}

                                                            alt="Left Handle"
                                                          />
                                                        ) : (
                                                          <img
                                                            src={right}
                                                            style={{
                                                              float: 'right',
                                                              marginTop: `calc(${(((detail.quoteData.boxHeight - detail.quoteData.topnewHeights - detail.quoteData.bottomnewHeights) * screen / mCalc) / 2)}vw - 15px`,
                                                              marginRight: '2px',
                                                              width: screen === 3 ? '13px' : '8px'
                                                            }}

                                                            alt="Right Handle"
                                                          />

                                                        ))}

                                                        {detail.quoteData.mesh === 'yes' && detail.quoteData.boxNames[index] === 'Shutter' && detail.quoteData.boxHandle[index] === 'left' && (
                                                          <img src={meshimg} style={{ width: screen === 3 ? '40px' : '20px', float: 'left', marginLeft: screen === 3 ? detail.quoteData.boxHandle[index] === 'left' ? '-15px' : '0px' : detail.quoteData.boxHandle[index] === 'left' ? '-10px' : 'px', marginTop: screen === 3 ? `calc(${((detail.quoteData.boxHeight - detail.quoteData.topnewHeights - detail.quoteData.bottomnewHeights) * screen / mCalc)}vw - ${detail.quoteData.boxTop === 'on' ? '72px' : '67px'} )` : `calc(${((detail.quoteData.boxHeight - detail.quoteData.topnewHeights - detail.quoteData.bottomnewHeights) * screen / mCalc)}vw - 50px)` }} />
                                                        )}

                                                        {detail.quoteData.mesh === 'yes' && detail.quoteData.boxNames[index] === 'Shutter' && detail.quoteData.boxHandle[index] === 'right' && (
                                                          <img src={meshimg} style={{ width: screen === 3 ? '40px' : '20px', float: 'left', marginLeft: screen === 3 ? detail.quoteData.boxHandle[index] === 'right' ? '-1px' : '0px' : detail.quoteData.boxHandle[index] === 'right' ? '0px' : 'px', marginTop: screen === 3 ? `calc(${((detail.quoteData.boxHeight - detail.quoteData.topnewHeights - detail.quoteData.bottomnewHeights) * screen / mCalc)}vw - ${detail.quoteData.boxTop === 'on' ? '72px' : '67px'} )` : `calc(${((detail.quoteData.boxHeight - detail.quoteData.topnewHeights - detail.quoteData.bottomnewHeights) * screen / mCalc)}vw - 50px)` }} />
                                                        )}

                                                        {detail.quoteData.boxNames[index] === 'Shutter' && detail.quoteData.grill === 'yes' && createGrill(detail.quoteData.boxHeight, detail.quoteData.topnewHeights, detail.quoteData.bottomnewHeights, mCalc)}

                                                      </div>

                                                    ))}

                                                  </div>

                                                  {detail.quoteData.boxBottom === 'on' && (
                                                    [...Array(detail.quoteData.numBottomBoxes)].map((_, index) => (
                                                      <div style={{ display: 'flex', width: `calc(${detail.quoteData.boxWidth * screen / mCalc / detail.quoteData.numFrames + 'vw'} - 23px)`, height: `calc(${(detail.quoteData.bottomnewHeights * screen / mCalc) + 'vw'} -10px)`, margin: '5px' }}>
                                                        {[...Array(detail.quoteData.bottomFixedNum)].map((_, index) => (
                                                          <div style={{ flex: '1', background: 'lightgreen', width: `calc(${detail.quoteData.bottomFixedWidth[index] * screen / mCalc / detail.quoteData.numFrames + 'vw'} - 18px)`, height: `calc(${(detail.quoteData.bottomnewHeights * screen / mCalc) + 'vw'} - 5px)`, border: 'solid 1px #000', marginLeft: index === 0 ? '0px' : '5px', }}>

                                                          </div>
                                                        ))}
                                                      </div>
                                                    ))
                                                  )}

                                                  {detail.quoteData.numSecondInnerBoxes === 0 ? (
                                                    <div className="expanding-arrow-line" style={{ float: 'left', marginLeft: '-5px', width: ((detail.quoteData.boxWidth / detail.quoteData.numFrames) * screen / mCalc) + 'vw', top: (detail.quoteData.boxHeight - detail.quoteData.topnewHeights) * screen / mCalc + 'vw', }} >
                                                      <div className="arrow-left"></div>
                                                      <div className="line" align="center">
                                                        <div style={{ marginTop: '-8px', background: 'white', maxWidth: '60px', textAlign: 'center' }}><p>{typeof (detail.quoteData.boxWidth / detail.quoteData.numFrames) === 'number' ? (detail.quoteData.boxWidth / detail.quoteData.numFrames).toFixed(2) : (detail.quoteData.boxWidth / detail.quoteData.numFrames)}</p></div>
                                                      </div>
                                                      <div className="arrow-right"></div>
                                                    </div>
                                                  ) : (
                                                    <div className="expanding-arrow-line" style={{ float: 'left', marginLeft: '-5px', width: ((detail.quoteData.boxWidth / detail.quoteData.numFrames) * screen / mCalc) + 'vw', bottom: '-10px', top: '50px', }} >
                                                      <div className="arrow-left"></div>
                                                      <div className="line" align="center">
                                                        <div style={{ marginTop: '-8px', background: 'white', maxWidth: '60px', textAlign: 'center' }}><p>{typeof (detail.quoteData.boxWidth / detail.quoteData.numFrames) === 'number' ? (detail.quoteData.boxWidth / detail.quoteData.numFrames).toFixed(2) : (detail.quoteData.boxWidth / detail.quoteData.numFrames)}</p></div>
                                                      </div>
                                                      <div className="arrow-right"></div>
                                                    </div>
                                                  )}

                                                </div>
                                              ))}
                                            </div>


                                            <div style={{ width: '45px', float: 'left', paddingLeft: '15px', textAlign: 'left' }}>
                      {detail.quoteData.boxTop === 'on' &&
                      <>
                        <div className="expanding-arrow-line" style={{ float: 'left', height: `calc(${detail.quoteData.topnewHeights * screen / mCalc}vw + 10px)`, top: '0', position: 'relative' }}>
                          <div className="arrow-up" style={{ borderBottom: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', top: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
                          <div className="line" style={{ width: '1px', height: '100%', marginLeft: '0px', position: 'absolute', top: '0' }}>
                            <div style={{ marginLeft: '-10px', marginTop: detail.quoteData.topnewHeights * (screen / mCalc / 2) + 'vw', background: 'white', maxWidth: '60px', textAlign: 'left' }}><p style={{ width: '50px' }}>{typeof detail.quoteData.topnewHeights === 'number' ? detail.quoteData.topnewHeights.toFixed(2) : detail.quoteData.topnewHeights}</p></div>
                          </div>
                          <div className="arrow-down" style={{ borderTop: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', bottom: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
                        </div>
                      
                      <div className="expanding-arrow-line" style={{ float: 'left', height: `calc(${(detail.quoteData.boxHeight - detail.quoteData.topnewHeights - detail.quoteData.bottomnewHeights) * screen / mCalc}vw - ${detail.quoteData.boxTop === 'on' ? '30px' : '15px'})`, top: `calc(${detail.quoteData.topnewHeights * screen / mCalc}vw + ${detail.quoteData.boxTop === 'on' ? '15px' : '0px'})`, position: 'relative' }}>
                        <div className="arrow-up" style={{ borderBottom: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', top: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
                        <div className="line" style={{ width: '1px', height: '100%', marginLeft: '0px', position: 'absolute', top: '0' }}>
                          <div style={{ marginLeft: '-10px', marginTop: (detail.quoteData.boxHeight - detail.quoteData.topnewHeights - detail.quoteData.bottomnewHeights) * ((screen / mCalc / 2) - (screen / mCalc / 8)) + 'vw', background: 'white', maxWidth: '60px', textAlign: 'left' }}><p style={{ width: '50px' }}>{ Number(detail.quoteData.boxHeight - detail.quoteData.topnewHeights - detail.quoteData.bottomnewHeights).toFixed(2)}</p></div>
                        </div>
                        <div className="arrow-down" style={{ borderTop: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', bottom: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
                      </div>
                      </>
                     }
                      {detail.quoteData.boxBottom === 'on' &&
                      <>
                        <div className="expanding-arrow-line" style={{ float: 'left', height: `calc(${(detail.quoteData.boxHeight - detail.quoteData.topnewHeights - detail.quoteData.bottomnewHeights) * screen / mCalc}vw - ${detail.quoteData.boxTop === 'on' ? '30px' : '15px'})`, top: `calc(${detail.quoteData.topnewHeights * screen / mCalc}vw + ${detail.quoteData.boxTop === 'on' ? '15px' : '0px'})`, position: 'relative' }}>
                        <div className="arrow-up" style={{ borderBottom: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', top: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
                        <div className="line" style={{ width: '1px', height: '100%', marginLeft: '0px', position: 'absolute', top: '0' }}>
                          <div style={{ marginLeft: '-10px', marginTop: (detail.quoteData.boxHeight - detail.quoteData.topnewHeights - detail.quoteData.bottomnewHeights) * ((screen / mCalc / 2) - (screen / mCalc / 8)) + 'vw', background: 'white', maxWidth: '60px', textAlign: 'left' }}><p style={{ width: '50px' }}>{ Number(detail.quoteData.boxHeight - detail.quoteData.topnewHeights - detail.quoteData.bottomnewHeights).toFixed(2)}</p></div>
                        </div>
                        <div className="arrow-down" style={{ borderTop: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', bottom: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
                      </div>
                        <div className="expanding-arrow-line" style={{ float: 'left', height: `calc(${(detail.quoteData.bottomnewHeights) * screen / mCalc}vw + 5px)`, top: `calc(${(detail.quoteData.boxHeight - detail.quoteData.bottomnewHeights) * screen / mCalc}vw - ${detail.quoteData.boxTop === 'on' ? '10px' : '10px'})`, position: 'relative' }}>
                          <div className="arrow-up" style={{ borderBottom: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', top: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
                          <div className="line" style={{ width: '1px', height: '100%', marginLeft: '0px', position: 'absolute', top: '0' }}>
                            <div style={{ marginLeft: '-10px', marginTop: (detail.quoteData.bottomnewHeights) * ((screen / mCalc / 2) - (screen / mCalc / 8)) + 'vw', background: 'white', maxWidth: '60px', textAlign: 'left' }}><p style={{ width: '50px' }}>{ Number(detail.quoteData.bottomnewHeights).toFixed(2)}</p></div>
                          </div>
                          <div className="arrow-down" style={{ borderTop: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', bottom: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
                        </div>
                        </>
                      }

                    </div>

                    <div style={{ width: '30px', float: 'left', paddingLeft: detail.quoteData.boxBottom === 'on' || detail.quoteData.boxTop === 'on' ?'0px' : '15px', textAlign: 'left' }}>
                      <div className="expanding-arrow-line" style={{ float: 'left', height: `calc(${detail.quoteData.boxHeight * screen / mCalc}vw)`, top: '0', position: 'relative' }}>
                        <div className="arrow-up" style={{ borderBottom: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', top: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
                        <div className="line" style={{ width: '1px', height: '100%', marginLeft: '0px', position: 'absolute', top: '0' }}>
                          <div style={{ marginLeft: '-10px', marginTop: detail.quoteData.boxHeight * (screen / mCalc / 2) + 'vw', background: 'white', maxWidth: '60px', textAlign: 'left' }}><p style={{ width: '50px' }}>{ Number(detail.quoteData.boxHeight).toFixed(2)}</p></div>
                        </div>
                        <div className="arrow-down" style={{ borderTop: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', bottom: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
                      </div>
                    </div>


                                          </div>

                                        </div>
                                      </div>




                                    </div>
                                  )}
                                  {detail.quoteData.item === 'SlidingDoor' && (
                                    <div class="col-md-12 col-xxl-12 col-xl-12" >
                                      <div class="card" style={{ background: '#fff' }}>
                                        <div class="card-body mb-5">

                                          <div>

                                            <div style={{ float: 'left', width: detail.quoteData.boxWidth * screen / mCalc + 'vw', height: detail.quoteData.boxHeight * screen / mCalc + 'vw', border: '1px solid #333' }}>


                                              {[...Array(detail.quoteData.numFrames)].map((_, index) => (
                                                <div style={{ float: 'left', margin: '5px', border: '1px solid #333', width: `calc(${detail.quoteData.boxWidth * screen / mCalc / detail.quoteData.numFrames + 'vw'} - 12px)`, height: `calc(${detail.quoteData.boxHeight * screen / mCalc + 'vw'} - 12px)` }}>





                                                  {detail.quoteData.boxTop === 'on' && (

                                                    [...Array(detail.quoteData.numTopBoxes)].map((_, index) => (
                                                      <div style={{ display: 'flex', width: `calc(${detail.quoteData.boxWidth * screen / mCalc / detail.quoteData.numFrames + 'vw'} - 23px)`, height: `calc(${(detail.quoteData.topnewHeights * screen / mCalc)}vw )`, margin: '5px' }}>
                                                        {[...Array(detail.quoteData.topFixedNum)].map((_, index) => (
                                                          <div style={{ flex: '1', background: 'lightgreen', width: `calc(${detail.quoteData.topFixedWidth[index] * screen / mCalc / detail.quoteData.numFrames + 'vw'} - 18px)`, height: `calc(${(detail.quoteData.topnewHeights * screen / mCalc)}vw )`, border: 'solid 1px #000', marginLeft: index === 0 ? '0px' : '5px', }}>

                                                          </div>
                                                        ))}
                                                      </div>
                                                    ))
                                                  )}

                                                  <div style={{ display: 'flex' }}>
                                                    {[...Array(detail.quoteData.numSecondInnerBoxes)].map((_, index) => (

                                                      <div key={index} style={{ flex: '1', height: `calc(${(detail.quoteData.boxHeight - detail.quoteData.topnewHeights - detail.quoteData.bottomnewHeights) * screen / mCalc}vw - ${detail.quoteData.boxTop === 'on' ? '30px' : '25px'})`, width: `calc(${detail.quoteData.newWidths[index] * screen / mCalc}vw - 5px - 19px)`, backgroundColor: detail.quoteData.panelType === 1 ? 'lightgray' : detail.quoteData.panelType === 2 ? 'lightyellow' : 'lightblue', marginTop: detail.quoteData.boxTop === 'on' ? '0px' : '5px', marginRight: index === detail.quoteData.numSecondInnerBoxes - 1 ? '5px' : '2.5px', marginLeft: index === 0 ? '5px' : '2.5px', border: '1px solid #333' }}>


                                                        <div className="expanding-arrow-line" style={{
                                                          position: 'absolute',
                                                          float: 'left',
                                                          width: `calc(${detail.quoteData.newWidths[index] * screen / mCalc}vw - 15px)`,
                                                          bottom: '-10px',
                                                          top: `calc(${detail.quoteData.boxHeight * screen / mCalc}vw - 25px)`,
                                                        }}

                                                        >
                                                          <div className="arrow-left"></div>
                                                          <div className="line" align="center">
                                                            <div style={{ marginTop: '-7px', background: 'white', maxWidth: '60px', textAlign: 'center' }}><p>{typeof detail.quoteData.newWidths[index] === 'number' ? detail.quoteData.newWidths[index].toFixed(2) : detail.quoteData.newWidths[index]}</p></div>
                                                          </div>
                                                          <div className="arrow-right"></div>
                                                        </div>



                                                        {detail.quoteData.boxNames[index] === 'Shutter' && (detail.quoteData.boxHandle[index] === 'left' ? (
                                                          <img
                                                            src={Handle}
                                                            style={{
                                                              float: 'left',
                                                              marginTop: `calc(${(((detail.quoteData.boxHeight - detail.quoteData.topnewHeights - detail.quoteData.bottomnewHeights) * screen / mCalc) / 2)}vw - 15px`,
                                                              marginLeft: '2px',
                                                              width: screen === 3 ? '5px' : '4px'
                                                            }}

                                                            alt="Left Handle"
                                                          />
                                                        ) : (
                                                          <img
                                                            src={Handle}
                                                            style={{
                                                              float: 'right',
                                                              marginTop: `calc(${(((detail.quoteData.boxHeight - detail.quoteData.topnewHeights - detail.quoteData.bottomnewHeights) * screen / mCalc) / 2)}vw - 15px`,
                                                              marginRight: '2px',
                                                              width: screen === 3 ? '5px' : '4px'
                                                            }}

                                                            alt="Right Handle"
                                                          />

                                                        ))}

                                                        {detail.quoteData.mesh === 'yes' && detail.quoteData.boxNames[index] === 'Shutter' && detail.quoteData.boxHandle[index] === 'left' && (
                                                          <img src={detail.quoteData.meshimg} style={{ width: screen === 3 ? '40px' : '20px', float: 'left', marginLeft: screen === 3 ? detail.quoteData.boxHandle[index] === 'left' ? '-15px' : '0px' : detail.quoteData.boxHandle[index] === 'left' ? '-10px' : 'px', marginTop: screen === 3 ? `calc(${((detail.quoteData.boxHeight - detail.quoteData.topnewHeights - detail.quoteData.bottomnewHeights) * screen / mCalc)}vw - ${detail.quoteData.boxTop === 'on' ? '72px' : '67px'} )` : `calc(${((detail.quoteData.boxHeight - detail.quoteData.topnewHeights - detail.quoteData.bottomnewHeights) * screen / mCalc)}vw - 45px)` }} />
                                                        )}

                                                        {detail.quoteData.mesh === 'yes' && detail.quoteData.boxNames[index] === 'Shutter' && detail.quoteData.boxHandle[index] === 'right' && (
                                                          <img src={detail.quoteData.meshimg} style={{ width: screen === 3 ? '40px' : '20px', float: 'left', marginLeft: screen === 3 ? detail.quoteData.boxHandle[index] === 'right' ? '-1px' : '0px' : detail.quoteData.boxHandle[index] === 'right' ? '0px' : 'px', marginTop: screen === 3 ? `calc(${((detail.quoteData.boxHeight - detail.quoteData.topnewHeights - detail.quoteData.bottomnewHeights) * screen / mCalc)}vw - ${detail.quoteData.boxTop === 'on' ? '72px' : '67px'} )` : `calc(${((detail.quoteData.boxHeight - detail.quoteData.topnewHeights - detail.quoteData.bottomnewHeights) * screen / mCalc)}vw - 45px)` }} />
                                                        )}

                                                        {detail.quoteData.boxNames[index] === 'Shutter' && detail.quoteData.grill === 'yes' && createGrill(detail.quoteData.boxHeight, detail.quoteData.topnewHeights, detail.quoteData.bottomnewHeights, mCalc)}


                                                      </div>

                                                    ))}

                                                  </div>






                                                  {detail.quoteData.numSecondInnerBoxes === 0 ? (
                                                    <div className="expanding-arrow-line" style={{ float: 'left', marginLeft: '-5px', width: ((detail.quoteData.boxWidth / detail.quoteData.numFrames) * screen / mCalc) + 'vw', top: detail.quoteData.boxHeight * screen / mCalc + 'vw', }} >
                                                      <div className="arrow-left"></div>
                                                      <div className="line" align="center">
                                                        <div style={{ marginTop: '-8px', background: 'white', maxWidth: '60px', textAlign: 'center' }}><p>{typeof (detail.quoteData.boxWidth / detail.quoteData.numFrames) === 'number' ? (detail.quoteData.boxWidth / detail.quoteData.numFrames).toFixed(2) : (detail.quoteData.boxWidth / detail.quoteData.numFrames)}</p></div>
                                                      </div>
                                                      <div className="arrow-right"></div>
                                                    </div>
                                                  ) : (
                                                    <div className="expanding-arrow-line" style={{ float: 'left', marginLeft: '-5px', width: ((detail.quoteData.boxWidth / detail.quoteData.numFrames) * screen / mCalc) + 'vw', bottom: '-10px', top: '40px', }} >
                                                      <div className="arrow-left"></div>
                                                      <div className="line" align="center">
                                                        <div style={{ marginTop: '-8px', background: 'white', maxWidth: '60px', textAlign: 'center' }}><p>{typeof (detail.quoteData.boxWidth / detail.quoteData.numFrames) === 'number' ? (detail.quoteData.boxWidth / detail.quoteData.numFrames).toFixed(2) : (detail.quoteData.boxWidth / detail.quoteData.numFrames)}</p></div>
                                                      </div>
                                                      <div className="arrow-right"></div>
                                                    </div>
                                                  )}


                                                </div>
                                              ))}
                                            </div>

                                            <div style={{ width: '45px', float: 'left', paddingLeft: '15px', textAlign: 'left' }}>
                      {detail.quoteData.boxTop === 'on' &&
                      <>
                        <div className="expanding-arrow-line" style={{ float: 'left', height: `calc(${detail.quoteData.topnewHeights * screen / mCalc}vw + 10px)`, top: '0', position: 'relative' }}>
                          <div className="arrow-up" style={{ borderBottom: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', top: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
                          <div className="line" style={{ width: '1px', height: '100%', marginLeft: '0px', position: 'absolute', top: '0' }}>
                            <div style={{ marginLeft: '-10px', marginTop: detail.quoteData.topnewHeights * (screen / mCalc / 2) + 'vw', background: 'white', maxWidth: '60px', textAlign: 'left' }}><p style={{ width: '50px' }}>{typeof detail.quoteData.topnewHeights === 'number' ? detail.quoteData.topnewHeights.toFixed(2) : detail.quoteData.topnewHeights}</p></div>
                          </div>
                          <div className="arrow-down" style={{ borderTop: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', bottom: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
                        </div>
                      
                      <div className="expanding-arrow-line" style={{ float: 'left', height: `calc(${(detail.quoteData.boxHeight - detail.quoteData.topnewHeights - detail.quoteData.bottomnewHeights) * screen / mCalc}vw - ${detail.quoteData.boxTop === 'on' ? '30px' : '15px'})`, top: `calc(${detail.quoteData.topnewHeights * screen / mCalc}vw + ${detail.quoteData.boxTop === 'on' ? '15px' : '0px'})`, position: 'relative' }}>
                        <div className="arrow-up" style={{ borderBottom: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', top: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
                        <div className="line" style={{ width: '1px', height: '100%', marginLeft: '0px', position: 'absolute', top: '0' }}>
                          <div style={{ marginLeft: '-10px', marginTop: (detail.quoteData.boxHeight - detail.quoteData.topnewHeights - detail.quoteData.bottomnewHeights) * ((screen / mCalc / 2) - (screen / mCalc / 8)) + 'vw', background: 'white', maxWidth: '60px', textAlign: 'left' }}><p style={{ width: '50px' }}>{ Number(detail.quoteData.boxHeight - detail.quoteData.topnewHeights - detail.quoteData.bottomnewHeights).toFixed(2)}</p></div>
                        </div>
                        <div className="arrow-down" style={{ borderTop: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', bottom: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
                      </div>
                      </>
                     }
                      {detail.quoteData.boxBottom === 'on' &&
                      <>
                        <div className="expanding-arrow-line" style={{ float: 'left', height: `calc(${(detail.quoteData.boxHeight - detail.quoteData.topnewHeights - detail.quoteData.bottomnewHeights) * screen / mCalc}vw - ${detail.quoteData.boxTop === 'on' ? '30px' : '15px'})`, top: `calc(${detail.quoteData.topnewHeights * screen / mCalc}vw + ${detail.quoteData.boxTop === 'on' ? '15px' : '0px'})`, position: 'relative' }}>
                        <div className="arrow-up" style={{ borderBottom: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', top: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
                        <div className="line" style={{ width: '1px', height: '100%', marginLeft: '0px', position: 'absolute', top: '0' }}>
                          <div style={{ marginLeft: '-10px', marginTop: (detail.quoteData.boxHeight - detail.quoteData.topnewHeights - detail.quoteData.bottomnewHeights) * ((screen / mCalc / 2) - (screen / mCalc / 8)) + 'vw', background: 'white', maxWidth: '60px', textAlign: 'left' }}><p style={{ width: '50px' }}>{ Number(detail.quoteData.boxHeight - detail.quoteData.topnewHeights - detail.quoteData.bottomnewHeights).toFixed(2)}</p></div>
                        </div>
                        <div className="arrow-down" style={{ borderTop: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', bottom: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
                      </div>
                        <div className="expanding-arrow-line" style={{ float: 'left', height: `calc(${(detail.quoteData.bottomnewHeights) * screen / mCalc}vw + 5px)`, top: `calc(${(detail.quoteData.boxHeight - detail.quoteData.bottomnewHeights) * screen / mCalc}vw - ${detail.quoteData.boxTop === 'on' ? '10px' : '10px'})`, position: 'relative' }}>
                          <div className="arrow-up" style={{ borderBottom: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', top: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
                          <div className="line" style={{ width: '1px', height: '100%', marginLeft: '0px', position: 'absolute', top: '0' }}>
                            <div style={{ marginLeft: '-10px', marginTop: (detail.quoteData.bottomnewHeights) * ((screen / mCalc / 2) - (screen / mCalc / 8)) + 'vw', background: 'white', maxWidth: '60px', textAlign: 'left' }}><p style={{ width: '50px' }}>{ Number(detail.quoteData.bottomnewHeights).toFixed(2)}</p></div>
                          </div>
                          <div className="arrow-down" style={{ borderTop: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', bottom: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
                        </div>
                        </>
                      }

                    </div>

                    <div style={{ width: '30px', float: 'left', paddingLeft: detail.quoteData.boxBottom === 'on' || detail.quoteData.boxTop === 'on' ?'0px' : '15px', textAlign: 'left' }}>
                      <div className="expanding-arrow-line" style={{ float: 'left', height: `calc(${detail.quoteData.boxHeight * screen / mCalc}vw)`, top: '0', position: 'relative' }}>
                        <div className="arrow-up" style={{ borderBottom: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', top: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
                        <div className="line" style={{ width: '1px', height: '100%', marginLeft: '0px', position: 'absolute', top: '0' }}>
                          <div style={{ marginLeft: '-10px', marginTop: detail.quoteData.boxHeight * (screen / mCalc / 2) + 'vw', background: 'white', maxWidth: '60px', textAlign: 'left' }}><p style={{ width: '50px' }}>{ Number(detail.quoteData.boxHeight).toFixed(2)}</p></div>
                        </div>
                        <div className="arrow-down" style={{ borderTop: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', bottom: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
                      </div>
                    </div>

                                          </div>

                                        </div>
                                      </div>

                                    </div>
                                  )}
                                  {detail.quoteData.item === 'SlidingWindow' && (
                                    <div class="col-md-12 col-xxl-12 col-xl-12" >
                                      <div class="card" style={{ background: '#fff' }}>
                                        <div class="card-body mb-5">

                                          <div>
                                            <div style={{ float: 'left', width: detail.quoteData.boxWidth * screen / mCalc + 'vw', height: detail.quoteData.boxHeight * screen / mCalc + 'vw', border: '1px solid #333' }}>



                                              {[...Array(detail.quoteData.numFrames)].map((_, index) => (
                                                <div style={{ float: 'left', margin: '5px', border: '1px solid #333', width: `calc(${detail.quoteData.boxWidth * screen / mCalc / detail.quoteData.numFrames + 'vw'} - 12px)`, height: `calc(${detail.quoteData.boxHeight * screen / mCalc + 'vw'} - 12px)` }}>


                                                  {detail.quoteData.boxTop === 'on' && (

                                                    [...Array(detail.quoteData.numTopBoxes)].map((_, index) => (
                                                      <div style={{ display: 'flex', width: `calc(${detail.quoteData.boxWidth * screen / mCalc / detail.quoteData.numFrames + 'vw'} - 23px)`, height: `calc(${(detail.quoteData.topnewHeights * screen / mCalc)}vw )`, margin: '5px' }}>
                                                        {[...Array(detail.quoteData.topFixedNum)].map((_, index) => (
                                                          <div style={{ flex: '1', background: 'lightgreen', width: `calc(${detail.quoteData.topFixedWidth[index] * screen / mCalc / detail.quoteData.numFrames + 'vw'} - 18px)`, height: `calc(${(detail.quoteData.topnewHeights * screen / mCalc)}vw )`, border: 'solid 1px #000', marginLeft: index === 0 ? '0px' : '5px', }}>

                                                          </div>
                                                        ))}
                                                      </div>
                                                    ))
                                                  )}

                                                  <div style={{ display: 'flex' }}>
                                                    {[...Array(detail.quoteData.numSecondInnerBoxes)].map((_, index) => (

                                                      <div key={index} style={{ flex: '1', height: `calc(${(detail.quoteData.boxHeight - detail.quoteData.topnewHeights - detail.quoteData.bottomnewHeights) * screen / mCalc}vw - ${detail.quoteData.boxTop === 'on' ? '30px' : '25px'})`, width: `calc(${detail.quoteData.newWidths[index] * screen / mCalc}vw - 5px - 19px)`, backgroundColor: detail.quoteData.boxColors[index] || 'lightblue', marginTop: detail.quoteData.boxTop === 'on' ? '0px' : '5px', marginRight: index === detail.quoteData.numSecondInnerBoxes - 1 ? '5px' : '2.5px', marginLeft: index === 0 ? '5px' : '2.5px', border: '1px solid #333' }}>


                                                        <div className="expanding-arrow-line" style={{
                                                          position: 'absolute',
                                                          float: 'left',
                                                          width: `calc(${detail.quoteData.newWidths[index] * screen / mCalc}vw - 15px)`,
                                                          bottom: '-10px',
                                                          top: `calc(${detail.quoteData.boxHeight * screen / mCalc}vw - 25px)`,
                                                        }}

                                                        >
                                                          <div className="arrow-left"></div>
                                                          <div className="line" align="center">
                                                            <div style={{ marginTop: '-7px', background: 'white', maxWidth: '60px', textAlign: 'center' }}><p>{typeof detail.quoteData.newWidths[index] === 'number' ? detail.quoteData.newWidths[index].toFixed(2) : detail.quoteData.newWidths[index]}</p></div>
                                                          </div>
                                                          <div className="arrow-right"></div>
                                                        </div>



                                                        <div style={{
                                  height:'7px',
                                  width: '1px',
                                  float: 'right',
                                  right: '0px',
                                  marginTop: `calc(${(detail.quoteData.boxHeight-detail.quoteData.topnewHeights - detail.quoteData.bottomnewHeights) * screen}vw - ${detail.quoteData.boxTop === 'on' ? '31px' : '26px' } )`,
                                  marginRight: '-1px',
                                  background: index === (detail.quoteData.numSecondInnerBoxes-1) ? 'transparent' : 'black',
                                  }}></div>


                                                        {detail.quoteData.boxNames[index] === 'Shutter' && (detail.quoteData.boxHandle[index] === 'left' ? (
                                                          <img
                                                            src={Handle}
                                                            style={{
                                                              float: 'left',
                                                              marginTop: `calc(${(((detail.quoteData.boxHeight - detail.quoteData.topnewHeights - detail.quoteData.bottomnewHeights) * screen / mCalc) / 2)}vw - 20px`,
                                                              marginRight: '2px',
                                                              width: screen === 3 ? '5px' : '4px'
                                                            }}

                                                            alt="Left Handle"
                                                          />
                                                        ) : (
                                                          <img
                                                            src={Handle}
                                                            style={{
                                                              float: 'right',
                                                              marginTop: `calc(${(((detail.quoteData.boxHeight - detail.quoteData.topnewHeights - detail.quoteData.bottomnewHeights) * screen / mCalc) / 2)}vw - 20px`,
                                                              marginRight: '2px',
                                                              width: screen === 3 ? '5px' : '4px'
                                                            }}

                                                            alt="Right Handle"
                                                          />

                                                        ))}

                                                        {detail.quoteData.mesh === 'yes' && detail.quoteData.boxNames[index] === 'Shutter' && detail.quoteData.boxHandle[index] === 'left' && (
                                                          <img src={meshimg} style={{ width: screen === 3 ? '40px' : '20px', float: 'left', marginLeft: screen === 3 ? detail.quoteData.boxHandle[index] === 'left' ? '-8px' : '0px' : detail.quoteData.boxHandle[index] === 'left' ? '-8px' : 'px', marginTop: screen === 3 ? `calc(${((detail.quoteData.boxHeight - detail.quoteData.topnewHeights - detail.quoteData.bottomnewHeights) * screen / mCalc)}vw - ${detail.quoteData.boxTop === 'on' ? '72px' : '67px'} )` : `calc(${((detail.quoteData.boxHeight - detail.quoteData.topnewHeights - detail.quoteData.bottomnewHeights) * screen / mCalc)}vw - 50px)` }} />
                                                        )}

                                                        {detail.quoteData.mesh === 'yes' && detail.quoteData.boxNames[index] === 'Shutter' && detail.quoteData.boxHandle[index] === 'right' && (
                                                          <img src={meshimg} style={{ width: screen === 3 ? '40px' : '20px', float: 'left', marginLeft: screen === 3 ? detail.quoteData.boxHandle[index] === 'right' ? '-1px' : '0px' : detail.quoteData.boxHandle[index] === 'right' ? '0px' : 'px', marginTop: screen === 3 ? `calc(${((detail.quoteData.boxHeight - detail.quoteData.topnewHeights - detail.quoteData.bottomnewHeights) * screen / mCalc)}vw - ${detail.quoteData.boxTop === 'on' ? '72px' : '67px'} )` : `calc(${((detail.quoteData.boxHeight - detail.quoteData.topnewHeights - detail.quoteData.bottomnewHeights) * screen / mCalc)}vw - 50px)` }} />
                                                        )}

                                                        {detail.quoteData.boxNames[index] === 'Shutter' && detail.quoteData.grill === 'yes' && createGrill(detail.quoteData.boxHeight, detail.quoteData.topnewHeights, detail.quoteData.bottomnewHeights, mCalc)}

                                                      </div>

                                                    ))}

                                                  </div>


                                                  {detail.quoteData.boxBottom === 'on' && (
                                                    [...Array(detail.quoteData.numBottomBoxes)].map((_, index) => (
                                                      <div style={{ display: 'flex', width: `calc(${detail.quoteData.boxWidth * screen / mCalc / detail.quoteData.numFrames + 'vw'} - 23px)`, height: `calc(${(detail.quoteData.bottomnewHeights * screen / mCalc) + 'vw'} -10px)`, margin: '5px' }}>
                                                        {[...Array(detail.quoteData.bottomFixedNum)].map((_, index) => (
                                                          <div style={{ flex: '1', background: 'lightgreen', width: `calc(${detail.quoteData.bottomFixedNum[index] * screen / mCalc / detail.quoteData.numFrames + 'vw'} - 18px)`, height: `calc(${(detail.quoteData.bottomnewHeights * screen / mCalc) + 'vw'} - 5px)`, border: 'solid 1px #000', marginLeft: index === 0 ? '0px' : '5px', }}>

                                                          </div>
                                                        ))}
                                                      </div>
                                                    ))
                                                  )}







                                                  {detail.quoteData.numSecondInnerBoxes === 0 ? (
                                                    <div className="expanding-arrow-line" style={{ float: 'left', marginLeft: '-5px', width: ((detail.quoteData.boxWidth / detail.quoteData.numFrames) * screen / mCalc) + 'vw', top: (detail.quoteData.boxHeight - detail.quoteData.topnewHeights) * screen / mCalc + 'vw', }} >
                                                      <div className="arrow-left"></div>
                                                      <div className="line" align="center">
                                                        <div style={{ marginTop: '-8px', background: 'white', maxWidth: '60px', textAlign: 'center' }}><p>{typeof (detail.quoteData.boxWidth / detail.quoteData.numFrames) === 'number' ? (detail.quoteData.boxWidth / detail.quoteData.numFrames).toFixed(2) : (detail.quoteData.boxWidth / detail.quoteData.numFrames)}</p></div>
                                                      </div>
                                                      <div className="arrow-right"></div>
                                                    </div>
                                                  ) : (
                                                    <div className="expanding-arrow-line" style={{ float: 'left', marginLeft: '-5px', width: ((detail.quoteData.boxWidth / detail.quoteData.numFrames) * screen / mCalc) + 'vw', bottom: '-10px', top: '50px', }} >
                                                      <div className="arrow-left"></div>
                                                      <div className="line" align="center">
                                                        <div style={{ marginTop: '-8px', background: 'white', maxWidth: '60px', textAlign: 'center' }}><p>{typeof (detail.quoteData.boxWidth / detail.quoteData.numFrames) === 'number' ? (detail.quoteData.boxWidth / detail.quoteData.numFrames).toFixed(2) : (detail.quoteData.boxWidth / detail.quoteData.numFrames)}</p></div>
                                                      </div>
                                                      <div className="arrow-right"></div>
                                                    </div>
                                                  )}

                                                </div>
                                              ))}
                                            </div>





                                            <div style={{ width: '45px', float: 'left', paddingLeft: '15px', textAlign: 'left' }}>
                      {detail.quoteData.boxTop === 'on' &&
                      <>
                        <div className="expanding-arrow-line" style={{ float: 'left', height: `calc(${detail.quoteData.topnewHeights * screen / mCalc}vw + 10px)`, top: '0', position: 'relative' }}>
                          <div className="arrow-up" style={{ borderBottom: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', top: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
                          <div className="line" style={{ width: '1px', height: '100%', marginLeft: '0px', position: 'absolute', top: '0' }}>
                            <div style={{ marginLeft: '-10px', marginTop: detail.quoteData.topnewHeights * (screen / mCalc / 2) + 'vw', background: 'white', maxWidth: '60px', textAlign: 'left' }}><p style={{ width: '50px' }}>{typeof detail.quoteData.topnewHeights === 'number' ? detail.quoteData.topnewHeights.toFixed(2) : detail.quoteData.topnewHeights}</p></div>
                          </div>
                          <div className="arrow-down" style={{ borderTop: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', bottom: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
                        </div>
                      
                      <div className="expanding-arrow-line" style={{ float: 'left', height: `calc(${(detail.quoteData.boxHeight - detail.quoteData.topnewHeights - detail.quoteData.bottomnewHeights) * screen / mCalc}vw - ${detail.quoteData.boxTop === 'on' ? '30px' : '15px'})`, top: `calc(${detail.quoteData.topnewHeights * screen / mCalc}vw + ${detail.quoteData.boxTop === 'on' ? '15px' : '0px'})`, position: 'relative' }}>
                        <div className="arrow-up" style={{ borderBottom: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', top: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
                        <div className="line" style={{ width: '1px', height: '100%', marginLeft: '0px', position: 'absolute', top: '0' }}>
                          <div style={{ marginLeft: '-10px', marginTop: (detail.quoteData.boxHeight - detail.quoteData.topnewHeights - detail.quoteData.bottomnewHeights) * ((screen / mCalc / 2) - (screen / mCalc / 8)) + 'vw', background: 'white', maxWidth: '60px', textAlign: 'left' }}><p style={{ width: '50px' }}>{ Number(detail.quoteData.boxHeight - detail.quoteData.topnewHeights - detail.quoteData.bottomnewHeights).toFixed(2)}</p></div>
                        </div>
                        <div className="arrow-down" style={{ borderTop: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', bottom: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
                      </div>
                      </>
                     }
                      {detail.quoteData.boxBottom === 'on' &&
                      <>
                        <div className="expanding-arrow-line" style={{ float: 'left', height: `calc(${(detail.quoteData.boxHeight - detail.quoteData.topnewHeights - detail.quoteData.bottomnewHeights) * screen / mCalc}vw - ${detail.quoteData.boxTop === 'on' ? '30px' : '15px'})`, top: `calc(${detail.quoteData.topnewHeights * screen / mCalc}vw + ${detail.quoteData.boxTop === 'on' ? '15px' : '0px'})`, position: 'relative' }}>
                        <div className="arrow-up" style={{ borderBottom: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', top: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
                        <div className="line" style={{ width: '1px', height: '100%', marginLeft: '0px', position: 'absolute', top: '0' }}>
                          <div style={{ marginLeft: '-10px', marginTop: (detail.quoteData.boxHeight - detail.quoteData.topnewHeights - detail.quoteData.bottomnewHeights) * ((screen / mCalc / 2) - (screen / mCalc / 8)) + 'vw', background: 'white', maxWidth: '60px', textAlign: 'left' }}><p style={{ width: '50px' }}>{ Number(detail.quoteData.boxHeight - detail.quoteData.topnewHeights - detail.quoteData.bottomnewHeights).toFixed(2)}</p></div>
                        </div>
                        <div className="arrow-down" style={{ borderTop: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', bottom: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
                      </div>
                        <div className="expanding-arrow-line" style={{ float: 'left', height: `calc(${(detail.quoteData.bottomnewHeights) * screen / mCalc}vw + 5px)`, top: `calc(${(detail.quoteData.boxHeight - detail.quoteData.bottomnewHeights) * screen / mCalc}vw - ${detail.quoteData.boxTop === 'on' ? '10px' : '10px'})`, position: 'relative' }}>
                          <div className="arrow-up" style={{ borderBottom: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', top: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
                          <div className="line" style={{ width: '1px', height: '100%', marginLeft: '0px', position: 'absolute', top: '0' }}>
                            <div style={{ marginLeft: '-10px', marginTop: (detail.quoteData.bottomnewHeights) * ((screen / mCalc / 2) - (screen / mCalc / 8)) + 'vw', background: 'white', maxWidth: '60px', textAlign: 'left' }}><p style={{ width: '50px' }}>{ Number(detail.quoteData.bottomnewHeights).toFixed(2)}</p></div>
                          </div>
                          <div className="arrow-down" style={{ borderTop: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', bottom: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
                        </div>
                        </>
                      }

                    </div>

                    <div style={{ width: '30px', float: 'left', paddingLeft: detail.quoteData.boxBottom === 'on' || detail.quoteData.boxTop === 'on' ?'0px' : '15px', textAlign: 'left' }}>
                      <div className="expanding-arrow-line" style={{ float: 'left', height: `calc(${detail.quoteData.boxHeight * screen / mCalc}vw)`, top: '0', position: 'relative' }}>
                        <div className="arrow-up" style={{ borderBottom: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', top: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
                        <div className="line" style={{ width: '1px', height: '100%', marginLeft: '0px', position: 'absolute', top: '0' }}>
                          <div style={{ marginLeft: '-10px', marginTop: detail.quoteData.boxHeight * (screen / mCalc / 2) + 'vw', background: 'white', maxWidth: '60px', textAlign: 'left' }}><p style={{ width: '50px' }}>{ Number(detail.quoteData.boxHeight).toFixed(2)}</p></div>
                        </div>
                        <div className="arrow-down" style={{ borderTop: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', bottom: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
                      </div>
                    </div>

                                          </div>






                                        </div>
                                      </div>
                                    </div>
                                  )}

                                  {detail.quoteData.item === 'Ventilator' && (
                                    <div class="col-md-12 col-xxl-12 col-xl-12" >
                                      <div class="card" style={{ background: '#fff' }}>
                                        <div class="card-body mb-5">
                                          {detail.quoteData.boxHeight > 1 && detail.quoteData.boxWidth > 1 && (

                                            detail.quoteData.fan === 'on' ? (
                                              <>

                                                <div style={{ float: 'left', width: detail.quoteData.boxWidth * screen + 'vw', height: detail.quoteData.boxHeight * screen + 'vw', border: '1px solid #333' }}>

                                                  {detail.quoteData.fanTop === 'Top' && detail.quoteData.fanLeft === 'Left' && divider === 'Vertical' && switchs === 'on' && (
                                                    <div style={{ float: 'left', height: `calc(${detail.quoteData.boxHeight * screen}vw - 10px)`, width: `calc(${detail.quoteData.boxWidth * screen}vw - 10px)`, margin: '4px', border: '1px solid #333' }}>
                                                      <img src={Fan} style={{ float: 'left', width: (detail.quoteData.boxWidth > 1.25 ? .75 : .45) * screen + 'vw', marginLeft: '4px', marginTop: '4px' }} />
                                                      <div style={{ float: 'left', height: `calc(${(detail.quoteData.boxHeight) * screen}vw - 20px)`, width: `calc(${(detail.quoteData.boxWidth - (detail.quoteData.boxWidth > 1.25 ? .85 : .55)) * screen}vw - 26px)`, margin: '5px', border: '1px solid #333', backgroundImage: `repeating-linear-gradient(lightblue, white ${(6 / detail.quoteData.boxHeight) * screen}%, lightblue ${(6 / detail.quoteData.boxHeight) * screen}%)` }}></div>
                                                      <div style={{ float: 'left', height: `calc(${(detail.quoteData.boxHeight - (detail.quoteData.boxWidth > 1.25 ? .85 : .55)) * screen}vw - 25px)`, width: `calc(${(detail.quoteData.boxWidth > 1.25 ? .85 : .55) * screen}vw - 0px)`, margin: '4px', marginTop: `calc(-${(detail.quoteData.boxHeight - (detail.quoteData.boxWidth > 1.25 ? .85 : .55)) * screen}vw + 20px)`, border: '1px solid #333', background: 'lightblue' }}></div>
                                                    </div>
                                                  )}

                                                  {detail.quoteData.fanTop === 'Bottom' && detail.quoteData.fanLeft === 'Left' && divider === 'Vertical' && switchs === 'on' && (
                                                    <div style={{ float: 'left', height: `calc(${detail.quoteData.boxHeight * screen}vw - 10px)`, width: `calc(${detail.quoteData.boxWidth * screen}vw - 10px)`, margin: '4px', border: '1px solid #333' }}>
                                                      <div style={{ float: 'left', height: `calc(${(detail.quoteData.boxHeight - (detail.quoteData.boxWidth > 1.25 ? .85 : .55)) * screen}vw - 25px)`, width: `calc(${(detail.quoteData.boxWidth > 1.25 ? .85 : .55) * screen}vw - 0px)`, margin: '4px', marginTop: '5px', border: '1px solid #333', background: 'lightblue' }}></div>
                                                      <div style={{ float: 'right', height: `calc(${(detail.quoteData.boxHeight) * screen}vw - 20px)`, width: `calc(${(detail.quoteData.boxWidth - (detail.quoteData.boxWidth > 1.25 ? .85 : .55)) * screen}vw - 26px)`, margin: '5px', marginTop: `calc(-${(detail.quoteData.boxHeight - (detail.quoteData.boxWidth > 1.25 ? .85 : .55)) * screen}vw + 20px)`, border: '1px solid #333', backgroundImage: `repeating-linear-gradient(lightblue, white ${(6 / detail.quoteData.boxHeight) * screen}%, lightblue ${(6 / detail.quoteData.boxHeight) * screen}%)` }}></div>
                                                      <img src={Fan} style={{ float: 'left', width: (detail.quoteData.boxWidth > 1.25 ? .75 : .45) * screen + 'vw', marginLeft: '4px', marginBottom: '4px' }} />
                                                    </div>
                                                  )}

                                                  {detail.quoteData.fanTop === 'Top' && detail.quoteData.fanLeft === 'Right' && divider === 'Vertical' && switchs === 'on' && (
                                                    <div style={{ float: 'left', height: `calc(${detail.quoteData.boxHeight * screen}vw - 10px)`, width: `calc(${detail.quoteData.boxWidth * screen}vw - 10px)`, margin: '4px', border: '1px solid #333' }}>
                                                      <img src={Fan} style={{ float: 'right', width: (detail.quoteData.boxWidth > 1.25 ? .75 : .45) * screen + 'vw', marginRight: '4px', marginTop: '4px' }} />
                                                      <div style={{ float: 'left', height: `calc(${(detail.quoteData.boxHeight) * screen}vw - 20px)`, width: `calc(${(detail.quoteData.boxWidth - (detail.quoteData.boxWidth > 1.25 ? .85 : .55)) * screen}vw - 26px)`, margin: '5px', border: '1px solid #333', backgroundImage: `repeating-linear-gradient(lightblue, white ${(6 / detail.quoteData.boxHeight) * screen}%, lightblue ${(6 / detail.quoteData.boxHeight) * screen}%)` }}></div>
                                                      <div style={{ float: 'right', height: `calc(${(detail.quoteData.boxHeight - (detail.quoteData.boxWidth > 1.25 ? .85 : .55)) * screen}vw - 25px)`, width: `calc(${(detail.quoteData.boxWidth > 1.25 ? .85 : .55) * screen}vw - 0px)`, margin: '4px', marginTop: `calc(-${(detail.quoteData.boxHeight - (detail.quoteData.boxWidth > 1.25 ? .85 : .55)) * screen}vw + 20px)`, border: '1px solid #333', background: 'lightblue' }}></div>
                                                    </div>
                                                  )}

                                                  {detail.quoteData.fanTop === 'Bottom' && detail.quoteData.fanLeft === 'Right' && divider === 'Vertical' && switchs === 'on' && (
                                                    <div style={{ float: 'left', height: `calc(${detail.quoteData.boxHeight * screen}vw - 10px)`, width: `calc(${detail.quoteData.boxWidth * screen}vw - 10px)`, margin: '4px', border: '1px solid #333' }}>
                                                      <div style={{ float: 'right', height: `calc(${(detail.quoteData.boxHeight - (detail.quoteData.boxWidth > 1.25 ? .85 : .55)) * screen}vw - 25px)`, width: `calc(${(detail.quoteData.boxWidth > 1.25 ? .85 : .55) * screen}vw - 0px)`, margin: '4px', marginTop: '5px', border: '1px solid #333', background: 'lightblue' }}></div>
                                                      <div style={{ float: 'left', height: `calc(${(detail.quoteData.boxHeight) * screen}vw - 20px)`, width: `calc(${(detail.quoteData.boxWidth - (detail.quoteData.boxWidth > 1.25 ? .85 : .55)) * screen}vw - 26px)`, margin: '5px', marginTop: `calc(-${(detail.quoteData.boxHeight - (detail.quoteData.boxWidth > 1.25 ? .85 : .55)) * screen}vw + 20px)`, border: '1px solid #333', backgroundImage: `repeating-linear-gradient(lightblue, white ${(6 / detail.quoteData.boxHeight) * screen}%, lightblue ${(6 / detail.quoteData.boxHeight) * screen}%)` }}></div>
                                                      <img src={Fan} style={{ float: 'right', width: (detail.quoteData.boxWidth > 1.25 ? .75 : .45) * screen + 'vw', marginRight: '4px', marginBottom: '4px' }} />
                                                    </div>
                                                  )}

                                                  {detail.quoteData.fanTop === 'Top' && detail.quoteData.fanLeft === 'Left' && divider === 'Vertical' && switchs === 'off' && (
                                                    <div style={{ float: 'left', height: `calc(${detail.quoteData.boxHeight * screen}vw - 10px)`, width: `calc(${detail.quoteData.boxWidth * screen}vw - 10px)`, margin: '4px', border: '1px solid #333' }}>
                                                      <img src={Fan} style={{ float: 'left', width: (detail.quoteData.boxWidth > 1.25 ? .75 : .45) * screen + 'vw', marginLeft: '4px', marginTop: '4px' }} />
                                                      <div style={{ float: 'left', height: `calc(${(detail.quoteData.boxHeight) * screen}vw - 20px)`, width: `calc(${(detail.quoteData.boxWidth - (detail.quoteData.boxWidth > 1.25 ? .85 : .55)) * screen}vw - 26px)`, margin: '5px', border: '1px solid #333', background: 'lightblue' }}></div>
                                                      <div style={{ float: 'left', height: `calc(${(detail.quoteData.boxHeight - (detail.quoteData.boxWidth > 1.25 ? .85 : .55)) * screen}vw - 25px)`, width: `calc(${(detail.quoteData.boxWidth > 1.25 ? .85 : .55) * screen}vw - 0px)`, margin: '4px', marginTop: `calc(-${(detail.quoteData.boxHeight - (detail.quoteData.boxWidth > 1.25 ? .85 : .55)) * screen}vw + 20px)`, border: '1px solid #333', backgroundImage: `repeating-linear-gradient(lightblue, white ${(10 / detail.quoteData.boxHeight) * screen}%, lightblue ${(10 / detail.quoteData.boxHeight) * screen}%)` }}></div>
                                                    </div>
                                                  )}

                                                  {detail.quoteData.fanTop === 'Bottom' && detail.quoteData.fanLeft === 'Left' && divider === 'Vertical' && switchs === 'off' && (
                                                    <div style={{ float: 'left', height: `calc(${detail.quoteData.boxHeight * screen}vw - 10px)`, width: `calc(${detail.quoteData.boxWidth * screen}vw - 10px)`, margin: '4px', border: '1px solid #333' }}>
                                                      <div style={{ float: 'left', height: `calc(${(detail.quoteData.boxHeight - (detail.quoteData.boxWidth > 1.25 ? .85 : .55)) * screen}vw - 25px)`, width: `calc(${(detail.quoteData.boxWidth > 1.25 ? .85 : .55) * screen}vw - 0px)`, margin: '4px', marginTop: '5px', border: '1px solid #333', backgroundImage: `repeating-linear-gradient(lightblue, white ${(10 / detail.quoteData.boxHeight) * screen}%, lightblue ${(10 / detail.quoteData.boxHeight) * screen}%)` }}></div>
                                                      <div style={{ float: 'right', height: `calc(${(detail.quoteData.boxHeight) * screen}vw - 20px)`, width: `calc(${(detail.quoteData.boxWidth - (detail.quoteData.boxWidth > 1.25 ? .85 : .55)) * screen}vw - 26px)`, margin: '5px', marginTop: `calc(-${(detail.quoteData.boxHeight - (detail.quoteData.boxWidth > 1.25 ? .85 : .55)) * screen}vw + 20px)`, border: '1px solid #333', background: 'lightblue' }}></div>
                                                      <img src={Fan} style={{ float: 'left', width: (detail.quoteData.boxWidth > 1.25 ? .75 : .45) * screen + 'vw', marginLeft: '4px', marginBottom: '4px' }} />
                                                    </div>
                                                  )}

                                                  {detail.quoteData.fanTop === 'Top' && detail.quoteData.fanLeft === 'Right' && divider === 'Vertical' && switchs === 'off' && (
                                                    <div style={{ float: 'left', height: `calc(${detail.quoteData.boxHeight * screen}vw - 10px)`, width: `calc(${detail.quoteData.boxWidth * screen}vw - 10px)`, margin: '4px', border: '1px solid #333' }}>
                                                      <img src={Fan} style={{ float: 'right', width: (detail.quoteData.boxWidth > 1.25 ? .75 : .45) * screen + 'vw', marginRight: '4px', marginTop: '4px' }} />
                                                      <div style={{ float: 'left', height: `calc(${(detail.quoteData.boxHeight) * screen}vw - 20px)`, width: `calc(${(detail.quoteData.boxWidth - (detail.quoteData.boxWidth > 1.25 ? .85 : .55)) * screen}vw - 26px)`, margin: '5px', border: '1px solid #333', background: 'lightblue' }}></div>
                                                      <div style={{ float: 'right', height: `calc(${(detail.quoteData.boxHeight - (detail.quoteData.boxWidth > 1.25 ? .85 : .55)) * screen}vw - 25px)`, width: `calc(${(detail.quoteData.boxWidth > 1.25 ? .85 : .55) * screen}vw - 0px)`, margin: '4px', marginTop: `calc(-${(detail.quoteData.boxHeight - (detail.quoteData.boxWidth > 1.25 ? .85 : .55)) * screen}vw + 20px)`, border: '1px solid #333', backgroundImage: `repeating-linear-gradient(lightblue, white ${(10 / detail.quoteData.boxHeight) * screen}%, lightblue ${(10 / detail.quoteData.boxHeight) * screen}%)` }}></div>
                                                    </div>
                                                  )}

                                                  {detail.quoteData.fanTop === 'Bottom' && detail.quoteData.fanLeft === 'Right' && divider === 'Vertical' && switchs === 'off' && (
                                                    <div style={{ float: 'left', height: `calc(${detail.quoteData.boxHeight * screen}vw - 10px)`, width: `calc(${detail.quoteData.boxWidth * screen}vw - 10px)`, margin: '4px', border: '1px solid #333' }}>
                                                      <div style={{ float: 'right', height: `calc(${(detail.quoteData.boxHeight - (detail.quoteData.boxWidth > 1.25 ? .85 : .55)) * screen}vw - 25px)`, width: `calc(${(detail.quoteData.boxWidth > 1.25 ? .85 : .55) * screen}vw - 0px)`, margin: '4px', marginTop: '5px', border: '1px solid #333', backgroundImage: `repeating-linear-gradient(lightblue, white ${(10 / detail.quoteData.boxHeight) * screen}%, lightblue ${(10 / detail.quoteData.boxHeight) * screen}%)` }}></div>
                                                      <div style={{ float: 'left', height: `calc(${(detail.quoteData.boxHeight) * screen}vw - 20px)`, width: `calc(${(detail.quoteData.boxWidth - (detail.quoteData.boxWidth > 1.25 ? .85 : .55)) * screen}vw - 26px)`, margin: '5px', marginTop: `calc(-${(detail.quoteData.boxHeight - (detail.quoteData.boxWidth > 1.25 ? .85 : .55)) * screen}vw + 20px)`, border: '1px solid #333', background: 'lightblue' }}></div>
                                                      <img src={Fan} style={{ float: 'right', width: (detail.quoteData.boxWidth > 1.25 ? .75 : .45) * screen + 'vw', marginRight: '4px', marginBottom: '4px' }} />
                                                    </div>
                                                  )}

                                                  {detail.quoteData.fanTop === 'Top' && detail.quoteData.fanLeft === 'Left' && divider === 'Horizontal' && switchs === 'on' && (
                                                    <div style={{ float: 'left', height: `calc(${detail.quoteData.boxHeight * screen}vw - 10px)`, width: `calc(${detail.quoteData.boxWidth * screen}vw - 10px)`, margin: '4px', border: '1px solid #333' }}>
                                                      <img src={Fan} style={{ float: 'left', width: (detail.quoteData.boxWidth > 1.25 ? .75 : .45) * screen + 'vw', marginLeft: '4px', marginTop: '4px' }} />
                                                      <div style={{ float: 'right', height: `calc(${(detail.quoteData.boxWidth > 1.25 ? .85 : .55) * screen}vw - 0px)`, width: `calc(${(detail.quoteData.boxWidth - (detail.quoteData.boxWidth > 1.25 ? .85 : .55)) * screen}vw - 26px)`, margin: '5px', border: '1px solid #333', backgroundImage: `repeating-linear-gradient(lightblue, white ${(10 / detail.quoteData.boxHeight) * screen}%, lightblue ${(10 / detail.quoteData.boxHeight) * screen}%)` }}></div>
                                                      <div style={{ float: 'left', height: `calc(${(detail.quoteData.boxHeight - (detail.quoteData.boxWidth > 1.25 ? .85 : .55)) * screen}vw - 25px)`, width: `calc(${(detail.quoteData.boxWidth) * screen}vw - 20px)`, margin: '4px', marginTop: '0px', border: '1px solid #333', background: 'lightblue' }}></div>
                                                    </div>
                                                  )}

                                                  {detail.quoteData.fanTop === 'Bottom' && detail.quoteData.fanLeft === 'Left' && divider === 'Horizontal' && switchs === 'on' && (
                                                    <div style={{ float: 'left', height: `calc(${detail.quoteData.boxHeight * screen}vw - 10px)`, width: `calc(${detail.quoteData.boxWidth * screen}vw - 10px)`, margin: '4px', border: '1px solid #333' }}>
                                                      <div style={{ float: 'left', height: `calc(${(detail.quoteData.boxHeight - (detail.quoteData.boxWidth > 1.25 ? .85 : .55)) * screen}vw - 25px)`, width: `calc(${(detail.quoteData.boxWidth) * screen}vw - 20px)`, margin: '4px', marginTop: '5px', border: '1px solid #333', background: 'lightblue' }}></div>
                                                      <div style={{ float: 'right', height: `calc(${(detail.quoteData.boxWidth > 1.25 ? .85 : .55) * screen}vw - 0px)`, width: `calc(${(detail.quoteData.boxWidth - (detail.quoteData.boxWidth > 1.25 ? .85 : .55)) * screen}vw - 26px)`, marginRight: '5px', marginBottom: '4px', border: '1px solid #333', backgroundImage: `repeating-linear-gradient(lightblue, white ${(10 / detail.quoteData.boxHeight) * screen}%, lightblue ${(10 / detail.quoteData.boxHeight) * screen}%)` }}></div>
                                                      <img src={Fan} style={{ float: 'left', width: (detail.quoteData.boxWidth > 1.25 ? .75 : .45) * screen + 'vw', marginLeft: '4px', marginBottom: '4px' }} />
                                                    </div>
                                                  )}

                                                  {detail.quoteData.fanTop === 'Top' && detail.quoteData.fanLeft === 'Right' && divider === 'Horizontal' && switchs === 'on' && (
                                                    <div style={{ float: 'left', height: `calc(${detail.quoteData.boxHeight * screen}vw - 10px)`, width: `calc(${detail.quoteData.boxWidth * screen}vw - 10px)`, margin: '4px', border: '1px solid #333' }}>
                                                      <img src={Fan} style={{ float: 'right', width: (detail.quoteData.boxWidth > 1.25 ? .75 : .45) * screen + 'vw', marginRight: '4px', marginTop: '4px' }} />
                                                      <div style={{ float: 'left', height: `calc(${(detail.quoteData.boxWidth > 1.25 ? .85 : .55) * screen}vw - 0px)`, width: `calc(${(detail.quoteData.boxWidth - (detail.quoteData.boxWidth > 1.25 ? .85 : .55)) * screen}vw - 26px)`, margin: '5px', border: '1px solid #333', backgroundImage: `repeating-linear-gradient(lightblue, white ${(10 / detail.quoteData.boxHeight) * screen}%, lightblue ${(10 / detail.quoteData.boxHeight) * screen}%)` }}></div>
                                                      <div style={{ float: 'left', height: `calc(${(detail.quoteData.boxHeight - (detail.quoteData.boxWidth > 1.25 ? .85 : .55)) * screen}vw - 25px)`, width: `calc(${(detail.quoteData.boxWidth) * screen}vw - 20px)`, margin: '4px', marginTop: '0px', border: '1px solid #333', background: 'lightblue' }}></div>
                                                    </div>
                                                  )}

                                                  {detail.quoteData.fanTop === 'Bottom' && detail.quoteData.fanLeft === 'Right' && divider === 'Horizontal' && switchs === 'on' && (
                                                    <div style={{ float: 'left', height: `calc(${detail.quoteData.boxHeight * screen}vw - 10px)`, width: `calc(${detail.quoteData.boxWidth * screen}vw - 10px)`, margin: '4px', border: '1px solid #333' }}>
                                                      <div style={{ float: 'left', height: `calc(${(detail.quoteData.boxHeight - (detail.quoteData.boxWidth > 1.25 ? .85 : .55)) * screen}vw - 25px)`, width: `calc(${(detail.quoteData.boxWidth) * screen}vw - 20px)`, margin: '4px', marginTop: '5px', border: '1px solid #333', background: 'lightblue' }}></div>
                                                      <div style={{ float: 'left', height: `calc(${(detail.quoteData.boxWidth > 1.25 ? .85 : .55) * screen}vw - 0px)`, width: `calc(${(detail.quoteData.boxWidth - (detail.quoteData.boxWidth > 1.25 ? .85 : .55)) * screen}vw - 26px)`, marginLeft: '5px', marginBottom: '4px', border: '1px solid #333', backgroundImage: `repeating-linear-gradient(lightblue, white ${(10 / detail.quoteData.boxHeight) * screen}%, lightblue ${(10 / detail.quoteData.boxHeight) * screen}%)` }}></div>
                                                      <img src={Fan} style={{ float: 'right', width: (detail.quoteData.boxWidth > 1.25 ? .75 : .45) * screen + 'vw', marginRight: '4px', marginBottom: '4px' }} />
                                                    </div>
                                                  )}

                                                  {detail.quoteData.fanTop === 'Top' && detail.quoteData.fanLeft === 'Left' && divider === 'Horizontal' && switchs === 'off' && (
                                                    <div style={{ float: 'left', height: `calc(${detail.quoteData.boxHeight * screen}vw - 10px)`, width: `calc(${detail.quoteData.boxWidth * screen}vw - 10px)`, margin: '4px', border: '1px solid #333' }}>
                                                      <img src={Fan} style={{ float: 'left', width: (detail.quoteData.boxWidth > 1.25 ? .75 : .45) * screen + 'vw', marginLeft: '4px', marginTop: '4px' }} />
                                                      <div style={{ float: 'right', height: `calc(${(detail.quoteData.boxWidth > 1.25 ? .85 : .55) * screen}vw - 0px)`, width: `calc(${(detail.quoteData.boxWidth - (detail.quoteData.boxWidth > 1.25 ? .85 : .55)) * screen}vw - 26px)`, margin: '5px', border: '1px solid #333', background: 'lightblue' }}></div>
                                                      <div style={{ float: 'left', height: `calc(${(detail.quoteData.boxHeight - (detail.quoteData.boxWidth > 1.25 ? .85 : .55)) * screen}vw - 25px)`, width: `calc(${(detail.quoteData.boxWidth) * screen}vw - 20px)`, margin: '4px', marginTop: '0px', border: '1px solid #333', backgroundImage: `repeating-linear-gradient(lightblue, white ${(10 / detail.quoteData.boxHeight) * screen}%, lightblue ${(10 / detail.quoteData.boxHeight) * screen}%)` }}></div>
                                                    </div>
                                                  )}

                                                  {detail.quoteData.fanTop === 'Top' && detail.quoteData.fanLeft === 'Right' && divider === 'Horizontal' && switchs === 'off' && (
                                                    <div style={{ float: 'left', height: `calc(${detail.quoteData.boxHeight * screen}vw - 10px)`, width: `calc(${detail.quoteData.boxWidth * screen}vw - 10px)`, margin: '4px', border: '1px solid #333' }}>
                                                      <img src={Fan} style={{ float: 'right', width: (detail.quoteData.boxWidth > 1.25 ? .75 : .45) * screen + 'vw', marginRight: '4px', marginTop: '4px' }} />
                                                      <div style={{ float: 'left', height: `calc(${(detail.quoteData.boxWidth > 1.25 ? .85 : .55) * screen}vw - 0px)`, width: `calc(${(detail.quoteData.boxWidth - (detail.quoteData.boxWidth > 1.25 ? .85 : .55)) * screen}vw - 26px)`, margin: '5px', border: '1px solid #333', background: 'lightblue' }}></div>
                                                      <div style={{ float: 'left', height: `calc(${(detail.quoteData.boxHeight - (detail.quoteData.boxWidth > 1.25 ? .85 : .55)) * screen}vw - 25px)`, width: `calc(${(detail.quoteData.boxWidth) * screen}vw - 20px)`, margin: '4px', marginTop: '0px', border: '1px solid #333', backgroundImage: `repeating-linear-gradient(lightblue, white ${(10 / detail.quoteData.boxHeight) * screen}%, lightblue ${(10 / detail.quoteData.boxHeight) * screen}%)` }}></div>
                                                    </div>
                                                  )}

                                                  {detail.quoteData.fanTop === 'Bottom' && detail.quoteData.fanLeft === 'Right' && divider === 'Horizontal' && switchs === 'off' && (
                                                    <div style={{ float: 'left', height: `calc(${detail.quoteData.boxHeight * screen}vw - 10px)`, width: `calc(${detail.quoteData.boxWidth * screen}vw - 10px)`, margin: '4px', border: '1px solid #333' }}>
                                                      <div style={{ float: 'left', height: `calc(${(detail.quoteData.boxHeight - (detail.quoteData.boxWidth > 1.25 ? .85 : .55)) * screen}vw - 25px)`, width: `calc(${(detail.quoteData.boxWidth) * screen}vw - 20px)`, margin: '4px', marginTop: '5px', border: '1px solid #333', backgroundImage: `repeating-linear-gradient(lightblue, white ${(10 / detail.quoteData.boxHeight) * screen}%, lightblue ${(10 / detail.quoteData.boxHeight) * screen}%)` }}></div>
                                                      <img src={Fan} style={{ float: 'right', width: (detail.quoteData.boxWidth > 1.25 ? .75 : .45) * screen + 'vw', marginRight: '4px', marginTop: '0px' }} />
                                                      <div style={{ float: 'left', height: `calc(${(detail.quoteData.boxWidth > 1.25 ? .85 : .55) * screen}vw - 0px)`, width: `calc(${(detail.quoteData.boxWidth - (detail.quoteData.boxWidth > 1.25 ? .85 : .55)) * screen}vw - 26px)`, marginLeft: '4px', border: '1px solid #333', background: 'lightblue' }}></div>
                                                    </div>
                                                  )}

                                                  {detail.quoteData.fanTop === 'Bottom' && detail.quoteData.fanLeft === 'Left' && divider === 'Horizontal' && switchs === 'off' && (
                                                    <div style={{ float: 'left', height: `calc(${detail.quoteData.boxHeight * screen}vw - 10px)`, width: `calc(${detail.quoteData.boxWidth * screen}vw - 10px)`, margin: '4px', border: '1px solid #333' }}>
                                                      <div style={{ float: 'left', height: `calc(${(detail.quoteData.boxHeight - (detail.quoteData.boxWidth > 1.25 ? .85 : .55)) * screen}vw - 25px)`, width: `calc(${(detail.quoteData.boxWidth) * screen}vw - 20px)`, margin: '4px', marginTop: '5px', border: '1px solid #333', backgroundImage: `repeating-linear-gradient(lightblue, white ${(10 / detail.quoteData.boxHeight) * screen}%, lightblue ${(10 / detail.quoteData.boxHeight) * screen}%)` }}></div>
                                                      <img src={Fan} style={{ float: 'left', width: (detail.quoteData.boxWidth > 1.25 ? .75 : .45) * screen + 'vw', marginLeft: '4px', marginRight: '4px' }} />
                                                      <div style={{ float: 'right', height: `calc(${(detail.quoteData.boxWidth > 1.25 ? .85 : .55) * screen}vw - 0px)`, width: `calc(${(detail.quoteData.boxWidth - (detail.quoteData.boxWidth > 1.25 ? .85 : .55)) * screen}vw - 26px)`, marginRight: '5px', border: '1px solid #333', background: 'lightblue' }}></div>
                                                    </div>
                                                  )}

                                                </div>

                                              </>

                                            ) : (
                                              <>
                                                <div style={{ float: 'left', width: detail.quoteData.boxWidth * screen + 'vw', height: detail.quoteData.boxHeight * screen + 'vw', border: '1px solid #333' }}>
                                                  {detail.quoteData.switchFixed === 'on' ? (
                                                    <div style={{ float: 'right', height: `calc(${(detail.quoteData.boxHeight) * screen}vw - 12px)`, width: `calc(${(detail.quoteData.boxWidth) * screen}vw - 12px)`, marginRight: '5px', marginTop: '5px', background: 'lightblue', border: '1px solid #333' }}></div>
                                                  ) : (
                                                    <>
                                                      <div style={{ float: 'right', height: `calc(${(detail.quoteData.boxHeight) * screen}vw - 12px)`, width: `calc(${(detail.quoteData.boxWidth) * screen}vw - 12px)`, marginRight: '5px', marginTop: '5px', backgroundImage: `repeating-linear-gradient(lightblue, white ${(6 / detail.quoteData.boxHeight) * screen}%, lightblue ${(6 / detail.quoteData.boxHeight) * screen}%)`, border: '1px solid #333' }}></div>
                                                    </>
                                                  )}

                                                </div>
                                              </>
                                            )
                                          )}

                                          {detail.quoteData.boxHeight === 1 && detail.quoteData.boxWidth > 1 && (

                                            detail.quoteData.fan === 'on' ? (
                                              <>
                                                {detail.quoteData.switchFixed === 'on' && (

                                                  <div style={{ float: 'left', width: detail.quoteData.boxWidth * screen + 'vw', height: detail.quoteData.boxHeight * screen + 'vw', border: '1px solid #333' }}>
                                                    <div style={{ float: 'left', height: `calc(${detail.quoteData.boxHeight * screen}vw - 10px)`, width: `calc(${detail.quoteData.boxWidth * screen}vw - 10px)`, margin: '4px', border: '1px solid #333' }}>
                                                      <img src={Fan} style={{ float: 'left', width: .75 * screen + 'vw', margin: '4px', }} />
                                                      <div style={{ float: 'right', height: `calc(${(detail.quoteData.boxHeight) * screen}vw - 22px)`, width: `calc(${(detail.quoteData.boxWidth - .94) * screen}vw - 10px)`, marginRight: '5px', marginTop: '5px', background: 'lightblue', border: '1px solid #333' }}></div>
                                                    </div>
                                                  </div>
                                                )}

                                                {detail.quoteData.switchFixed === 'off' && (

                                                  <div style={{ float: 'left', width: detail.quoteData.boxWidth * screen + 'vw', height: detail.quoteData.boxHeight * screen + 'vw', border: '1px solid #333' }}>
                                                    <div style={{ float: 'left', height: `calc(${detail.quoteData.boxHeight * screen}vw - 10px)`, width: `calc(${detail.quoteData.boxWidth * screen}vw - 10px)`, margin: '4px', border: '1px solid #333' }}>
                                                      <img src={Fan} style={{ float: 'left', width: .75 * screen + 'vw', margin: '4px', }} />
                                                      <div style={{ float: 'right', height: `calc(${(detail.quoteData.boxHeight) * screen}vw - 22px)`, width: `calc(${(detail.quoteData.boxWidth - .94) * screen}vw - 10px)`, marginRight: '5px', marginTop: '5px', backgroundImage: `repeating-linear-gradient(lightblue, white ${(6 / detail.quoteData.boxHeight) * screen}%, lightblue ${(6 / detail.quoteData.boxHeight) * screen}%)`, border: '1px solid #333' }}></div>
                                                    </div>
                                                  </div>
                                                )}
                                              </>
                                            ) : (

                                              <div style={{ float: 'left', width: detail.quoteData.boxWidth * screen + 'vw', height: detail.quoteData.boxHeight * screen + 'vw', border: '1px solid #333' }}>
                                                {detail.quoteData.switchFixed === 'off' ?
                                                  <div style={{ float: 'right', height: `calc(${(detail.quoteData.boxHeight) * screen}vw - 12px)`, width: `calc(${(detail.quoteData.boxWidth) * screen}vw - 10px)`, marginRight: '5px', marginTop: '5px', backgroundImage: `repeating-linear-gradient(lightblue, white ${(6 / detail.quoteData.boxHeight) * screen}%, lightblue ${(6 / detail.quoteData.boxHeight) * screen}%)`, border: '1px solid #333' }}></div>
                                                  :
                                                  <div style={{ float: 'right', height: `calc(${(detail.quoteData.boxHeight) * screen}vw - 12px)`, width: `calc(${(detail.quoteData.boxWidth) * screen}vw - 10px)`, marginRight: '5px', marginTop: '5px', background: 'lightblue', border: '1px solid #333' }}></div>
                                                }
                                              </div>

                                            )

                                          )}

                                          {detail.quoteData.boxHeight >= 1 && detail.quoteData.boxWidth == 1 && (
                                            detail.quoteData.fan === 'on' ? (
                                              <>
                                                {detail.quoteData.switchFixed === 'on' && (
                                                  <div style={{ float: 'left', width: detail.quoteData.boxWidth * screen + 'vw', height: detail.quoteData.boxHeight * screen + 'vw', border: '1px solid #333' }}>
                                                    <div style={{ float: 'left', height: `calc(${detail.quoteData.boxHeight * screen}vw - 10px)`, width: `calc(${detail.quoteData.boxWidth * screen}vw - 10px)`, margin: '4px', border: '1px solid #333' }}>
                                                      <img src={Fan} style={{ float: 'left', width: .75 * screen + 'vw', margin: '4px', }} />
                                                      <div style={{ float: 'right', height: `calc(${(detail.quoteData.boxHeight - .75) * screen}vw - 25px)`, width: `calc(${(detail.quoteData.boxWidth) * screen}vw - 20px)`, marginRight: '5px', background: 'lightblue', border: '1px solid #333' }}></div>
                                                    </div>
                                                  </div>
                                                )}

                                                {detail.quoteData.switchFixed === 'off' && (
                                                  <div style={{ float: 'left', width: detail.quoteData.boxWidth * screen + 'vw', height: detail.quoteData.boxHeight * screen + 'vw', border: '1px solid #333' }}>
                                                    <div style={{ float: 'left', height: `calc(${detail.quoteData.boxHeight * screen}vw - 10px)`, width: `calc(${detail.quoteData.boxWidth * screen}vw - 10px)`, margin: '4px', border: '1px solid #333' }}>
                                                      <img src={Fan} style={{ float: 'left', width: .75 * screen + 'vw', margin: '4px', }} />
                                                      <div style={{ float: 'right', height: `calc(${(detail.quoteData.boxHeight - .75) * screen}vw - 25px)`, width: `calc(${(detail.quoteData.boxWidth) * screen}vw - 20px)`, marginRight: '5px', backgroundImage: `repeating-linear-gradient(lightblue, white ${(6 / detail.quoteData.boxHeight) * screen}%, lightblue ${(6 / detail.quoteData.boxHeight) * screen}%)`, border: '1px solid #333' }}></div>
                                                    </div>
                                                  </div>
                                                )}
                                              </>
                                            ) : (
                                              <div style={{ float: 'left', width: detail.quoteData.boxWidth * screen + 'vw', height: detail.quoteData.boxHeight * screen + 'vw', border: '1px solid #333' }}>
                                                {detail.quoteData.switchFixed === 'off' ?
                                                  <div style={{ float: 'right', height: `calc(${(detail.quoteData.boxHeight) * screen}vw - 12px)`, width: `calc(${(detail.quoteData.boxWidth) * screen}vw - 12px)`, marginRight: '5px', marginTop: '5px', backgroundImage: `repeating-linear-gradient(lightblue, white ${(6 / detail.quoteData.boxHeight) * screen}%, lightblue ${(6 / detail.quoteData.boxHeight) * screen}%)`, border: '1px solid #333' }}></div>
                                                  :
                                                  <div style={{ float: 'right', height: `calc(${(detail.quoteData.boxHeight) * screen}vw - 12px)`, width: `calc(${(detail.quoteData.boxWidth) * screen}vw - 12px)`, marginRight: '5px', marginTop: '5px', background: 'lightblue', border: '1px solid #333' }}></div>
                                                }
                                              </div>
                                            )
                                          )}

                                          {(detail.quoteData.boxWidth > 1 || detail.quoteData.boxHeight > 1) && (
                                            <>
                                              <div className="expanding-arrow-line" style={{
                                                position: 'absolute',
                                                float: 'left',
                                                width: `calc(${detail.quoteData.boxWidth * screen}vw )`,
                                                bottom: '-10px',
                                                top: `calc(${detail.quoteData.boxHeight * screen}vw - 25px)`,
                                              }}>
                                                <div className="arrow-left"></div>
                                                <div className="line" align="center">
                                                  <div style={{ marginTop: '-7px', background: 'white', maxWidth: '20px', textAlign: 'center' }}><p>{typeof detail.quoteData.boxWidth === 'number' ? detail.quoteData.boxWidth.toFixed(2) : detail.quoteData.boxWidth}</p></div>
                                                </div>
                                                <div className="arrow-right"></div>
                                              </div>

                                              <div style={{ width: '30px', float: 'left', paddingLeft: '15px', textAlign: 'left' }}>
                                                <div className="expanding-arrow-line" style={{ float: 'left', height: `calc(${detail.quoteData.boxHeight * screen}vw)`, top: '0', position: 'relative' }}>
                                                  <div className="arrow-up" style={{ borderBottom: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', top: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
                                                  <div className="line" style={{ width: '1px', height: '100%', marginLeft: '0px', position: 'absolute', top: '0' }}>
                                                    <div style={{ marginLeft: '-10px', marginTop: detail.quoteData.boxHeight * (screen / 2) + 'vw', background: 'white', maxWidth: '50px', textAlign: 'center' }}><p style={{ width: '20px' }}>{typeof detail.quoteData.boxHeight === 'number' ? detail.quoteData.boxHeight.toFixed(2) : detail.quoteData.boxHeight}</p></div>
                                                  </div>
                                                  <div className="arrow-down" style={{ borderTop: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', bottom: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
                                                </div>
                                              </div>
                                            </>
                                          )}


                                        </div>
                                      </div>
                                    </div>

                                  )}



                                </td>

                                <td>{detail.quoteData.qty}</td>
                                <td>Area: {detail.quoteData.frameArea * detail.quoteData.qty} SqFt<br></br>
                                  RATE: ₹ {numeral(detail.quoteData.grandTotal * detail.quoteData.qty).format('0,0.00')}<br></br>
                                  RATE/SQFT: ₹ {numeral(detail.quoteData.grandTotal / detail.quoteData.frameArea).format('0,0.00')}</td>
                                {matchedQuotes[0]?.quoteID === quoteIds ? (
                                  <></>
                                ) : (
                                  <>
                                    {detail.quoteData.item === 'CasementDoor' && (
                                      <td>
                                        <a href={`editcasementDoor?id=${quoteIds}&arrayValues=${detailIndex}`} className="btn btn-primary w-100">Edit</a>
                                      </td>
                                    )}
                                    {detail.quoteData.item === 'SlidingDoor' && (
                                      <td>
                                        <a href={`editslidingDoor?id=${quoteIds}&arrayValues=${detailIndex}`} className="btn btn-primary w-100">Edit</a>
                                      </td>
                                    )}
                                    {detail.quoteData.item === 'CasementWindow' && (
                                      <td>
                                        <a href={`editcasementWindow?id=${quoteIds}&arrayValues=${detailIndex}`} className="btn btn-primary w-100">Edit</a>
                                      </td>
                                    )}
                                    {detail.quoteData.item === 'SlidingWindow' && (
                                      <td>
                                        <a href={`editslidingWindow?id=${quoteIds}&arrayValues=${detailIndex}`} className="btn btn-primary w-100">Edit</a>
                                      </td>
                                    )}
                                    {detail.quoteData.item === 'Ventilator' && (
                                      <td>
                                        <a href={`editventilator?id=${quoteIds}&arrayValues=${detailIndex}`} className="btn btn-primary w-100">Edit</a>
                                      </td>
                                    )}
                                  </>
                                )}

                                <td>
                                  <a href={`quotees?id=${quoteIds}&arrayValues=${detailIndex}`} class="btn btn-success w-100" >View</a>
                                </td>
                                {matchedQuotes[0]?.quoteID === quoteIds ? (
                                  <></>
                                ) : (
                                  <td>
                                    <a onClick={() => removequote(quoteIds, detail.rowId)} ><i class="btn btn-danger ti ti-trash"></i></a>
                                  </td>

                                )}

                              </tr>


                            ))}

                          </tbody>
                        ))}
                      </table>
                    </div>
                    <hr />
                  </div>

                  {matchedQuotes[0]?.quoteID === quoteIds ? (
                    <></>
                  ) : (
                    <div class="row mb-5">
                      <div class="col-12">

                        <div class="d-grid" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                        ><a
                          class="btn btn-outline-secondary"
                          href="/select"
                          onClick={handleAddClick}
                        ><i class="ti ti-plus"></i> Add Items</a ></div>
                      </div>
                    </div>
                  )}

                </>)}
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
}


export default Quotesdet;







