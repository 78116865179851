import React, { useEffect, useState } from 'react';
import { ReactSession } from 'react-client-session';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import Navbar from "./navbar";
import Header from "./header";
import left from '../images/handleLeft.png';
import right from '../images/handleRight.png';
import meshimg from '../images/mesh.png';
import numeral from 'numeral';
import Handle from '../images/slideHandle.png';
import Fan from '../images/fan.png';
import './box.css';
ReactSession.setStoreType("localStorage");

function Quotees() {
  const urlParams = new URLSearchParams(window.location.search);
  const quoteIds = urlParams.get('id');
  const arrayvalue = urlParams.get('arrayValues');
  // console.log("url",arrayvalue);
  const [quoteData, setquoteData] = useState(null);
  const [state, setState] = useState(null);

  const [updatedData, setUpdatedData] = useState(null);

  const userId = ReactSession.get("upvcuserId");
  const userType = ReactSession.get("upvcuserType");
  const quoteId = ReactSession.get("upvcquoteId");
  const navigate = useNavigate();
  const indexPath = '/';
  const selectPath = '/select';

  useEffect(() => {
    if (userId === 0) {
      navigate(indexPath);
    }
  }, [userId, navigate]);


  const [measure, setMeasure] = useState('ft');
  const [mCalc, setMcalc] = useState(1);
  const [boxWidth, setBoxWidth] = useState(3);
  const [boxHeight, setBoxHeight] = useState(6);
  const [numFirstInnerBoxes, setNumFirstInnerBoxes] = useState(0);
  const [numFrames, setNumFrames] = useState(0);
  const [numSecondInnerBoxes, setNumSecondInnerBoxes] = useState(0);
  const [newHeights, setNewInnerBoxHeight] = useState(0);
  const [panelHeights, setpanelHeight] = useState(0);
  const [panelType, setpanelType] = useState(0);
  const [newWidths, setNewInnerBoxWidth] = useState([]);
  const [boxColors, setBoxColors] = useState([]);
  const [boxNames, setBoxNames] = useState([]);
  const [boxHandle, setBoxHandle] = useState([]);
  const [deviceWidth, setDeviceWidth] = useState(window.innerWidth);
  const [screen, setScreen] = useState(deviceWidth > 700 ? 4 : 8);
  const [boxPosition, setBoxPosition] = useState('Bottom');
  const [shutterCount, setshutterCount] = useState(0);
  const [fixedCount, setfixedCount] = useState(0);
  const [bottomFrame, setBottomFrame] = useState('yes');
  const [mesh, setMesh] = useState('no');
  const [grill, setGrill] = useState('no');
  const [data, setData] = useState([]);
  const [values, setValues] = useState([]);
  const [grandTotal, setGrandTotal] = useState(0);
  const [frameTotal, setframeTotal] = useState(0);
  const [frameArea, setframeArea] = useState(0);
  const [hardTotal, sethardTotal] = useState(0);
  const [marginTotal, setmarginTotal] = useState(0);
  const [prodTotal, setprodTotal] = useState(0);
  const [wastage, setWastage] = useState([]);
  const [fanTop, setfanTop] = useState('Top');
  const [fanLeft, setfanLeft] = useState('Left');
  const [divider, setDevider] = useState('Vertical');
  const [switchs, setSwitch] = useState('on');
  const [switchFixed, setFixed] = useState('on');
  const [fanType, setfanType] = useState(1);
  const [item, setItem] = useState('');
  const [qty, setQty] = useState(0);
  const [topFixedWidth, settopFixedWidth] = useState([]);
  const [topFixedNum, settopFixedNum] = useState(0);
  const [bottomFixedWidth, setbottomFixedWidth] = useState([]);
  const [bottomFixedNum, setbottomFixedNum] = useState(0);
  const [boxTop, setboxTop] = useState('off');
  const [boxBottom, setboxBottom] = useState('off');
  const [numTopBoxes, setnumTopBoxes] = useState(0);
  const [numBottomBoxes, setnumBottomBoxes] = useState(0);
  const [topnewHeights, setTopBoxHeight] = useState(0);
  const [bottomnewHeights, setBottomBoxHeight] = useState(0);
  // console.log("boxPosition",boxPosition);
  const [fan, setFan] = useState('on');



  const Empid = ReactSession.get("upvcempid");
  let users = '';
  if (userType === '3') {
    users = Empid;
  } else {
    users = userId;
  }
  const user = { "userId": users };




  const fetchData = async () => {
    try {
      const response = await axios.post('https://upvcapi.sninfoserv.com/all-quotes/', user);
      setquoteData(response.data);
      // console.log("String",response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };




  useEffect(() => {
    fetchData();
  }, []);

  const filteredQuotes = quoteData && quoteData.data.filter(quote => quote.quoteId === quoteIds);


  useEffect(() => {
    if (filteredQuotes) {
      setState(filteredQuotes[0].quoteDetails[arrayvalue].quoteData)


    }


  }
    , [filteredQuotes])
  //console.log(state)






  useEffect(() => {
    if (state) {
      setMeasure(state.measure)
      setBoxHeight(state.boxHeight);
      setBoxWidth(state.boxWidth);
      setNumFirstInnerBoxes(state.numFirstInnerBoxes);
      setNumFrames(state.numFrames);
      setNumSecondInnerBoxes(state.numSecondInnerBoxes);
      setNewInnerBoxHeight(state.newHeights);
      setNewInnerBoxWidth(state.newWidths);
      setBoxColors(state.boxColors);
      setBoxNames(state.boxNames);
      setBoxHandle(state.boxHandle);
      setDeviceWidth(state.deviceWidth);
      setScreen(state.screen);
      setBoxPosition(state.boxPosition);
      setMesh(state.mesh);
      setGrill(state.grill);
      setData(state.data);
      setValues(state.values);
      setGrandTotal(state.grandTotal);
      setframeTotal(state.frameTotal);
      sethardTotal(state.hardTotal);
      setmarginTotal(state.marginTotal);
      setprodTotal(state.prodTotal);
      setWastage(state.wastage);
      setfanTop(state.fanTop);
      setfanLeft(state.fanLeft);
      setDevider(state.divider);
      setSwitch(state.switchs);
      setFixed(state.switchFixed);
      setFan(state.fan);
      setfanType(state.fanType);
      setshutterCount(state.shutterCount);
      setfixedCount(state.fixedCount);
      setItem(state.item);
      setpanelHeight(state.panelHeight);
      setpanelType(state.panelType);
      setframeArea(state.frameArea);
      setframeTotal(state.frameTotal);
      setQty(state.qty);
      settopFixedWidth(state.topFixedWidth);
      setbottomFixedWidth(state.bottomFixedWidth);
      setTopBoxHeight(state.topnewHeights);
      setbottomFixedNum(state.bottomFixedNum);
      settopFixedNum(state.topFixedNum);
      setBottomBoxHeight(state.bottomnewHeights);
      setboxTop(state.boxTop);
      setboxBottom(state.boxBottom);
      setnumTopBoxes(state.numTopBoxes);
      setnumBottomBoxes(state.numBottomBoxes);
      setBottomFrame(state.bottomFrame);







    }

  }, [state])
  useEffect(() => {
    const updateDeviceWidth = () => {
      setDeviceWidth(window.innerWidth);
      setScreen(window.innerWidth > 700 ? 4 : 8);
    };

    window.addEventListener('resize', updateDeviceWidth);

    return () => {
      window.removeEventListener('resize', updateDeviceWidth);
    };
  }, []);





  const Frame = () => {
    if (item === 'Ventilator') {
      return (Number(boxHeight) + Number(boxWidth)) * 2;
    }
    else {
      if (measure === 'ft') {
        return 2 * (Number(boxWidth) + Number(boxHeight)).toFixed(2);
      } else {
        return 2 * (Number(boxWidth / 304.8) + Number(boxHeight / 304.8)).toFixed(2);
      }
    }
  };




  const Shutter = () => {
    if (measure === 'ft') {
      return (((((boxHeight - topnewHeights) * shutterCount) * 2) * numFrames) + ((boxWidth / numSecondInnerBoxes) * shutterCount) * 2).toFixed(2);
    } else {
      return ((((((boxHeight / 304.8) - (topnewHeights / 304.8)) * shutterCount) * 2) * numFrames) + (((boxWidth / 304.8) / numSecondInnerBoxes) * shutterCount) * 2).toFixed(2);
    }
  };

  const Panel = () => {
    if (measure === 'ft') {
      if (panelType === 1) {
        return ((boxHeight / (150 * 0.00328084)) * boxWidth).toFixed(2);
      } else {
        return 0;
      }
    } else {
      if (panelType === 1) {
        return (((boxHeight / 304.8) / (150 * 0.00328084)) * (boxWidth / 304.8)).toFixed(2);
      } else {
        return 0;
      }
    }
  };


  let fanLength;

  if (fanType === 1) {
    fanLength = 1;
  } else if (fanType === 2) {
    fanLength = 0.75;
  } else if (fanType === 3) {
    fanLength = 0.5833;
  } else {
    fanLength = 0;
  }


  const Mullion = () => {
    if (item === 'Ventilator') {
      if (boxHeight > 1 && boxWidth > 1) {
        if (fan === 'on') {
          if (divider === 'Vertical') {
            return Number(boxHeight) + fanLength;
          } else {
            return Number(boxWidth) + fanLength;
          }
        } else {
          return 0;
        }
      } else {
        if (fan === 'on') {
          if (divider === 'Vertical') {
            return fanLength;
          } else {
            return fanLength;
          }
        } else {
          return 0;
        }
      }
    }
    else {

      if (measure === 'ft') {
        return (((boxHeight - topnewHeights - bottomnewHeights) * (numSecondInnerBoxes - 1)) + (topnewHeights * boxWidth) + (topnewHeights * (topFixedNum - 1)) + (bottomnewHeights * boxWidth) + (bottomnewHeights * (bottomFixedNum - 1))).toFixed(2);
      } else {
        return ((((boxHeight / 304.8) - topnewHeights - bottomnewHeights) * (numSecondInnerBoxes - 1)) + (topnewHeights * (boxWidth / 304.8)) + (topnewHeights * (topFixedNum - 1)) + (bottomnewHeights * (boxWidth / 304.8)) + (bottomnewHeights * (bottomFixedNum - 1))).toFixed(2);
      }

    }

  };





  const LouverBlades = () => {
    if (Number(boxHeight) === 1 && Number(boxWidth) > 1) {
      if (switchFixed === 'on') {
        return 0;
      } else {
        if (fan === 'on') {
          return 0;
        } else {
          return (Number(boxHeight) * 4);
        }
      }
    } else if (Number(boxHeight) > 1 && Number(boxWidth) === 1) {
      if (switchFixed === 'on') {
        return 0;
      } else {
        if (fan === 'on') {
          return 0;
        } else {
          return ((Number(boxHeight)) * 4);
        }
      }
    } else {
      if (divider === 'Vertical' && switchs === 'on') {

        return (4 * Number(boxHeight));
      }
      if (divider === 'Vertical' && switchs === 'off') {
        if (fan === 'on') {
          return (4 * (Number(boxHeight) - 1));
        } else {
          return (4 * (Number(boxHeight)));
        }
      }
      if (divider === 'Horizontal' && switchs === 'on') {
        if (fan === 'on') {
          return (4 * (Number(boxHeight) - 1));
        } else {
          return (4 * (Number(boxHeight)));
        }
      }
      if (divider === 'Horizontal' && switchs === 'off') {
        if (fan === 'on') {
          return (4 * (Number(boxHeight) - 1));
        } else {
          return (4 * (Number(boxHeight)));
        }
      }
    }
  };


  const Glass = () => {
    if (Number(boxHeight) === 1 && Number(boxWidth) > 1) {
      if (switchFixed === 'on') {
        if (fan === 'on') {
          return ((Number(boxHeight)) * Number(boxWidth) - 1);
        } else {
          return ((Number(boxHeight)) * Number(boxWidth));
        }
      } else {
        return 0;
      }
    } else if (Number(boxHeight) > 1 && Number(boxWidth) === 1) {
      if (switchFixed === 'on') {
        if (fan === 'on') {
          return ((Number(boxHeight) - 1) * Number(boxWidth));
        } else {
          return ((Number(boxHeight)) * Number(boxWidth));
        }
      } else {
        return 0;
      }
    } else if (Number(boxHeight) === 1 && Number(boxWidth) === 1) {
      if (switchFixed === 'on') {
        if (fan === 'on') {
          return ((Number(boxHeight) - 1) * Number(boxWidth));
        } else {
          return ((Number(boxHeight)) * Number(boxWidth));
        }
      } else {
        return 0;
      }
    } else {
      if (divider === 'Vertical' && switchs === 'on') {
        return ((Number(boxHeight) - 1) * (Number(boxWidth) - 1)) + ((Number(boxHeight) * 4) * (95 * 0.00328084)) * (Number(boxWidth) - 1);
      }
      if (divider === 'Vertical' && switchs === 'off') {
        return ((Number(boxHeight)) * (Number(boxWidth) - 1)) + (((Number(boxHeight) - 1) * 4) * (95 * 0.00328084)) * (Number(boxWidth) - 1);
      }
      if (divider === 'Horizontal' && switchs === 'on') {
        return ((Number(boxHeight) - 1) * (Number(boxWidth))) + (((Number(boxHeight) - 1) * 4) * (95 * 0.00328084)) * (Number(boxWidth) - 1);
      }
      if (divider === 'Horizontal' && switchs === 'off') {
        return ((Number(boxHeight) - 1) * (Number(boxWidth) - 1)) + (((Number(boxHeight) - 1) * 4) * (95 * 0.00328084)) * (Number(boxWidth));
      }
    }
  };

  const louverBeeding = () => {
    if (Number(boxHeight) === 1 && Number(boxWidth) > 1) {
      if (switchFixed === 'on') {
        return 0;
      } else {
        if (fan === 'on') {
          return ((Number(boxHeight) + Number(boxWidth) - 1) * 2);
        } else {
          return (Number(boxWidth) * 4);
        }
      }
    } else if (Number(boxHeight) > 1 && Number(boxWidth) === 1) {
      if (switchFixed === 'on') {
        return 0;
      } else {
        if (fan === 'on') {
          return ((Number(boxHeight) - 1 + Number(boxWidth)) * 2);
        } else {
          return ((Number(boxHeight) + Number(boxWidth)) * 2);
        }
      }
    } else if (Number(boxHeight) === 1 && Number(boxWidth) === 1) {
      if (switchFixed === 'on') {
        return 0;
      } else {
        if (fan === 'on') {
          return ((Number(boxHeight) - 1 + Number(boxWidth)) * 2);
        } else {
          return ((Number(boxHeight) + Number(boxWidth)) * 2);
        }
      }
    } else {
      if (divider === 'Vertical' && switchs === 'on') {
        return (2 * (Number(boxHeight) - 1 + Number(boxWidth) - 1));
      }
      if (divider === 'Vertical' && switchs === 'off') {
        return (2 * (Number(boxHeight) + Number(boxWidth) - 1));
      }
      if (divider === 'Horizontal' && switchs === 'on') {
        return (2 * (Number(boxHeight) - 1 + Number(boxWidth)));
      }
      if (divider === 'Horizontal' && switchs === 'off') {
        return (2 * (Number(boxHeight) - 1 + Number(boxWidth) - 1));
      }
    }
  };

  const Beeding = () => {
    if (item === 'Ventilator') {
      return Frame();
    }
    else {

      if (measure === 'ft') {
        if (topnewHeights === 0) {
          return (((boxHeight * numSecondInnerBoxes * 2) + ((boxWidth * 2 / numFrames))) * numFrames).toFixed(2);
        } else {
          return (((topnewHeights * 2) + ((boxHeight - topnewHeights) * (numSecondInnerBoxes * 2)) + ((boxWidth * 4 / numFrames))) * numFrames).toFixed(2);
        }
      } else {
        if (topnewHeights === 0) {
          return ((((boxHeight / 304.8) * numSecondInnerBoxes * 2) + (((boxWidth / 304.8) * 2 / numFrames))) * numFrames).toFixed(2);
        } else {
          return ((((topnewHeights / 304.8) * 2) + (((boxHeight / 304.8) - (topnewHeights / 304.8)) * (numSecondInnerBoxes * 2)) + (((boxWidth / 304.8) * 4 / numFrames))) * numFrames).toFixed(2);
        }
      }

    }

  };

  const beedingGasket = () => {
    if (item === 'Ventilator') {
      return Frame() + (boxWidth * 2);
    } else {
      if (measure === 'ft') {
        if (topnewHeights === 0) {
          return ((((boxHeight * numSecondInnerBoxes * 2) + ((boxWidth * 2 / numFrames))) * numFrames) * 1).toFixed(2);
        } else {
          return ((((topnewHeights * 2) + ((boxHeight - topnewHeights) * (numSecondInnerBoxes * 2)) + ((boxWidth * 4 / numFrames))) * numFrames) * 1).toFixed(2);
        }
      } else {
        if (topnewHeights === 0) {
          return (((((boxHeight / 304.8) * numSecondInnerBoxes * 2) + (((boxWidth / 304.8) * 2 / numFrames))) * numFrames) * 1).toFixed(2);
        } else {
          return (((((topnewHeights / 304.8) * 2) + (((boxHeight / 304.8) - (topnewHeights / 304.8)) * (numSecondInnerBoxes * 2)) + (((boxWidth / 304.8) * 4 / numFrames))) * numFrames) * 1).toFixed(2);
        }
      }
    }

  };

  const shutterGasket = () => {
    if (measure === 'ft') {
      return ((((((boxHeight - topnewHeights) * shutterCount) * 2 * numFrames)) + ((boxWidth / numSecondInnerBoxes) * shutterCount) * 2) * 2).toFixed(2);
    } else {
      return (((((((boxHeight / 304.8) - (topnewHeights / 304.8)) * shutterCount) * 2 * numFrames)) + (((boxWidth / 304.8) / numSecondInnerBoxes) * shutterCount) * 2) * 2).toFixed(2);
    }
  };


  const frameGasket = () => {
    if (item === 'Ventilator') {
      return Frame();
    } else {
      if (measure === 'ft') {
        return ((boxHeight * 2) + (boxWidth * 2)).toFixed(2);
      } else {
        return (((boxHeight / 304.8) * 2) + ((boxWidth / 304.8) * 2)).toFixed(2);
      }
    }

  };

  const steelFrame = () => {
    if (item === 'Ventilator') {
      return Frame();
    } else {
      if (measure === 'ft') {
        return ((boxHeight * 2) + (boxWidth * 2)).toFixed(2);
      } else {
        return (((boxHeight / 304.8) * 2) + ((boxWidth / 304.8) * 2)).toFixed(2);
      }
    }
  };

  const steelMullion = () => {
    return Mullion();
  };

  const steelShutter = () => {
    if (measure === 'ft') {
      return (((((boxHeight - topnewHeights) * shutterCount) * 2) + ((boxWidth / numSecondInnerBoxes) * shutterCount) * 2) * numFrames).toFixed(2);
    } else {
      return ((((((boxHeight / 304.8) - (topnewHeights / 304.8)) * shutterCount) * 2) + (((boxWidth / 304.8) / numSecondInnerBoxes) * shutterCount) * 2) * numFrames).toFixed(2);
    }
  };

  const cuplerJoint = () => {
    if (measure === 'ft') {
      return ((boxHeight) * (numFrames - 1)).toFixed(2);
    } else {
      return ((boxHeight / 304.8) * (numFrames - 1)).toFixed(2);
    }
  };

  const meshFrame = () => {
    if (item === 'SlidingWindow') {
      if (measure === 'ft') {
        if (mesh === 'yes') {
          return (((((Number(boxHeight) - newHeights) * (shutterCount - 1)) * 2) + ((Number(boxWidth) / shutterCount) * (shutterCount - 1)) * 2) * numFrames).toFixed(2);
        } else {
          return 0;
        }
      } else {
        if (mesh === 'yes') {
          return ((((((Number(boxHeight) / 304.8) - (newHeights / 304.8)) * (shutterCount - 1)) * 2) + (((Number(boxWidth) / 304.8) / shutterCount) * (shutterCount - 1)) * 2) * numFrames).toFixed(2);
        } else {
          return 0;
        }
      }
    } else {
      if (measure === 'ft') {
        if (mesh === 'yes') {
          return (((((boxHeight - topnewHeights - bottomnewHeights) * shutterCount) * 2) + ((boxWidth / numSecondInnerBoxes) * shutterCount) * 2) * numFrames).toFixed(2);
        } else {
          return 0;
        }
      } else {
        if (mesh === 'yes') {
          return ((((((boxHeight / 304.8) - (topnewHeights / 304.8) - (bottomnewHeights / 304.8)) * shutterCount) * 2) + (((boxWidth / 304.8) / numSecondInnerBoxes) * shutterCount) * 2) * numFrames).toFixed(2);
        } else {
          return 0;
        }
      }
    }

  };

  const meshGasket = () => {
    if (item === 'SlidingWindow') {
      if (measure === 'ft') {
        if (mesh === 'yes') {
          return (((((Number(boxHeight) - newHeights) * (shutterCount - 1)) * 2) + ((Number(boxWidth) / shutterCount) * (shutterCount - 1)) * 2) * numFrames).toFixed(2);
        } else {
          return 0;
        }
      } else {
        if (mesh === 'yes') {
          return ((((((Number(boxHeight) / 304.8) - (newHeights / 304.8)) * (shutterCount - 1)) * 2) + (((Number(boxWidth) / 304.8) / shutterCount) * (shutterCount - 1)) * 2) * numFrames).toFixed(2);
        } else {
          return 0;
        }
      }
    } else {
      return meshFrame();
    }
  };


  const interLock = () => {
    return ((boxHeight) * (numSecondInnerBoxes - 1)) * 2;
  };
  const meshArea = () => {
    if (mesh === 'yes') {

      const indices = boxNames
        .map((name, index) => name === 'Shutter' ? index : -1)
        .filter(index => index !== -1);

      const widths = indices.map(index => newWidths[index]);
      const totalWidth = widths.reduce((sum, width) => sum + width, 0);
      const meashsArea = (boxHeight - topnewHeights - bottomnewHeights) * totalWidth;

      return meashsArea;

    } else {
      return 0;
    }
  };

  const gillArea = () => {
    if (grill === 'yes') {

      const indices = boxNames
        .map((name, index) => name === 'Shutter' ? index : -1)
        .filter(index => index !== -1);

      const widths = indices.map(index => Number(newWidths[index]));
      const totalWidth = Number(widths.reduce((sum, width) => sum + width, 0).toFixed(2)); // Adjust the number of decimal places as needed

      const grillsArea = (Number(boxHeight) - topnewHeights - bottomnewHeights) * Number(totalWidth);

      return grillsArea;

    } else {
      return 0;
    }
  };





  const fixedShutters = (fixedCount + numFirstInnerBoxes) * numFrames;


  const calculatedBoxWidth = measure === 'ft' ? boxWidth : (boxWidth / 304.8);
  const calculatedBoxHeight = measure === 'ft' ? boxHeight : (boxHeight / 304.8);
  // const calculatednewWidths = newWidths.map(width => 
  //   measure === 'ft' ? parseFloat(width) : parseFloat(width) / 304.8
  // );
  const calculatednewHeights = measure === 'ft' ? newHeights : (newHeights / 304.8);


  const boxes = Array.from({ length: (boxHeight - topnewHeights - bottomnewHeights) * 1.9 }).map((_, index) => (
    <div
      key={index}
      style={{
        position: 'relative',
        width: '100%',
        height: '0.26vw',
        border: '1px solid #333',
        background: '#fff',
        marginTop: `calc((${boxHeight * screen / mCalc + 'vw'} - 25px) / ${calculatedBoxHeight * 2.6}) `,
      }}
    ></div>
  ));



  // console.log(newWidths);
  //   console.log(numSecondInnerBoxes);
  return (
    <div>
      <Navbar />
      <Header />



      {item === 'CasementDoor' && (
        <div class="pc-container">
          <div class="pc-content">

            <div class="row">





              <div class="col-md-12 col-xxl-12 col-xl-12" >
                <div class="card" style={{ background: '#fff' }}>
                  <div class="card-body mb-5">

                    <div>

                      <div style={{ float: 'left', width: boxWidth * screen / mCalc + 'vw', height: boxHeight * screen / mCalc + 'vw', border: '1px solid #333' }}>



                        {[...Array(numFrames)].map((_, index) => (
                          <div style={{ float: 'left', margin: '5px', border: '1px solid #333', width: `calc(${Number(boxWidth) * screen / mCalc / numFrames + 'vw'} - 12px)`, height: bottomFrame === 'no' ? `calc(${Number(boxHeight) * screen / mCalc + 'vw'} - 6px)` : `calc(${Number(boxHeight) * screen / mCalc + 'vw'} - 12px)`, }}>


                            {boxTop === 'on' && (

                              [...Array(numTopBoxes)].map((_, index) => (
                                <div style={{ display: 'flex', width: `calc(${boxWidth * screen / mCalc / numFrames + 'vw'} - 23px)`, height: `calc(${(topnewHeights * screen / mCalc)}vw )`, margin: '5px' }}>
                                  {[...Array(topFixedNum)].map((_, index) => (
                                    <div style={{ flex: '1', background: 'lightgreen', width: `calc(${topFixedWidth[index] * screen / mCalc / numFrames + 'vw'} - 18px)`, height: `calc(${(topnewHeights * screen / mCalc)}vw )`, border: 'solid 1px #000', marginLeft: index === 0 ? '0px' : '5px', }}>

                                    </div>
                                  ))}
                                </div>
                              ))
                            )}


                            <div style={{ display: 'flex' }}>
                              {[...Array(numSecondInnerBoxes)].map((_, index) => (

                                <div key={index} style={{ flex: '1', height: `calc(${(boxHeight - topnewHeights - bottomnewHeights) * screen / mCalc}vw - ${bottomFrame === 'yes' ? '30px' : '25px'} - ${boxTop === 'on' ? '0px' : '-5px'})`, width: `calc(${newWidths[index] * screen / mCalc}vw - 5px - 19px)`, backgroundColor: panelType === 1 ? 'lightgray' : panelType === 2 ? 'lightyellow' : 'lightblue', marginTop: boxTop === 'on' ? '0px' : '5px', marginRight: index === numSecondInnerBoxes - 1 ? '5px' : '2.5px', marginLeft: index === 0 ? '5px' : '2.5px', border: '1px solid #333' }}>


                                  <div className="expanding-arrow-line" style={{
                                    position: 'absolute',
                                    float: 'left',
                                    width: `calc(${newWidths[index] * screen / mCalc}vw - 15px)`,
                                    bottom: '-10px',
                                    top: `calc(${boxHeight * screen / mCalc}vw - 25px)`,
                                  }}

                                  >
                                    <div className="arrow-left"></div>
                                    <div className="line" align="center">
                                      <div style={{ marginTop: '-7px', background: 'white', maxWidth: '60px', textAlign: 'center' }}><p>{typeof newWidths[index] === 'number' ? newWidths[index].toFixed(2) : newWidths[index]}</p></div>
                                    </div>
                                    <div className="arrow-right"></div>
                                  </div>



                                  {boxNames[index] === 'Shutter' && (boxHandle[index] === 'left' ? (
                                    <img
                                      src={left}
                                      style={{
                                        float: 'left',
                                        marginTop: `calc(${(((boxHeight - topnewHeights - bottomnewHeights) * screen / mCalc) / 2)}vw - 15px`,
                                        marginLeft: '2px',
                                        width: screen === 3 ? '13px' : '8px'
                                      }}

                                      alt="Left Handle"
                                    />
                                  ) : (
                                    <img
                                      src={right}
                                      style={{
                                        float: 'right',
                                        marginTop: `calc(${(((boxHeight - topnewHeights - bottomnewHeights) * screen / mCalc) / 2)}vw - 15px`,
                                        marginRight: '2px',
                                        width: screen === 3 ? '13px' : '8px'
                                      }}

                                      alt="Right Handle"
                                    />

                                  ))}




                                  {boxNames[index] === 'Shutter' && grill === 'yes' && boxes}

                                </div>

                              ))}

                            </div>


                            {numSecondInnerBoxes === 0 ? (
                              <div className="expanding-arrow-line" style={{ float: 'left', marginLeft: '-5px', width: ((Number(boxWidth) / numFrames) * screen / mCalc) + 'vw', top: (Number(boxHeight) - newHeights) * screen / mCalc + 'vw', }} >
                                <div className="arrow-left"></div>
                                <div className="line" align="center">
                                  <div style={{ marginTop: '-8px', background: 'white', maxWidth: '60px', textAlign: 'center' }}><p>{typeof (Number(boxWidth) / numFrames) === 'number' ? (Number(boxWidth) / numFrames).toFixed(2) : (Number(boxWidth) / numFrames)}</p></div>
                                </div>
                                <div className="arrow-right"></div>
                              </div>
                            ) : (
                              <div className="expanding-arrow-line" style={{ float: 'left', marginLeft: '-5px', width: ((Number(boxWidth) / numFrames) * screen / mCalc) + 'vw', bottom: '-10px', top: bottomFrame === 'yes' ? '50px' : '45px', }} >
                                <div className="arrow-left"></div>
                                <div className="line" align="center">
                                  <div style={{ marginTop: '-8px', background: 'white', maxWidth: '60px', textAlign: 'center' }}><p>{typeof (Number(boxWidth) / numFrames) === 'number' ? (Number(boxWidth) / numFrames).toFixed(2) : (Number(boxWidth) / numFrames)}</p></div>
                                </div>
                                <div className="arrow-right"></div>
                              </div>
                            )}

                          </div>
                        ))}
                      </div>

                      <div style={{ width: '45px', float: 'left', paddingLeft: '15px', textAlign: 'left' }}>
                      {boxTop === 'on' &&
                      <>
                        <div className="expanding-arrow-line" style={{ float: 'left', height: `calc(${topnewHeights * screen / mCalc}vw + 10px)`, top: '0', position: 'relative' }}>
                          <div className="arrow-up" style={{ borderBottom: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', top: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
                          <div className="line" style={{ width: '1px', height: '100%', marginLeft: '0px', position: 'absolute', top: '0' }}>
                            <div style={{ marginLeft: '-10px', marginTop: topnewHeights * (screen / mCalc / 2) + 'vw', background: 'white', maxWidth: '60px', textAlign: 'left' }}><p style={{ width: '50px' }}>{Number(topnewHeights).toFixed(2)}</p></div>
                          </div>
                          <div className="arrow-down" style={{ borderTop: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', bottom: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
                        </div>

                        <div className="expanding-arrow-line" style={{ float: 'left', height: `calc(${(boxHeight - topnewHeights - bottomnewHeights) * screen / mCalc}vw - ${boxTop === 'on' ? '30px' : '15px'})`, top: `calc(${topnewHeights * screen / mCalc}vw + ${boxTop === 'on' ? '15px' : '0px'})`, position: 'relative' }}>
                          <div className="arrow-up" style={{ borderBottom: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', top: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
                            <div className="line" style={{ width: '1px', height: '100%', marginLeft: '0px', position: 'absolute', top: '0' }}>
                            <div style={{ marginLeft: '-10px', marginTop: (boxHeight - topnewHeights - bottomnewHeights) * ((screen / mCalc / 2) - (screen / mCalc / 8)) + 'vw', background: 'white', maxWidth: '60px', textAlign: 'left' }}><p style={{ width: '50px' }}>{Number(boxHeight - topnewHeights - bottomnewHeights).toFixed(2) }</p></div>
                            </div>
                          <div className="arrow-down" style={{ borderTop: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', bottom: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
                        </div>
                      </>
                      }
                    
                      {boxBottom === 'on' &&
                      <>
                        <div className="expanding-arrow-line" style={{ float: 'left', height: `calc(${(bottomnewHeights) * screen / mCalc}vw + 5px)`, top: `calc(${(boxHeight - bottomnewHeights) * screen / mCalc}vw - ${boxTop === 'on' ? '10px' : '10px'})`, position: 'relative' }}>
                          <div className="arrow-up" style={{ borderBottom: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', top: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
                          <div className="line" style={{ width: '1px', height: '100%', marginLeft: '0px', position: 'absolute', top: '0' }}>
                            <div style={{ marginLeft: '-10px', marginTop: (bottomnewHeights) * ((screen / mCalc / 2) - (screen / mCalc / 8)) + 'vw', background: 'white', maxWidth: '60px', textAlign: 'left' }}><p style={{ width: '50px' }}>{Number(bottomnewHeights).toFixed(2)}</p></div>
                          </div>
                          <div className="arrow-down" style={{ borderTop: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', bottom: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
                        </div>

                        <div className="expanding-arrow-line" style={{ float: 'left', height: `calc(${(boxHeight - topnewHeights - bottomnewHeights) * screen / mCalc}vw - ${boxTop === 'on' ? '30px' : '15px'})`, top: `calc(${topnewHeights * screen / mCalc}vw + ${boxTop === 'on' ? '15px' : '0px'})`, position: 'relative' }}>
                          <div className="arrow-up" style={{ borderBottom: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', top: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
                            <div className="line" style={{ width: '1px', height: '100%', marginLeft: '0px', position: 'absolute', top: '0' }}>
                            <div style={{ marginLeft: '-10px', marginTop: (boxHeight - topnewHeights - bottomnewHeights) * ((screen / mCalc / 2) - (screen / mCalc / 8)) + 'vw', background: 'white', maxWidth: '60px', textAlign: 'left' }}><p style={{ width: '50px' }}>{Number(boxHeight - topnewHeights - bottomnewHeights).toFixed(2)}</p></div>
                            </div>
                          <div className="arrow-down" style={{ borderTop: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', bottom: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
                        </div>
                      </>
                      }

                    </div>

                    <div style={{ width: '30px', float: 'left', paddingLeft: boxBottom === 'on' || boxTop === 'on' ?'0px' : '15px', textAlign: 'left' }}>
                      <div className="expanding-arrow-line" style={{ float: 'left', height: `calc(${boxHeight * screen / mCalc}vw)`, top: '0', position: 'relative' }}>
                        <div className="arrow-up" style={{ borderBottom: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', top: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
                        <div className="line" style={{ width: '1px', height: '100%', marginLeft: '0px', position: 'absolute', top: '0' }}>
                          <div style={{ marginLeft: '-10px', marginTop: boxHeight * (screen / mCalc / 2) + 'vw', background: 'white', maxWidth: '60px', textAlign: 'left' }}><p style={{ width: '50px' }}>{Number(boxHeight).toFixed(2)}</p></div>
                        </div>
                        <div className="arrow-down" style={{ borderTop: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', bottom: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
                      </div>
                    </div>

                    </div>

                  </div>
                </div>


              </div>

              {numSecondInnerBoxes === 0 ? (<div></div>) : (
                <>
                  <div class="col-md-12 col-xxl-2 col-xl-2">
                    <div class="card">
                      <div class="card-body">
                        <div class="d-flex align-items-center">
                          <div class="flex-grow-1 ms-3">
                            <h6 class="mb-0">Area</h6>
                          </div>
                        </div>
                        <div class="bg-body p-3 mt-3 rounded">
                          <div class="mt-3 row align-items-center">
                            <div class="col-12">
                              <h6 class="mb-1">{frameArea * qty} sqft</h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-12 col-xxl-2 col-xl-2">
                    <div class="card">
                      <div class="card-body">
                        <div class="d-flex align-items-center">
                          <div class="flex-grow-1 ms-3">
                            <h6 class="mb-0">Shutters</h6>
                          </div>
                        </div>
                        <div class="bg-body p-3 mt-3 rounded">
                          <div class="mt-3 row align-items-center">
                            <div class="col-12">
                              <h6 class="mb-1">{shutterCount * numFrames}</h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-12 col-xxl-2 col-xl-2">
                    <div class="card">
                      <div class="card-body">
                        <div class="d-flex align-items-center">
                          <div class="flex-grow-1 ms-3">
                            <h6 class="mb-0">Fixed</h6>
                          </div>
                        </div>
                        <div class="bg-body p-3 mt-3 rounded">
                          <div class="mt-3 row align-items-center">
                            <div class="col-12">
                              <h6 class="mb-1">{fixedShutters}</h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-12 col-xxl-2 col-xl-2">
                    <div class="card">
                      <div class="card-body">
                        <div class="d-flex align-items-center">
                          <div class="flex-grow-1 ms-3">
                            <h6 class="mb-0">Mullions</h6>
                          </div>
                        </div>
                        <div class="bg-body p-3 mt-3 rounded">
                          <div class="mt-3 row align-items-center">
                            <div class="col-12">
                              <h6 class="mb-1">{newHeights === 0 ? ((numSecondInnerBoxes - 1) * numFrames) : ((numSecondInnerBoxes) * numFrames)}</h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-12 col-xxl-2 col-xl-2">
                    <div class="card">
                      <div class="card-body">
                        <div class="d-flex align-items-center">
                          <div class="flex-grow-1 ms-3">
                            <h6 class="mb-0">Total</h6>
                          </div>
                        </div>
                        <div class="bg-body p-3 mt-3 rounded">
                          <div class="mt-3 row align-items-center">
                            <div class="col-12">
                              <h6 class="mb-1">₹ {numeral(grandTotal * qty).format('0,0.00')}</h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-12 col-xxl-2 col-xl-2">
                    <div class="card">
                      <div class="card-body">
                        <div class="d-flex align-items-center">
                          <div class="flex-grow-1 ms-3">
                            <h6 class="mb-0">Rate/Sqft</h6>
                          </div>
                        </div>
                        <div class="bg-body p-3 mt-3 rounded">
                          <div class="mt-3 row align-items-center">
                            <div class="col-12">
                              <h6 class="mb-1">₹ {numeral(grandTotal / frameArea).format('0,0.00')}</h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>




                  <div class="col-md-12 col-xxl-12 col-xl-12">
                    <div class="card">
                      <div class="card-header">
                        <h5>Profile Cost</h5>
                      </div>
                      <div class="card-body">
                        <table id="left-right-fix" class="table stripe row-border order-column">
                          <thead>
                            <tr>
                              <th>Product Description</th>
                              <th width="10%">Req. Qty (Feet)</th>
                              <th width="10%">Kg/Feet</th>
                              <th width="10%">Req. Qty (Kg)</th>
                              <th width="10%">Rate/Kg</th>
                              <th width="10%">Amount</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Casement Frame</td>
                              <td>{numeral(Frame()).format('0,0.00')}</td>
                              <td>{numeral(values.frame.wpf).format('0,0.00')}</td>
                              <td>{numeral(values.frame.rw).format('0,0.00')}</td>
                              <td>{numeral(values.frame.rpf).format('0,0.00')}</td>
                              <td>{numeral(values.frame.amount).format('0,0.00')}</td>
                            </tr>
                            <tr>
                              <td>Casement Shutter</td>
                              <td>{numeral(Shutter()).format('0,0.00')}</td>
                              <td>{numeral(values.shutter.wpf).format('0,0.00')}</td>
                              <td>{numeral(values.shutter.rw).format('0,0.00')}</td>
                              <td>{numeral(values.shutter.rpf).format('0,0.00')}</td>
                              <td>{numeral(values.shutter.amount).format('0,0.00')}</td>
                            </tr>
                            <tr>
                              <td>Mullion</td>
                              <td>{numeral(Mullion()).format('0,0.00')}</td>
                              <td>{numeral(values.mullion.wpf).format('0,0.00')}</td>
                              <td>{numeral(values.mullion.rw).format('0,0.00')}</td>
                              <td>{numeral(values.mullion.rpf).format('0,0.00')}</td>
                              <td>{numeral(values.mullion.amount).format('0,0.00')}</td>
                            </tr>
                            <tr>
                              <td>Beeding</td>
                              <td>{numeral(Beeding()).format('0,0.00')}</td>
                              <td>{numeral(values.beeding.wpf).format('0,0.00')}</td>
                              <td>{numeral(values.beeding.rw).format('0,0.00')}</td>
                              <td>{numeral(values.beeding.rpf).format('0,0.00')}</td>
                              <td>{numeral(values.beeding.amount).format('0,0.00')}</td>
                            </tr>
                            <tr>
                              <td>Frame Gasket</td>
                              <td>{numeral(frameGasket()).format('0,0.00')}</td>
                              <td>{numeral(values.frameGasket.wpf).format('0,0.00')}</td>
                              <td>{numeral(values.frameGasket.rw).format('0,0.00')}</td>
                              <td>{numeral(values.frameGasket.rpf).format('0,0.00')}</td>
                              <td>{numeral(values.frameGasket.amount).format('0,0.00')}</td>
                            </tr>
                            <tr>
                              <td>Shutter Gasket</td>
                              <td>{numeral(shutterGasket()).format('0,0.00')}</td>
                              <td>{numeral(values.shutterGasket.wpf).format('0,0.00')}</td>
                              <td>{numeral(values.shutterGasket.rw).format('0,0.00')}</td>
                              <td>{numeral(values.shutterGasket.rpf).format('0,0.00')}</td>
                              <td>{numeral(values.shutterGasket.amount).format('0,0.00')}</td>
                            </tr>
                            <tr>
                              <td>Beeding Gasket</td>
                              <td>{numeral(beedingGasket()).format('0,0.00')}</td>
                              <td>{numeral(values.beedingGasket.wpf).format('0,0.00')}</td>
                              <td>{numeral(values.beedingGasket.rw).format('0,0.00')}</td>
                              <td>{numeral(values.beedingGasket.rpf).format('0,0.00')}</td>
                              <td>{numeral(values.beedingGasket.amount).format('0,0.00')}</td>
                            </tr>

                            {panelType === 1 && (
                              <tr>
                                <td>Panel</td>
                                <td>{numeral(Panel()).format('0,0.00')}</td>
                                <td>{numeral(values.panel.wpf).format('0,0.00')}</td>
                                <td>{numeral(values.panel.rw).format('0,0.00')}</td>
                                <td>{numeral(values.panel.rpf).format('0,0.00')}</td>
                                <td>{numeral(values.panel.amount).format('0,0.00')}</td>
                              </tr>
                            )}


                            <tr>
                              <td colspan="5">Total</td>
                              <td>{numeral(frameTotal).format('0,0.00')}</td>
                            </tr>
                            <tr>
                              <th colspan="5">Wastage ( {data.wastage}% )</th>
                              <th>{numeral(frameTotal + wastage).format('0,0.00')}</th>
                            </tr>
                            <tr>
                              <th colspan="5">Profile Cost/Sqft</th>
                              <th>{numeral((frameTotal + wastage) / frameArea).format('0,0.00')}</th>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <hr />
                      <div class="card-header">
                        <h5>Hardware Cost</h5>
                      </div>
                      <div class="card-body">
                        <table id="left-right-fix" class="table stripe row-border order-column">
                          <thead>
                            <tr>
                              <th>Product Description</th>
                              <th width="10%">Req. Qty </th>
                              <th width="10%">Price</th>
                              <th width="10%">Amount</th>
                            </tr>
                          </thead>
                          <tbody>

                            <tr>
                              <td>Steel Frame</td>
                              <td>{numeral(values.steelFrame.qty).format('0,0.00')}</td>
                              <td>{numeral(values.steelFrame.price).format('0,0.00')}</td>
                              <td>{numeral(values.steelFrame.amount).format('0,0.00')}</td>
                            </tr>
                            <tr>
                              <td>Steel Mullion</td>
                              <td>{numeral(values.steelMullion.qty).format('0,0.00')}</td>
                              <td>{numeral(values.steelMullion.price).format('0,0.00')}</td>
                              <td>{numeral(values.steelMullion.amount).format('0,0.00')}</td>
                            </tr>
                            <tr>
                              <td>Steel Shutter</td>
                              <td>{numeral(values.steelShutter.qty).format('0,0.00')}</td>
                              <td>{numeral(values.steelShutter.price).format('0,0.00')}</td>
                              <td>{numeral(values.steelShutter.amount).format('0,0.00')}</td>
                            </tr>
                            <tr>
                              <td>Multipoint Lock</td>
                              <td>{numeral(values.mpl.qty * qty).format('0,0.00')}</td>
                              <td>{numeral(values.mpl.price * qty).format('0,0.00')}</td>
                              <td>{numeral(values.mpl.amount * qty).format('0,0.00')}</td>
                            </tr>
                            <tr>
                              <td>Friction Stay</td>
                              <td>{numeral(values.frictionstay.qty * qty).format('0,0.00')}</td>
                              <td>{numeral(values.frictionstay.price * qty).format('0,0.00')}</td>
                              <td>{numeral(values.frictionstay.amount * qty).format('0,0.00')}</td>
                            </tr>
                            <tr>
                              <td>Door Stopper</td>
                              <td>{numeral(values.doorstopper.qty * qty).format('0,0.00')}</td>
                              <td>{numeral(values.doorstopper.price * qty).format('0,0.00')}</td>
                              <td>{numeral(values.doorstopper.amount * qty).format('0,0.00')}</td>
                            </tr>
                            <tr>
                              <td>Casement Door Set</td>
                              <td>{numeral(values.casementdoorset.qty * qty).format('0,0.00')}</td>
                              <td>{numeral(values.casementdoorset.price * qty).format('0,0.00')}</td>
                              <td>{numeral(values.casementdoorset.amount * qty).format('0,0.00')}</td>
                            </tr>
                            <tr>
                              <td>Espag with SS Receiver</td>
                              <td>{numeral(values.espag.qty * qty).format('0,0.00')}</td>
                              <td>{numeral(values.espag.price * qty).format('0,0.00')}</td>
                              <td>{numeral(values.espag.amount * qty).format('0,0.00')}</td>
                            </tr>

                            <tr>
                              <td>Espag with MUlti Lock</td>
                              <td>{numeral(values.espagm.qty * qty).format('0,0.00')}</td>
                              <td>{numeral(values.espagm.price * qty).format('0,0.00')}</td>
                              <td>{numeral(values.espagm.amount * qty).format('0,0.00')}</td>
                            </tr>
                            <tr>
                              <td>3D Hinges</td>
                              <td>{numeral(values.hings.qty).format('0,0.00')}</td>
                              <td>{numeral(values.hings.price).format('0,0.00')}</td>
                              <td>{numeral(values.hings.amount).format('0,0.00')}</td>
                            </tr>
                            {grill === 'yes' && (
                              <tr>
                                <td>Grill</td>
                                <td>{numeral(values.grill.qty * qty).format('0,0.00')}</td>
                                <td>{numeral(values.grill.price * qty).format('0,0.00')}</td>
                                <td>{numeral(values.grill.amount * qty).format('0,0.00')}</td>
                              </tr>
                            )}
                            <tr>
                              <td>Screws/Misc</td>
                              <td>{numeral(values.screws.qty).format('0,0.00')}</td>
                              <td>{numeral(values.screws.price).format('0,0.00')}</td>
                              <td>{numeral(values.screws.amount).format('0,0.00')}</td>
                            </tr>

                            <tr>
                              <th colspan="3">Total</th>
                              <th>{numeral(hardTotal).format('0,0.00')}</th>
                            </tr>
                            <tr>
                              <th colspan="3">Hardware Cost/Sqft</th>
                              <th>{numeral(hardTotal / frameArea).format('0,0.00')}</th>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      {userType != '3' && (<>
                        <hr />

                        <div class="card-header ">
                          <h5>Margin Cost</h5>
                        </div>
                        <div class="card-body">
                          <table id="left-right-fix" class="table stripe row-border order-column">
                            <thead>
                              <tr>
                                <th>Product Description</th>
                                <th width="10%">Req. Qty </th>
                                <th width="10%">Price</th>
                                <th width="10%">Amount</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>Margin</td>
                                <td>{numeral(values.margin.qty).format('0,0.00')}</td>
                                <td>{numeral(values.margin.price).format('0,0.00')}</td>
                                <td>{numeral(values.margin.amount).format('0,0.00')}</td>
                              </tr>
                              <tr>
                                <th colspan="3">Total</th>
                                <th>{numeral(marginTotal).format('0,0.00')}</th>
                              </tr>
                              <tr>
                                <th colspan="3">Margin Cost/Sqft</th>
                                <th>{numeral(marginTotal / frameArea).format('0,0.00')}</th>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <hr />

                        <div class="card-header">
                          <h5>Production Cost</h5>
                        </div>
                        <div class="card-body">
                          <table id="left-right-fix" class="table stripe row-border order-column">
                            <thead>
                              <tr>
                                <th>Product Description</th>
                                <th width="10%">Req. Qty </th>
                                <th width="10%">Price</th>
                                <th width="10%">Amount</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>Markting OH</td>
                                <td>{numeral(values.marketing.qty).format('0,0.00')}</td>
                                <td>{numeral(values.marketing.price).format('0,0.00')}</td>
                                <td>{numeral(values.marketing.amount).format('0,0.00')}</td>
                              </tr>
                              <tr>
                                <td>Transport</td>
                                <td>{numeral(values.transport.qty).format('0,0.00')}</td>
                                <td>{numeral(values.transport.price).format('0,0.00')}</td>
                                <td>{numeral(values.transport.amount).format('0,0.00')}</td>
                              </tr>
                              <tr>
                                <td>Labour (Factory)</td>
                                <td>{numeral(values.labourF.qty).format('0,0.00')}</td>
                                <td>{numeral(values.labourF.price).format('0,0.00')}</td>
                                <td>{numeral(values.labourF.amount).format('0,0.00')}</td>
                              </tr>
                              <tr>
                                <td>Labour (Site)</td>
                                <td>{numeral(values.labourS.qty).format('0,0.00')}</td>
                                <td>{numeral(values.labourS.price).format('0,0.00')}</td>
                                <td>{numeral(values.labourS.amount).format('0,0.00')}</td>
                              </tr>
                              {panelType === 2 && (
                                <tr>
                                  <td>Board</td>
                                  <td>{numeral(values.board.qty).format('0,0.00')}</td>
                                  <td>{numeral(values.board.price).format('0,0.00')}</td>
                                  <td>{numeral(values.board.amount).format('0,0.00')}</td>
                                </tr>
                              )}
                              {panelType === 3 && (
                                <tr>
                                  <td>Glass</td>
                                  <td>{numeral(values.glass.qty).format('0,0.00')}</td>
                                  <td>{numeral(values.glass.price).format('0,0.00')}</td>
                                  <td>{numeral(values.glass.amount).format('0,0.00')}</td>
                                </tr>
                              )}
                              <tr>
                                <th colspan="3">Total</th>
                                <th>{numeral(prodTotal).format('0,0.00')}</th>
                              </tr>
                              <tr>
                                <th colspan="3">Production Cost/Sqft</th>
                                <th>{numeral(prodTotal / frameArea).format('0,0.00')}</th>
                              </tr>

                            </tbody>
                          </table>
                        </div>
                      </>
                      )}


                    </div>
                  </div>





                </>
              )}


            </div>
          </div>
        </div>



      )}

      {item === 'CasementWindow' && (
        <div class="pc-container">
          <div class="pc-content">

            <div class="row">





              <div class="col-md-12 col-xxl-12 col-xl-12">
                <div class="card" style={{ background: '#fff' }}>
                  <div class="card-body mb-5">

                    <div>

                      <div style={{ float: 'left', width: boxWidth * screen / mCalc + 'vw', height: boxHeight * screen / mCalc + 'vw', border: '1px solid #333' }}>



                        {[...Array(numFrames)].map((_, index) => (
                          <div style={{ float: 'left', margin: '5px', border: '1px solid #333', width: `calc(${boxWidth * screen / mCalc / numFrames + 'vw'} - 12px)`, height: `calc(${boxHeight * screen / mCalc + 'vw'} - 12px)` }}>


                            {boxTop === 'on' && (

                              [...Array(numTopBoxes)].map((_, index) => (
                                <div style={{ display: 'flex', width: `calc(${boxWidth * screen / mCalc / numFrames + 'vw'} - 23px)`, height: `calc(${(topnewHeights * screen / mCalc)}vw )`, margin: '5px' }}>
                                  {[...Array(topFixedNum)].map((_, index) => (
                                    <div style={{ flex: '1', background: 'lightgreen', width: `calc(${topFixedWidth[index] * screen / mCalc / numFrames + 'vw'} - 18px)`, height: `calc(${(topnewHeights * screen / mCalc)}vw )`, border: 'solid 1px #000', marginLeft: index === 0 ? '0px' : '5px', }}>

                                    </div>
                                  ))}
                                </div>
                              ))
                            )}

                            <div style={{ display: 'flex' }}>
                              {[...Array(numSecondInnerBoxes)].map((_, index) => (

                                <div key={index} style={{ flex: '1', height: `calc(${(boxHeight - topnewHeights - bottomnewHeights) * screen / mCalc}vw - ${boxTop === 'on' ? '30px' : '25px'})`, width: `calc(${newWidths[index] * screen / mCalc}vw - 5px - 19px)`, backgroundColor: boxColors[index] || 'lightblue', marginTop: boxTop === 'on' ? '0px' : '5px', marginRight: index === numSecondInnerBoxes - 1 ? '5px' : '2.5px', marginLeft: index === 0 ? '5px' : '2.5px', border: '1px solid #333' }}>


                                  <div className="expanding-arrow-line" style={{
                                    position: 'absolute',
                                    float: 'left',
                                    width: `calc(${newWidths[index] * screen / mCalc}vw - 15px)`,
                                    bottom: '-10px',
                                    top: `calc(${boxHeight * screen / mCalc}vw - 25px)`,
                                  }}

                                  >
                                    <div className="arrow-left"></div>
                                    <div className="line" align="center">
                                      <div style={{ marginTop: '-7px', background: 'white', maxWidth: '60px', textAlign: 'center' }}><p>{typeof newWidths[index] === 'number' ? newWidths[index].toFixed(2) : newWidths[index]}</p></div>
                                    </div>
                                    <div className="arrow-right"></div>
                                  </div>



                                  {boxNames[index] === 'Shutter' && (boxHandle[index] === 'left' ? (
                                    <img
                                      src={left}
                                      style={{
                                        float: 'left',
                                        marginTop: `calc(${(((boxHeight - topnewHeights - bottomnewHeights) * screen / mCalc) / 2)}vw - 15px`,
                                        marginLeft: '2px',
                                        width: screen === 3 ? '13px' : '8px'
                                      }}

                                      alt="Left Handle"
                                    />
                                  ) : (
                                    <img
                                      src={right}
                                      style={{
                                        float: 'right',
                                        marginTop: `calc(${(((boxHeight - topnewHeights - bottomnewHeights) * screen / mCalc) / 2)}vw - 15px`,
                                        marginRight: '2px',
                                        width: screen === 3 ? '13px' : '8px'
                                      }}

                                      alt="Right Handle"
                                    />

                                  ))}

                                  {mesh === 'yes' && boxNames[index] === 'Shutter' && boxHandle[index] === 'left' && (
                                    <img src={meshimg} style={{ width: screen === 3 ? '40px' : '20px', float: 'left', marginLeft: screen === 3 ? boxHandle[index] === 'left' ? '-15px' : '0px' : boxHandle[index] === 'left' ? '-10px' : 'px', marginTop: screen === 3 ? `calc(${((boxHeight - topnewHeights - bottomnewHeights) * screen / mCalc)}vw - ${boxTop === 'on' ? '72px' : '67px'} )` : `calc(${((boxHeight - topnewHeights - bottomnewHeights) * screen / mCalc)}vw - 45px)` }} />
                                  )}

                                  {mesh === 'yes' && boxNames[index] === 'Shutter' && boxHandle[index] === 'right' && (
                                    <img src={meshimg} style={{ width: screen === 3 ? '40px' : '20px', float: 'left', marginLeft: screen === 3 ? boxHandle[index] === 'right' ? '-1px' : '0px' : boxHandle[index] === 'right' ? '0px' : 'px', marginTop: screen === 3 ? `calc(${((boxHeight - topnewHeights - bottomnewHeights) * screen / mCalc)}vw - ${boxTop === 'on' ? '72px' : '67px'} )` : `calc(${((boxHeight - topnewHeights - bottomnewHeights) * screen / mCalc)}vw - 45px)` }} />
                                  )}

                                  {boxNames[index] === 'Shutter' && grill === 'yes' && boxes}

                                </div>

                              ))}

                            </div>

                            {boxBottom === 'on' && (
                              [...Array(numBottomBoxes)].map((_, index) => (
                                <div style={{ display: 'flex', width: `calc(${boxWidth * screen / mCalc / numFrames + 'vw'} - 23px)`, height: `calc(${(bottomnewHeights * screen / mCalc) + 'vw'} -10px)`, margin: '5px' }}>
                                  {[...Array(bottomFixedNum)].map((_, index) => (
                                    <div style={{ flex: '1', background: 'lightgreen', width: `calc(${bottomFixedWidth[index] * screen / mCalc / numFrames + 'vw'} - 18px)`, height: `calc(${(bottomnewHeights * screen / mCalc) + 'vw'} - 5px)`, border: 'solid 1px #000', marginLeft: index === 0 ? '0px' : '5px', }}>

                                    </div>
                                  ))}
                                </div>
                              ))
                            )}

                            {numSecondInnerBoxes === 0 ? (
                              <div className="expanding-arrow-line" style={{ float: 'left', marginLeft: '-5px', width: ((boxWidth / numFrames) * screen / mCalc) + 'vw', top: (boxHeight - topnewHeights) * screen / mCalc + 'vw', }} >
                                <div className="arrow-left"></div>
                                <div className="line" align="center">
                                  <div style={{ marginTop: '-8px', background: 'white', maxWidth: '60px', textAlign: 'center' }}><p>{typeof (boxWidth / numFrames) === 'number' ? (boxWidth / numFrames).toFixed(2) : (boxWidth / numFrames)}</p></div>
                                </div>
                                <div className="arrow-right"></div>
                              </div>
                            ) : (
                              <div className="expanding-arrow-line" style={{ float: 'left', marginLeft: '-5px', width: ((boxWidth / numFrames) * screen / mCalc) + 'vw', bottom: '-10px', top: '50px', }} >
                                <div className="arrow-left"></div>
                                <div className="line" align="center">
                                  <div style={{ marginTop: '-8px', background: 'white', maxWidth: '60px', textAlign: 'center' }}><p>{typeof (boxWidth / numFrames) === 'number' ? (boxWidth / numFrames).toFixed(2) : (boxWidth / numFrames)}</p></div>
                                </div>
                                <div className="arrow-right"></div>
                              </div>
                            )}

                          </div>
                        ))}
                      </div>


                      <div style={{ width: '45px', float: 'left', paddingLeft: '15px', textAlign: 'left' }}>
                      {boxTop === 'on' &&
                      <>
                        <div className="expanding-arrow-line" style={{ float: 'left', height: `calc(${topnewHeights * screen / mCalc}vw + 10px)`, top: '0', position: 'relative' }}>
                          <div className="arrow-up" style={{ borderBottom: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', top: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
                          <div className="line" style={{ width: '1px', height: '100%', marginLeft: '0px', position: 'absolute', top: '0' }}>
                            <div style={{ marginLeft: '-10px', marginTop: topnewHeights * (screen / mCalc / 2) + 'vw', background: 'white', maxWidth: '60px', textAlign: 'left' }}><p style={{ width: '50px' }}>{typeof topnewHeights === 'number' ? topnewHeights.toFixed(2) : topnewHeights}</p></div>
                          </div>
                          <div className="arrow-down" style={{ borderTop: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', bottom: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
                        </div>
                      
                      <div className="expanding-arrow-line" style={{ float: 'left', height: `calc(${(boxHeight - topnewHeights - bottomnewHeights) * screen / mCalc}vw - ${boxTop === 'on' ? '30px' : '15px'})`, top: `calc(${topnewHeights * screen / mCalc}vw + ${boxTop === 'on' ? '15px' : '0px'})`, position: 'relative' }}>
                        <div className="arrow-up" style={{ borderBottom: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', top: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
                        <div className="line" style={{ width: '1px', height: '100%', marginLeft: '0px', position: 'absolute', top: '0' }}>
                          <div style={{ marginLeft: '-10px', marginTop: (boxHeight - topnewHeights - bottomnewHeights) * ((screen / mCalc / 2) - (screen / mCalc / 8)) + 'vw', background: 'white', maxWidth: '60px', textAlign: 'left' }}><p style={{ width: '50px' }}>{ Number(boxHeight - topnewHeights - bottomnewHeights).toFixed(2)}</p></div>
                        </div>
                        <div className="arrow-down" style={{ borderTop: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', bottom: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
                      </div>
                      </>
                     }
                      {boxBottom === 'on' &&
                      <>
                        <div className="expanding-arrow-line" style={{ float: 'left', height: `calc(${(boxHeight - topnewHeights - bottomnewHeights) * screen / mCalc}vw - ${boxTop === 'on' ? '30px' : '15px'})`, top: `calc(${topnewHeights * screen / mCalc}vw + ${boxTop === 'on' ? '15px' : '0px'})`, position: 'relative' }}>
                        <div className="arrow-up" style={{ borderBottom: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', top: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
                        <div className="line" style={{ width: '1px', height: '100%', marginLeft: '0px', position: 'absolute', top: '0' }}>
                          <div style={{ marginLeft: '-10px', marginTop: (boxHeight - topnewHeights - bottomnewHeights) * ((screen / mCalc / 2) - (screen / mCalc / 8)) + 'vw', background: 'white', maxWidth: '60px', textAlign: 'left' }}><p style={{ width: '50px' }}>{ Number(boxHeight - topnewHeights - bottomnewHeights).toFixed(2)}</p></div>
                        </div>
                        <div className="arrow-down" style={{ borderTop: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', bottom: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
                      </div>
                        <div className="expanding-arrow-line" style={{ float: 'left', height: `calc(${(bottomnewHeights) * screen / mCalc}vw + 5px)`, top: `calc(${(boxHeight - bottomnewHeights) * screen / mCalc}vw - ${boxTop === 'on' ? '10px' : '10px'})`, position: 'relative' }}>
                          <div className="arrow-up" style={{ borderBottom: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', top: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
                          <div className="line" style={{ width: '1px', height: '100%', marginLeft: '0px', position: 'absolute', top: '0' }}>
                            <div style={{ marginLeft: '-10px', marginTop: (bottomnewHeights) * ((screen / mCalc / 2) - (screen / mCalc / 8)) + 'vw', background: 'white', maxWidth: '60px', textAlign: 'left' }}><p style={{ width: '50px' }}>{ Number(bottomnewHeights).toFixed(2)}</p></div>
                          </div>
                          <div className="arrow-down" style={{ borderTop: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', bottom: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
                        </div>
                        </>
                      }

                    </div>

                    <div style={{ width: '30px', float: 'left', paddingLeft: boxBottom === 'on' || boxTop === 'on' ?'0px' : '15px', textAlign: 'left' }}>
                      <div className="expanding-arrow-line" style={{ float: 'left', height: `calc(${boxHeight * screen / mCalc}vw)`, top: '0', position: 'relative' }}>
                        <div className="arrow-up" style={{ borderBottom: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', top: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
                        <div className="line" style={{ width: '1px', height: '100%', marginLeft: '0px', position: 'absolute', top: '0' }}>
                          <div style={{ marginLeft: '-10px', marginTop: boxHeight * (screen / mCalc / 2) + 'vw', background: 'white', maxWidth: '60px', textAlign: 'left' }}><p style={{ width: '50px' }}>{ Number(boxHeight).toFixed(2)}</p></div>
                        </div>
                        <div className="arrow-down" style={{ borderTop: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', bottom: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
                      </div>
                    </div>


                    </div>

                  </div>
                </div>




              </div>


              {numSecondInnerBoxes === 0 ? (<div></div>) : (
                <>
                  <div class="col-md-12 col-xxl-2 col-xl-2">
                    <div class="card">
                      <div class="card-body">
                        <div class="d-flex align-items-center">
                          <div class="flex-grow-1 ms-3">
                            <h6 class="mb-0">Area</h6>
                          </div>
                        </div>
                        <div class="bg-body p-3 mt-3 rounded">
                          <div class="mt-3 row align-items-center">
                            <div class="col-12">
                              <h6 class="mb-1">{frameArea * qty} sqft</h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-12 col-xxl-2 col-xl-2">
                    <div class="card">
                      <div class="card-body">
                        <div class="d-flex align-items-center">
                          <div class="flex-grow-1 ms-3">
                            <h6 class="mb-0">Shutters</h6>
                          </div>
                        </div>
                        <div class="bg-body p-3 mt-3 rounded">
                          <div class="mt-3 row align-items-center">
                            <div class="col-12">
                              <h6 class="mb-1">{shutterCount * numFrames}</h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-12 col-xxl-2 col-xl-2">
                    <div class="card">
                      <div class="card-body">
                        <div class="d-flex align-items-center">
                          <div class="flex-grow-1 ms-3">
                            <h6 class="mb-0">Fixed</h6>
                          </div>
                        </div>
                        <div class="bg-body p-3 mt-3 rounded">
                          <div class="mt-3 row align-items-center">
                            <div class="col-12">
                              <h6 class="mb-1">{fixedShutters}</h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-12 col-xxl-2 col-xl-2">
                    <div class="card">
                      <div class="card-body">
                        <div class="d-flex align-items-center">
                          <div class="flex-grow-1 ms-3">
                            <h6 class="mb-0">Mullions</h6>
                          </div>
                        </div>
                        <div class="bg-body p-3 mt-3 rounded">
                          <div class="mt-3 row align-items-center">
                            <div class="col-12">
                              <h6 class="mb-1">{newHeights === 0 ? ((numSecondInnerBoxes - 1) * numFrames) : ((numSecondInnerBoxes) * numFrames)}</h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-12 col-xxl-2 col-xl-2">
                    <div class="card">
                      <div class="card-body">
                        <div class="d-flex align-items-center">
                          <div class="flex-grow-1 ms-3">
                            <h6 class="mb-0">Total</h6>
                          </div>
                        </div>
                        <div class="bg-body p-3 mt-3 rounded">
                          <div class="mt-3 row align-items-center">
                            <div class="col-12">
                              <h6 class="mb-1">₹ {numeral(grandTotal * qty).format('0,0.00')}</h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-12 col-xxl-2 col-xl-2">
                    <div class="card">
                      <div class="card-body">
                        <div class="d-flex align-items-center">
                          <div class="flex-grow-1 ms-3">
                            <h6 class="mb-0">Rate/Sqft</h6>
                          </div>
                        </div>
                        <div class="bg-body p-3 mt-3 rounded">
                          <div class="mt-3 row align-items-center">
                            <div class="col-12">
                              <h6 class="mb-1">₹ {numeral(grandTotal / frameArea).format('0,0.00')}</h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>




                  <div class="col-md-12 col-xxl-12 col-xl-12">
                    <div class="card">
                      <div class="card-header">
                        <h5>Profile Cost</h5>
                      </div>
                      <div class="card-body">
                        <table id="left-right-fix" class="table stripe row-border order-column">
                          <thead>
                            <tr>
                              <th>Product Description</th>
                              <th width="10%">Req. Qty (Feet)</th>
                              <th width="10%">Kg/Feet</th>
                              <th width="10%">Req. Qty (Kg)</th>
                              <th width="10%">Rate/Kg</th>
                              <th width="10%">Amount</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Casement Outer Frame</td>
                              <td>{numeral(Frame()).format('0,0.00')}</td>
                              <td>{numeral(values.frame.wpf).format('0,0.00')}</td>
                              <td>{numeral(values.frame.rw).format('0,0.00')}</td>
                              <td>{numeral(values.frame.rpf).format('0,0.00')}</td>
                              <td>{numeral(values.frame.amount).format('0,0.00')}</td>
                            </tr>
                            <tr>
                              <td>Casement Shutter</td>
                              <td>{numeral(Shutter()).format('0,0.00')}</td>
                              <td>{numeral(values.shutter.wpf).format('0,0.00')}</td>
                              <td>{numeral(values.shutter.rw).format('0,0.00')}</td>
                              <td>{numeral(values.shutter.rpf).format('0,0.00')}</td>
                              <td>{numeral(values.shutter.amount).format('0,0.00')}</td>
                            </tr>

                            {mesh === 'yes' && (

                              <tr>
                                <td>Mesh Frame</td>
                                <td>{numeral(meshFrame()).format('0,0.00')}</td>
                                <td>{numeral(values.meshFrame.wpf).format('0,0.00')}</td>
                                <td>{numeral(values.meshFrame.rw).format('0,0.00')}</td>
                                <td>{numeral(values.meshFrame.rpf).format('0,0.00')}</td>
                                <td>{numeral(values.meshFrame.amount).format('0,0.00')}</td>
                              </tr>

                            )}

                            <tr>
                              <td>Beeding</td>
                              <td>{numeral(Beeding()).format('0,0.00')}</td>
                              <td>{numeral(values.beeding.wpf).format('0,0.00')}</td>
                              <td>{numeral(values.beeding.rw).format('0,0.00')}</td>
                              <td>{numeral(values.beeding.rpf).format('0,0.00')}</td>
                              <td>{numeral(values.beeding.amount).format('0,0.00')}</td>
                            </tr>
                            <tr>
                              <td>Mullion</td>
                              <td>{numeral(Mullion()).format('0,0.00')}</td>
                              <td>{numeral(values.mullion.wpf).format('0,0.00')}</td>
                              <td>{numeral(values.mullion.rw).format('0,0.00')}</td>
                              <td>{numeral(values.mullion.rpf).format('0,0.00')}</td>
                              <td>{numeral(values.mullion.amount).format('0,0.00')}</td>
                            </tr>
                            <tr>
                              <td>Frame Gasket</td>
                              <td>{numeral(frameGasket()).format('0,0.00')}</td>
                              <td>{numeral(values.frameGasket.wpf).format('0,0.00')}</td>
                              <td>{numeral(values.frameGasket.rw).format('0,0.00')}</td>
                              <td>{numeral(values.frameGasket.rpf).format('0,0.00')}</td>
                              <td>{numeral(values.frameGasket.amount).format('0,0.00')}</td>
                            </tr>
                            <tr>
                              <td>Shutter Gasket</td>
                              <td>{numeral(shutterGasket()).format('0,0.00')}</td>
                              <td>{numeral(values.shutterGasket.wpf).format('0,0.00')}</td>
                              <td>{numeral(values.shutterGasket.rw).format('0,0.00')}</td>
                              <td>{numeral(values.shutterGasket.rpf).format('0,0.00')}</td>
                              <td>{numeral(values.shutterGasket.amount).format('0,0.00')}</td>
                            </tr>
                            <tr>
                              <td>Mullion Gasket</td>
                              <td>{numeral(Mullion()).format('0,0.00')}</td>
                              <td>{numeral(values.mullionGasket.wpf).format('0,0.00')}</td>
                              <td>{numeral(values.mullionGasket.rw).format('0,0.00')}</td>
                              <td>{numeral(values.mullionGasket.rpf).format('0,0.00')}</td>
                              <td>{numeral(values.mullionGasket.amount).format('0,0.00')}</td>
                            </tr>
                            <tr>
                              <td>Beeding Gasket</td>
                              <td>{numeral(beedingGasket()).format('0,0.00')}</td>
                              <td>{numeral(values.beedingGasket.wpf).format('0,0.00')}</td>
                              <td>{numeral(values.beedingGasket.rw).format('0,0.00')}</td>
                              <td>{numeral(values.beedingGasket.rpf).format('0,0.00')}</td>
                              <td>{numeral(values.beedingGasket.amount).format('0,0.00')}</td>
                            </tr>
                            {mesh === 'yes' && (

                              <tr>
                                <td>Mesh Gasket</td>
                                <td>{numeral(meshGasket()).format('0,0.00')}</td>
                                <td>{numeral(values.meshGasket.wpf).format('0,0.00')}</td>
                                <td>{numeral(values.meshGasket.rw).format('0,0.00')}</td>
                                <td>{numeral(values.meshGasket.rpf).format('0,0.00')}</td>
                                <td>{numeral(values.meshGasket.amount).format('0,0.00')}</td>
                              </tr>

                            )}
                            {numFrames === 1 ? (<></>) : (
                              <tr>
                                <td>Cupler Joint/Square Joint </td>
                                <td>{numeral(cuplerJoint()).format('0,0.00')}</td>
                                <td>{numeral(values.cupler.wpf).format('0,0.00')}</td>
                                <td>{numeral(values.cupler.rw).format('0,0.00')}</td>
                                <td>{numeral(values.cupler.rpf).format('0,0.00')}</td>
                                <td>{numeral(values.cupler.amount).format('0,0.00')}</td>
                              </tr>
                            )}

                            <tr>
                              <td colspan="5">Total</td>
                              <td>{numeral(frameTotal).format('0,0.00')}</td>
                            </tr>
                            <tr>
                              <th colspan="5">Wastage ( {data.wastage}% )</th>
                              <th>{numeral(frameTotal + wastage).format('0,0.00')}</th>
                            </tr>
                            <tr>
                              <th colspan="5">Profile Cost/Sqft</th>
                              <th>{numeral((frameTotal + wastage) / frameArea).format('0,0.00')}</th>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <hr />
                      <div class="card-header">
                        <h5>Hardware Cost</h5>
                      </div>
                      <div class="card-body">
                        <table id="left-right-fix" class="table stripe row-border order-column">
                          <thead>
                            <tr>
                              <th>Product Description</th>
                              <th width="10%">Req. Qty </th>
                              <th width="10%">Price</th>
                              <th width="10%">Amount</th>
                            </tr>
                          </thead>
                          <tbody>
                            {mesh === 'yes' && (
                              <>
                                <tr>
                                  <td>Mesh 3.4 SS</td>
                                  <td>{numeral(values.mesh.qty).format('0,0.00')}</td>
                                  <td>{numeral(values.mesh.price).format('0,0.00')}</td>
                                  <td>{numeral(values.mesh.amount).format('0,0.00')}</td>
                                </tr>
                                <tr>
                                  <td>Steel Mesh</td>
                                  <td>{numeral(values.steelMesh.qty).format('0,0.00')}</td>
                                  <td>{numeral(values.steelMesh.price).format('0,0.00')}</td>
                                  <td>{numeral(values.steelMesh.amount).format('0,0.00')}</td>
                                </tr>

                                <tr>
                                  <td>Mesh Jump Arrester</td>
                                  <td>{numeral(values.meshja.qty * qty).format('0,0.00')}</td>
                                  <td>{numeral(values.meshja.price * qty).format('0,0.00')}</td>
                                  <td>{numeral(values.meshja.amount * qty).format('0,0.00')}</td>
                                </tr>
                              </>
                            )}
                            <tr>
                              <td>Steel Frame</td>
                              <td>{numeral(values.steelFrame.qty).format('0,0.00')}</td>
                              <td>{numeral(values.steelFrame.price).format('0,0.00')}</td>
                              <td>{numeral(values.steelFrame.amount).format('0,0.00')}</td>
                            </tr>
                            <tr>
                              <td>Steel Mullion</td>
                              <td>{numeral(values.steelMullion.qty).format('0,0.00')}</td>
                              <td>{numeral(values.steelMullion.price).format('0,0.00')}</td>
                              <td>{numeral(values.steelMullion.amount).format('0,0.00')}</td>
                            </tr>

                            <tr>
                              <td>Mullion Clamp</td>
                              <td>{numeral(values.mullionclamp.qty * qty).format('0,0.00')}</td>
                              <td>{numeral(values.mullionclamp.price * qty).format('0,0.00')}</td>
                              <td>{numeral(values.mullionclamp.amount * qty).format('0,0.00')}</td>
                            </tr>
                            <tr>
                              <td>Steel Shutter</td>
                              <td>{numeral(values.steelShutter.qty).format('0,0.00')}</td>
                              <td>{numeral(values.steelShutter.price).format('0,0.00')}</td>
                              <td>{numeral(values.steelShutter.amount).format('0,0.00')}</td>
                            </tr>
                            {/* <tr>
                              <td>Multi Point Lock ( MPL )</td>
                              <td>{numeral(values.mpl.qty).format('0,0.00')}</td>
                              <td>{numeral(values.mpl.price).format('0,0.00')}</td>
                              <td>{numeral(values.mpl.amount).format('0,0.00')}</td>
                            </tr> */}
                            <tr>
                              <td>Handle</td>
                              <td>{numeral(values.handle.qty).format('0,0.00')}</td>
                              <td>{numeral(values.handle.price).format('0,0.00')}</td>
                              <td>{numeral(values.handle.amount).format('0,0.00')}</td>
                            </tr>
                            <tr>
                              <td>Friction Stay</td>
                              <td>{numeral(values.frictionstay.qty * qty).format('0,0.00')}</td>
                              <td>{numeral(values.frictionstay.price * qty).format('0,0.00')}</td>
                              <td>{numeral(values.frictionstay.amount * qty).format('0,0.00')}</td>
                            </tr>
                            <tr>
                              <td>Runner Block</td>
                              <td>{numeral(values.runnerblock.qty * qty).format('0,0.00')}</td>
                              <td>{numeral(values.runnerblock.price * qty).format('0,0.00')}</td>
                              <td>{numeral(values.runnerblock.amount * qty).format('0,0.00')}</td>
                            </tr>
                            <tr>
                              <td>SecurityClip</td>
                              <td>{numeral(values.securityclip.qty * qty).format('0,0.00')}</td>
                              <td>{numeral(values.securityclip.price * qty).format('0,0.00')}</td>
                              <td>{numeral(values.securityclip.amount * qty).format('0,0.00')}</td>
                            </tr>
                            <tr>
                              <td>Espag with SS Receiver</td>
                              <td>{numeral(values.espag.qty * qty).format('0,0.00')}</td>
                              <td>{numeral(values.espag.price * qty).format('0,0.00')}</td>
                              <td>{numeral(values.espag.amount * qty).format('0,0.00')}</td>
                            </tr>
                            <tr>
                              <td>Espag with Multi Lock</td>
                              <td>{numeral(values.espagm.qty * qty).format('0,0.00')}</td>
                              <td>{numeral(values.espagm.price * qty).format('0,0.00')}</td>
                              <td>{numeral(values.espagm.amount * qty).format('0,0.00')}</td>
                            </tr>
                            <tr>
                              <td>Adjust Roller</td>
                              <td>{numeral(values.adjroll.qty * qty).format('0,0.00')}</td>
                              <td>{numeral(values.adjroll.price * qty).format('0,0.00')}</td>
                              <td>{numeral(values.adjroll.amount * qty).format('0,0.00')}</td>
                            </tr>
                            <tr>
                              <td>Hings Set</td>
                              <td>{numeral(values.hings.qty).format('0,0.00')}</td>
                              <td>{numeral(values.hings.price).format('0,0.00')}</td>
                              <td>{numeral(values.hings.amount).format('0,0.00')}</td>
                            </tr>
                            {grill === 'yes' && (
                              <tr>
                                <td>Grill</td>
                                <td>{numeral(values.grill.qty * qty).format('0,0.00')}</td>
                                <td>{numeral(values.grill.price * qty).format('0,0.00')}</td>
                                <td>{numeral(values.grill.amount * qty).format('0,0.00')}</td>
                              </tr>
                            )}
                            <tr>
                              <td>Screws/Misc</td>
                              <td>{numeral(values.screws.qty).format('0,0.00')}</td>
                              <td>{numeral(values.screws.price).format('0,0.00')}</td>
                              <td>{numeral(values.screws.amount).format('0,0.00')}</td>
                            </tr>
                            <tr>
                              <th colspan="3">Total</th>
                              <th>{numeral(hardTotal).format('0,0.00')}</th>
                            </tr>
                            <tr>
                              <th colspan="3">Hardware Cost/Sqft</th>
                              <th>{numeral(hardTotal / frameArea).format('0,0.00')}</th>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      {userType != '3' && (<>
                        <hr />

                        <div class="card-header ">
                          <h5>Margin Cost</h5>
                        </div>
                        <div class="card-body">
                          <table id="left-right-fix" class="table stripe row-border order-column">
                            <thead>
                              <tr>
                                <th>Product Description</th>
                                <th width="10%">Req. Qty </th>
                                <th width="10%">Price</th>
                                <th width="10%">Amount</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>Margin</td>
                                <td>{numeral(values.margin.qty).format('0,0.00')}</td>
                                <td>{numeral(values.margin.price).format('0,0.00')}</td>
                                <td>{numeral(values.margin.amount).format('0,0.00')}</td>
                              </tr>
                              <tr>
                                <th colspan="3">Total</th>
                                <th>{numeral(marginTotal).format('0,0.00')}</th>
                              </tr>
                              <tr>
                                <th colspan="3">Margin Cost/Sqft</th>
                                <th>{numeral(marginTotal / frameArea).format('0,0.00')}</th>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <hr />

                        <div class="card-header">
                          <h5>Production Cost</h5>
                        </div>
                        <div class="card-body">
                          <table id="left-right-fix" class="table stripe row-border order-column">
                            <thead>
                              <tr>
                                <th>Product Description</th>
                                <th width="10%">Req. Qty </th>
                                <th width="10%">Price</th>
                                <th width="10%">Amount</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>Markting OH</td>
                                <td>{numeral(values.marketing.qty).format('0,0.00')}</td>
                                <td>{numeral(values.marketing.price).format('0,0.00')}</td>
                                <td>{numeral(values.marketing.amount).format('0,0.00')}</td>
                              </tr>
                              <tr>
                                <td>Transport</td>
                                <td>{numeral(values.transport.qty).format('0,0.00')}</td>
                                <td>{numeral(values.transport.price).format('0,0.00')}</td>
                                <td>{numeral(values.transport.amount).format('0,0.00')}</td>
                              </tr>
                              <tr>
                                <td>Labour (Factory)</td>
                                <td>{numeral(values.labourF.qty).format('0,0.00')}</td>
                                <td>{numeral(values.labourF.price).format('0,0.00')}</td>
                                <td>{numeral(values.labourF.amount).format('0,0.00')}</td>
                              </tr>
                              <tr>
                                <td>Labour (Site)</td>
                                <td>{numeral(values.labourS.qty).format('0,0.00')}</td>
                                <td>{numeral(values.labourS.price).format('0,0.00')}</td>
                                <td>{numeral(values.labourS.amount).format('0,0.00')}</td>
                              </tr>
                              <tr>
                                <td>Glass</td>
                                <td>{numeral(values.glass.qty).format('0,0.00')}</td>
                                <td>{numeral(values.glass.price).format('0,0.00')}</td>
                                <td>{numeral(values.glass.amount).format('0,0.00')}</td>
                              </tr>
                              <tr>
                                <th colspan="3">Total</th>
                                <th>{numeral(prodTotal).format('0,0.00')}</th>
                              </tr>
                              <tr>
                                <th colspan="3">Production Cost/Sqft</th>
                                <th>{numeral(prodTotal / frameArea).format('0,0.00')}</th>
                              </tr>

                            </tbody>
                          </table>
                        </div>
                      </>
                      )}

                    </div>
                  </div>





                </>
              )}


            </div>
          </div>
        </div>



      )}

      {item === 'SlidingWindow' && (
        <div class="pc-container">
          <div class="pc-content">

            <div class="row">





              <div class="col-md-12 col-xxl-12 col-xl-12" >
                <div class="card" style={{ background: '#fff' }}>
                  <div class="card-body mb-5">

                    <div>
                      <div style={{ float: 'left', width: boxWidth * screen / mCalc + 'vw', height: boxHeight * screen / mCalc + 'vw', border: '1px solid #333' }}>



                        {[...Array(numFrames)].map((_, index) => (
                          <div style={{ float: 'left', margin: '5px', border: '1px solid #333', width: `calc(${boxWidth * screen / mCalc / numFrames + 'vw'} - 12px)`, height: `calc(${boxHeight * screen / mCalc + 'vw'} - 12px)` }}>


                            {boxTop === 'on' && (

                              [...Array(numTopBoxes)].map((_, index) => (
                                <div style={{ display: 'flex', width: `calc(${boxWidth * screen / mCalc / numFrames + 'vw'} - 23px)`, height: `calc(${(topnewHeights * screen / mCalc)}vw )`, margin: '5px' }}>
                                  {[...Array(topFixedNum)].map((_, index) => (
                                    <div style={{ flex: '1', background: 'lightgreen', width: `calc(${topFixedWidth[index] * screen / mCalc / numFrames + 'vw'} - 18px)`, height: `calc(${(topnewHeights * screen / mCalc)}vw )`, border: 'solid 1px #000', marginLeft: index === 0 ? '0px' : '5px', }}>

                                    </div>
                                  ))}
                                </div>
                              ))
                            )}

                            <div style={{ display: 'flex' }}>
                              {[...Array(numSecondInnerBoxes)].map((_, index) => (

                                <div key={index} style={{ flex: '1', height: `calc(${(boxHeight - topnewHeights - bottomnewHeights) * screen / mCalc}vw - ${boxTop === 'on' ? '30px' : '25px'})`, width: `calc(${newWidths[index] * screen / mCalc}vw - 5px - 19px)`, backgroundColor: boxColors[index] || 'lightblue', marginTop: boxTop === 'on' ? '0px' : '5px', marginRight: index === numSecondInnerBoxes - 1 ? '5px' : '2.5px', marginLeft: index === 0 ? '5px' : '2.5px', border: '1px solid #333' }}>


                                  <div className="expanding-arrow-line" style={{
                                    position: 'absolute',
                                    float: 'left',
                                    width: `calc(${newWidths[index] * screen / mCalc}vw - 15px)`,
                                    bottom: '-10px',
                                    top: `calc(${boxHeight * screen / mCalc}vw - 25px)`,
                                  }}

                                  >
                                    <div className="arrow-left"></div>
                                    <div className="line" align="center">
                                      <div style={{ marginTop: '-7px', background: 'white', maxWidth: '60px', textAlign: 'center' }}><p>{typeof newWidths[index] === 'number' ? newWidths[index].toFixed(2) : newWidths[index]}</p></div>
                                    </div>
                                    <div className="arrow-right"></div>
                                  </div>


                                  <div style={{
                                  height:'7px',
                                  width: '1px',
                                  float: 'right',
                                  right: '0px',
                                  marginTop: `calc(${(boxHeight-topnewHeights - bottomnewHeights) * screen}vw - ${boxTop === 'on' ? '31px' : '26px' } )`,
                                  marginRight: '-1px',
                                  background: index === (numSecondInnerBoxes-1) ? 'transparent' : 'black',
                                  }}></div>




                                  {boxNames[index] === 'Shutter' && (boxHandle[index] === 'left' ? (
                                    <img
                                      src={Handle}
                                      style={{
                                        float: 'left',
                                        marginTop: `calc(${(((boxHeight - topnewHeights - bottomnewHeights) * screen / mCalc) / 2)}vw - 15px`,
                                        marginRight: '2px',
                                        width: screen === 3 ? '5px' : '4px'
                                      }}

                                      alt="Left Handle"
                                    />
                                  ) : (
                                    <img
                                      src={Handle}
                                      style={{
                                        float: 'right',
                                        marginTop: `calc(${(((boxHeight - topnewHeights - bottomnewHeights) * screen / mCalc) / 2)}vw - 15px`,
                                        marginRight: '2px',
                                        width: screen === 3 ? '5px' : '4px'
                                      }}

                                      alt="Right Handle"
                                    />

                                  ))}

                                  {mesh === 'yes' && boxNames[index] === 'Shutter' && boxHandle[index] === 'left' && (
                                    <img src={meshimg} style={{ width: screen === 3 ? '40px' : '20px', float: 'left', marginLeft: screen === 3 ? boxHandle[index] === 'left' ? '-8px' : '0px' : boxHandle[index] === 'left' ? '-15px' : 'px', marginTop: screen === 3 ? `calc(${((boxHeight - topnewHeights - bottomnewHeights) * screen / mCalc)}vw - ${boxTop === 'on' ? '72px' : '67px'} )` : `calc(${((boxHeight - topnewHeights - bottomnewHeights) * screen / mCalc)}vw - 45px)` }} />
                                  )}

                                  {mesh === 'yes' && boxNames[index] === 'Shutter' && boxHandle[index] === 'right' && (
                                    <img src={meshimg} style={{ width: screen === 3 ? '40px' : '20px', float: 'left', marginLeft: screen === 3 ? boxHandle[index] === 'right' ? '-1px' : '0px' : boxHandle[index] === 'right' ? '0px' : 'px', marginTop: screen === 3 ? `calc(${((boxHeight - topnewHeights - bottomnewHeights) * screen / mCalc)}vw - ${boxTop === 'on' ? '72px' : '67px'} )` : `calc(${((boxHeight - topnewHeights - bottomnewHeights) * screen / mCalc)}vw - 45px)` }} />
                                  )}

                                  {boxNames[index] === 'Shutter' && grill === 'yes' && boxes}

                                </div>

                              ))}

                            </div>


                            {boxBottom === 'on' && (
                              [...Array(numBottomBoxes)].map((_, index) => (
                                <div style={{ display: 'flex', width: `calc(${boxWidth * screen / mCalc / numFrames + 'vw'} - 23px)`, height: `calc(${(bottomnewHeights * screen / mCalc) + 'vw'} -10px)`, margin: '5px' }}>
                                  {[...Array(bottomFixedNum)].map((_, index) => (
                                    <div style={{ flex: '1', background: 'lightgreen', width: `calc(${bottomFixedWidth[index] * screen / mCalc / numFrames + 'vw'} - 18px)`, height: `calc(${(bottomnewHeights * screen / mCalc) + 'vw'} - 5px)`, border: 'solid 1px #000', marginLeft: index === 0 ? '0px' : '5px', }}>

                                    </div>
                                  ))}
                                </div>
                              ))
                            )}


                            {numSecondInnerBoxes === 0 ? (
                              <div className="expanding-arrow-line" style={{ float: 'left', marginLeft: '-5px', width: ((Number(boxWidth) / numFrames) * screen / mCalc) + 'vw', top: (Number(boxHeight) - newHeights) * screen / mCalc + 'vw', }} >
                                <div className="arrow-left"></div>
                                <div className="line" align="center">
                                  <div style={{ marginTop: '-8px', background: 'white', maxWidth: '60px', textAlign: 'center' }}><p>{typeof (Number(boxWidth) / numFrames) === 'number' ? (Number(boxWidth) / numFrames).toFixed(2) : (Number(boxWidth) / numFrames)}</p></div>
                                </div>
                                <div className="arrow-right"></div>
                              </div>
                            ) : (
                              <div className="expanding-arrow-line" style={{ float: 'left', marginLeft: '-5px', width: ((Number(boxWidth) / numFrames) * screen / mCalc) + 'vw', bottom: '-10px', top: '50px', }} >
                                <div className="arrow-left"></div>
                                <div className="line" align="center">
                                  <div style={{ marginTop: '-8px', background: 'white', maxWidth: '60px', textAlign: 'center' }}><p>{typeof (Number(boxWidth) / numFrames) === 'number' ? (Number(boxWidth) / numFrames).toFixed(2) : (Number(boxWidth) / numFrames)}</p></div>
                                </div>
                                <div className="arrow-right"></div>
                              </div>
                            )}

                          </div>
                        ))}
                      </div>

                      <div style={{ width: '45px', float: 'left', paddingLeft: '15px', textAlign: 'left' }}>
                      {boxTop === 'on' &&
                      <>
                        <div className="expanding-arrow-line" style={{ float: 'left', height: `calc(${topnewHeights * screen / mCalc}vw + 10px)`, top: '0', position: 'relative' }}>
                          <div className="arrow-up" style={{ borderBottom: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', top: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
                          <div className="line" style={{ width: '1px', height: '100%', marginLeft: '0px', position: 'absolute', top: '0' }}>
                            <div style={{ marginLeft: '-10px', marginTop: topnewHeights * (screen / mCalc / 2) + 'vw', background: 'white', maxWidth: '60px', textAlign: 'left' }}><p style={{ width: '50px' }}>{typeof topnewHeights === 'number' ? topnewHeights.toFixed(2) : topnewHeights}</p></div>
                          </div>
                          <div className="arrow-down" style={{ borderTop: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', bottom: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
                        </div>
                        <div className="expanding-arrow-line" style={{ float: 'left', height: `calc(${(boxHeight - topnewHeights - bottomnewHeights) * screen / mCalc}vw - ${boxTop === 'on' ? '30px' : '15px'})`, top: `calc(${topnewHeights * screen / mCalc}vw + ${boxTop === 'on' ? '15px' : '0px'})`, position: 'relative' }}>
                        <div className="arrow-up" style={{ borderBottom: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', top: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
                        <div className="line" style={{ width: '1px', height: '100%', marginLeft: '0px', position: 'absolute', top: '0' }}>
                          <div style={{ marginLeft: '-10px', marginTop: (boxHeight - topnewHeights - bottomnewHeights) * ((screen / mCalc / 2) - (screen / mCalc / 8)) + 'vw', background: 'white', maxWidth: '60px', textAlign: 'left' }}><p style={{ width: '50px' }}>{typeof (boxHeight - topnewHeights - bottomnewHeights) === 'number' ? (boxHeight - topnewHeights - bottomnewHeights).toFixed(2) : (boxHeight - topnewHeights - bottomnewHeights)}</p></div>
                        </div>
                        <div className="arrow-down" style={{ borderTop: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', bottom: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
                      </div>
                      </>
                      }
                      
                      {boxBottom === 'on' &&
                      <>
                      <div className="expanding-arrow-line" style={{ float: 'left', height: `calc(${(boxHeight - topnewHeights - bottomnewHeights) * screen / mCalc}vw - ${boxTop === 'on' ? '30px' : '15px'})`, top: `calc(${topnewHeights * screen / mCalc}vw + ${boxTop === 'on' ? '15px' : '0px'})`, position: 'relative' }}>
                      <div className="arrow-up" style={{ borderBottom: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', top: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
                      <div className="line" style={{ width: '1px', height: '100%', marginLeft: '0px', position: 'absolute', top: '0' }}>
                        <div style={{ marginLeft: '-10px', marginTop: (boxHeight - topnewHeights - bottomnewHeights) * ((screen / mCalc / 2) - (screen / mCalc / 8)) + 'vw', background: 'white', maxWidth: '60px', textAlign: 'left' }}><p style={{ width: '50px' }}>{typeof (boxHeight - topnewHeights - bottomnewHeights) === 'number' ? (boxHeight - topnewHeights - bottomnewHeights).toFixed(2) : (boxHeight - topnewHeights - bottomnewHeights)}</p></div>
                      </div>
                      <div className="arrow-down" style={{ borderTop: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', bottom: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
                    </div>
                        <div className="expanding-arrow-line" style={{ float: 'left', height: `calc(${(bottomnewHeights) * screen / mCalc}vw + 5px)`, top: `calc(${(boxHeight - bottomnewHeights) * screen / mCalc}vw - ${boxTop === 'on' ? '10px' : '10px'})`, position: 'relative' }}>
                          <div className="arrow-up" style={{ borderBottom: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', top: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
                          <div className="line" style={{ width: '1px', height: '100%', marginLeft: '0px', position: 'absolute', top: '0' }}>
                            <div style={{ marginLeft: '-10px', marginTop: (bottomnewHeights) * ((screen / mCalc / 2) - (screen / mCalc / 8)) + 'vw', background: 'white', maxWidth: '60px', textAlign: 'left' }}><p style={{ width: '50px' }}>{typeof (bottomnewHeights) === 'number' ? (bottomnewHeights).toFixed(2) : (bottomnewHeights)}</p></div>
                          </div>
                          <div className="arrow-down" style={{ borderTop: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', bottom: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
                        </div>
                        </>
                      }

                    </div>

                    <div style={{ width: '30px', float: 'left', paddingLeft: boxBottom === 'on' || boxTop === 'on' ?'0px' : '15px', textAlign: 'left' }}>
                      <div className="expanding-arrow-line" style={{ float: 'left', height: `calc(${boxHeight * screen / mCalc}vw)`, top: '0', position: 'relative' }}>
                        <div className="arrow-up" style={{ borderBottom: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', top: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
                        <div className="line" style={{ width: '1px', height: '100%', marginLeft: '0px', position: 'absolute', top: '0' }}>
                          <div style={{ marginLeft: '-10px', marginTop: boxHeight * (screen / mCalc / 2) + 'vw', background: 'white', maxWidth: '60px', textAlign: 'left' }}><p style={{ width: '50px' }}>{Number(boxHeight).toFixed(2)}</p></div>
                        </div>
                        <div className="arrow-down" style={{ borderTop: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', bottom: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
                      </div>
                    </div>

                    </div>

                  </div>
                </div>

              </div>

              {numSecondInnerBoxes === 0 ? (<div></div>) : (
                <>
                  <div class="col-md-12 col-xxl-2 col-xl-2">
                    <div class="card">
                      <div class="card-body">
                        <div class="d-flex align-items-center">
                          <div class="flex-grow-1 ms-3">
                            <h6 class="mb-0">Area</h6>
                          </div>
                        </div>
                        <div class="bg-body p-3 mt-3 rounded">
                          <div class="mt-3 row align-items-center">
                            <div class="col-12">
                              <h6 class="mb-1">{frameArea * qty} sqft</h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-12 col-xxl-2 col-xl-2">
                    <div class="card">
                      <div class="card-body">
                        <div class="d-flex align-items-center">
                          <div class="flex-grow-1 ms-3">
                            <h6 class="mb-0">Shutters</h6>
                          </div>
                        </div>
                        <div class="bg-body p-3 mt-3 rounded">
                          <div class="mt-3 row align-items-center">
                            <div class="col-12">
                              <h6 class="mb-1">{shutterCount * numFrames}</h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-12 col-xxl-2 col-xl-2">
                    <div class="card">
                      <div class="card-body">
                        <div class="d-flex align-items-center">
                          <div class="flex-grow-1 ms-3">
                            <h6 class="mb-0">Fixed</h6>
                          </div>
                        </div>
                        <div class="bg-body p-3 mt-3 rounded">
                          <div class="mt-3 row align-items-center">
                            <div class="col-12">
                              <h6 class="mb-1">{fixedShutters}</h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-12 col-xxl-2 col-xl-2">
                    <div class="card">
                      <div class="card-body">
                        <div class="d-flex align-items-center">
                          <div class="flex-grow-1 ms-3">
                            <h6 class="mb-0">Mullions</h6>
                          </div>
                        </div>
                        <div class="bg-body p-3 mt-3 rounded">
                          <div class="mt-3 row align-items-center">
                            <div class="col-12">
                              <h6 class="mb-1">{newHeights === 0 ? 0 : 1}</h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-12 col-xxl-2 col-xl-2">
                    <div class="card">
                      <div class="card-body">
                        <div class="d-flex align-items-center">
                          <div class="flex-grow-1 ms-3">
                            <h6 class="mb-0">Total</h6>
                          </div>
                        </div>
                        <div class="bg-body p-3 mt-3 rounded">
                          <div class="mt-3 row align-items-center">
                            <div class="col-12">
                              <h6 class="mb-1">₹ {numeral(grandTotal * qty).format('0,0.00')}</h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-12 col-xxl-2 col-xl-2">
                    <div class="card">
                      <div class="card-body">
                        <div class="d-flex align-items-center">
                          <div class="flex-grow-1 ms-3">
                            <h6 class="mb-0">Rate/Sqft</h6>
                          </div>
                        </div>
                        <div class="bg-body p-3 mt-3 rounded">
                          <div class="mt-3 row align-items-center">
                            <div class="col-12">
                              <h6 class="mb-1">₹ {numeral(grandTotal / frameArea).format('0,0.00')}</h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>




                  <div class="col-md-12 col-xxl-12 col-xl-12">
                    <div class="card">
                      <div class="card-header">
                        <h5>Profile Cost</h5>
                      </div>
                      <div class="card-body">
                        <table id="left-right-fix" class="table stripe row-border order-column">
                          <thead>
                            <tr>
                              <th>Product Description</th>
                              <th width="10%">Req. Qty (Feet)</th>
                              <th width="10%">Kg/Feet</th>
                              <th width="10%">Req. Qty (Kg)</th>
                              <th width="10%">Rate/Kg</th>
                              <th width="10%">Amount</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>{shutterCount}{mesh === 'yes' ? '.5' : ''} Track Outer Frame</td>
                              <td>{numeral(Frame()).format('0,0.00')}</td>
                              <td>{numeral(values.frame.wpf).format('0,0.00')}</td>
                              <td>{numeral(values.frame.rw).format('0,0.00')}</td>
                              <td>{numeral(values.frame.rpf).format('0,0.00')}</td>
                              <td>{numeral(values.frame.amount).format('0,0.00')}</td>
                            </tr>
                            <tr>
                              <td>Shutter Frame</td>
                              <td>{numeral(Shutter()).format('0,0.00')}</td>
                              <td>{numeral(values.shutter.wpf).format('0,0.00')}</td>
                              <td>{numeral(values.shutter.rw).format('0,0.00')}</td>
                              <td>{numeral(values.shutter.rpf).format('0,0.00')}</td>
                              <td>{numeral(values.shutter.amount).format('0,0.00')}</td>
                            </tr>
                            {mesh === 'yes' && (

                              <tr>
                                <td>Mesh Frame</td>
                                <td>{numeral(meshFrame()).format('0,0.00')}</td>
                                <td>{numeral(values.meshFrame.wpf).format('0,0.00')}</td>
                                <td>{numeral(values.meshFrame.rw).format('0,0.00')}</td>
                                <td>{numeral(values.meshFrame.rpf).format('0,0.00')}</td>
                                <td>{numeral(values.meshFrame.amount).format('0,0.00')}</td>
                              </tr>

                            )}


                            <tr>
                              <td>Mullion</td>
                              <td>{numeral(Mullion()).format('0,0.00')}</td>
                              <td>{numeral(values.mullion.wpf).format('0,0.00')}</td>
                              <td>{numeral(values.mullion.rw).format('0,0.00')}</td>
                              <td>{numeral(values.mullion.rpf).format('0,0.00')}</td>
                              <td>{numeral(values.mullion.amount).format('0,0.00')}</td>
                            </tr>


                            <tr>
                              <td>Beeding</td>
                              <td>{numeral(Beeding()).format('0,0.00')}</td>
                              <td>{numeral(values.beeding.wpf).format('0,0.00')}</td>
                              <td>{numeral(values.beeding.rw).format('0,0.00')}</td>
                              <td>{numeral(values.beeding.rpf).format('0,0.00')}</td>
                              <td>{numeral(values.beeding.amount).format('0,0.00')}</td>
                            </tr>
                            <tr>
                              <td>InterLock</td>
                              <td>{numeral(interLock()).format('0,0.00')}</td>
                              <td>{numeral(values.interLock.wpf).format('0,0.00')}</td>
                              <td>{numeral(values.interLock.rw).format('0,0.00')}</td>
                              <td>{numeral(values.interLock.rpf).format('0,0.00')}</td>
                              <td>{numeral(values.interLock.amount).format('0,0.00')}</td>
                            </tr>

                            {numFrames === 1 ? (<></>) : (
                              <tr>
                                <td>Cupler Joint/Square Joint </td>
                                <td>{numeral(cuplerJoint()).format('0,0.00')}</td>
                                <td>{numeral(values.cupler.wpf).format('0,0.00')}</td>
                                <td>{numeral(values.cupler.rw).format('0,0.00')}</td>
                                <td>{numeral(values.cupler.rpf).format('0,0.00')}</td>
                                <td>{numeral(values.cupler.amount).format('0,0.00')}</td>
                              </tr>
                            )}
                            <tr>
                              <td>Frame Gasket</td>
                              <td>{numeral(frameGasket()).format('0,0.00')}</td>
                              <td>{numeral(values.frameGasket.wpf).format('0,0.00')}</td>
                              <td>{numeral(values.frameGasket.rw).format('0,0.00')}</td>
                              <td>{numeral(values.frameGasket.rpf).format('0,0.00')}</td>
                              <td>{numeral(values.frameGasket.amount).format('0,0.00')}</td>
                            </tr>
                            <tr>
                              <td>Shutter Gasket</td>
                              <td>{numeral(shutterGasket()).format('0,0.00')}</td>
                              <td>{numeral(values.shutterGasket.wpf).format('0,0.00')}</td>
                              <td>{numeral(values.shutterGasket.rw).format('0,0.00')}</td>
                              <td>{numeral(values.shutterGasket.rpf).format('0,0.00')}</td>
                              <td>{numeral(values.shutterGasket.amount).format('0,0.00')}</td>
                            </tr>

                            {newHeights > 0 && (
                              <tr>
                                <td>Mullion Gasket</td>
                                <td>{numeral(Mullion()).format('0,0.00')}</td>
                                <td>{numeral(values.mullionGasket.wpf).format('0,0.00')}</td>
                                <td>{numeral(values.mullionGasket.rw).format('0,0.00')}</td>
                                <td>{numeral(values.mullionGasket.rpf).format('0,0.00')}</td>
                                <td>{numeral(values.mullionGasket.amount).format('0,0.00')}</td>
                              </tr>
                            )}

                            <tr>
                              <td>Beeding Gasket</td>
                              <td>{numeral(beedingGasket()).format('0,0.00')}</td>
                              <td>{numeral(values.beedingGasket.wpf).format('0,0.00')}</td>
                              <td>{numeral(values.beedingGasket.rw).format('0,0.00')}</td>
                              <td>{numeral(values.beedingGasket.rpf).format('0,0.00')}</td>
                              <td>{numeral(values.beedingGasket.amount).format('0,0.00')}</td>
                            </tr>
                            {mesh === 'yes' && (

                              <tr>
                                <td>Mesh Gasket</td>
                                <td>{numeral(meshGasket()).format('0,0.00')}</td>
                                <td>{numeral(values.meshGasket.wpf).format('0,0.00')}</td>
                                <td>{numeral(values.meshGasket.rw).format('0,0.00')}</td>
                                <td>{numeral(values.meshGasket.rpf).format('0,0.00')}</td>
                                <td>{numeral(values.meshGasket.amount).format('0,0.00')}</td>
                              </tr>

                            )}


                            <tr>
                              <td colspan="5">Total</td>
                              <td>{numeral(frameTotal).format('0,0.00')}</td>
                            </tr>
                            <tr>
                              <th colspan="5">Wastage ( {data.wastage}% )</th>
                              <th>{numeral(frameTotal + wastage).format('0,0.00')}</th>
                            </tr>
                            <tr>
                              <th colspan="5">Profile Cost/Sqft</th>
                              <th>{numeral((frameTotal + wastage) / frameArea).format('0,0.00')}</th>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <hr />
                      <div class="card-header">
                        <h5>Hardware Cost</h5>

                      </div>
                      <div class="card-body">
                        <table id="left-right-fix" class="table stripe row-border order-column">
                          <thead>
                            <tr>
                              <th>Product Description</th>
                              <th width="10%">Req. Qty </th>
                              <th width="10%">Price</th>
                              <th width="10%">Amount</th>
                            </tr>
                          </thead>
                          <tbody>

                            <tr>
                              <td>Steel Frame</td>
                              <td>{numeral(values.steelFrame.qty).format('0,0.00')}</td>
                              <td>{numeral(values.steelFrame.price).format('0,0.00')}</td>
                              <td>{numeral(values.steelFrame.amount).format('0,0.00')}</td>
                            </tr>
                            <tr>
                              <td>Steel Shutter</td>
                              <td>{numeral(values.steelShutter.qty).format('0,0.00')}</td>
                              <td>{numeral(values.steelShutter.price).format('0,0.00')}</td>
                              <td>{numeral(values.steelShutter.amount).format('0,0.00')}</td>
                            </tr>

                            <tr>
                              <td>Steel Mullion</td>
                              <td>{numeral(values.steelMullion.qty).format('0,0.00')}</td>
                              <td>{numeral(values.steelMullion.price).format('0,0.00')}</td>
                              <td>{numeral(values.steelMullion.amount).format('0,0.00')}</td>
                            </tr>

                            <tr>
                              <td>Mullion Clamp</td>
                              <td>{numeral(values.mullionclamp.qty * qty).format('0,0.00')}</td>
                              <td>{numeral(values.mullionclamp.price * qty).format('0,0.00')}</td>
                              <td>{numeral(values.mullionclamp.amount * qty).format('0,0.00')}</td>
                            </tr>


                            {mesh === 'yes' && (
                              <>
                                <tr>
                                  <td>Steel Mesh</td>
                                  <td>{numeral(values.steelMesh.qty).format('0,0.00')}</td>
                                  <td>{numeral(values.steelMesh.price).format('0,0.00')}</td>
                                  <td>{numeral(values.steelMesh.amount).format('0,0.00')}</td>
                                </tr>
                                <tr>
                                  <td>Mesh Jump Arrester</td>
                                  <td>{numeral(values.meshja.qty * qty).format('0,0.00')}</td>
                                  <td>{numeral(values.meshja.price * qty).format('0,0.00')}</td>
                                  <td>{numeral(values.meshja.amount * qty).format('0,0.00')}</td>
                                </tr>
                              </>
                            )}
                            
                            <tr>
                              <td>Friction Stay</td>
                              <td>{numeral(values.frictionstay.qty * qty).format('0,0.00')}</td>
                              <td>{numeral(values.frictionstay.price * qty).format('0,0.00')}</td>
                              <td>{numeral(values.frictionstay.amount * qty).format('0,0.00')}</td>
                            </tr>
                            <tr>
                              <td>Espag with SS Receiver</td>
                              <td>{numeral(values.espag.qty * qty).format('0,0.00')}</td>
                              <td>{numeral(values.espag.price * qty).format('0,0.00')}</td>
                              <td>{numeral(values.espag.amount * qty).format('0,0.00')}</td>
                            </tr>

                            <tr>
                              <td>Aluminium Track</td>
                              <td>{numeral(values.alTrack.qty).format('0,0.00')}</td>
                              <td>{numeral(values.alTrack.price).format('0,0.00')}</td>
                              <td>{numeral(values.alTrack.amount).format('0,0.00')}</td>
                            </tr>
                            <tr>
                              <td>Bump Arrester</td>
                              <td>{numeral(values.bArrester.qty).format('0,0.00')}</td>
                              <td>{numeral(values.bArrester.price).format('0,0.00')}</td>
                              <td>{numeral(values.bArrester.amount).format('0,0.00')}</td>
                            </tr>
                            <tr>
                              <td>Jump Stopper</td>
                              <td>{numeral(values.jStopper.qty).format('0,0.00')}</td>
                              <td>{numeral(values.jStopper.price).format('0,0.00')}</td>
                              <td>{numeral(values.jStopper.amount).format('0,0.00')}</td>
                            </tr>
                            <tr>
                              <td>Dust Arrester</td>
                              <td>{numeral(values.dArrester.qty).format('0,0.00')}</td>
                              <td>{numeral(values.dArrester.price).format('0,0.00')}</td>
                              <td>{numeral(values.dArrester.amount).format('0,0.00')}</td>
                            </tr>
                            <tr>
                              <td>Grove Roller</td>
                              <td>{numeral(values.gRoller.qty).format('0,0.00')}</td>
                              <td>{numeral(values.gRoller.price).format('0,0.00')}</td>
                              <td>{numeral(values.gRoller.amount).format('0,0.00')}</td>
                            </tr>
                            <tr>
                              <td>Touch Lock with SS Receiver</td>
                              <td>{numeral(values.touchlock.qty * qty).format('0,0.00')}</td>
                              <td>{numeral(values.touchlock.price * qty).format('0,0.00')}</td>
                              <td>{numeral(values.touchlock.amount * qty).format('0,0.00')}</td>
                            </tr>
                            <tr>
                              <td>Weather Strip</td>
                              <td>{numeral(values.wStrip.qty).format('0,0.00')}</td>
                              <td>{numeral(values.wStrip.price).format('0,0.00')}</td>
                              <td>{numeral(values.wStrip.amount).format('0,0.00')}</td>
                            </tr>
                            {grill === 'yes' && (
                              <tr>
                                <td>Grill</td>
                                <td>{numeral(values.grill.qty * qty).format('0,0.00')}</td>
                                <td>{numeral(values.grill.price * qty).format('0,0.00')}</td>
                                <td>{numeral(values.grill.amount * qty).format('0,0.00')}</td>
                              </tr>
                            )}
                            <tr>
                              <td>Screws/Misc</td>
                              <td>{numeral(values.screws.qty).format('0,0.00')}</td>
                              <td>{numeral(values.screws.price).format('0,0.00')}</td>
                              <td>{numeral(values.screws.amount).format('0,0.00')}</td>
                            </tr>
                            {mesh === 'yes' && (
                              <>
                                <tr>
                                  <td>Mesh 30A Grade</td>
                                  <td>{numeral(values.mesh.qty).format('0,0.00')}</td>
                                  <td>{numeral(values.mesh.price).format('0,0.00')}</td>
                                  <td>{numeral(values.mesh.amount).format('0,0.00')}</td>
                                </tr>
                                <tr>
                                  <td>Mesh Roller</td>
                                  <td>{numeral(values.mRoller.qty).format('0,0.00')}</td>
                                  <td>{numeral(values.mRoller.price).format('0,0.00')}</td>
                                  <td>{numeral(values.mRoller.amount).format('0,0.00')}</td>
                                </tr>
                              </>
                            )}
                            <tr>
                              <th colspan="3">Total</th>
                              <th>{numeral(hardTotal).format('0,0.00')}</th>
                            </tr>
                            <tr>
                              <th colspan="3">Hardware Cost/Sqft</th>
                              <th>{numeral(hardTotal / frameArea).format('0,0.00')}</th>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      {userType != '3' && (<>
                        <hr />

                        <div class="card-header ">
                          <h5>Margin Cost</h5>
                        </div>
                        <div class="card-body">
                          <table id="left-right-fix" class="table stripe row-border order-column">
                            <thead>
                              <tr>
                                <th>Product Description</th>
                                <th width="10%">Req. Qty </th>
                                <th width="10%">Price</th>
                                <th width="10%">Amount</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>Margin</td>
                                <td>{numeral(values.margin.qty).format('0,0.00')}</td>
                                <td>{numeral(values.margin.price).format('0,0.00')}</td>
                                <td>{numeral(values.margin.amount).format('0,0.00')}</td>
                              </tr>
                              <tr>
                                <th colspan="3">Total</th>
                                <th>{numeral(marginTotal).format('0,0.00')}</th>
                              </tr>
                              <tr>
                                <th colspan="3">Margin Cost/Sqft</th>
                                <th>{numeral(marginTotal / frameArea).format('0,0.00')}</th>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <hr />

                        <div class="card-header">
                          <h5>Production Cost</h5>
                        </div>
                        <div class="card-body">
                          <table id="left-right-fix" class="table stripe row-border order-column">
                            <thead>
                              <tr>
                                <th>Product Description</th>
                                <th width="10%">Req. Qty </th>
                                <th width="10%">Price</th>
                                <th width="10%">Amount</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>Markting OH</td>
                                <td>{numeral(values.marketing.qty).format('0,0.00')}</td>
                                <td>{numeral(values.marketing.price).format('0,0.00')}</td>
                                <td>{numeral(values.marketing.amount).format('0,0.00')}</td>
                              </tr>
                              <tr>
                                <td>Transport</td>
                                <td>{numeral(values.transport.qty).format('0,0.00')}</td>
                                <td>{numeral(values.transport.price).format('0,0.00')}</td>
                                <td>{numeral(values.transport.amount).format('0,0.00')}</td>
                              </tr>
                              <tr>
                                <td>Labour (Factory)</td>
                                <td>{numeral(values.labourF.qty).format('0,0.00')}</td>
                                <td>{numeral(values.labourF.price).format('0,0.00')}</td>
                                <td>{numeral(values.labourF.amount).format('0,0.00')}</td>
                              </tr>
                              <tr>
                                <td>Labour (Site)</td>
                                <td>{numeral(values.labourS.qty).format('0,0.00')}</td>
                                <td>{numeral(values.labourS.price).format('0,0.00')}</td>
                                <td>{numeral(values.labourS.amount).format('0,0.00')}</td>
                              </tr>
                              <tr>
                                <td>Glass</td>
                                <td>{numeral(values.glass.qty).format('0,0.00')}</td>
                                <td>{numeral(values.glass.price).format('0,0.00')}</td>
                                <td>{numeral(values.glass.amount).format('0,0.00')}</td>
                              </tr>
                              <tr>
                                <th colspan="3">Total</th>
                                <th>{numeral(prodTotal).format('0,0.00')}</th>
                              </tr>
                              <tr>
                                <th colspan="3">Production Cost/Sqft</th>
                                <th>{numeral(prodTotal / frameArea).format('0,0.00')}</th>
                              </tr>

                            </tbody>
                          </table>
                        </div>
                      </>
                      )}


                    </div>
                  </div>


                </>
              )}


            </div>
          </div>
        </div>


      )}

      {item === 'SlidingDoor' && (

        <div class="pc-container">
          <div class="pc-content">

            <div class="row">
              <div class="col-md-12 col-xxl-12 col-xl-12" >
                <div class="card" style={{ background: '#fff' }}>
                  <div class="card-body mb-5">

                    <div>

                      <div style={{ float: 'left', width: boxWidth * screen / mCalc + 'vw', height: boxHeight * screen / mCalc + 'vw', border: '1px solid #333' }}>


                        {[...Array(numFrames)].map((_, index) => (
                          <div style={{ float: 'left', margin: '5px', border: '1px solid #333', width: `calc(${boxWidth * screen / mCalc / numFrames + 'vw'} - 12px)`, height: `calc(${boxHeight * screen / mCalc + 'vw'} - 12px)` }}>





                            {boxTop === 'on' && (

                              [...Array(numTopBoxes)].map((_, index) => (
                                <div style={{ display: 'flex', width: `calc(${boxWidth * screen / mCalc / numFrames + 'vw'} - 23px)`, height: `calc(${(topnewHeights * screen / mCalc)}vw )`, margin: '5px' }}>
                                  {[...Array(topFixedNum)].map((_, index) => (
                                    <div style={{ flex: '1', background: 'lightgreen', width: `calc(${topFixedWidth[index] * screen / mCalc / numFrames + 'vw'} - 18px)`, height: `calc(${(topnewHeights * screen / mCalc)}vw )`, border: 'solid 1px #000', marginLeft: index === 0 ? '0px' : '5px', }}>

                                    </div>
                                  ))}
                                </div>
                              ))
                            )}

                            <div style={{ display: 'flex' }}>
                              {[...Array(numSecondInnerBoxes)].map((_, index) => (

                                <div key={index} style={{ flex: '1', height: `calc(${(boxHeight - topnewHeights - bottomnewHeights) * screen / mCalc}vw - ${boxTop === 'on' ? '30px' : '25px'})`, width: `calc(${newWidths[index] * screen / mCalc}vw - 5px - 19px)`, backgroundColor: panelType === 1 ? 'lightgray' : panelType === 2 ? 'lightyellow' : 'lightblue', marginTop: boxTop === 'on' ? '0px' : '5px', marginRight: index === numSecondInnerBoxes - 1 ? '5px' : '2.5px', marginLeft: index === 0 ? '5px' : '2.5px', border: '1px solid #333' }}>


                                  <div className="expanding-arrow-line" style={{
                                    position: 'absolute',
                                    float: 'left',
                                    width: `calc(${newWidths[index] * screen / mCalc}vw - 15px)`,
                                    bottom: '-10px',
                                    top: `calc(${boxHeight * screen / mCalc}vw - 25px)`,
                                  }}

                                  >
                                    <div className="arrow-left"></div>
                                    <div className="line" align="center">
                                      <div style={{ marginTop: '-7px', background: 'white', maxWidth: '60px', textAlign: 'center' }}><p>{typeof newWidths[index] === 'number' ? newWidths[index].toFixed(2) : newWidths[index]}</p></div>
                                    </div>
                                    <div className="arrow-right"></div>
                                  </div>



                                  {boxNames[index] === 'Shutter' && (boxHandle[index] === 'left' ? (
                                    <img
                                      src={Handle}
                                      style={{
                                        float: 'left',
                                        marginTop: `calc(${(((boxHeight - topnewHeights - bottomnewHeights) * screen / mCalc) / 2)}vw - 15px`,
                                        marginLeft: '2px',
                                        width: screen === 3 ? '5px' : '4px'
                                      }}

                                      alt="Left Handle"
                                    />
                                  ) : (
                                    <img
                                      src={Handle}
                                      style={{
                                        float: 'right',
                                        marginTop: `calc(${(((boxHeight - topnewHeights - bottomnewHeights) * screen / mCalc) / 2)}vw - 15px`,
                                        marginRight: '2px',
                                        width: screen === 3 ? '5px' : '4px'
                                      }}

                                      alt="Right Handle"
                                    />

                                  ))}

                                  {mesh === 'yes' && boxNames[index] === 'Shutter' && boxHandle[index] === 'left' && (
                                    <img src={meshimg} style={{ width: screen === 3 ? '40px' : '20px', float: 'left', marginLeft: screen === 3 ? boxHandle[index] === 'left' ? '-15px' : '0px' : boxHandle[index] === 'left' ? '-10px' : 'px', marginTop: screen === 3 ? `calc(${((boxHeight - topnewHeights - bottomnewHeights) * screen / mCalc)}vw - ${boxTop === 'on' ? '72px' : '67px'} )` : `calc(${((boxHeight - topnewHeights - bottomnewHeights) * screen / mCalc)}vw - 45px)` }} />
                                  )}

                                  {mesh === 'yes' && boxNames[index] === 'Shutter' && boxHandle[index] === 'right' && (
                                    <img src={meshimg} style={{ width: screen === 3 ? '40px' : '20px', float: 'left', marginLeft: screen === 3 ? boxHandle[index] === 'right' ? '-1px' : '0px' : boxHandle[index] === 'right' ? '0px' : 'px', marginTop: screen === 3 ? `calc(${((boxHeight - topnewHeights - bottomnewHeights) * screen / mCalc)}vw - ${boxTop === 'on' ? '72px' : '67px'} )` : `calc(${((boxHeight - topnewHeights - bottomnewHeights) * screen / mCalc)}vw - 45px)` }} />
                                  )}

                                  {boxNames[index] === 'Shutter' && grill === 'yes' && boxes}

                                </div>

                              ))}

                            </div>






                            {numSecondInnerBoxes === 0 ? (
                              <div className="expanding-arrow-line" style={{ float: 'left', marginLeft: '-5px', width: ((boxWidth / numFrames) * screen / mCalc) + 'vw', top: boxHeight * screen / mCalc + 'vw', }} >
                                <div className="arrow-left"></div>
                                <div className="line" align="center">
                                  <div style={{ marginTop: '-8px', background: 'white', maxWidth: '60px', textAlign: 'center' }}><p>{typeof (boxWidth / numFrames) === 'number' ? (boxWidth / numFrames).toFixed(2) : (boxWidth / numFrames)}</p></div>
                                </div>
                                <div className="arrow-right"></div>
                              </div>
                            ) : (
                              <div className="expanding-arrow-line" style={{ float: 'left', marginLeft: '-5px', width: ((boxWidth / numFrames) * screen / mCalc) + 'vw', bottom: '-10px', top: '40px', }} >
                                <div className="arrow-left"></div>
                                <div className="line" align="center">
                                  <div style={{ marginTop: '-8px', background: 'white', maxWidth: '60px', textAlign: 'center' }}><p>{typeof (boxWidth / numFrames) === 'number' ? (boxWidth / numFrames).toFixed(2) : (boxWidth / numFrames)}</p></div>
                                </div>
                                <div className="arrow-right"></div>
                              </div>
                            )}


                          </div>
                        ))}
                      </div>

                      <div style={{ width: '45px', float: 'left', paddingLeft: '15px', textAlign: 'left' }}>
                      {boxTop === 'on' &&
                      <>
                        <div className="expanding-arrow-line" style={{ float: 'left', height: `calc(${topnewHeights * screen / mCalc}vw + 10px)`, top: '0', position: 'relative' }}>
                          <div className="arrow-up" style={{ borderBottom: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', top: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
                          <div className="line" style={{ width: '1px', height: '100%', marginLeft: '0px', position: 'absolute', top: '0' }}>
                            <div style={{ marginLeft: '-10px', marginTop: topnewHeights * (screen / mCalc / 2) + 'vw', background: 'white', maxWidth: '60px', textAlign: 'left' }}><p style={{ width: '50px' }}>{Number(topnewHeights).toFixed(2)}</p></div>
                          </div>
                          <div className="arrow-down" style={{ borderTop: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', bottom: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
                        </div>

                        <div className="expanding-arrow-line" style={{ float: 'left', height: `calc(${(boxHeight - topnewHeights - bottomnewHeights) * screen / mCalc}vw - ${boxTop === 'on' ? '30px' : '15px'})`, top: `calc(${topnewHeights * screen / mCalc}vw + ${boxTop === 'on' ? '15px' : '0px'})`, position: 'relative' }}>
                          <div className="arrow-up" style={{ borderBottom: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', top: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
                            <div className="line" style={{ width: '1px', height: '100%', marginLeft: '0px', position: 'absolute', top: '0' }}>
                            <div style={{ marginLeft: '-10px', marginTop: (boxHeight - topnewHeights - bottomnewHeights) * ((screen / mCalc / 2) - (screen / mCalc / 8)) + 'vw', background: 'white', maxWidth: '60px', textAlign: 'left' }}><p style={{ width: '50px' }}>{Number(boxHeight - topnewHeights - bottomnewHeights).toFixed(2)}</p></div>
                            </div>
                          <div className="arrow-down" style={{ borderTop: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', bottom: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
                        </div>
                      </>
                      }
                      
                      {boxBottom === 'on' &&
                      <>
                        <div className="expanding-arrow-line" style={{ float: 'left', height: `calc(${(boxHeight - topnewHeights - bottomnewHeights) * screen / mCalc}vw - ${boxTop === 'on' ? '30px' : '15px'})`, top: `calc(${topnewHeights * screen / mCalc}vw + ${boxTop === 'on' ? '15px' : '0px'})`, position: 'relative' }}>
                        <div className="arrow-up" style={{ borderBottom: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', top: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
                          <div className="line" style={{ width: '1px', height: '100%', marginLeft: '0px', position: 'absolute', top: '0' }}>
                          <div style={{ marginLeft: '-10px', marginTop: (boxHeight - topnewHeights - bottomnewHeights) * ((screen / mCalc / 2) - (screen / mCalc / 8)) + 'vw', background: 'white', maxWidth: '60px', textAlign: 'left' }}><p style={{ width: '50px' }}>{Number(boxHeight - topnewHeights - bottomnewHeights).toFixed(2)}</p></div>
                          </div>
                        <div className="arrow-down" style={{ borderTop: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', bottom: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
                        </div>

                        <div className="expanding-arrow-line" style={{ float: 'left', height: `calc(${(bottomnewHeights) * screen / mCalc}vw + 5px)`, top: `calc(${(boxHeight - bottomnewHeights) * screen / mCalc}vw - ${boxTop === 'on' ? '10px' : '10px'})`, position: 'relative' }}>
                          <div className="arrow-up" style={{ borderBottom: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', top: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
                          <div className="line" style={{ width: '1px', height: '100%', marginLeft: '0px', position: 'absolute', top: '0' }}>
                            <div style={{ marginLeft: '-10px', marginTop: (bottomnewHeights) * ((screen / mCalc / 2) - (screen / mCalc / 8)) + 'vw', background: 'white', maxWidth: '60px', textAlign: 'left' }}><p style={{ width: '50px' }}>{Number(bottomnewHeights).toFixed(2)}</p></div>
                          </div>
                          <div className="arrow-down" style={{ borderTop: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', bottom: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
                        </div>
                      </>
                      }

                    </div>

                    <div style={{ width: '30px', float: 'left', paddingLeft: boxBottom === 'on' || boxTop === 'on' ?'0px' : '15px', textAlign: 'left' }}>
                      <div className="expanding-arrow-line" style={{ float: 'left', height: `calc(${boxHeight * screen / mCalc}vw)`, top: '0', position: 'relative' }}>
                        <div className="arrow-up" style={{ borderBottom: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', top: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
                        <div className="line" style={{ width: '1px', height: '100%', marginLeft: '0px', position: 'absolute', top: '0' }}>
                          <div style={{ marginLeft: '-10px', marginTop: boxHeight * (screen / mCalc / 2) + 'vw', background: 'white', maxWidth: '60px', textAlign: 'left' }}><p style={{ width: '50px' }}>{Number(boxHeight).toFixed(2)}</p></div>
                        </div>
                        <div className="arrow-down" style={{ borderTop: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', bottom: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
                      </div>
                    </div>

                    </div>

                  </div>
                </div>

              </div>

              {numSecondInnerBoxes === 0 ? (<div></div>) : (
                <>
                  <div class="col-md-12 col-xxl-2 col-xl-2">
                    <div class="card">
                      <div class="card-body">
                        <div class="d-flex align-items-center">
                          <div class="flex-grow-1 ms-3">
                            <h6 class="mb-0">Area</h6>
                          </div>
                        </div>
                        <div class="bg-body p-3 mt-3 rounded">
                          <div class="mt-3 row align-items-center">
                            <div class="col-12">
                              <h6 class="mb-1">{frameArea * qty} sqft</h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-12 col-xxl-2 col-xl-2">
                    <div class="card">
                      <div class="card-body">
                        <div class="d-flex align-items-center">
                          <div class="flex-grow-1 ms-3">
                            <h6 class="mb-0">Shutters</h6>
                          </div>
                        </div>
                        <div class="bg-body p-3 mt-3 rounded">
                          <div class="mt-3 row align-items-center">
                            <div class="col-12">
                              <h6 class="mb-1">{shutterCount * numFrames}</h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-12 col-xxl-2 col-xl-2">
                    <div class="card">
                      <div class="card-body">
                        <div class="d-flex align-items-center">
                          <div class="flex-grow-1 ms-3">
                            <h6 class="mb-0">Fixed</h6>
                          </div>
                        </div>
                        <div class="bg-body p-3 mt-3 rounded">
                          <div class="mt-3 row align-items-center">
                            <div class="col-12">
                              <h6 class="mb-1">{fixedShutters}</h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-12 col-xxl-2 col-xl-2">
                    <div class="card">
                      <div class="card-body">
                        <div class="d-flex align-items-center">
                          <div class="flex-grow-1 ms-3">
                            <h6 class="mb-0">Mullions</h6>
                          </div>
                        </div>
                        <div class="bg-body p-3 mt-3 rounded">
                          <div class="mt-3 row align-items-center">
                            <div class="col-12">
                              <h6 class="mb-1">{newHeights === 0 ? 0 : 1}</h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-12 col-xxl-2 col-xl-2">
                    <div class="card">
                      <div class="card-body">
                        <div class="d-flex align-items-center">
                          <div class="flex-grow-1 ms-3">
                            <h6 class="mb-0">Total</h6>
                          </div>
                        </div>
                        <div class="bg-body p-3 mt-3 rounded">
                          <div class="mt-3 row align-items-center">
                            <div class="col-12">
                              <h6 class="mb-1">₹ {numeral(grandTotal * qty).format('0,0.00')}</h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-12 col-xxl-2 col-xl-2">
                    <div class="card">
                      <div class="card-body">
                        <div class="d-flex align-items-center">
                          <div class="flex-grow-1 ms-3">
                            <h6 class="mb-0">Rate/Sqft</h6>
                          </div>
                        </div>
                        <div class="bg-body p-3 mt-3 rounded">
                          <div class="mt-3 row align-items-center">
                            <div class="col-12">
                              <h6 class="mb-1">₹ {numeral(grandTotal / frameArea).format('0,0.00')}</h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>




                  <div class="col-md-12 col-xxl-12 col-xl-12">
                    <div class="card">
                      <div class="card-header">
                        <h5>Profile Cost</h5>
                      </div>
                      <div class="card-body">
                        <table id="left-right-fix" class="table stripe row-border order-column">
                          <thead>
                            <tr>
                              <th>Product Description</th>
                              <th width="10%">Req. Qty (Feet)</th>
                              <th width="10%">Kg/Feet</th>
                              <th width="10%">Req. Qty (Kg)</th>
                              <th width="10%">Rate/Kg</th>
                              <th width="10%">Amount</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Track Outer Frame</td>
                              <td>{numeral(Frame()).format('0,0.00')}</td>
                              <td>{numeral(values.frame.wpf).format('0,0.00')}</td>
                              <td>{numeral(values.frame.rw).format('0,0.00')}</td>
                              <td>{numeral(values.frame.rpf).format('0,0.00')}</td>
                              <td>{numeral(values.frame.amount).format('0,0.00')}</td>
                            </tr>
                            <tr>
                              <td>Shutter Frame</td>
                              <td>{numeral(Shutter()).format('0,0.00')}</td>
                              <td>{numeral(values.shutter.wpf).format('0,0.00')}</td>
                              <td>{numeral(values.shutter.rw).format('0,0.00')}</td>
                              <td>{numeral(values.shutter.rpf).format('0,0.00')}</td>
                              <td>{numeral(values.shutter.amount).format('0,0.00')}</td>
                            </tr>
                            <tr>
                              <td>InterLock</td>
                              <td>{numeral(interLock()).format('0,0.00')}</td>
                              <td>{numeral(values.interLock.wpf).format('0,0.00')}</td>
                              <td>{numeral(values.interLock.rw).format('0,0.00')}</td>
                              <td>{numeral(values.interLock.rpf).format('0,0.00')}</td>
                              <td>{numeral(values.interLock.amount).format('0,0.00')}</td>
                            </tr>
                            <tr>
                              <td>Beeding</td>
                              <td>{numeral(Beeding()).format('0,0.00')}</td>
                              <td>{numeral(values.beeding.wpf).format('0,0.00')}</td>
                              <td>{numeral(values.beeding.rw).format('0,0.00')}</td>
                              <td>{numeral(values.beeding.rpf).format('0,0.00')}</td>
                              <td>{numeral(values.beeding.amount).format('0,0.00')}</td>
                            </tr>
                            <tr>
                              <td>Frame Gasket</td>
                              <td>{numeral(frameGasket()).format('0,0.00')}</td>
                              <td>{numeral(values.frameGasket.wpf).format('0,0.00')}</td>
                              <td>{numeral(values.frameGasket.rw).format('0,0.00')}</td>
                              <td>{numeral(values.frameGasket.rpf).format('0,0.00')}</td>
                              <td>{numeral(values.frameGasket.amount).format('0,0.00')}</td>
                            </tr>
                            <tr>
                              <td>Shutter Gasket</td>
                              <td>{numeral(shutterGasket()).format('0,0.00')}</td>
                              <td>{numeral(values.shutterGasket.wpf).format('0,0.00')}</td>
                              <td>{numeral(values.shutterGasket.rw).format('0,0.00')}</td>
                              <td>{numeral(values.shutterGasket.rpf).format('0,0.00')}</td>
                              <td>{numeral(values.shutterGasket.amount).format('0,0.00')}</td>
                            </tr>
                            <tr>
                              <td>Beeding Gasket</td>
                              <td>{numeral(beedingGasket()).format('0,0.00')}</td>
                              <td>{numeral(values.beedingGasket.wpf).format('0,0.00')}</td>
                              <td>{numeral(values.beedingGasket.rw).format('0,0.00')}</td>
                              <td>{numeral(values.beedingGasket.rpf).format('0,0.00')}</td>
                              <td>{numeral(values.beedingGasket.amount).format('0,0.00')}</td>
                            </tr>

                            {panelType === 1 && (
                              <tr>
                                <td>Panel</td>
                                <td>{numeral(Panel()).format('0,0.00')}</td>
                                <td>{numeral(values.panel.wpf).format('0,0.00')}</td>
                                <td>{numeral(values.panel.rw).format('0,0.00')}</td>
                                <td>{numeral(values.panel.rpf).format('0,0.00')}</td>
                                <td>{numeral(values.panel.amount).format('0,0.00')}</td>
                              </tr>
                            )}

                            {numFrames === 1 ? (<></>) : (
                              <tr>
                                <td>Cupler Joint/Square Joint </td>
                                <td>{numeral(cuplerJoint()).format('0,0.00')}</td>
                                <td>{numeral(values.cupler.wpf).format('0,0.00')}</td>
                                <td>{numeral(values.cupler.rw).format('0,0.00')}</td>
                                <td>{numeral(values.cupler.rpf).format('0,0.00')}</td>
                                <td>{numeral(values.cupler.amount).format('0,0.00')}</td>
                              </tr>
                            )}

                            <tr>
                              <td colspan="5">Total</td>
                              <td>{numeral(frameTotal).format('0,0.00')}</td>
                            </tr>
                            <tr>
                              <th colspan="5">Wastage ( {data.wastage}% )</th>
                              <th>{numeral(frameTotal + wastage).format('0,0.00')}</th>
                            </tr>
                            <tr>
                              <th colspan="5">Profile Cost/Sqft</th>
                              <th>{numeral((frameTotal + wastage) / frameArea).format('0,0.00')}</th>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <hr />
                      <div class="card-header">
                        <h5>Hardware Cost</h5>
                      </div>
                      <div class="card-body">
                        <table id="left-right-fix" class="table stripe row-border order-column">
                          <thead>
                            <tr>
                              <th>Product Description</th>
                              <th width="10%">Req. Qty </th>
                              <th width="10%">Price</th>
                              <th width="10%">Amount</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Steel Frame</td>
                              <td>{numeral(values.steelFrame.qty).format('0,0.00')}</td>
                              <td>{numeral(values.steelFrame.price).format('0,0.00')}</td>
                              <td>{numeral(values.steelFrame.amount).format('0,0.00')}</td>
                            </tr>
                            <tr>
                              <td>Steel Shutter</td>
                              <td>{numeral(values.steelShutter.qty).format('0,0.00')}</td>
                              <td>{numeral(values.steelShutter.price).format('0,0.00')}</td>
                              <td>{numeral(values.steelShutter.amount).format('0,0.00')}</td>
                            </tr>
                            <tr>
                              <td>Steel Mullion</td>
                              <td>{numeral(values.steelMullion.qty * qty).format('0,0.00')}</td>
                              <td>{numeral(values.steelMullion.price * qty).format('0,0.00')}</td>
                              <td>{numeral(values.steelMullion.amount * qty).format('0,0.00')}</td>
                            </tr>

                            <tr>
                              <td>Mullion Clamp</td>

                              <td>{numeral(values.mullionclamp.qty * qty).format('0,0.00')}</td>
                              <td>{numeral(values.mullionclamp.price * qty).format('0,0.00')}</td>
                              <td>{numeral(values.mullionclamp.amount * qty).format('0,0.00')}</td>
                            </tr>
                            <tr>
                              <td>Espag with SS Receiver</td>
                              <td>{numeral(values.espag.qty * qty).format('0,0.00')}</td>
                              <td>{numeral(values.espag.price * qty).format('0,0.00')}</td>
                              <td>{numeral(values.espag.amount * qty).format('0,0.00')}</td>
                            </tr>
                            <tr>
                              <td>Espag with Multi Lock</td>
                              <td>{numeral(values.espagm.qty * qty).format('0,0.00')}</td>
                              <td>{numeral(values.espagm.price * qty).format('0,0.00')}</td>
                              <td>{numeral(values.espagm.amount * qty).format('0,0.00')}</td>
                            </tr>
                            <tr>
                              <td>Friction Stay</td>

                              <td>{numeral(values.frictionstay.qty * qty).format('0,0.00')}</td>
                              <td>{numeral(values.frictionstay.price * qty).format('0,0.00')}</td>
                              <td>{numeral(values.frictionstay.amount * qty).format('0,0.00')}</td>
                            </tr>
                            
                            <tr>
                              <td>Adjust Roller</td>
                              <td>{numeral(values.adjroll.qty * qty).format('0,0.00')}</td>
                              <td>{numeral(values.adjroll.price * qty).format('0,0.00')}</td>
                              <td>{numeral(values.adjroll.amount * qty).format('0,0.00')}</td>
                            </tr>
                            <tr>
                              <td>Aluminium Track</td>
                              <td>{numeral(values.alTrack.qty).format('0,0.00')}</td>
                              <td>{numeral(values.alTrack.price).format('0,0.00')}</td>
                              <td>{numeral(values.alTrack.amount).format('0,0.00')}</td>
                            </tr>
                            <tr>
                              <td>Bump Arrester</td>
                              <td>{numeral(values.bArrester.qty).format('0,0.00')}</td>
                              <td>{numeral(values.bArrester.price).format('0,0.00')}</td>
                              <td>{numeral(values.bArrester.amount).format('0,0.00')}</td>
                            </tr>
                            <tr>
                              <td>Jump Stopper</td>
                              <td>{numeral(values.jStopper.qty).format('0,0.00')}</td>
                              <td>{numeral(values.jStopper.price).format('0,0.00')}</td>
                              <td>{numeral(values.jStopper.amount).format('0,0.00')}</td>
                            </tr>
                            <tr>
                              <td>Dust Arrester</td>
                              <td>{numeral(values.dArrester.qty).format('0,0.00')}</td>
                              <td>{numeral(values.dArrester.price).format('0,0.00')}</td>
                              <td>{numeral(values.dArrester.amount).format('0,0.00')}</td>
                            </tr>
                            <tr>
                              <td>Grove Roller</td>
                              <td>{numeral(values.gRoller.qty).format('0,0.00')}</td>
                              <td>{numeral(values.gRoller.price).format('0,0.00')}</td>
                              <td>{numeral(values.gRoller.amount).format('0,0.00')}</td>
                            </tr>
                            <tr>
                              <td>Touch Lock</td>
                              <td>{numeral(values.touchLock.qty).format('0,0.00')}</td>
                              <td>{numeral(values.touchLock.price).format('0,0.00')}</td>
                              <td>{numeral(values.touchLock.amount).format('0,0.00')}</td>
                            </tr>
                            {grill === 'yes' && (
                              <tr>
                                <td>Grill</td>
                                <td>{numeral(values.grill.qty * qty).format('0,0.00')}</td>
                                <td>{numeral(values.grill.price * qty).format('0,0.00')}</td>
                                <td>{numeral(values.grill.amount * qty).format('0,0.00')}</td>
                              </tr>
                            )}
                            <tr>
                              <td>Screws/Misc</td>
                              <td>{numeral(values.screws.qty).format('0,0.00')}</td>
                              <td>{numeral(values.screws.price).format('0,0.00')}</td>
                              <td>{numeral(values.screws.amount).format('0,0.00')}</td>
                            </tr>
                            <tr>
                              <th colspan="3">Total</th>
                              <th>{numeral(hardTotal).format('0,0.00')}</th>
                            </tr>
                            <tr>
                              <th colspan="3">Hardware Cost/Sqft</th>
                              <th>{numeral(hardTotal / frameArea).format('0,0.00')}</th>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      {userType != '3' && (<>
                        <hr />

                        <div class="card-header ">
                          <h5>Margin Cost</h5>
                        </div>
                        <div class="card-body">
                          <table id="left-right-fix" class="table stripe row-border order-column">
                            <thead>
                              <tr>
                                <th>Product Description</th>
                                <th width="10%">Req. Qty </th>
                                <th width="10%">Price</th>
                                <th width="10%">Amount</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>Margin</td>
                                <td>{numeral(values.margin.qty).format('0,0.00')}</td>
                                <td>{numeral(values.margin.price).format('0,0.00')}</td>
                                <td>{numeral(values.margin.amount).format('0,0.00')}</td>
                              </tr>
                              <tr>
                                <th colspan="3">Total</th>
                                <th>{numeral(marginTotal).format('0,0.00')}</th>
                              </tr>
                              <tr>
                                <th colspan="3">Margin Cost/Sqft</th>
                                <th>{numeral(marginTotal / frameArea).format('0,0.00')}</th>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <hr />

                        <div class="card-header">
                          <h5>Production Cost</h5>
                        </div>
                        <div class="card-body">
                          <table id="left-right-fix" class="table stripe row-border order-column">
                            <thead>
                              <tr>
                                <th>Product Description</th>
                                <th width="10%">Req. Qty </th>
                                <th width="10%">Price</th>
                                <th width="10%">Amount</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>Markting OH</td>
                                <td>{numeral(values.marketing.qty).format('0,0.00')}</td>
                                <td>{numeral(values.marketing.price).format('0,0.00')}</td>
                                <td>{numeral(values.marketing.amount).format('0,0.00')}</td>
                              </tr>
                              <tr>
                                <td>Transport</td>
                                <td>{numeral(values.transport.qty).format('0,0.00')}</td>
                                <td>{numeral(values.transport.price).format('0,0.00')}</td>
                                <td>{numeral(values.transport.amount).format('0,0.00')}</td>
                              </tr>
                              <tr>
                                <td>Labour (Factory)</td>
                                <td>{numeral(values.labourF.qty).format('0,0.00')}</td>
                                <td>{numeral(values.labourF.price).format('0,0.00')}</td>
                                <td>{numeral(values.labourF.amount).format('0,0.00')}</td>
                              </tr>
                              <tr>
                                <td>Labour (Site)</td>
                                <td>{numeral(values.labourS.qty).format('0,0.00')}</td>
                                <td>{numeral(values.labourS.price).format('0,0.00')}</td>
                                <td>{numeral(values.labourS.amount).format('0,0.00')}</td>
                              </tr>
                              {panelType === 2 && (
                                <tr>
                                  <td>Board</td>
                                  <td>{numeral(values.board.qty).format('0,0.00')}</td>
                                  <td>{numeral(values.board.price).format('0,0.00')}</td>
                                  <td>{numeral(values.board.amount).format('0,0.00')}</td>
                                </tr>
                              )}
                              {panelType === 3 && (
                                <tr>
                                  <td>Glass</td>
                                  <td>{numeral(values.glass.qty).format('0,0.00')}</td>
                                  <td>{numeral(values.glass.price).format('0,0.00')}</td>
                                  <td>{numeral(values.glass.amount).format('0,0.00')}</td>
                                </tr>
                              )}
                              <tr>
                                <th colspan="3">Total</th>
                                <th>{numeral(prodTotal).format('0,0.00')}</th>
                              </tr>
                              <tr>
                                <th colspan="3">Production Cost/Sqft</th>
                                <th>{numeral(prodTotal / frameArea).format('0,0.00')}</th>
                              </tr>

                            </tbody>
                          </table>
                        </div>
                      </>
                      )}
                    </div>
                  </div>




                </>
              )}


            </div>
          </div>
        </div>


      )}


      {item === 'Ventilator' && (
        <div class="pc-container">
          <div class="pc-content">

            <div class="row">






              <div class="col-md-12 col-xxl-12 col-xl-12" >
                <div class="card" style={{ background: '#fff' }}>
                  <div class="card-body mb-5">
                    {boxHeight > 1 && boxWidth > 1 && (

                      fan === 'on' ? (
                        <>

                          <div style={{ float: 'left', width: boxWidth * screen + 'vw', height: boxHeight * screen + 'vw', border: '1px solid #333' }}>

                            {fanTop === 'Top' && fanLeft === 'Left' && divider === 'Vertical' && switchs === 'on' && (
                              <div style={{ float: 'left', height: `calc(${boxHeight * screen}vw - 10px)`, width: `calc(${boxWidth * screen}vw - 10px)`, margin: '4px', border: '1px solid #333' }}>
                                <img src={Fan} style={{ float: 'left', width: (boxWidth > 1.25 ? .85 : .55) * screen + 'vw', marginLeft: '4px', marginTop: '4px' }} />
                                <div style={{ float: 'left', height: `calc(${(boxHeight) * screen}vw - 20px)`, width: `calc(${(boxWidth - (boxWidth > 1.25 ? .85 : .55)) * screen}vw - 26px)`, margin: '5px', border: '1px solid #333', backgroundImage: `repeating-linear-gradient(lightblue, white ${(6 / boxHeight) * screen}%, lightblue ${(6 / boxHeight) * screen}%)` }}></div>
                                <div style={{ float: 'left', height: `calc(${(boxHeight - (boxWidth > 1.25 ? .85 : .55)) * screen}vw - 25px)`, width: `calc(${(boxWidth > 1.25 ? .85 : .55) * screen}vw - 0px)`, margin: '4px', marginTop: `calc(-${(boxHeight - (boxWidth > 1.25 ? .85 : .55)) * screen}vw + 20px)`, border: '1px solid #333', background: 'lightblue' }}></div>
                              </div>
                            )}

                            {fanTop === 'Bottom' && fanLeft === 'Left' && divider === 'Vertical' && switchs === 'on' && (
                              <div style={{ float: 'left', height: `calc(${boxHeight * screen}vw - 10px)`, width: `calc(${boxWidth * screen}vw - 10px)`, margin: '4px', border: '1px solid #333' }}>
                                <div style={{ float: 'left', height: `calc(${(boxHeight - (boxWidth > 1.25 ? .85 : .55)) * screen}vw - 25px)`, width: `calc(${(boxWidth > 1.25 ? .85 : .55) * screen}vw - 0px)`, margin: '4px', marginTop: '5px', border: '1px solid #333', background: 'lightblue' }}></div>
                                <div style={{ float: 'right', height: `calc(${(boxHeight) * screen}vw - 20px)`, width: `calc(${(boxWidth - (boxWidth > 1.25 ? .85 : .55)) * screen}vw - 26px)`, margin: '5px', marginTop: `calc(-${(boxHeight - (boxWidth > 1.25 ? .85 : .55)) * screen}vw + 20px)`, border: '1px solid #333', backgroundImage: `repeating-linear-gradient(lightblue, white ${(6 / boxHeight) * screen}%, lightblue ${(6 / boxHeight) * screen}%)` }}></div>
                                <img src={Fan} style={{ float: 'left', width: (boxWidth > 1.25 ? .85 : .55) * screen + 'vw', marginLeft: '4px', marginBottom: '4px' }} />
                              </div>
                            )}

                            {fanTop === 'Top' && fanLeft === 'Right' && divider === 'Vertical' && switchs === 'on' && (
                              <div style={{ float: 'left', height: `calc(${boxHeight * screen}vw - 10px)`, width: `calc(${boxWidth * screen}vw - 10px)`, margin: '4px', border: '1px solid #333' }}>
                                <img src={Fan} style={{ float: 'right', width: (boxWidth > 1.25 ? .85 : .55) * screen + 'vw', marginRight: '4px', marginTop: '4px' }} />
                                <div style={{ float: 'left', height: `calc(${(boxHeight) * screen}vw - 20px)`, width: `calc(${(boxWidth - (boxWidth > 1.25 ? .85 : .55)) * screen}vw - 26px)`, margin: '5px', border: '1px solid #333', backgroundImage: `repeating-linear-gradient(lightblue, white ${(6 / boxHeight) * screen}%, lightblue ${(6 / boxHeight) * screen}%)` }}></div>
                                <div style={{ float: 'right', height: `calc(${(boxHeight - (boxWidth > 1.25 ? .85 : .55)) * screen}vw - 25px)`, width: `calc(${(boxWidth > 1.25 ? .85 : .55) * screen}vw - 0px)`, margin: '4px', marginTop: `calc(-${(boxHeight - (boxWidth > 1.25 ? .85 : .55)) * screen}vw + 20px)`, border: '1px solid #333', background: 'lightblue' }}></div>
                              </div>
                            )}

                            {fanTop === 'Bottom' && fanLeft === 'Right' && divider === 'Vertical' && switchs === 'on' && (
                              <div style={{ float: 'left', height: `calc(${boxHeight * screen}vw - 10px)`, width: `calc(${boxWidth * screen}vw - 10px)`, margin: '4px', border: '1px solid #333' }}>
                                <div style={{ float: 'right', height: `calc(${(boxHeight - (boxWidth > 1.25 ? .85 : .55)) * screen}vw - 25px)`, width: `calc(${(boxWidth > 1.25 ? .85 : .55) * screen}vw - 0px)`, margin: '4px', marginTop: '5px', border: '1px solid #333', background: 'lightblue' }}></div>
                                <div style={{ float: 'left', height: `calc(${(boxHeight) * screen}vw - 20px)`, width: `calc(${(boxWidth - (boxWidth > 1.25 ? .85 : .55)) * screen}vw - 26px)`, margin: '5px', marginTop: `calc(-${(boxHeight - (boxWidth > 1.25 ? .85 : .55)) * screen}vw + 20px)`, border: '1px solid #333', backgroundImage: `repeating-linear-gradient(lightblue, white ${(6 / boxHeight) * screen}%, lightblue ${(6 / boxHeight) * screen}%)` }}></div>
                                <img src={Fan} style={{ float: 'right', width: (boxWidth > 1.25 ? .85 : .55) * screen + 'vw', marginRight: '4px', marginBottom: '4px' }} />
                              </div>
                            )}

                            {fanTop === 'Top' && fanLeft === 'Left' && divider === 'Vertical' && switchs === 'off' && (
                              <div style={{ float: 'left', height: `calc(${boxHeight * screen}vw - 10px)`, width: `calc(${boxWidth * screen}vw - 10px)`, margin: '4px', border: '1px solid #333' }}>
                                <img src={Fan} style={{ float: 'left', width: (boxWidth > 1.25 ? .85 : .55) * screen + 'vw', marginLeft: '4px', marginTop: '4px' }} />
                                <div style={{ float: 'left', height: `calc(${(boxHeight) * screen}vw - 20px)`, width: `calc(${(boxWidth - (boxWidth > 1.25 ? .85 : .55)) * screen}vw - 26px)`, margin: '5px', border: '1px solid #333', background: 'lightblue' }}></div>
                                <div style={{ float: 'left', height: `calc(${(boxHeight - (boxWidth > 1.25 ? .85 : .55)) * screen}vw - 25px)`, width: `calc(${(boxWidth > 1.25 ? .85 : .55) * screen}vw - 0px)`, margin: '4px', marginTop: `calc(-${(boxHeight - (boxWidth > 1.25 ? .85 : .55)) * screen}vw + 20px)`, border: '1px solid #333', backgroundImage: `repeating-linear-gradient(lightblue, white ${(10 / boxHeight) * screen}%, lightblue ${(10 / boxHeight) * screen}%)` }}></div>
                              </div>
                            )}

                            {fanTop === 'Bottom' && fanLeft === 'Left' && divider === 'Vertical' && switchs === 'off' && (
                              <div style={{ float: 'left', height: `calc(${boxHeight * screen}vw - 10px)`, width: `calc(${boxWidth * screen}vw - 10px)`, margin: '4px', border: '1px solid #333' }}>
                                <div style={{ float: 'left', height: `calc(${(boxHeight - (boxWidth > 1.25 ? .85 : .55)) * screen}vw - 25px)`, width: `calc(${(boxWidth > 1.25 ? .85 : .55) * screen}vw - 0px)`, margin: '4px', marginTop: '5px', border: '1px solid #333', backgroundImage: `repeating-linear-gradient(lightblue, white ${(10 / boxHeight) * screen}%, lightblue ${(10 / boxHeight) * screen}%)` }}></div>
                                <div style={{ float: 'right', height: `calc(${(boxHeight) * screen}vw - 20px)`, width: `calc(${(boxWidth - (boxWidth > 1.25 ? .85 : .55)) * screen}vw - 26px)`, margin: '5px', marginTop: `calc(-${(boxHeight - (boxWidth > 1.25 ? .85 : .55)) * screen}vw + 20px)`, border: '1px solid #333', background: 'lightblue' }}></div>
                                <img src={Fan} style={{ float: 'left', width: (boxWidth > 1.25 ? .85 : .55) * screen + 'vw', marginLeft: '4px', marginBottom: '4px' }} />
                              </div>
                            )}

                            {fanTop === 'Top' && fanLeft === 'Right' && divider === 'Vertical' && switchs === 'off' && (
                              <div style={{ float: 'left', height: `calc(${boxHeight * screen}vw - 10px)`, width: `calc(${boxWidth * screen}vw - 10px)`, margin: '4px', border: '1px solid #333' }}>
                                <img src={Fan} style={{ float: 'right', width: (boxWidth > 1.25 ? .85 : .55) * screen + 'vw', marginRight: '4px', marginTop: '4px' }} />
                                <div style={{ float: 'left', height: `calc(${(boxHeight) * screen}vw - 20px)`, width: `calc(${(boxWidth - (boxWidth > 1.25 ? .85 : .55)) * screen}vw - 26px)`, margin: '5px', border: '1px solid #333', background: 'lightblue' }}></div>
                                <div style={{ float: 'right', height: `calc(${(boxHeight - (boxWidth > 1.25 ? .85 : .55)) * screen}vw - 25px)`, width: `calc(${(boxWidth > 1.25 ? .85 : .55) * screen}vw - 0px)`, margin: '4px', marginTop: `calc(-${(boxHeight - (boxWidth > 1.25 ? .85 : .55)) * screen}vw + 20px)`, border: '1px solid #333', backgroundImage: `repeating-linear-gradient(lightblue, white ${(10 / boxHeight) * screen}%, lightblue ${(10 / boxHeight) * screen}%)` }}></div>
                              </div>
                            )}

                            {fanTop === 'Bottom' && fanLeft === 'Right' && divider === 'Vertical' && switchs === 'off' && (
                              <div style={{ float: 'left', height: `calc(${boxHeight * screen}vw - 10px)`, width: `calc(${boxWidth * screen}vw - 10px)`, margin: '4px', border: '1px solid #333' }}>
                                <div style={{ float: 'right', height: `calc(${(boxHeight - (boxWidth > 1.25 ? .85 : .55)) * screen}vw - 25px)`, width: `calc(${(boxWidth > 1.25 ? .85 : .55) * screen}vw - 0px)`, margin: '4px', marginTop: '5px', border: '1px solid #333', backgroundImage: `repeating-linear-gradient(lightblue, white ${(10 / boxHeight) * screen}%, lightblue ${(10 / boxHeight) * screen}%)` }}></div>
                                <div style={{ float: 'left', height: `calc(${(boxHeight) * screen}vw - 20px)`, width: `calc(${(boxWidth - (boxWidth > 1.25 ? .85 : .55)) * screen}vw - 26px)`, margin: '5px', marginTop: `calc(-${(boxHeight - (boxWidth > 1.25 ? .85 : .55)) * screen}vw + 20px)`, border: '1px solid #333', background: 'lightblue' }}></div>
                                <img src={Fan} style={{ float: 'right', width: (boxWidth > 1.25 ? .85 : .55) * screen + 'vw', marginRight: '4px', marginBottom: '4px' }} />
                              </div>
                            )}

                            {fanTop === 'Top' && fanLeft === 'Left' && divider === 'Horizontal' && switchs === 'on' && (
                              <div style={{ float: 'left', height: `calc(${boxHeight * screen}vw - 10px)`, width: `calc(${boxWidth * screen}vw - 10px)`, margin: '4px', border: '1px solid #333' }}>
                                <img src={Fan} style={{ float: 'left', width: (boxWidth > 1.25 ? .85 : .55) * screen + 'vw', marginLeft: '4px', marginTop: '4px' }} />
                                <div style={{ float: 'right', height: `calc(${(boxWidth > 1.25 ? .85 : .55) * screen}vw - 0px)`, width: `calc(${(boxWidth - (boxWidth > 1.25 ? .85 : .55)) * screen}vw - 26px)`, margin: '5px', border: '1px solid #333', backgroundImage: `repeating-linear-gradient(lightblue, white ${(10 / boxHeight) * screen}%, lightblue ${(10 / boxHeight) * screen}%)` }}></div>
                                <div style={{ float: 'left', height: `calc(${(boxHeight - (boxWidth > 1.25 ? .85 : .55)) * screen}vw - 25px)`, width: `calc(${(boxWidth) * screen}vw - 20px)`, margin: '4px', marginTop: '0px', border: '1px solid #333', background: 'lightblue' }}></div>
                              </div>
                            )}

                            {fanTop === 'Bottom' && fanLeft === 'Left' && divider === 'Horizontal' && switchs === 'on' && (
                              <div style={{ float: 'left', height: `calc(${boxHeight * screen}vw - 10px)`, width: `calc(${boxWidth * screen}vw - 10px)`, margin: '4px', border: '1px solid #333' }}>
                                <div style={{ float: 'left', height: `calc(${(boxHeight - (boxWidth > 1.25 ? .85 : .55)) * screen}vw - 25px)`, width: `calc(${(boxWidth) * screen}vw - 20px)`, margin: '4px', marginTop: '5px', border: '1px solid #333', background: 'lightblue' }}></div>
                                <div style={{ float: 'right', height: `calc(${(boxWidth > 1.25 ? .85 : .55) * screen}vw - 0px)`, width: `calc(${(boxWidth - (boxWidth > 1.25 ? .85 : .55)) * screen}vw - 26px)`, marginRight: '5px', marginBottom: '4px', border: '1px solid #333', backgroundImage: `repeating-linear-gradient(lightblue, white ${(10 / boxHeight) * screen}%, lightblue ${(10 / boxHeight) * screen}%)` }}></div>
                                <img src={Fan} style={{ float: 'left', width: (boxWidth > 1.25 ? .85 : .55) * screen + 'vw', marginLeft: '4px', marginBottom: '4px' }} />
                              </div>
                            )}

                            {fanTop === 'Top' && fanLeft === 'Right' && divider === 'Horizontal' && switchs === 'on' && (
                              <div style={{ float: 'left', height: `calc(${boxHeight * screen}vw - 10px)`, width: `calc(${boxWidth * screen}vw - 10px)`, margin: '4px', border: '1px solid #333' }}>
                                <img src={Fan} style={{ float: 'right', width: (boxWidth > 1.25 ? .85 : .55) * screen + 'vw', marginRight: '4px', marginTop: '4px' }} />
                                <div style={{ float: 'left', height: `calc(${(boxWidth > 1.25 ? .85 : .55) * screen}vw - 0px)`, width: `calc(${(boxWidth - (boxWidth > 1.25 ? .85 : .55)) * screen}vw - 26px)`, margin: '5px', border: '1px solid #333', backgroundImage: `repeating-linear-gradient(lightblue, white ${(10 / boxHeight) * screen}%, lightblue ${(10 / boxHeight) * screen}%)` }}></div>
                                <div style={{ float: 'left', height: `calc(${(boxHeight - (boxWidth > 1.25 ? .85 : .55)) * screen}vw - 25px)`, width: `calc(${(boxWidth) * screen}vw - 20px)`, margin: '4px', marginTop: '0px', border: '1px solid #333', background: 'lightblue' }}></div>
                              </div>
                            )}

                            {fanTop === 'Bottom' && fanLeft === 'Right' && divider === 'Horizontal' && switchs === 'on' && (
                              <div style={{ float: 'left', height: `calc(${boxHeight * screen}vw - 10px)`, width: `calc(${boxWidth * screen}vw - 10px)`, margin: '4px', border: '1px solid #333' }}>
                                <div style={{ float: 'left', height: `calc(${(boxHeight - (boxWidth > 1.25 ? .85 : .55)) * screen}vw - 25px)`, width: `calc(${(boxWidth) * screen}vw - 20px)`, margin: '4px', marginTop: '5px', border: '1px solid #333', background: 'lightblue' }}></div>
                                <div style={{ float: 'left', height: `calc(${(boxWidth > 1.25 ? .85 : .55) * screen}vw - 0px)`, width: `calc(${(boxWidth - (boxWidth > 1.25 ? .85 : .55)) * screen}vw - 26px)`, marginLeft: '5px', marginBottom: '4px', border: '1px solid #333', backgroundImage: `repeating-linear-gradient(lightblue, white ${(10 / boxHeight) * screen}%, lightblue ${(10 / boxHeight) * screen}%)` }}></div>
                                <img src={Fan} style={{ float: 'right', width: (boxWidth > 1.25 ? .85 : .55) * screen + 'vw', marginRight: '4px', marginBottom: '4px' }} />
                              </div>
                            )}

                            {fanTop === 'Top' && fanLeft === 'Left' && divider === 'Horizontal' && switchs === 'off' && (
                              <div style={{ float: 'left', height: `calc(${boxHeight * screen}vw - 10px)`, width: `calc(${boxWidth * screen}vw - 10px)`, margin: '4px', border: '1px solid #333' }}>
                                <img src={Fan} style={{ float: 'left', width: (boxWidth > 1.25 ? .85 : .55) * screen + 'vw', marginLeft: '4px', marginTop: '4px' }} />
                                <div style={{ float: 'right', height: `calc(${(boxWidth > 1.25 ? .85 : .55) * screen}vw - 0px)`, width: `calc(${(boxWidth - (boxWidth > 1.25 ? .85 : .55)) * screen}vw - 26px)`, margin: '5px', border: '1px solid #333', background: 'lightblue' }}></div>
                                <div style={{ float: 'left', height: `calc(${(boxHeight - (boxWidth > 1.25 ? .85 : .55)) * screen}vw - 25px)`, width: `calc(${(boxWidth) * screen}vw - 20px)`, margin: '4px', marginTop: '0px', border: '1px solid #333', backgroundImage: `repeating-linear-gradient(lightblue, white ${(10 / boxHeight) * screen}%, lightblue ${(10 / boxHeight) * screen}%)` }}></div>
                              </div>
                            )}

                            {fanTop === 'Top' && fanLeft === 'Right' && divider === 'Horizontal' && switchs === 'off' && (
                              <div style={{ float: 'left', height: `calc(${boxHeight * screen}vw - 10px)`, width: `calc(${boxWidth * screen}vw - 10px)`, margin: '4px', border: '1px solid #333' }}>
                                <img src={Fan} style={{ float: 'right', width: (boxWidth > 1.25 ? .85 : .55) * screen + 'vw', marginRight: '4px', marginTop: '4px' }} />
                                <div style={{ float: 'left', height: `calc(${(boxWidth > 1.25 ? .85 : .55) * screen}vw - 0px)`, width: `calc(${(boxWidth - (boxWidth > 1.25 ? .85 : .55)) * screen}vw - 26px)`, margin: '5px', border: '1px solid #333', background: 'lightblue' }}></div>
                                <div style={{ float: 'left', height: `calc(${(boxHeight - (boxWidth > 1.25 ? .85 : .55)) * screen}vw - 25px)`, width: `calc(${(boxWidth) * screen}vw - 20px)`, margin: '4px', marginTop: '0px', border: '1px solid #333', backgroundImage: `repeating-linear-gradient(lightblue, white ${(10 / boxHeight) * screen}%, lightblue ${(10 / boxHeight) * screen}%)` }}></div>
                              </div>
                            )}

                            {fanTop === 'Bottom' && fanLeft === 'Right' && divider === 'Horizontal' && switchs === 'off' && (
                              <div style={{ float: 'left', height: `calc(${boxHeight * screen}vw - 10px)`, width: `calc(${boxWidth * screen}vw - 10px)`, margin: '4px', border: '1px solid #333' }}>
                                <div style={{ float: 'left', height: `calc(${(boxHeight - (boxWidth > 1.25 ? .85 : .55)) * screen}vw - 25px)`, width: `calc(${(boxWidth) * screen}vw - 20px)`, margin: '4px', marginTop: '5px', border: '1px solid #333', backgroundImage: `repeating-linear-gradient(lightblue, white ${(10 / boxHeight) * screen}%, lightblue ${(10 / boxHeight) * screen}%)` }}></div>
                                <img src={Fan} style={{ float: 'right', width: (boxWidth > 1.25 ? .85 : .55) * screen + 'vw', marginRight: '4px', marginTop: '0px' }} />
                                <div style={{ float: 'left', height: `calc(${(boxWidth > 1.25 ? .85 : .55) * screen}vw - 0px)`, width: `calc(${(boxWidth - (boxWidth > 1.25 ? .85 : .55)) * screen}vw - 26px)`, marginLeft: '4px', border: '1px solid #333', background: 'lightblue' }}></div>
                              </div>
                            )}

                            {fanTop === 'Bottom' && fanLeft === 'Left' && divider === 'Horizontal' && switchs === 'off' && (
                              <div style={{ float: 'left', height: `calc(${boxHeight * screen}vw - 10px)`, width: `calc(${boxWidth * screen}vw - 10px)`, margin: '4px', border: '1px solid #333' }}>
                                <div style={{ float: 'left', height: `calc(${(boxHeight - (boxWidth > 1.25 ? .85 : .55)) * screen}vw - 25px)`, width: `calc(${(boxWidth) * screen}vw - 20px)`, margin: '4px', marginTop: '5px', border: '1px solid #333', backgroundImage: `repeating-linear-gradient(lightblue, white ${(10 / boxHeight) * screen}%, lightblue ${(10 / boxHeight) * screen}%)` }}></div>
                                <img src={Fan} style={{ float: 'left', width: (boxWidth > 1.25 ? .85 : .55) * screen + 'vw', marginLeft: '4px', marginRight: '4px' }} />
                                <div style={{ float: 'right', height: `calc(${(boxWidth > 1.25 ? .85 : .55) * screen}vw - 0px)`, width: `calc(${(boxWidth - (boxWidth > 1.25 ? .85 : .55)) * screen}vw - 26px)`, marginRight: '5px', border: '1px solid #333', background: 'lightblue' }}></div>
                              </div>
                            )}

                          </div>

                        </>

                      ) : (
                        <>
                          <div style={{ float: 'left', width: boxWidth * screen + 'vw', height: boxHeight * screen + 'vw', border: '1px solid #333' }}>
                            {switchFixed === 'on' ? (
                              <div style={{ float: 'right', height: `calc(${(boxHeight) * screen}vw - 12px)`, width: `calc(${(boxWidth) * screen}vw - 12px)`, marginRight: '5px', marginTop: '5px', background: 'lightblue', border: '1px solid #333' }}></div>
                            ) : (
                              <>
                                <div style={{ float: 'right', height: `calc(${(boxHeight) * screen}vw - 12px)`, width: `calc(${(boxWidth) * screen}vw - 12px)`, marginRight: '5px', marginTop: '5px', backgroundImage: `repeating-linear-gradient(lightblue, white ${(6 / boxHeight) * screen}%, lightblue ${(6 / boxHeight) * screen}%)`, border: '1px solid #333' }}></div>
                              </>
                            )}

                          </div>
                        </>
                      )
                    )}

                    {boxHeight === 1 && boxWidth > 1 && (

                      fan === 'on' ? (
                        <>
                          {switchFixed === 'on' && (

                            <div style={{ float: 'left', width: boxWidth * screen + 'vw', height: boxHeight * screen + 'vw', border: '1px solid #333' }}>
                              <div style={{ float: 'left', height: `calc(${boxHeight * screen}vw - 10px)`, width: `calc(${boxWidth * screen}vw - 10px)`, margin: '4px', border: '1px solid #333' }}>
                                <img src={Fan} style={{ float: 'left', width: .75 * screen + 'vw', margin: '4px', }} />
                                <div style={{ float: 'right', height: `calc(${(boxHeight) * screen}vw - 22px)`, width: `calc(${(boxWidth - .94) * screen}vw - 10px)`, marginRight: '5px', marginTop: '5px', background: 'lightblue', border: '1px solid #333' }}></div>
                              </div>
                            </div>
                          )}

                          {switchFixed === 'off' && (

                            <div style={{ float: 'left', width: boxWidth * screen + 'vw', height: boxHeight * screen + 'vw', border: '1px solid #333' }}>
                              <div style={{ float: 'left', height: `calc(${boxHeight * screen}vw - 10px)`, width: `calc(${boxWidth * screen}vw - 10px)`, margin: '4px', border: '1px solid #333' }}>
                                <img src={Fan} style={{ float: 'left', width: .75 * screen + 'vw', margin: '4px', }} />
                                <div style={{ float: 'right', height: `calc(${(boxHeight) * screen}vw - 22px)`, width: `calc(${(boxWidth - .94) * screen}vw - 10px)`, marginRight: '5px', marginTop: '5px', backgroundImage: `repeating-linear-gradient(lightblue, white ${(6 / boxHeight) * screen}%, lightblue ${(6 / boxHeight) * screen}%)`, border: '1px solid #333' }}></div>
                              </div>
                            </div>
                          )}
                        </>
                      ) : (

                        <div style={{ float: 'left', width: boxWidth * screen + 'vw', height: boxHeight * screen + 'vw', border: '1px solid #333' }}>
                          {switchFixed === 'off' ?
                            <div style={{ float: 'right', height: `calc(${(boxHeight) * screen}vw - 12px)`, width: `calc(${(boxWidth) * screen}vw - 10px)`, marginRight: '5px', marginTop: '5px', backgroundImage: `repeating-linear-gradient(lightblue, white ${(6 / boxHeight) * screen}%, lightblue ${(6 / boxHeight) * screen}%)`, border: '1px solid #333' }}></div>
                            :
                            <div style={{ float: 'right', height: `calc(${(boxHeight) * screen}vw - 12px)`, width: `calc(${(boxWidth) * screen}vw - 10px)`, marginRight: '5px', marginTop: '5px', background: 'lightblue', border: '1px solid #333' }}></div>
                          }
                        </div>

                      )

                    )}

                    {boxHeight >= 1 && boxWidth == 1 && (
                      fan === 'on' ? (
                        <>
                          {switchFixed === 'on' && (
                            <div style={{ float: 'left', width: boxWidth * screen + 'vw', height: boxHeight * screen + 'vw', border: '1px solid #333' }}>
                              <div style={{ float: 'left', height: `calc(${boxHeight * screen}vw - 10px)`, width: `calc(${boxWidth * screen}vw - 10px)`, margin: '4px', border: '1px solid #333' }}>
                                <img src={Fan} style={{ float: 'left', width: .75 * screen + 'vw', margin: '4px', }} />
                                <div style={{ float: 'right', height: `calc(${(boxHeight - .75) * screen}vw - 25px)`, width: `calc(${(boxWidth) * screen}vw - 20px)`, marginRight: '5px', background: 'lightblue', border: '1px solid #333' }}></div>
                              </div>
                            </div>
                          )}

                          {switchFixed === 'off' && (
                            <div style={{ float: 'left', width: boxWidth * screen + 'vw', height: boxHeight * screen + 'vw', border: '1px solid #333' }}>
                              <div style={{ float: 'left', height: `calc(${boxHeight * screen}vw - 10px)`, width: `calc(${boxWidth * screen}vw - 10px)`, margin: '4px', border: '1px solid #333' }}>
                                <img src={Fan} style={{ float: 'left', width: .75 * screen + 'vw', margin: '4px', }} />
                                <div style={{ float: 'right', height: `calc(${(boxHeight - .75) * screen}vw - 25px)`, width: `calc(${(boxWidth) * screen}vw - 20px)`, marginRight: '5px', backgroundImage: `repeating-linear-gradient(lightblue, white ${(6 / boxHeight) * screen}%, lightblue ${(6 / boxHeight) * screen}%)`, border: '1px solid #333' }}></div>
                              </div>
                            </div>
                          )}
                        </>
                      ) : (
                        <div style={{ float: 'left', width: boxWidth * screen + 'vw', height: boxHeight * screen + 'vw', border: '1px solid #333' }}>
                          {switchFixed === 'off' ?
                            <div style={{ float: 'right', height: `calc(${(boxHeight) * screen}vw - 12px)`, width: `calc(${(boxWidth) * screen}vw - 12px)`, marginRight: '5px', marginTop: '5px', backgroundImage: `repeating-linear-gradient(lightblue, white ${(6 / boxHeight) * screen}%, lightblue ${(6 / boxHeight) * screen}%)`, border: '1px solid #333' }}></div>
                            :
                            <div style={{ float: 'right', height: `calc(${(boxHeight) * screen}vw - 12px)`, width: `calc(${(boxWidth) * screen}vw - 12px)`, marginRight: '5px', marginTop: '5px', background: 'lightblue', border: '1px solid #333' }}></div>
                          }
                        </div>
                      )
                    )}

                    {(boxWidth > 1 || boxHeight > 1) && (
                      <>
                        <div className="expanding-arrow-line" style={{
                          position: 'absolute',
                          float: 'left',
                          width: `calc(${boxWidth * screen}vw )`,
                          bottom: '-10px',
                          top: `calc(${boxHeight * screen}vw - 25px)`,
                        }}>
                          <div className="arrow-left"></div>
                          <div className="line" align="center">
                            <div style={{ marginTop: '-7px', background: 'white', maxWidth: '20px', textAlign: 'center' }}><p>{typeof boxWidth === 'number' ? boxWidth.toFixed(2) : boxWidth}</p></div>
                          </div>
                          <div className="arrow-right"></div>
                        </div>

                        <div style={{ width: '30px', float: 'left', paddingLeft: '15px', textAlign: 'left' }}>
                          <div className="expanding-arrow-line" style={{ float: 'left', height: `calc(${boxHeight * screen}vw)`, top: '0', position: 'relative' }}>
                            <div className="arrow-up" style={{ borderBottom: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', top: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
                            <div className="line" style={{ width: '1px', height: '100%', marginLeft: '0px', position: 'absolute', top: '0' }}>
                              <div style={{ marginLeft: '-10px', marginTop: boxHeight * (screen / 2) + 'vw', background: 'white', maxWidth: '50px', textAlign: 'center' }}><p style={{ width: '20px' }}>{typeof boxHeight === 'number' ? boxHeight.toFixed(2) : boxHeight}</p></div>
                            </div>
                            <div className="arrow-down" style={{ borderTop: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', bottom: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
                          </div>
                        </div>
                      </>
                    )}


                  </div>
                </div>
              </div>

              {numSecondInnerBoxes === 0 ? (<div></div>) : (
                <>
                  <div class="col-md-12 col-xxl-3 col-xl-3">
                    <div class="card">
                      <div class="card-body">
                        <div class="d-flex align-items-center">
                          <div class="flex-grow-1 ms-3">
                            <h6 class="mb-0">Area</h6>
                          </div>
                        </div>
                        <div class="bg-body p-3 mt-3 rounded">
                          <div class="mt-3 row align-items-center">
                            <div class="col-12">
                              <h6 class="mb-1">{frameArea * qty} sqft</h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>



                  <div class="col-md-12  col-xxl-3 col-xl-3">
                    <div class="card">
                      <div class="card-body">
                        <div class="d-flex align-items-center">
                          <div class="flex-grow-1 ms-3">
                            <h6 class="mb-0">Mullions</h6>
                          </div>
                        </div>
                        <div class="bg-body p-3 mt-3 rounded">
                          <div class="mt-3 row align-items-center">
                            <div class="col-12">
                              <h6 class="mb-1">{newHeights === 0 ? 0 : 1}</h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-12  col-xxl-3 col-xl-3">
                    <div class="card">
                      <div class="card-body">
                        <div class="d-flex align-items-center">
                          <div class="flex-grow-1 ms-3">
                            <h6 class="mb-0">Total</h6>
                          </div>
                        </div>
                        <div class="bg-body p-3 mt-3 rounded">
                          <div class="mt-3 row align-items-center">
                            <div class="col-12">
                              <h6 class="mb-1">₹ {numeral(grandTotal * qty).format('0,0.00')}</h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-12 c col-xxl-3 col-xl-3">
                    <div class="card">
                      <div class="card-body">
                        <div class="d-flex align-items-center">
                          <div class="flex-grow-1 ms-3">
                            <h6 class="mb-0">Rate/Sqft</h6>
                          </div>
                        </div>
                        <div class="bg-body p-3 mt-3 rounded">
                          <div class="mt-3 row align-items-center">
                            <div class="col-12">
                              <h6 class="mb-1">₹ {numeral(grandTotal / frameArea).format('0,0.00')}</h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="col-md-12 col-xxl-12">
                    <div class="card">
                      <div class="card-header">
                        <h5>Profile Cost</h5>
                      </div>
                      <div class="card-body">
                        <table id="left-right-fix" class="table stripe row-border order-column">
                          <thead>
                            <tr>
                              <th>Product Description</th>
                              <th width="10%">Req. Qty (Feet)</th>
                              <th width="10%">Kg/Feet</th>
                              <th width="10%">Req. Qty (Kg)</th>
                              <th width="10%">Rate/Kg</th>
                              <th width="10%">Amount</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Outer Frame</td>
                              <td>{numeral(Frame()).format('0,0.00')}</td>
                              <td>{numeral(values.frameCost.wpf).format('0,0.00')}</td>
                              <td>{numeral(values.frameCost.rw).format('0,0.00')}</td>
                              <td>{numeral(values.frameCost.rpf).format('0,0.00')}</td>
                              <td>{numeral(values.frameCost.amount).format('0,0.00')}</td>
                            </tr>
                            {Mullion() > 0 &&
                              <tr>
                                <td>Mullion</td>
                                <td>{numeral(Mullion() * qty).format('0,0.00')}</td>
                                <td>{numeral(values.mullionCost.wpf * qty).format('0,0.00')}</td>
                                <td>{numeral(values.mullionCost.rw * qty).format('0,0.00')}</td>
                                <td>{numeral(values.mullionCost.rpf * qty).format('0,0.00')}</td>
                                <td>{numeral(values.mullionCost.amount * qty).format('0,0.00')}</td>
                              </tr>
                            }
                            <tr>
                              <td>Beeding</td>

                              <td>{numeral(Beeding() * qty).format('0,0.00')}</td>
                              <td>{numeral(values.beeding.wpf * qty).format('0,0.00')}</td>
                              <td>{numeral(values.beeding.rw * qty).format('0,0.00')}</td>
                              <td>{numeral(values.beeding.rpf * qty).format('0,0.00')}</td>
                              <td>{numeral(values.beeding.amount * qty).format('0,0.00')}</td>
                            </tr>
                            {louverBeeding() > 0 &&
                              <tr>
                                <td>Louver Beeding</td>

                                <td>{numeral(louverBeeding() * qty).format('0,0.00')}</td>
                                <td>{numeral(values.lovBeeding.wpf * qty).format('0,0.00')}</td>
                                <td>{numeral(values.lovBeeding.rw * qty).format('0,0.00')}</td>
                                <td>{numeral(values.lovBeeding.rpf * qty).format('0,0.00')}</td>
                                <td>{numeral(values.lovBeeding.amount * qty).format('0,0.00')}</td>
                              </tr>
                            }
                            <tr>
                              <td>Frame Gasket</td>
                              <td>{numeral(frameGasket() * qty).format('0,0.00')}</td>
                              <td>{numeral(values.frameGasket.wpf * qty).format('0,0.00')}</td>
                              <td>{numeral(values.frameGasket.rw * qty).format('0,0.00')}</td>
                              <td>{numeral(values.frameGasket.rpf * qty).format('0,0.00')}</td>
                              <td>{numeral(values.frameGasket.amount * qty).format('0,0.00')}</td>
                            </tr>
                            <tr>
                              <td>Beeding Gasket</td>
                              <td>{numeral(beedingGasket() * qty).format('0,0.00')}</td>
                              <td>{numeral(values.beedingGasket.wpf * qty).format('0,0.00')}</td>
                              <td>{numeral(values.beedingGasket.rw * qty).format('0,0.00')}</td>
                              <td>{numeral(values.beedingGasket.rpf * qty).format('0,0.00')}</td>
                              <td>{numeral(values.beedingGasket.amount * qty).format('0,0.00')}</td>
                            </tr>
                            <tr>
                              <td colspan="5">Total</td>
                              <td>{numeral(frameTotal).format('0,0.00')}</td>
                            </tr>
                            <tr>
                              <th colspan="5">Wastage ( {data.wastage}% )</th>
                              <th>{numeral(frameTotal + wastage).format('0,0.00')}</th>
                            </tr>
                            <tr>
                              <th colspan="5">Profile Cost/Sqft</th>
                              <th>{numeral(frameTotal + wastage).format('0,0.00')}</th>
                            </tr>


                          </tbody>
                        </table>
                      </div>
                      <hr />

                      <div class="card-header">
                        <h5>Hardware Cost</h5>
                      </div>
                      <div class="card-body">
                        <table id="left-right-fix" class="table stripe row-border order-column">
                          <thead>
                            <tr>
                              <th>Product Description</th>
                              <th width="10%">Req. Qty </th>
                              <th width="10%">Price</th>
                              <th width="10%">Amount</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Steel Frame</td>

                              <td>{numeral(values.steelFrame.qty * qty).format('0,0.00')}</td>
                              <td>{numeral(values.steelFrame.price * qty).format('0,0.00')}</td>
                              <td>{numeral(values.steelFrame.amount * qty).format('0,0.00')}</td>
                            </tr>
                            {Mullion() > 0 &&
                              <tr>
                                <td>Steel Mullion</td>
                                <td>{numeral(values.steelMullion.qty * qty).format('0,0.00')}</td>
                                <td>{numeral(values.steelMullion.price * qty).format('0,0.00')}</td>
                                <td>{numeral(values.steelMullion.amount * qty).format('0,0.00')}</td>
                              </tr>
                            }

                            <tr>
                              <td>Screws</td>
                              <td>{numeral(values.screws.qty).format('0,0.00')}</td>
                              <td>{numeral(values.screws.price).format('0,0.00')}</td>
                              <td>{numeral(values.screws.amount).format('0,0.00')}</td>
                            </tr>
                            {Glass() > 0 &&
                              <tr>
                                <td>Glass</td>
                                <td>{numeral(values.glass.qty).format('0,0.00')}</td>
                                <td>{numeral(values.glass.price).format('0,0.00')}</td>
                                <td>{numeral(values.glass.amount).format('0,0.00')}</td>
                              </tr>
                            }
                            {louverBeeding() > 0 &&

                              <tr>
                                <td>Louver Blades</td>
                                <td>{numeral(values.LouverBlades.qty).format('0,0.00')}</td>
                                <td>{numeral(values.LouverBlades.price).format('0,0.00')}</td>
                                <td>{numeral(values.LouverBlades.amount).format('0,0.00')}</td>
                              </tr>
                            }
                            <tr>
                              <th colspan="3">Total</th>
                              <th>{numeral(hardTotal).format('0,0.00')}</th>
                            </tr>
                            <tr>
                              <th colspan="3">Hardware Cost/Sqft</th>
                              <th>{numeral(hardTotal / frameArea).format('0,0.00')}</th>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      {userType != '3' && (<>
                        <hr />

                        <div class="card-header ">
                          <h5>Margin Cost</h5>
                        </div>
                        <div class="card-body">
                          <table id="left-right-fix" class="table stripe row-border order-column">
                            <thead>
                              <tr>
                                <th>Product Description</th>
                                <th width="10%">Req. Qty </th>
                                <th width="10%">Price</th>
                                <th width="10%">Amount</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>Margin</td>
                                <td>{numeral(values.margin.qty).format('0,0.00')}</td>
                                <td>{numeral(values.margin.price).format('0,0.00')}</td>
                                <td>{numeral(values.margin.amount).format('0,0.00')}</td>
                              </tr>
                              <tr>
                                <th colspan="3">Total</th>
                                <th>{numeral(marginTotal).format('0,0.00')}</th>
                              </tr>
                              <tr>
                                <th colspan="3">Margin Cost/Sqft</th>
                                <th>{numeral(marginTotal / frameArea).format('0,0.00')}</th>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <hr />

                        <div class="card-header">
                          <h5>Production Cost</h5>
                        </div>
                        <div class="card-body">
                          <table id="left-right-fix" class="table stripe row-border order-column">
                            <thead>
                              <tr>
                                <th>Product Description</th>
                                <th width="10%">Req. Qty </th>
                                <th width="10%">Price</th>
                                <th width="10%">Amount</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>Markting OH</td>
                                <td>{numeral(values.marketing.qty).format('0,0.00')}</td>
                                <td>{numeral(values.marketing.price).format('0,0.00')}</td>
                                <td>{numeral(values.marketing.amount).format('0,0.00')}</td>
                              </tr>
                              <tr>
                                <td>Transport</td>
                                <td>{numeral(values.transport.qty).format('0,0.00')}</td>
                                <td>{numeral(values.transport.price).format('0,0.00')}</td>
                                <td>{numeral(values.transport.amount).format('0,0.00')}</td>
                              </tr>
                              <tr>
                                <td>Labour (Factory)</td>
                                <td>{numeral(values.labourF.qty).format('0,0.00')}</td>
                                <td>{numeral(values.labourF.price).format('0,0.00')}</td>
                                <td>{numeral(values.labourF.amount).format('0,0.00')}</td>
                              </tr>
                              <tr>
                                <td>Labour (Site)</td>
                                <td>{numeral(values.labourS.qty).format('0,0.00')}</td>
                                <td>{numeral(values.labourS.price).format('0,0.00')}</td>
                                <td>{numeral(values.labourS.amount).format('0,0.00')}</td>
                              </tr>

                              <tr>
                                <th colspan="3">Total</th>
                                <th>{numeral(prodTotal).format('0,0.00')}</th>
                              </tr>
                              <tr>
                                <th colspan="3">Production Cost/Sqft</th>
                                <th>{numeral(prodTotal / frameArea).format('0,0.00')}</th>
                              </tr>

                            </tbody>
                          </table>
                        </div>
                      </>
                      )}



                    </div>
                  </div>


                </>
              )}



            </div>
          </div>
        </div>


      )}

    </div>

  )
}
export default Quotees;
